import {
  Box, Center,
  Flex,
  Image
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import React from 'react';

export default function TopBar() {
  return (
    <>
      <Box bg={'#393A3B'} px={4} h={20}>
          <Center color={"white"}>
            <Image src={Logo} w={[56, 60, 64, 72]} />
          </Center>
      </Box>
    </>
  );
}
