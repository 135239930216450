import Resource from 'api/resource';
import http from 'utils/http';

class PlantResource extends Resource {
  constructor() {
    super('plants');
  }

  bulkDelete(resource: any) {
    return http({
      url: '/bulk-destroy/plants',
      method: 'post',
      data: resource,
    });
  }

  relatedAfe(id: string, query?: any) {
    return http({
      url: `/plants/${id}/afe`,
      method: 'get',
      params: query,
    });
  }

  relatedBusinessUnit(id: string, query?: any) {
    return http({
      url: `/plants/${id}/business-unit`,
      method: 'get',
      params: query,
    });
  }

  relatedOperation(id: string, query?: any) {
    return http({
      url: `/plants/${id}/operation`,
      method: 'get',
      params: query,
    });
  }

  relatedShift(id: string, query?: any) {
    return http({
      url: `/plants/${id}/shift`,
      method: 'get',
      params: query,
    });
  }
}

export { PlantResource as default };
