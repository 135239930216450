import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ProductResource from 'api/product';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ProductForm from 'components/product/ProductForm';
import routes from 'constants/routes';
import { ProductSchema } from 'interface/product/productSchema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const EditProduct: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<any>();
  const methods = useForm<ProductSchema>();

  const api = new ProductResource();

  const productQuery = useQuery(
    [`product${id}-edit`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateProduct = useMutation((data: any) => api.update(id, data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Product has been updated.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('productList');
      navigate(routes.manage.products.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Product Update Failed.');
    },
  });

  const onSubmit = (data: any) => {
    const formData = {
      name: data?.name,
      product_code: data?.product_code,
      class_code: data?.class_code,
      product_group: data?.product_group?.value,
      price: data?.price,
    };
    updateProduct.mutate(formData);
  };

  if (productQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (productQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {'Product Not Found.'}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">{'Edit Product'}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateProduct.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <ProductForm productData={productQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateProduct.isLoading}>
                  Update
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditProduct;
