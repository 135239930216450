import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import ProductResource from 'api/product';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import { ProductSchema } from 'interface/product/productSchema';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { formatNumber } from 'utils';

const ViewProduct: React.FC = () => {
  const { id }: any = useParams();
  const api = new ProductResource();
  const navigate = useNavigate();

  const dataQuery = useQuery<ProductSchema>(
    [`product${id}`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.manage.products.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            Product Details
          </Heading>
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() =>
              navigate(routes.manage.products.edit.replace(':id', id))
            }>
            {'Edit Product'}
          </Button>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Product</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Product Code</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.product_code || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Class Code</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.class_code || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Product Group</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.product_group?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Rack Price</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.price &&
                    formatNumber(+dataQuery?.data?.price, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewProduct;
