export function api_store_mechanical_report_back_online(originalData: any) {
    return {
        report_date: originalData.report_date,
        shift_start: {
            date: originalData.shift_start?.date,
            time: originalData.shift_start?.time
        },
        shift_end: {
            date: originalData.shift_end?.date,
            time: originalData.shift_end?.time
        },
        op_hours: originalData.op_hours,
        rain: originalData.rain || false,
        injury: originalData.injury || false,
        damage: originalData.damage || false,
        afe: originalData.afe,
        plant: originalData.plant,
        pit: originalData.pit,
        business_unit: originalData.business_unit,
        operation: originalData.operation,
        shift: originalData.shift,
        manager: originalData.manager,
        foreman: originalData.foreman,
        labors: originalData.labors.map((labor: any) => ({
            labor: labor.labor?.id,
            clock_in: {
                date: labor.clock_in?.date,
                time: labor.clock_in?.time
            },
            clock_out: {
                date: labor.clock_out?.date,
                time: labor.clock_out?.time
            },
            note: labor.note,
            activities: (labor.activities || []).map((activity: any) => ({
                afe: activity.afe,
                activity: activity.activity,
                repaired_equipment: activity.repaired_equipment,
                used_equipment: activity.used_equipment,
                hours: activity.hours
            }))
        })),
        assigned_equipments: (originalData.assigned_equipment || []).map((eq: any) => ({
            equipment: eq.equipment?.id,
            op_hour: eq.op_hour,
            note: eq.note
        })),
        purchase_rental_reports: (originalData.purchase || []).map((purchase: any) => ({
            ownership_type: purchase.ownership_type?.id,
            vendor: purchase.vendor?.id,
            equipment: purchase.equipment?.id,
            estimate_cost: purchase.estimate_cost,
            description: purchase.description,
            pmt_type: purchase.pmt_type?.id
        })),
        equipment_status_detail: (originalData.equipment || []).map((equipment: any) => ({
            type: equipment.type?.id,
            vendor: equipment.vendor?.id,
            equipment: equipment.equipment?.id,
            cost_estimate: equipment.cost_estimate
        })),
        notes: (originalData.notes || []).map((note: any) => ({
            note: note.note
        }))
    };
}
export function api_store_foreman_report_back_online(originalData: any) {
    return {
        report_date: originalData.report_date,
        shift_start: originalData.shift_start,
        shift_end: originalData.shift_end,
        op_hours: originalData.op_hours,
        injury: originalData.injury || false,
        rain: originalData.rain || false,
        damage: originalData.damage || false,
        afe: originalData.afe,
        plant: originalData.plant,
        pit: originalData.pit,
        business_unit: originalData.business_unit,
        operation: originalData.operation,
        shift: originalData.shift,
        manager: originalData.manager,
        foreman: originalData.foreman,
        labors: originalData.labors.map((labor: any) => ({
            labor: labor.labor.id,
            clock_in: labor.clock_in,
            clock_out: labor.clock_out,
            activities: (labor.activities || []).map((activity: any) => ({
                afe: activity.afe,
                activity: activity.activity,
                equipment: activity.equipment,
                business_unit: activity.business_unit,
                operation: activity.operation,
                hours: activity.hours
            }))
        })),
        other_equipments: (originalData.capital_equipment || []).map((eq: any) => ({
            equipment: eq.equipment.id,
            op_hour: eq.op_hour,
            op_rate_per_hour: eq.op_rate_per_hour,
            idle_hours: eq.idle_hours,
            business_unit: eq.business_unit.id,
            operation: eq.operation.id,
            note: eq.note
        })),
        shift_downtimes: (originalData.shift_downtime || []).map((downtime: any) => ({
            hours: downtime.hours,
            downtime: downtime.downtime.id
        })),
        operational_downtimes: (originalData.operation_downtime || []).map((downtime: any) => ({
            hours: downtime.hours,
            downtime: downtime.downtime.id,
            equipment: downtime.equipment.id
        })),
        purchase_rental_reports: (originalData.purchase || []).map((purchase: any) => ({
            ownership_type: purchase.ownership_type.id,
            vendor: purchase.vendor.id,
            equipment: purchase.equipment.id,
            estimate_cost: purchase.estimate_cost,
            description: purchase.description,
            pmt_type: purchase.pmt_type.id
        })),
        productions: (originalData.products || []).map((product: any) => ({
            split: product.split,
            pay: product.pay || false,
            product: product.product.id,
            business_unit: product.business_unit.id,
            operation: product.operation.id
        })),
        fuel_usage: (originalData.fuel_usage || []).map((fuel: any) => ({
            equipment: fuel.equipment.id,
            gallons: fuel.gallons,
            fuel: fuel.fuel.id,
            note: fuel.note
        })),
        notes: (originalData.notes || []).map((note: any) => ({
            note: note.note
        }))
    };
}
