import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import SaleResource from 'api/sale';
import routes from 'constants/routes';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { formatNumber } from 'utils';

const SaleListItem: React.FC<any> = (props) => {
  const { listData, setSaleIds, saleIds } = props;
  const navigate = useNavigate();
  const api = new SaleResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleCheckBox = () => {
    const index = saleIds.indexOf(listData.id);
    if (index > -1) {
      setSaleIds([...saleIds.slice(0, index), ...saleIds.slice(index + 1)]);
    } else {
      setSaleIds([...saleIds, listData.id]);
    }
  };

  const deleteData = useMutation(() => api.destroy(listData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('saleList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: 'Sale has been deleted.',
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  return (
    <Tr key={listData.id}>
      <Td>
        <Checkbox
          isChecked={saleIds.includes(listData.id)}
          onChange={handleCheckBox}></Checkbox>
      </Td>
      <Td _hover={{ cursor: 'pointer' }} key={listData.id}>
        {listData?.id}
      </Td>
      <Td
        _hover={{ cursor: 'pointer' }}
        key={listData.id}
        onClick={() =>
          navigate(routes.sales.view.replace(':id', listData.id.toString()))
        }>
        {listData?.ticket_number}
      </Td>
      <Td>{listData?.invoice_number}</Td>
      <Td>{listData?.invoice_date}</Td>
      <Td>{listData?.pit?.name}</Td>
      <Td>{listData?.product?.name}</Td>
      <Td>{listData?.customer_name}</Td>
      <Td>{dayjs(listData.date).format('ddd')}</Td>
      <Td>{listData.date}</Td>
      <Td>{listData.job_location}</Td>
      <Td>{listData.loader_scale_operator}</Td>
      <Td>{formatNumber(+listData.gross_wt_tons, 2)}</Td>
      <Td>{formatNumber(+listData.tare_wt_tons, 2)}</Td>
      <Td>{formatNumber(+listData.net_wt_tons, 2)}</Td>
      <Td>{formatNumber(+listData.price_per_ton, 2)}</Td>
      <Td>{formatNumber(+listData.delivery_point, 2)}</Td>
      <Td>{formatNumber(+listData.sales_tax_rate, 2)}</Td>
      <Td>{formatNumber(+listData.trucking_cost_ton, 2)}</Td>
      <Td>{formatNumber(+listData.trucking_price_ton, 2)}</Td>
      <Td>{formatNumber(+listData.trucking_revenue_ton, 2)}</Td>
      <Td>{formatNumber(+listData.trucking_margin_ton, 2)}</Td>
      <Td>{listData?.status}</Td>
      <Td>
        <Link to={routes.sales.view.replace(':id', listData?.id.toString())}>
          <Tooltip hasArrow label={'View'}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={'View'}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </Link>
        <Link to={routes.sales.edit.replace(':id', listData?.id.toString())}>
          <Tooltip hasArrow label={'Edit'}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={'Edit'}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </Link>

        <Tooltip hasArrow label={'Delete'}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={'Delete'}
            color="red.300"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Sale'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete this ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default SaleListItem;
