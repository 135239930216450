import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import ProductResource from 'api/product';
import ProductForm from 'components/product/ProductForm';
import routes from 'constants/routes';
import { ProductSchema } from 'interface/product/productSchema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const CreateProduct: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const navigate = useNavigate();
  const methods = useForm<ProductSchema>();
  const api = new ProductResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addProduct = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Product has been created.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('productList');
      navigate(routes.manage.products.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Product Creation Failed.');
    },
  });

  const onSubmit = (data: any) => {
    const formData = {
      name: data?.name,
      product_code: data?.product_code,
      class_code: data?.class_code,
      product_group: data?.product_group?.value,
      price: data?.price,
    };
    addProduct.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">Add Product</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addProduct.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <ProductForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addProduct.isLoading}>
                  Add Product
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateProduct;
