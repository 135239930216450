import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import BusinessUnitResource from 'api/businessUnit';
import OperationResource from 'api/operation';
import PitResource from 'api/pit';
import ShiftResource from 'api/shift';
import UserResource from 'api/user';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { PlantListSchemaType, PlantSchema } from 'interface/plant/plantSchema';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const PlantForm: React.FC<PlantListSchemaType> = (props) => {
  const { plantData } = props;
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<PlantSchema>();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<any>([]);
  const [selectedOperation, setSelectedOperation] = useState<any>([]);
  const [selectedShift, setSelectedShift] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  // Pit List
  const pitAPI = new PitResource();
  const { data: pitQuery, isLoading: isPitLoading } = useQuery(
    [`pit-list`],
    () => pitAPI.list({ limit: 50 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const pitList = pitQuery?.results;
  const pitOptions = pitList?.map((pit: any) => ({
    label: pit.name,
    value: pit.id,
  }));

  // Business Unit List
  const businessUnitAPI = new BusinessUnitResource();
  const businessUnitQuery = useQuery([`getAllBusinessUnit`], async () => {
    const response = await businessUnitAPI.list();
    return response?.data;
  });

  // Operation List
  const operationAPI = new OperationResource();
  const operationQuery = useQuery([`getAllOperation`], async () => {
    const response = await operationAPI.list();
    return response?.data;
  });

  // Shift List
  const shiftAPI = new ShiftResource();
  const shiftQuery = useQuery([`getAllShift`], async () => {
    const response = await shiftAPI.list();
    return response?.data;
  });

  // Users List
  const usersAPI = new UserResource();
  const usersQuery = useQuery([`getAllUser`], async () => {
    const response = await usersAPI.list();
    return response?.data;
  });

  useEffect(() => {
    if (!plantData) return;
    const pit: any = {
      label: plantData?.pit?.name,
      value: plantData?.pit?.id,
    };
    setValue('pit', pit);
    const businessUnit = plantData.business_unit;
    setSelectedBusinessUnit(businessUnit);
    const operation = plantData.operation;
    setSelectedOperation(operation);
    const shift = plantData.shift;
    setSelectedShift(shift);
    const users = plantData.users;
    setSelectedUsers(users);
  }, [plantData, setValue]);

  useEffect(() => {
    setValue('business_unit', selectedBusinessUnit);
    setValue('operation', selectedOperation);
    setValue('shift', selectedShift);
    setValue('users', selectedUsers);
  }, [
    selectedBusinessUnit,
    selectedOperation,
    selectedShift,
    selectedUsers,
    setValue,
  ]);

  const mapUserToUserOption = (user: any) => {
    return {
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
    };
  };

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.name} isRequired>
            <FormLabel>Plant</FormLabel>
            <Input
              {...register('name', {
                required: 'Plant',
                maxLength: {
                  value: 100,
                  message: 'Too many characters.',
                },
              })}
              type="text"
              defaultValue={plantData?.name}
              placeholder={'Plant'}
            />
            <FormErrorMessage>
              {errors?.name && errors?.name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.msha_identification}>
            <FormLabel>MSHA Identification</FormLabel>
            <Input
              {...register('msha_identification', {
                required: false,
                minLength: {
                  value: 1,
                  message: 'Too few characters.',
                },
                maxLength: {
                  value: 32,
                  message: 'Too many characters.',
                },
              })}
              type="text"
              defaultValue={plantData?.msha_identification}
              placeholder={'MSHA Identification'}
            />
            <FormErrorMessage>
              {errors?.msha_identification &&
                errors?.msha_identification?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.pit} isRequired>
            <FormLabel>Pit</FormLabel>
            <Controller
              control={control}
              name="pit"
              rules={{
                required: 'Pit',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="pit"
                  placeholder={'Pit'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isPitLoading}
                  options={pitOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  menuPortalTarget={document.body}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.pit && errors?.pit?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        {/* <GridItem>
          <FormControl isInvalid={!!errors?.pit} isRequired>
            <FormLabel>Business Unit</FormLabel>
            <MulitSelectAll
              options={businessUnitQuery?.data?.results}
              placeholder={'Business Unit'}
              arrayValue={selectedBusinessUnit}
              setArrayValue={setSelectedBusinessUnit}
            />
            <FormErrorMessage>
              {errors?.business_unit && errors?.business_unit?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.operation} isRequired>
            <FormLabel>Operation</FormLabel>
            <MulitSelectAll
              options={operationQuery?.data?.results}
              placeholder={'Operation'}
              arrayValue={selectedOperation}
              setArrayValue={setSelectedOperation}
            />
            <FormErrorMessage>
              {errors?.operation && errors?.operation?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.shift} isRequired>
            <FormLabel>Shift</FormLabel>
            <MulitSelectAll
              options={shiftQuery?.data?.results}
              placeholder={'Shift'}
              arrayValue={selectedShift}
              setArrayValue={setSelectedShift}
            />
            <FormErrorMessage>
              {errors?.shift && errors?.shift?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem> */}
      </Grid>
    </Stack>
  );
};

export default PlantForm;
