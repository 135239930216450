import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import NoteReportResource from 'api/noteReport';
import { CenterSpinner } from 'components/common/CenterSpinner';
import TagModal from 'components/common/TagModal';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useDebounceFunc from 'hooks/useDebounceFunc';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from "uuid";

interface Props {
  title?: string;
  reportId: string;
}

const NotesReport: React.FC<Props> = (props) => {
  const { setReportBody, updateQueue, reportBody } = useContext(OfflineContext);
  const [offlineNotesFilteredByPreviousReportId, setOfflineNotesFilteredByPreviousReportId] = useState<any>([]);
  const isOnline = useConnectionStatus();

  const { title = 'Notes', reportId } = props;
  const [openIndex, setOpenIndex] = useState(0);
  const api = new NoteReportResource();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [noteData, setNoteData] = useState<any>([]);
  const [noteDataLength, setNoteDataLength] = useState<number>(0);
  const containerRef = useRef<any>();
  const [tagData, setTagData] = useState(0);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const methods = useForm<any>();
  const [addErrMsg, setAddErrMsg] = useState<any>();
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);

  const noteQuery = useQuery(
    [`getNoteStatus`],
    async () => {
      const response = await api.getNoteTag(reportId);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: noteListQuery } = useQuery(
    [`foreman-note-list`],
    () =>
      api
        .listNoteReport(reportId, {
          limit: 1000,
        })
        .then((res) => {
          return res?.data?.results;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const handleToggle = (index: number) => {
    setOpenIndex(index);
    let display = document.querySelector<HTMLElement>(`#note${index}`)!.style
      .display;
    if (display === '') display = 'none';
    document.querySelector<HTMLElement>(`#note${index}`)!.style.display =
      display === 'none' ? 'block' : 'none';
  };

  const handleDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
    queryClient.invalidateQueries('foreman-note-list');
    queryClient.invalidateQueries('getNoteStatus');
  };

  const deleteData = useMutation(
    (noteId: number) => api.destroyNoteReport(reportId, noteId + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('foreman-note-list');
        queryClient.invalidateQueries('getNoteStatus');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (isOnline) {
      if (id != 0) deleteData.mutate(id);
    } else {
      setOfflineNotesFilteredByPreviousReportId((prev: any) => prev.filter((r: any) => r.id !== id))
    }
    onDeleteModalClose();
  };

  const addData = useMutation(
    (data: any) => api.storeNoteReport(reportId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('foreman-note-list');
        queryClient.invalidateQueries('getNoteStatus');
        setOpenIndex(1);
        setAddModalOpen(false);
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      note: data?.note,
    };
    if (isOnline) {
      addData.mutate(formData);
    } else {
      setOfflineNotesFilteredByPreviousReportId((prev: any) => ([...prev, {
        ...formData,
        note: data?.note,
        id: uuid()
      }]))
    }
    onAddModalClose();
  };

  const updateData = useMutation(
    (data: any) =>
      api.updateNoteReport(reportId, data?.noteId, { note: data?.body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('foreman-note-list');
        queryClient.invalidateQueries('getNoteStatus');
      },
    }
  );

  const handleTextArea = (noteId: string, body: string) => {
    if (!noteId) return;
    const data = {
      noteId: noteId,
      body: body,
    };
    updateData.mutate(data);
  };

  const debounce = useDebounceFunc(handleTextArea, 3000);

  const notesList = useMemo(() => isOnline
    ? noteListQuery?.length > 0
      ? noteListQuery
      : []
    : offlineNotesFilteredByPreviousReportId, [offlineNotesFilteredByPreviousReportId, noteListQuery])

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/notes')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev, notes: offlineNotesFilteredByPreviousReportId
    }))
    // update the queue
    updateQueue(reportBody.id, { ...reportBody, notes: offlineNotesFilteredByPreviousReportId })
  }, [offlineNotesFilteredByPreviousReportId])

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('foreman-note-list');
    queryClient.invalidateQueries('getEquipmentStatus');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">{title}</Heading>
          <Tooltip hasArrow label={'Tag'}>
            <IconButton
              icon={<BsTag />}
              variant="link"
              aria-label={'Tag'}
              color={'gray.300'}
              size="lg"
              onClick={() => handleTagModal(noteQuery?.data, 'tagStatus')}
            />
          </Tooltip>
          {tagData === noteQuery?.data?.tag && (
            <TagModal
              isModalOpen={tagModalOpen}
              setModalOpen={setTagModalOpen}
              fieldData={noteQuery?.data?.tag}
              onModalSubmit={() => onTagModalSubmit()}
            />
          )}
        </Flex>
        <Divider />
        {/* {!isNoteListLoading && */}
        {notesList?.map((note: any, index: number) => {
          let indexNumber = index + 1;
          return (
            <Stack
              key={note?.id}
              direction="column"
              p={['3', '6']}
              spacing="4"
              border={'1px solid #C5C6C9'}>
              <Flex justify="space-between">
                <Stack direction="row">
                  <Tooltip
                    hasArrow
                    label={openIndex === indexNumber ? 'Down' : 'Up'}>
                    <IconButton
                      icon={
                        openIndex === indexNumber ? (
                          <TriangleUpIcon />
                        ) : (
                          <TriangleDownIcon />
                        )
                      }
                      variant="link"
                      aria-label={'Up'}
                      color="gray.300"
                      size="lg"
                      onClick={() => handleToggle(indexNumber)}
                    />
                  </Tooltip>
                  <Heading size="md">{`Note ${indexNumber}`}</Heading>
                </Stack>

                <Stack direction="row">
                  <Tooltip hasArrow label={'Tag'}>
                    <IconButton
                      icon={<BsTag />}
                      variant="link"
                      aria-label={'Tag'}
                      color={'gray.300'}
                      size="lg"
                      onClick={() => handleTagModal(note, 'tagDetail')}
                    />
                  </Tooltip>
                  {tagData === note?.tag && (
                    <TagModal
                      isModalOpen={tagDetailModalOpen}
                      setModalOpen={setTagDetailModalOpen}
                      fieldData={tagData}
                      onModalSubmit={() => onTagModalSubmit()}
                    />
                  )}
                  <Tooltip hasArrow label={'Delete'}>
                    <IconButton
                      icon={<BsTrash />}
                      variant="link"
                      aria-label={'Delete'}
                      color="gray.300"
                      size="lg"
                      onClick={() => handleDelete(note?.id)}
                    />
                  </Tooltip>
                </Stack>
              </Flex>
              <Modal
                isOpen={isDeleteModalOpen}
                isCentered
                onClose={onDeleteModalClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{'Delete Note'}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {'Are you sure you want to delete this ?'}
                    {deleteMsg && (
                      <Alert status="error" mb={2}>
                        <AlertIcon />
                        {deleteMsg}
                      </Alert>
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button
                        colorScheme="red"
                        onClick={() => onDeleteConfirm(confirmDeleteId)}
                        isLoading={deleteData.isLoading}>
                        {'Delete'}
                      </Button>

                      <Button variant="outline" onClick={onDeleteModalClose}>
                        {'Cancel'}
                      </Button>
                    </ButtonGroup>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <Textarea
                placeholder="Enter notes here"
                id={`note${indexNumber}`}
                defaultValue={note?.note || ''}
                onChange={(event: any) =>
                  debounce(note?.id, event.target.value)
                }
                style={{
                  display: openIndex === indexNumber ? 'block' : 'none',
                }}></Textarea>
              {isOnline && !noteListQuery && <CenterSpinner type="normal" />}
            </Stack>
          );
        })}

        {/* Add Modal */}
        <Modal
          isOpen={isAddModalOpen}
          isCentered
          onClose={onAddModalClose}
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Add Note'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack direction="column" spacing="4">
                    {addErrMsg && (
                      <Alert status="error">
                        <AlertIcon />
                        {addErrMsg}
                      </Alert>
                    )}
                    <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.note}>
                          <FormLabel>{'Note'}</FormLabel>
                          <Textarea
                            {...methods.register('note', {
                              required: false,
                            })}
                            placeholder="Note..."
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.note &&
                              !!methods.formState.errors?.note?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <ButtonGroup>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={addData.isLoading}>
                        Add
                      </Button>
                      <Button variant="outline" onClick={onAddModalClose}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Flex p={['3', '6']}>
          <Button
            variant={'outline'}
            onClick={() => {
              methods.reset();
              setAddModalOpen(true);
            }}>
            Add Note
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default NotesReport;
