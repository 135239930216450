import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ProductResource from 'api/product';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ProductListItem from 'components/product/ProductListItem';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  PRODUCT_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import useDebounce from 'hooks/useDebounce';
import { FilterParams } from 'interface/common/filterParam';
import { ProductSchema } from 'interface/product/productSchema';
import React, { useRef, useState } from 'react';
import { BiImport, BiSearch } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DateFormat, formatNumber } from 'utils';
import CsvUpload from 'utils/CsvUpload';
import { csvExport } from 'utils/fileExport';

const ProductList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const [productIds, setProductIds] = useState<any>([]);
  const api = new ProductResource();
  const navigate = useNavigate();
  const searchRef = useRef<any>('');
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'productList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: useDebounce(filterParams.keyword, 500),
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
      };
      const response = await api.list(queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };

  const handleSearch = () => {
    filterList({ keyword: searchRef.current.value });
  };

  const handleExport = async () => {
    let dataArray = [
      {
        ['Serial Number']: '',
        ['Product']: '',
        ['Product Code']: '',
        ['Product Group']: '',
        ['Rack Price']: '',
      },
    ];

    const response = await api.list({ paginated: false });

    if (response.data.length > 0) {
      if (productIds.length > 0) {
        dataArray = await response?.data
          ?.filter((res: any, index: number) => productIds.includes(res.id))
          .map((data: any) => {
            return {
              ['Serial Number']: data?.id,
              ['Product']: data?.name,
              ['Product Code']: data?.product_code,
              ['Product Group']: data?.product_group?.name || '-',
              ['Rack Price']: formatNumber(+data?.price, 2) || 0,
            };
          });
      } else {
        dataArray = await response?.data?.map((res: any, index: number) => {
          return {
            ['Serial Number']: res?.id,
            ['Product']: res?.name,
            ['Product Code']: res?.product_code,
            ['Product Group']: res?.product_group?.name || '-',
            ['Rack Price']: formatNumber(+res?.price, 2) || 0,
          };
        });
      }
    }

    csvExport(dataArray, `Product_${DateFormat(new Date())}` + '.xlsx');
  };

  const deleteData = useMutation(
    () => api.bulkDelete({ product_ids: productIds }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('productList');
        setDeleteModalOpen(false);
        toast({
          title: `${productIds.length} Product has been deleted.`,
          status: 'success',
          isClosable: true,
        });
        setProductIds([]);
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Product management</Heading>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                ref={searchRef}
                onKeyUp={handleSearch}
              />
            </InputGroup>
          </Stack>

          <Stack direction="row">
            {productIds.length && (
              <Button color="red.300" onClick={onDeletePress} size="sm">
                Delete Product ({productIds.length})
              </Button>
            )}
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiImport} w="6" h="6" />}
              onClick={handleExport}
              size="sm">
              Download
            </Button>
            <CsvUpload
              url="upload-products-csv"
              setRefetch={setRefetch}
              headerSchema={PRODUCT_HEADER}
              label="Import"
              modal="product"
            />
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => navigate(routes.manage.products.create)}>
              Add Product
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Checkbox
                      isChecked={
                        productIds.length ===
                        dataList?.results?.map(
                          (product: ProductSchema) => product.id
                        ).length
                      }
                      onChange={() => {
                        const products = dataList?.results?.map(
                          (product: ProductSchema) => product.id
                        );
                        if (productIds.length === products.length) {
                          setProductIds([]);
                        } else {
                          setProductIds(products);
                        }
                      }}></Checkbox>
                  </Th>
                  <Th>Product</Th>
                  <Th>Product Code</Th>
                  <Th>Class Code</Th>
                  <Th>Product Group</Th>
                  <Th>Rack Price</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map(
                    (listData: ProductSchema, index: number) => (
                      <ProductListItem
                        listData={listData}
                        key={listData.id}
                        setProductIds={setProductIds}
                        productIds={productIds}
                      />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Product'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete all ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
};

export default ProductList;
