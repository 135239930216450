import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import PeopleResource from 'api/people';
import UserResource from 'api/user';
import CustomTagTitle from 'components/common/CustomTagTitle';
import routes from 'constants/routes';
import { PeopleListType } from 'interface/people/peopleListType';
import React, { useState } from 'react';
import { BiEdit, BiListMinus, BiListPlus, BiShow } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { capitalizeEachWord, capitalizeFirstLetter } from 'utils';

const PeopleListItem: React.FC<PeopleListType> = (Props) => {
  const { listData, setPeopleIds, peopleIds } = Props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const api = new PeopleResource();
  const apiUser = new UserResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);

  const handleCheckBox = () => {
    const index = peopleIds.indexOf(listData.id);
    if (index > -1) {
      setPeopleIds([
        ...peopleIds.slice(0, index),
        ...peopleIds.slice(index + 1),
      ]);
    } else {
      setPeopleIds([...peopleIds, listData.id]);
    }
  };

  const deleteData = useMutation(() => api.destroy(listData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('peopleList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: 'People has been deleted.',
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  const resendInvite = useMutation(() => api.reinvite(listData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('peopleList');

      setDeleteModalOpen(false);
      toast({
        title: 'Invitation has been send.',
        status: 'success',
        isClosable: true,
      });
    },
  });

  const changeStatus = useMutation(() => apiUser.toggleStatus(listData.id), {
    onSuccess: (res) => {
      const statusText = listData?.is_active_employee
        ? 'deactivated'
        : 'activated';
      queryClient.invalidateQueries('peopleList');

      setStatusModalOpen(false);
      toast({
        title: `Employee has been ${statusText}.`,
        status: 'success',
        isClosable: true,
      });
    },
  });

  const onStatusPress = () => {
    setStatusModalOpen(true);
  };

  const onStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const onStatusConfirm = () => {
    changeStatus.mutate();
  };

  const getStatus = () => {
    let btn: any;
    let text: string = '-';
    if (
      !listData?.is_active &&
      (listData?.role?.name === 'company_admin' ||
        listData?.role?.name === 'foreman' ||
        listData?.role?.name === 'mechanic')
    ) {
      text = 'Pending';
      btn = (
        <Button
          size="sm"
          colorScheme="primary"
          onClick={() => resendInvite.mutate()}>
          Resend Invite
        </Button>
      );
    }
    if (listData?.is_active) {
      text = 'Active';
    }
    return (
      <>
        <HStack>
          <Text>{text}</Text>
          {btn}
        </HStack>
      </>
    );
  };

  return (
    <Tr key={listData.id}>
      {/* <Td>
        <Checkbox
          isChecked={peopleIds.includes(listData.id)}
          onChange={handleCheckBox}></Checkbox>
      </Td> */}
      <Td>
        <CustomTagTitle
          type="text"
          status={listData?.is_active_employee}
          name={`${listData?.first_name} ${listData?.last_name}` || '-'}
        />
      </Td>
      <Td>{capitalizeFirstLetter(listData?.user_type)}</Td>
      <Td>{listData?.company_name || '-'}</Td>
      <Td>{listData?.ein_ssn || '-'}</Td>
      <Td>{listData?.address || '-'}</Td>
      <Td>{listData?.phone || '-'}</Td>
      <Td>{listData?.email}</Td>
      <Td>{getStatus()}</Td>
      <Td>{capitalizeEachWord(listData?.role?.name) || '-'}</Td>
      <Td>
        <Link
          to={
            // listData?.user_type === 'employee'
            //   ? routes.manage.users.view.replace(':id', listData?.id.toString())
            // :
            routes.manage.peoples.view.replace(':id', listData?.id.toString())
          }>
          <Tooltip hasArrow label={'View'}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={'View'}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </Link>
        <Link
          to={
            // listData?.user_type === 'employee'
            //   ? routes.manage.users.edit.replace(':id', listData?.id.toString())
            // :
            routes.manage.peoples.edit.replace(':id', listData?.id.toString())
          }>
          <Tooltip hasArrow label={'Edit'}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={'Edit'}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </Link>
        {listData?.user_type.toLowerCase() === 'employee' && (
          <Tooltip
            hasArrow
            label={
              listData.is_active_employee ? 'Hide Employee' : 'Show Employee'
            }>
            <IconButton
              icon={
                listData.is_active_employee ? <BiListMinus /> : <BiListPlus />
              }
              variant="link"
              aria-label={
                listData.is_active_employee ? 'Hide Employee' : 'Show Employee'
              }
              color="red.300"
              size={'lg'}
              onClick={onStatusPress}
            />
          </Tooltip>
        )}
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete People'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete this ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isStatusModalOpen}
          isCentered
          onClose={onStatusModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {listData?.is_active_employee ? 'Hide Employee' : 'Show Employee'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to{' '}
              {listData?.is_active_employee ? 'hide' : 'show'} this employee ?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onStatusConfirm}
                  isLoading={changeStatus.isLoading}>
                  Submit
                </Button>

                <Button variant="outline" onClick={onStatusModalClose}>
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default PeopleListItem;
