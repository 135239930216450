import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import InventoryResource from 'api/inventory';
import PitResource from 'api/pit';
import ProductResource from 'api/product';
import SaleResource from 'api/sale';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CreatedAtInput from 'components/common/CreatedAtInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import SaleListItem from 'components/sale/SaleListItem';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  SALE_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import { SaleSchema } from 'interface/sale/saleSchema';
import React, { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BiImport, BiSearch } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { DateFormat, DateFormatMDY, DateFormatYMD, formatNumber } from 'utils';
import CsvUpload from 'utils/CsvUpload';
import { csvExport } from 'utils/fileExport';

const SaleList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const [saleIds, setSaleIds] = useState<any>([]);
  const api = new SaleResource();
  const navigate = useNavigate();
  const searchRef = useRef<any>('');
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
    start_date: '',
    end_date: '',
    pit: '',
    product: '',
    product_type: '',
    sale_date_range: dateRange,
    customer_name: '',
    status: '',
  });

  // Pit List
  const pitAPI = new PitResource();
  const { data: pitQuery, isLoading: isPitLoading } = useQuery(
    [`pit-list`],
    () => pitAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const pitList = pitQuery?.results;
  const pitOptions = pitList?.map((pit: any) => ({
    label: pit.name,
    value: pit.id,
  }));

  // Product List
  const productAPI = new ProductResource();
  const { data: productQuery, isLoading: isProductLoading } = useQuery(
    [`product-list`],
    () => productAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const productList = productQuery?.results;
  const productOptions = productList?.map((product: any) => ({
    label: `${product.class_code} - ${product.name}`,
    value: product.id,
  }));

  // User List
  const customerAPI = new PitResource();
  const { data: customerQuery, isLoading: isCustomerLoading } = useQuery(
    [`sale-customer-list`],
    () => api.customer({ limit: 5000 }).then((res) => res?.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const customerList = customerQuery?.results;
  const customerOptions = customerList?.map((customer: any) => ({
    label: `${customer.customer_name}`,
    value: customer.customer_name,
  }));

  const inventoryAPI = new InventoryResource();
  const { data: statusQuery, isLoading: isStatusLoading } = useQuery(
    [`status-list`],
    () => inventoryAPI.statusList().then((res) => res?.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const statusList = statusQuery?.data;
  const statusOptions = statusList?.map((status: any) => ({
    label: status,
    value: status,
  }));

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'saleList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        sale_date: filterParams.sale_date,
        pit: filterParams.pit,
        product: filterParams.product,
        product_type: filterParams.product_type,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
        customer_name: filterParams.customer_name,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
        date: filterParams.sale_date,
        pit: filterParams.pit,
        product: filterParams.product,
        product_type: filterParams.product_type,
        date__gte: filterParams.start_date,
        date__lte: filterParams.end_date,
        customer_name: filterParams.customer_name,
      };
      const response = await api.list(queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };

  const handleFilter = () => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      // sale_date: methods.getValues('sale_date')
      //   ? DateFormatMDY(methods.getValues('sale_date'))
      //   : '',
      product: methods.getValues('product')?.value,
      pit: methods.getValues('pit')?.value,
      customer_name: methods.getValues('customer_name')?.value,
      status: methods.getValues('status')?.value,
    }));
  };

  const handleExport = async () => {
    let dataArray = [
      {
        ['Serial Number']: '',
        ['Pit']: '',
        ['Product']: '',
        ['Customer']: '',
        ['Trucking Company']: '',
        ['Ticket Number']: '',
        ['Invoice Number']: '',
        ['Invoice Date']: '',
        ['Date']: '',
        ['PO']: '',
        ['Truck Driver']: '',
        ['Vechicle ID']: '',
        ['Job Name']: '',
        ['Job Location']: '',
        ['Order Info']: '',
        ['Loader Scale Operator']: '',
        ['Gross Wt. Tons']: '',
        ['Tare Wt. Tons']: '',
        ['Net Wt. Tons']: '',
        ['Sale Type']: '',
        ['Price Per Ton']: '',
        ['Delivery Point']: '',
        ['Sales Tax Rate']: '',
        ['Trucking Cost Ton']: '',
        ['Trucking Price Ton']: '',
        ['Trucking Revenue Ton']: '',
        ['Trucking Margin Ton']: '',
        ['Other Revenue']: '',
        ['Product Revenue']: '',
        ['Total Sales Tax']: '',
        ['Notes']: '',
        ['Status']: '',
      },
    ];

    const response = await api.list({ paginated: false });

    if (response.data.length > 0) {
      if (saleIds.length > 0) {
        dataArray = await response?.data
          ?.filter((res: any, index: number) => saleIds.includes(res.id))
          .map((data: any) => {
            return {
              ['Serial Number']: data?.id,
              ['Pit']: data?.pit?.name,
              ['Product']: data?.product?.name,
              ['Customer']: `${data?.customer_name}` || '-',
              ['Trucking Company']: `${data?.trucking_company}` || '-',
              ['Ticket Number']: data?.ticket_number,
              ['Invoice Number']: data?.invoice_number,
              ['Invoice Date']: data?.invoice_date,
              ['Date']: DateFormatMDY(data?.date),
              ['PO']: data?.po,
              ['Truck Driver']: data?.truck_driver || '-',
              ['Vechicle ID']: data?.vehicle_id || '-',
              ['Job Name']: data?.job_name || '-',
              ['Job Location']: data?.job_location || '-',
              ['Order Info']: data?.order_info || '-',
              ['Loader Scale Operator']: data?.loader_scale_operator || '-',
              ['Gross Wt. Tons']: formatNumber(+data?.gross_wt_tons, 2) || 0,
              ['Tare Wt. Tons']: formatNumber(+data?.tare_wt_tons, 2) || 0,
              ['Net Wt. Tons']: formatNumber(+data?.net_wt_tons, 2) || 0,
              ['Sale Type']: formatNumber(+data?.sale_type, 2) || 0,
              ['Price Per Ton']: formatNumber(+data?.price_per_ton, 2) || 0,
              ['Delivery Point']: formatNumber(+data?.delivery_point, 2) || 0,
              ['Sales Tax Rate']: formatNumber(+data?.sales_tax_rate, 2) || 0,
              ['Trucking Cost Ton']:
                formatNumber(+data?.trucking_cost_ton, 2) || 0,
              ['Trucking Price Ton']:
                formatNumber(+data?.trucking_price_ton, 2) || 0,
              ['Trucking Revenue Ton']:
                formatNumber(+data?.trucking_revenue_ton, 2) || 0,
              ['Trucking Margin Ton']:
                formatNumber(+data?.trucking_margin_ton, 2) || 0,
              ['Other Revenue']: formatNumber(+data?.other_revenue, 2) || 0,
              ['Product Revenue']: formatNumber(+data?.product_revenue, 2) || 0,
              ['Total Sales Tax']: formatNumber(+data?.total_sales_tax, 2) || 0,
              ['Notes']: data?.notes || '-',
              ['Status']: data?.status || '-',
            };
          });
      } else {
        dataArray = await response?.data?.map((res: any, index: number) => {
          return {
            ['Serial Number']: res?.id,
            ['Pit']: res?.pit?.name,
            ['Product']: res?.product?.name,
            ['Customer']: `${res?.customer_name}` || '-',
            ['Trucking Company']: `${res?.trucking_company}` || '-',
            ['Ticket Number']: res?.ticket_number,
            ['Invoice Number']: res?.invoice_number,
            ['Invoice Date']: res?.invoice_date,
            ['Date']: DateFormatMDY(res?.date),
            ['PO']: res?.po,
            ['Truck Driver']: res?.truck_driver || '-',
            ['Vechicle ID']: res?.vehicle_id || '-',
            ['Job Name']: res?.job_name || '-',
            ['Job Location']: res?.job_location || '-',
            ['Order Info']: res?.order_info || '-',
            ['Loader Scale Operator']: res?.loader_scale_operator || '-',
            ['Gross Wt. Tons']: formatNumber(+res?.gross_wt_tons, 2) || 0,
            ['Tare Wt. Tons']: formatNumber(+res?.tare_wt_tons, 2) || 0,
            ['Net Wt. Tons']: formatNumber(+res?.net_wt_tons, 2) || 0,
            ['Sale Type']: formatNumber(+res?.sale_type, 2) || 0,
            ['Price Per Ton']: formatNumber(+res?.price_per_ton, 2) || 0,
            ['Delivery Point']: formatNumber(+res?.delivery_point, 2) || 0,
            ['Sales Tax Rate']: formatNumber(+res?.sales_tax_rate, 2) || 0,
            ['Trucking Cost Ton']:
              formatNumber(+res?.trucking_cost_ton, 2) || 0,
            ['Trucking Price Ton']:
              formatNumber(+res?.trucking_price_ton, 2) || 0,
            ['Trucking Revenue Ton']:
              formatNumber(+res?.trucking_revenue_ton, 2) || 0,
            ['Trucking Margin Ton']:
              formatNumber(+res?.trucking_margin_ton, 2) || 0,
            ['Other Revenue']: formatNumber(+res?.other_revenue, 2) || 0,
            ['Product Revenue']: formatNumber(+res?.product_revenue, 2) || 0,
            ['Total Sales Tax']: formatNumber(+res?.total_sales_tax, 2) || 0,
            ['Notes']: res?.notes || '-',
            ['Status']: res?.status || '-',
          };
        });
      }
    }

    csvExport(dataArray, `Sale_${DateFormat(new Date())}` + '.xlsx');
  };

  const deleteData = useMutation(() => api.bulkDelete({ sale_ids: saleIds }), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('saleList');
      setDeleteModalOpen(false);
      toast({
        title: `${saleIds.length} Sale has been deleted.`,
        status: 'success',
        isClosable: true,
      });
      setSaleIds([]);
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  const handleSearch = () => {
    filterList({ keyword: searchRef.current.value });
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Sales</Heading>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                ref={searchRef}
                onKeyUp={handleSearch}
              />
            </InputGroup>
          </Stack>

          <Stack direction="row">
            {saleIds.length > 0 && (
              <Button color="red.300" onClick={onDeletePress} size="sm">
                Delete Sale ({saleIds.length})
              </Button>
            )}
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => navigate(routes.sales.create)}>
              Transaction
            </Button>
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiImport} w="6" h="6" />}
              onClick={handleExport}
              size="sm">
              Download
            </Button>
            <CsvUpload
              url="upload-sales-csv"
              modal="sale"
              setRefetch={setRefetch}
              headerSchema={SALE_HEADER}
              label="Import"
            />
          </Stack>
        </Flex>

        <Flex justify="space-between">
          <Stack direction="row">
            <FormProvider {...methods}>
              <form>
                <Grid
                  gap="4"
                  templateColumns={['repeat(1, 1fr)', 'repeat(6, 1fr)']}
                  bg={'white'}
                  flex="1">
                  <GridItem>
                    <FormControl>
                      <Controller
                        control={methods.control}
                        name="sale_date"
                        rules={{
                          required: 'Report Date',
                        }}
                        render={({ field }) => (
                          <ReactDatePicker
                            fixedHeight
                            id="sale_date"
                            customInput={<CreatedAtInput />}
                            onChange={(selected: any) => {
                              setDateRange(selected);
                              setFilterParams((prevState: any) => ({
                                ...prevState,
                                currentPage: INITIAL_CURRENT_PAGE,
                                start_date: DateFormatYMD(selected[0]),
                                end_date: DateFormatYMD(selected[1]),
                              }));
                            }}
                            isClearable={true}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            selected={field.value}
                            shouldCloseOnSelect={true}
                            autoComplete="off"
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem w={'300px'}>
                    <FormControl>
                      <Controller
                        control={methods.control}
                        name="product"
                        render={({ field }) => (
                          <ReactSelect
                            id="product"
                            placeholder={'Select Product'}
                            onChange={(
                              selected: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              field.onChange(selected);
                              handleFilter();
                            }}
                            isLoading={isProductLoading}
                            options={productOptions}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                            value={field.value}
                            isClearable
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <Controller
                        control={methods.control}
                        name="pit"
                        render={({ field }) => (
                          <ReactSelect
                            id="pit"
                            placeholder={'Select Pit'}
                            onChange={(
                              selected: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              field.onChange(selected);
                              handleFilter();
                            }}
                            isLoading={isPitLoading}
                            options={pitOptions}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                            value={field.value}
                            isClearable
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <Controller
                        control={methods.control}
                        name="customer_name"
                        render={({ field }) => (
                          <ReactSelect
                            id="customer_name"
                            placeholder={'Select Customer'}
                            onChange={(
                              selected: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              field.onChange(selected);
                              handleFilter();
                            }}
                            isLoading={isCustomerLoading}
                            options={customerOptions}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                            value={field.value}
                            isClearable
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl>
                      <Controller
                        control={methods.control}
                        name="status"
                        render={({ field }) => (
                          <ReactSelect
                            id="status"
                            placeholder={'Select Status'}
                            onChange={(
                              selected: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              field.onChange(selected);
                              handleFilter();
                            }}
                            // isLoading={isCustomerLoading}
                            options={statusOptions}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                            value={field.value}
                            isClearable
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </form>
            </FormProvider>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Checkbox
                      isChecked={
                        saleIds.length ===
                        dataList?.results?.map((sale: SaleSchema) => sale.id)
                          .length
                      }
                      onChange={() => {
                        const sales = dataList?.results?.map(
                          (sale: SaleSchema) => sale.id
                        );
                        if (saleIds.length === sales.length) {
                          setSaleIds([]);
                        } else {
                          setSaleIds(sales);
                        }
                      }}></Checkbox>
                  </Th>
                  <Th>Serial Number</Th>
                  <Th>Ticket Number</Th>
                  <Th>Invoice Number</Th>
                  <Th>Invoice Date</Th>
                  <Th>Pit</Th>
                  <Th>Product</Th>
                  <Th>Customer Name</Th>
                  <Th>Day</Th>
                  <Th>Date</Th>
                  <Th>Location</Th>
                  <Th>Loader Scale Operator</Th>
                  <Th>Gross Wt. Tons</Th>
                  <Th>Tare Wt. Tons</Th>
                  <Th>Net Wt. Tons</Th>
                  <Th>Price Per Ton</Th>
                  <Th>Delivery Point</Th>
                  <Th>Sales Tax Rate</Th>
                  <Th>Trucking Cost Ton</Th>
                  <Th>Trucking Price Ton</Th>
                  <Th>Trucking Revenue Ton</Th>
                  <Th>Trucking Margin Ton</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map(
                    (listData: SaleSchema, index: number) => (
                      <SaleListItem
                        listData={listData}
                        key={listData.id}
                        setSaleIds={setSaleIds}
                        saleIds={saleIds}
                      />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={17} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Sale'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete all ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
};

export default SaleList;
