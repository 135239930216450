import Resource from 'api/resource';
import http from 'utils/http';

class OperationDownTimeReportResource extends Resource {
  constructor() {
    super('reports');
  }

  // Operation DownTime Report
  listOperationDownTimeReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/operation-downtime`,
      method: 'get',
      params: query,
    });
  }
  getOperationDownTimeReport(id: string, OperationDownTimeId: string) {
    return http({
      url: `/reports/${id}/operation-downtime/${OperationDownTimeId}`,
      method: 'get',
    });
  }
  storeOperationDownTimeReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/operation-downtime`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateOperationDownTimeReport(id: string, resource: any) {
    return http({
      url: `/reports/${id}/operation-downtime`,
      method: 'put',
      data: resource,
    });
  }
  destroyOperationDownTimeReport(id: string, OperationDownTimeId: string) {
    return http({
      url: `/reports/${id}/operation-downtime/${OperationDownTimeId}`,
      method: 'delete',
    });
  }

  listOperationDownTimeDetailReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/operation-downtime-detail`,
      method: 'get',
      params: query,
    });
  }
  getOperationDownTimeDetailReport(
    id: string,
    OperationDownTimeDetailId: string
  ) {
    return http({
      url: `/reports/${id}/operation-downtime-detail/${OperationDownTimeDetailId}`,
      method: 'get',
    });
  }
  storeOperationDownTimeDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/operation-downtime-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateOperationDownTimeDetailReport(
    id: string,
    OperationDownTimeDetailId: string,
    resource: any
  ) {
    return http({
      url: `/reports/${id}/operation-downtime-detail/${OperationDownTimeDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyOperationDownTimeDetailReport(
    id: string,
    OperationDownTimeDetailId: string
  ) {
    return http({
      url: `/reports/${id}/operation-downtime-detail/${OperationDownTimeDetailId}`,
      method: 'delete',
    });
  }
}

export { OperationDownTimeReportResource as default };
