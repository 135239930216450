import { Td, Tr } from '@chakra-ui/react';
import React from 'react';

const PaymentListItem: React.FC<any> = (props) => {
  const { listData } = props;

  return (
    <Tr key={listData.id}>
      <Td>${listData?.hourly || 0}</Td>
      <Td>${listData?.overtime || 0}</Td>
      <Td>${listData?.salary || 0}</Td>
      <Td>${listData?.additional_pay || 0}</Td>
    </Tr>
  );
};

export default PaymentListItem;
