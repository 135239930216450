import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import AFEResource from 'api/afe';
import ReportResource from 'api/report';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CreatedAtInput from 'components/common/CreatedAtInput';
import { YesNoList } from 'constants/common';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import { ReportSearchInterface } from 'interface/report/reportSchema';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  changeURL,
  DateFormatMDY,
  formatShiftDateTime,
  TimeDifference,
  TimeDifferenceInHourWithDecimal,
} from 'utils';
import { api_store_mechanical_report_back_online } from 'utils/agents';
import { v4 as uuid } from 'uuid';

interface Props {
  setReportId: Function;
  setReportName: Function;
}

const MechanicalOperationalDetailReport: React.FC<Props> = (props) => {
  const isOnline = useConnectionStatus();
  const { queue, setQueue, setPreviousReportId, setReportBody } =
    useContext(OfflineContext);
  const { setReportId, setReportName } = props;
  const containerRef = useRef<any>();
  const methods = useForm<any>();
  const api = new ReportResource();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const reportIdParams = params.get('report_id') || '';
  const [shiftHours, setShiftHours] = useState(0);
  const [shiftStartState, setShiftStartState] = useState<Date | null>();
  const [shiftEndState, setShiftEndState] = useState<Date | null>();
  const [downTimeState, setDownTimeState] = useState(0);
  const [opHours, setOpHours] = useState(0);

  const [filterParams, setFilterParams] = useState<ReportSearchInterface>({
    searchPit: '',
    searchPlant: '',
    searchBusinessUnit: '',
    searchOperation: '',
    searchShift: '',
    searchManager: '',
    searchForeman: '',
    searchAfe: '',
  });

  // Pit List
  const dispatch = useDispatch();
  const plantRef = useRef<any>(null);
  const pitRef = useRef<any>(null);
  const afeRef = useRef<any>(null);
  const businessUnitRef = useRef<any>(null);
  const operationRef = useRef<any>(null);
  const shiftRef = useRef<any>(null);
  const managerRef = useRef<any>(null);
  const foremanRef = useRef<any>(null);
  const injuryRef = useRef<any>(null);
  const rainRef = useRef<any>(null);
  const damageRef = useRef<any>(null);

  const afeAPI = new AFEResource();
  // Access the lists from the global state (e.g., Redux store)
  const {
    plantOptions,
    pitOptions,
    afeOptions,
    isPitLoading,
    isPlantLoading,
    isAfeLoading,
    businessUnitOptions,
    isBusinessUnitLoading,
    operationOptions,
    isOperationLoading,
    mechanicReportList,
    foremanList,
    isForemanLoading,
    managerOptions,
    isManagerLoading,
    shiftOptions,
    isShiftLoading,
  } = useSelector(
    (state: any) => ({
      plantOptions: state?.data?.report?.plantList.plantOptions,
      isPlantLoading: state?.data?.report?.plantList.isPlantLoading,
      pitOptions: state?.data?.report?.pitList.pitOptions,
      isPitLoading: state?.data?.report?.pitList.isPitLoading,
      afeOptions: state?.data?.report?.afeList.afeOptions,
      isAfeLoading: state?.data?.report?.afeList.isAfeLoading,
      businessUnitOptions:
        state?.data?.report?.businessUnitList.businessUnitOptions,
      isBusinessUnitLoading:
        state?.data?.report?.businessUnitList.isBusinessUnitLoading,
      operationOptions: state?.data?.report?.operationList.operationOptions,
      isOperationLoading: state?.data?.report?.operationList.isOperationLoading,
      managerOptions: state?.data?.report?.managerList.managerOptions,
      isManagerLoading: state?.data?.report?.managerList.isManagerLoading,
      shiftOptions: state?.data?.report?.shiftList.shiftOptions,
      isShiftLoading: state?.data?.report?.shiftList.isShiftLoading,
      mechanicReportList: state?.data?.report?.mechanicReport,
      foremanList: state?.data?.report?.foremanList.foremanOptions,
      isForemanLoading: state?.data?.report?.isForemanLoading,
    }),
    shallowEqual
  );
  const foremanOptions = foremanList?.map((foreman: any) => ({
    ...foreman,
    label: `${foreman.first_name} ${foreman.last_name}`,
    value: foreman.id,
  }));

  const handleAfeChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchAfe: value,
    }));
  };

  const handlePitChange = (value: string) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchPit: value,
    }));
  };

  const handlePlantChange = (value: string) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchPlant: value,
    }));
  };

  useEffect(() => {
    if (location.pathname.includes('/operation-detail')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  const getReportData = async () => {
    const report = await api.getMechanicReport(reportIdParams);
    if (report?.data?.id) {
      const {
        report_date,
        downtime,
        injury,
        rain,
        damage,
        pit,
        plant,
        afe,
        business_unit,
        operation,
        shift,
        manager,
        foreman,
        shift_start,
        shift_end,
        op_hours,
      } = report?.data;
      if (report_date) {
        const localDate = moment(report_date, 'YYYY-MM-DD');
        const utcDate = localDate.utc();
        methods.setValue('report_date', utcDate.toDate());
      }
      methods.setValue('downtime', downtime);
      pitRef.current.setValue({ label: pit?.name, value: pit?.id });
      methods.setValue('op_hours', op_hours);
      setTimeout(() => {
        plantRef?.current?.setValue({ label: plant?.name, value: plant?.id });

        setTimeout(async () => {
          if (await hasAfeList(afe?.id)) {
            afeRef?.current?.setValue({ label: afe?.name, value: afe?.id });
          }
          businessUnitRef?.current?.setValue({
            label: business_unit?.name,
            value: business_unit?.id,
          });

          operationRef?.current?.setValue({
            label: operation?.name,
            value: operation?.id,
          });

          shiftRef?.current?.setValue({ label: shift?.name, value: shift?.id });
        }, 0);
      }, 5000);

      methods.setValue('shift', { label: shift?.name, value: shift?.id });
      shiftRef.current.setValue({ label: shift?.name, value: shift?.id });

      managerRef.current.setValue({
        label: `${manager?.first_name} ${manager?.last_name}`,
        value: manager?.id,
      });
      foremanRef.current.setValue({
        label: `${foreman?.first_name} ${foreman?.last_name}`,
        value: foreman?.id,
      });
      injuryRef.current.setValue(
        injury ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
      );
      rainRef.current.setValue(
        rain ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
      );
      damageRef.current.setValue(
        damage ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
      );
      if (typeof shift_start === 'string') {
        methods.setValue('shift_start', new Date(shift_start));
        methods.setValue('shift_end', new Date(shift_end));
        const hours = TimeDifference(
          new Date(shift_start),
          new Date(shift_end)
        );
        setShiftHours(hours);
        setDownTimeState(hours - downtime);
      } else {
        methods.setValue('shift_start', formatShiftDateTime(shift_start));
        methods.setValue('shift_end', formatShiftDateTime(shift_end));
        const hours = +TimeDifferenceInHourWithDecimal(
          formatShiftDateTime(shift_start),
          formatShiftDateTime(shift_end)
        );
        setShiftHours(hours);
        setDownTimeState(hours - downtime);
      }
      setOpHours(op_hours);
      setReportName(`${DateFormatMDY(report_date)} - ${plant?.name}`);
    }
  };

  useEffect(() => {
    if (!reportIdParams) {
      injuryRef.current.setValue({ label: 'No', value: 2 });
      rainRef.current.setValue({ label: 'No', value: 2 });
      damageRef.current.setValue({ label: 'No', value: 2 });
      return;
    }
    getReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportIdParams]);
  const toast = useToast();

  const submitData = useMutation((data: any) => api.storeMechanicReport(data), {
    onSuccess: (res: any) => {
      setReportId(res?.data?.data?.id);
      setReportName(
        `${DateFormatMDY(res?.data?.data?.report_date)} - ${
          res?.data?.data?.plant?.name
        }`
      );
      const searchURL = changeURL({ report_id: res?.data?.data?.id });
      navigate(`?${searchURL}`);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey)
        toast({
          title: error?.response?.data[objKey[0]][0],
          status: 'error',
          isClosable: true,
        });
      else
        toast({
          title: 'Something is wrong. Please contact administrator.',
          status: 'error',
          isClosable: true,
        });
    },
  });

  const onSubmit = (data: any) => {
    const shiftStartDateTime = {
      date: moment(data?.shift_start).format('MM/DD/YYYY'),
      time: moment(data?.shift_start).format('HH:mm:ss'),
    };
    const shiftEndDateTime = {
      date: moment(data?.shift_end).format('MM/DD/YYYY'),
      time: moment(data?.shift_end).format('HH:mm:ss'),
    };
    const timeD = TimeDifference(data?.shift_start, data?.shift_end);
    if (timeD < 0) {
      alert('Please check clock in and out');
    } else {
      const formData = {
        id: uuid(),
        report: 'mechanic',
        report_date: DateFormatMDY(data?.report_date),
        shift_start: shiftStartDateTime,
        shift_end: shiftEndDateTime,
        downtime: data?.downtime,
        op_hours: data?.op_hours,
        injury: data?.injury?.value === '1' ? true : false,
        rain: data?.rain.value === '1' ? true : false,
        damage: data?.damage.value === '1' ? true : false,
        afe: data?.afe.value,
        plant: data?.plant.value,
        pit: data?.pit.value,
        business_unit: data?.business_unit.value,
        operation: data?.operation.value,
        shift: data?.shift.value,
        manager: data?.manager.value,
        foreman: data?.foreman.value,
        labors: [],
        equipment: [],
        capital_equipment: [],
        assigned_equipment: [],
        shift_downtime: [],
        operation_downtime: [],
        purchase: [],
        products: [],
        notes: [],
      };
      if (isOnline) submitData.mutate(formData);
      else {
        setReportId('offline');
        setReportBody((prev: any) => ({ ...prev, ...formData }));
        setQueue({
          data: formData,
          functionName: 'store',
          fn: async (data) => {
            const requestData = api_store_mechanical_report_back_online(data);
            console.log(
              '[back online]::[request data]::[mechanical report]',
              requestData
            );
            const { data: responseData } =
              await api.storeMechanicReportBackOnline(requestData);
            return responseData.data;
          },
        });
      }

      toast({
        title: 'Request stored successfully',
        isClosable: true,
        status: 'success',
      });
    }
  };

  // const subscription = methods.watch(['shift_start', 'shift_end']);
  // useEffect(() => {
  //   if (subscription[0] > subscription[1]) {
  //     methods.setError(
  //       'shift_start',
  //       { type: 'focus', message: 'Please check shift start.' },
  //       { shouldFocus: true }
  //     );
  //   } else if (subscription[1] < subscription[0]) {
  //     methods.setError(
  //       'shift_end',
  //       { type: 'focus', message: 'Please check shift end.' },
  //       { shouldFocus: true }
  //     );
  //   } else {
  //     methods.clearErrors(['shift_start', 'shift_end']);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [subscription]);

  const handleShiftHours = () => {
    const shiftStart = methods.getValues('shift_start');
    const shiftEnd = methods.getValues('shift_end');
    const hours = +TimeDifferenceInHourWithDecimal(shiftStart, shiftEnd);
    setShiftHours(hours);
  };

  const { reports } = useSelector(
    (state: any) => state.data.offline.mechanicalReport
  );

  const handlePreviousData = async (afeData: string) => {
    const previousReports = reports?.filter(
      (report: any) =>
        report.plant.id === methods.getValues('plant').value &&
        report.pit.id === methods.getValues('pit').value &&
        report.afe.id === afeData
    );
    console.log('previousReports', previousReports);
    if (!reportIdParams) {
      if (isOnline) {
        const response = await api.getPreviousReport({
          afe: afeData,
          plant: methods.getValues('plant').value,
          pit: methods.getValues('pit').value,
        });
        if (response?.data) {
          const {
            damage,
            rain,
            injury,
            shift_start,
            shift_end,
            downtime,
            foreman,
            manager,
            shift,
            operation,
            business_unit,
            id,
          } = response.data;
          setPreviousReportId(id);
          damageRef?.current?.setValue(
            damage ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
          );
          rainRef?.current?.setValue(
            rain ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
          );
          injuryRef?.current?.setValue(
            injury ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
          );
          foremanRef?.current?.setValue({
            label: `${foreman?.first_name} ${foreman?.last_name}`,
            value: foreman?.id,
          });
          managerRef?.current?.setValue({
            label: `${manager?.first_name} ${manager?.last_name}`,
            value: manager?.id,
          });
          shiftRef?.current?.setValue({ label: shift?.name, value: shift?.id });
          businessUnitRef?.current?.setValue({
            label: business_unit?.name,
            value: business_unit?.id,
          });
          operationRef?.current?.setValue({
            label: operation?.name,
            value: operation?.id,
          });
        }
      } else {
        const previousReport = previousReports[previousReports.length - 1];

        if (previousReport) {
          setPreviousReportId(previousReport.id);
          const {
            damage,
            rain,
            injury,
            shift_start,
            shift_end,
            downtime,
            foreman,
            manager,
            shift,
            operation,
            business_unit,
            id,
          } = previousReports[previousReports.length - 1];
          damageRef?.current?.setValue(
            damage ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
          );
          rainRef?.current?.setValue(
            rain ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
          );
          injuryRef?.current?.setValue(
            injury ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
          );

          foremanRef?.current?.setValue({
            label: `${foreman?.first_name} ${foreman?.last_name}`,
            value: foreman?.id,
          });
          managerRef?.current?.setValue({
            label: `${manager?.first_name} ${manager?.last_name}`,
            value: manager?.id,
          });
          shiftRef?.current?.setValue({ label: shift?.name, value: shift?.id });
          businessUnitRef?.current?.setValue({
            label: business_unit?.name,
            value: business_unit?.id,
          });
          operationRef?.current?.setValue({
            label: operation?.name,
            value: operation?.id,
          });
        } else {
          toast({
            title: 'No previous report found within a 14 day period',
            status: 'info',
            isClosable: true,
          });
        }
      }
    }
  };

  const hasAfeList = async (id: number) => {
    let flag = false;
    const response = await afeAPI
      .list({
        completed: false,
        limit: 10000,
      })
      .then((res) => {
        const data = res?.data?.results?.find((res: any) => res.id === id);
        if (data) {
          flag = true;
        } else {
          flag = false;
        }
      });
    return flag;
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        ref={containerRef}
        background={'#fff'}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Operational Details</Heading>
        </Flex>
        <Divider />
        <Stack mt={4}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4" px={2} pb={2}>
                <Stack direction="row" align="start" spacing="4">
                  <Grid
                    gap="4"
                    templateColumns={['repeat(1, 1fr)', 'repeat(6, 1fr)']}
                    bg={'white'}
                    flex="1">
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.report_date}>
                        <FormLabel>Report Date</FormLabel>
                        <Controller
                          control={methods.control}
                          name="report_date"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactDatePicker
                              fixedHeight
                              id="report_date"
                              customInput={<CreatedAtInput />}
                              onChange={field.onChange}
                              selected={field.value}
                              shouldCloseOnSelect={true}
                              autoComplete="off"
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.report_date?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.pit}>
                        <FormLabel>Pit</FormLabel>
                        <Controller
                          control={methods.control}
                          name="pit"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="pit"
                              ref={pitRef}
                              placeholder={'Select Pit'}
                              onChange={field.onChange}
                              isLoading={isPitLoading}
                              options={pitOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                              onInputChange={handlePitChange}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.pit?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.plant}>
                        <FormLabel>Plant</FormLabel>
                        <Controller
                          control={methods.control}
                          name="plant"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="plant"
                              ref={plantRef}
                              placeholder={'Select Plant'}
                              onChange={field.onChange}
                              isLoading={isPlantLoading}
                              options={plantOptions.filter(
                                (plant: any) =>
                                  plant.pit.id ===
                                  methods.getValues('pit')?.value
                              )}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                              onInputChange={handlePlantChange}
                              value={field.value}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.plant?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.afe}>
                        <FormLabel>AFE</FormLabel>
                        <Controller
                          control={methods.control}
                          name="afe"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="afe"
                              ref={afeRef}
                              placeholder={'Select AFE'}
                              onChange={(selectedOption: any, action) => {
                                if (selectedOption?.value) {
                                  handlePreviousData(selectedOption?.value);
                                  field.onChange(selectedOption, action);
                                }
                              }}
                              isLoading={isAfeLoading}
                              options={afeOptions.filter(
                                (afe: any) =>
                                  afe.plant.id ===
                                  methods.getValues('plant')?.value
                              )}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                              onInputChange={handleAfeChange}
                              value={field.value}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.afe?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.business_unit}>
                        <FormLabel>Business Unit</FormLabel>
                        <Controller
                          control={methods.control}
                          name="business_unit"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="business_unit"
                              ref={businessUnitRef}
                              placeholder={'Select Business Unit'}
                              onChange={field.onChange}
                              isLoading={isBusinessUnitLoading}
                              options={businessUnitOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.business_unit?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.operation}>
                        <FormLabel>Operation</FormLabel>
                        <Controller
                          control={methods.control}
                          name="operation"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="operation"
                              ref={operationRef}
                              placeholder={'Select Operation'}
                              onChange={field.onChange}
                              isLoading={isOperationLoading}
                              options={operationOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.operation?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.shift}>
                        <FormLabel>Shift</FormLabel>
                        <Controller
                          control={methods.control}
                          name="shift"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="shift"
                              ref={shiftRef}
                              placeholder={'Select Shift'}
                              onChange={field.onChange}
                              isLoading={isShiftLoading}
                              options={shiftOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.shift?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.manager}>
                        <FormLabel>Manager</FormLabel>
                        <Controller
                          control={methods.control}
                          name="manager"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="manager"
                              ref={managerRef}
                              placeholder={'Select Manager'}
                              onChange={field.onChange}
                              isLoading={isManagerLoading}
                              options={managerOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.manager?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.foreman}>
                        <FormLabel>Foreman</FormLabel>
                        <Controller
                          control={methods.control}
                          name="foreman"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="foreman"
                              ref={foremanRef}
                              placeholder={'Select Foreman'}
                              onChange={field.onChange}
                              isLoading={isForemanLoading}
                              options={foremanOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.foreman?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.shift_start}>
                        <FormLabel>Shift Start</FormLabel>
                        <Controller
                          control={methods.control}
                          name="shift_start"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactDatePicker
                              fixedHeight
                              customInput={<CreatedAtInput />}
                              selected={field.value}
                              onChange={(selected) => {
                                setShiftStartState(selected);
                                field.onChange(selected);
                                handleShiftHours();
                              }}
                              shouldCloseOnSelect={true}
                              timeInputLabel="Time:"
                              showTimeInput
                              // showTimeSelectOnly
                              dateFormat={'HH:mm:ss'}
                            />
                          )}
                        />
                        {/* <FormErrorMessage>
                          {methods?.formState?.errors?.shift_start?.message?.toString()}
                        </FormErrorMessage> */}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.shift_end}>
                        <FormLabel>Shift End</FormLabel>
                        <Controller
                          control={methods.control}
                          name="shift_end"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactDatePicker
                              fixedHeight
                              customInput={<CreatedAtInput />}
                              selected={field.value}
                              onChange={(selected) => {
                                setShiftEndState(selected);
                                field.onChange(selected);
                                handleShiftHours();
                              }}
                              shouldCloseOnSelect={true}
                              timeInputLabel="Time:"
                              showTimeInput
                              // showTimeSelectOnly
                              dateFormat={'HH:mm:ss'}
                            />
                          )}
                        />
                        {/* <FormErrorMessage>
                          {methods?.formState?.errors?.shift_end?.message?.toString()}
                        </FormErrorMessage> */}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel>Shift Hours</FormLabel>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder={'Shift Hours'}
                          value={shiftHours}
                          disabled
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.op_hours}>
                        <FormLabel>Op Hours</FormLabel>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder={'Op Hours'}
                          defaultValue={0}
                          {...methods.register('op_hours', {
                            required: 'Required',
                          })}
                          onChange={(
                            selected: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setOpHours(+selected.target.value);
                          }}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.op_hours?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.downtime}>
                        <FormLabel>DownTime</FormLabel>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder={'DownTime'}
                          value={shiftHours - opHours}
                          disabled
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.downtime?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.injury}>
                        <FormLabel>Select Injury</FormLabel>
                        <Controller
                          control={methods.control}
                          name="injury"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="injury"
                              ref={injuryRef}
                              placeholder={'Select Injury'}
                              onChange={field.onChange}
                              options={YesNoList}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.injury?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.rain}>
                        <FormLabel>Select Rain</FormLabel>
                        <Controller
                          control={methods.control}
                          name="rain"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="rain"
                              ref={rainRef}
                              placeholder={'Select Rain'}
                              onChange={field.onChange}
                              options={YesNoList}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.rain?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.damage}>
                        <FormLabel>Select Damage</FormLabel>
                        <Controller
                          control={methods.control}
                          name="damage"
                          rules={{
                            required: 'Required',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              id="damage"
                              ref={damageRef}
                              placeholder={'Select Damage'}
                              onChange={field.onChange}
                              options={YesNoList}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {methods?.formState?.errors?.damage?.message?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Stack>
                <Flex justify={'end'}>
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={submitData.isLoading}>
                      Submit
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Box>
    </>
  );
};

export default MechanicalOperationalDetailReport;
