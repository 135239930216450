import routes from 'constants/routes';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

interface Props {
  serviceName: string;
  component: any;
  users?: any;
}

const UserRoleBasedRouting: React.FC<Props> = (props) => {
  const { serviceName, users, component: Component } = props;
  const navigate = useNavigate();
  let permitted = false;
  switch (serviceName) {
    case 'settings':
      if (users.role.toLowerCase() !== 'super_admin') {
        return <Navigate to={routes.dashboard} />;
      }
      break;
    case 'deactivate-request-service':
      if (users.role.toLowerCase() !== 'super_admin') {
        return <Navigate to={routes.dashboard} />;
      }
      break;
    case 'read:company':
    case 'manage:company':
      if (users.role.toLowerCase() === 'super_admin') {
        permitted = true;
      }
      break;
    case 'read:dashboard':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:user':
    case 'manage:user':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:pit':
    case 'manage:pit':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:plant':
    case 'manage:plant':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:people':
    case 'manage:people':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:equipment':
    case 'manage:equipment':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:product':
    case 'manage:product':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:inventory':
    case 'manage:inventory':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:adjustment':
    case 'manage:adjustment':
      if (users.role.toLowerCase() === 'company_admin') {
        permitted = true;
      }
      break;
    case 'read:sale':
    case 'manage:sale':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:afe':
    case 'manage:afe':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:foreman':
    case 'manage:foreman':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'read:mechanic':
    case 'manage:mechanic':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
    case 'manage:daily-report':
      switch (users.role) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          permitted = true;
          break;
      }
      break;
  }

  const getRouting = () => {
    return permitted ? Component : navigate(-1);
  };

  return <>{getRouting()}</>;
};

export default connect((state: any) => ({
  users: state?.data?.auth?.user,
}))(UserRoleBasedRouting);
