import Resource from 'api/resource';
import http from 'utils/http';

class FuelUsageReportResource extends Resource {
  constructor() {
    super('reports');
  }

  allDate(query?: any) {
    return http({
      url: 'fuel-usage-details',
      method: 'get',
      params: query,
    });
  }

  listFuelUsageReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/fuel-usage`,
      method: 'get',
      params: query,
    });
  }
  getFuelUsageReport(id: string, fuelUsageId: string) {
    return http({
      url: `/reports/${id}/fuel-usage/${fuelUsageId}`,
      method: 'get',
    });
  }
  storeFuelUsageReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/fuel-usage`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateFuelUsageReport(id: string, resource: any) {
    return http({
      url: `/reports/${id}/fuel-usage`,
      method: 'put',
      data: resource,
    });
  }
  destroyFuelUsageReport(id: string, fuelUsageId: string) {
    return http({
      url: `/reports/${id}/fuel-usage/${fuelUsageId}`,
      method: 'delete',
    });
  }

  listFuelUsageDetailReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/fuel-usage-detail`,
      method: 'get',
      params: query,
    });
  }
  getFuelUsageDetailReport(id: string, fuelUsageDetailId: string) {
    return http({
      url: `/reports/${id}/fuel-usage-detail/${fuelUsageDetailId}`,
      method: 'get',
    });
  }
  storeFuelUsageDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/fuel-usage-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateFuelUsageDetailReport(
    id: string,
    fuelUsageDetailId: string,
    resource: any
  ) {
    return http({
      url: `/reports/${id}/fuel-usage-detail/${fuelUsageDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyFuelUsageDetailReport(id: string, fuelUsageDetailId: string) {
    return http({
      url: `/reports/${id}/fuel-usage-detail/${fuelUsageDetailId}`,
      method: 'delete',
    });
  }
}

export { FuelUsageReportResource as default };
