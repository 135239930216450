import Resource from 'api/resource';
import http from 'utils/http';

class EquipmentRepairResource extends Resource {
  constructor() {
    super('equipment-repair-maintainance-log');
  }

  getRepairReasons(query?: any) {
    return http({
      url: `/repair-reasons`,
      method: 'get',
      params: query,
    });
  }

  // Mechanical Equipment Repair Report
  mechanicalListEquipmentRepairReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetEquipmentRepairReport(id: string, equipmentStatusId: string) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log/${equipmentStatusId}`,
      method: 'get',
    });
  }
  mechanicalStoreEquipmentRepairReport(
    id: string,
    resource: any,
    headers?: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdateEquipmentRepairReport(id: string, resource: any) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyEquipmentRepairReport(
    id: string,
    equipmentStatusId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log/${equipmentStatusId}`,
      method: 'delete',
    });
  }

  mechanicalListEquipmentRepairDetailReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log-detail`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetEquipmentRepairDetailReport(
    id: string,
    equipmentStatusDetailId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log-detail/${equipmentStatusDetailId}`,
      method: 'get',
    });
  }
  mechanicalStoreEquipmentRepairDetailReport(
    id: string,
    resource: any,
    headers?: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdateEquipmentRepairDetailReport(
    id: string,
    equipmentStatusDetailId: string,
    resource: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log-detail/${equipmentStatusDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyEquipmentRepairDetailReport(
    id: string,
    equipmentStatusDetailId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-repair-maintainance-log-detail/${equipmentStatusDetailId}`,
      method: 'delete',
    });
  }
}

export { EquipmentRepairResource as default };
