import Resource from 'api/resource';
import http from 'utils/http';

class InventoryResource extends Resource {
  constructor() {
    super('inventories');
  }

  statusList() {
    return http({
      url: 'inventories/status',
      method: 'get',
    });
  }
}

export { InventoryResource as default };
