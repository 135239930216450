export enum OfflineActionTypes {
  SET_MECHANICAL_REPORT = 'SET_MECHANICAL_REPORT',
  SET_FOREMAN_REPORT = 'SET_FOREMAN_REPORT',
  SET_LABORS = 'SET_LABORS',
  SET_BUSINESS_UNITS = 'SET_BUSINESS_UNITS',
  SET_OPERATIONS = 'SET_OPERATIONS',
  SET_LABOUR_ACTIVITIES = 'SET_LABOUR_ACTIVITIES',
  SET_CAPITAL_EQUIPMENT = 'SET_CAPITAL_EQUIPMENT',
  SET_EQUIPMENTS = 'SET_EQUIPMENTS',
  SET_EQUIPMENT_TYPES = 'SET_EQUIPMENT_TYPES',
  SET_VENDOR = 'SET_VENDOR',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_OPERATION_DOWNTIME_CODES = 'SET_OPERATION_DOWNTIME_CODES',
  SET_SHIFT_DOWNTIME_CODES = 'SET_SHIFT_DOWNTIME_CODES',
  SET_EMPLOYEES = 'SET_EMPLOYEES',
  SET_FUEL_USAGE = 'SET_FUEL_USAGE'
}

export interface OfflineAction {
  data: any;
  type: OfflineActionTypes;
}

export function setMechanicalReport(reports: any): OfflineAction {
  return {
    data: reports,
    type: OfflineActionTypes.SET_MECHANICAL_REPORT,
  };
}

export function setForemanReport(reports: any): OfflineAction {
  return {
    data: reports,
    type: OfflineActionTypes.SET_FOREMAN_REPORT,
  };
}

export function setLabors(labors: any): OfflineAction {
  return { data: labors, type: OfflineActionTypes.SET_LABORS };
}

export function setBusinessUnitList(business_units: any): OfflineAction {
  return { data: business_units, type: OfflineActionTypes.SET_BUSINESS_UNITS };
}

export function setOperationsList(operations: any): OfflineAction {
  return { data: operations, type: OfflineActionTypes.SET_OPERATIONS };
}

export function setEquipmentList(equipments: any): OfflineAction {
  return { data: equipments, type: OfflineActionTypes.SET_EQUIPMENTS };
}

export function setEquipmentTypeList(t_equipment: any): OfflineAction {
  return { data: t_equipment, type: OfflineActionTypes.SET_EQUIPMENT_TYPES };
}

export function setLabourActivitiesList(items: any): OfflineAction {
  return { data: items, type: OfflineActionTypes.SET_LABOUR_ACTIVITIES };
}

export function setVendorList(vendors: any): OfflineAction {
  return { data: vendors, type: OfflineActionTypes.SET_VENDOR };
}

export function setProductList(products: any): OfflineAction {
  return { data: products, type: OfflineActionTypes.SET_PRODUCTS };
}

export function setOperationDowntimeCodeList(operation_downtime_codes: any): OfflineAction {
  return { data: operation_downtime_codes, type: OfflineActionTypes.SET_OPERATION_DOWNTIME_CODES };
}

export function setShiftDowntimeCodeList(shift_downtime_codes: any): OfflineAction {
  return { data: shift_downtime_codes, type: OfflineActionTypes.SET_SHIFT_DOWNTIME_CODES };
}

export function setEmployeeList(employees: any): OfflineAction {
  return { data: employees, type: OfflineActionTypes.SET_EMPLOYEES };
}

export function setFuelUsage(fuel_usage: any): OfflineAction {
  return { data: fuel_usage, type: OfflineActionTypes.SET_FUEL_USAGE };
}

export function setCapitalEquipment(capital_equipment: any): OfflineAction {
  return { data: capital_equipment, type: OfflineActionTypes.SET_CAPITAL_EQUIPMENT };
}
