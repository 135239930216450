import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import ProductResource from 'api/product';
import { reactSelectStyles } from 'assets/css/commonStyles';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const AFEProductPriceForm: React.FC<any> = (props) => {
  const { listData } = props;
  // Product List
  const productAPI = new ProductResource();
  const { data: productQuery, isLoading: isProductLoading } = useQuery(
    [`product-list`],
    () =>
      productAPI
        .list({
          limit: 50000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const productList = productQuery?.results;
  const productOptions = productList?.map((product: any) => ({
    label: `${product.class_code} - ${product.name}`,
    value: product.id,
  }));

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  useEffect(() => {
    if (!listData) return;
    setValue('price', listData?.price);
    setValue('product', {
      label: `${listData?.product?.class_code} - ${listData?.product?.name}`,
      value: listData?.product?.id,
    });
  }, [listData, setValue]);

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.product} isRequired>
            <FormLabel>Product</FormLabel>
            <Controller
              control={control}
              name="product"
              rules={{
                required: 'Product',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="product"
                  placeholder={'Product'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isProductLoading}
                  options={productOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  menuPortalTarget={document.body}
                />
              )}
            />
            <FormErrorMessage>
              {!!errors?.product && !!errors?.product?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.price}>
            <FormLabel>{'Price'}</FormLabel>
            <Input
              {...register('price', {
                required: true,
              })}
              type="number"
              step="0.01"
              defaultValue={+listData?.price}
              placeholder={'Price'}
            />
            <FormErrorMessage>
              {!!errors?.price && !!errors?.price?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default AFEProductPriceForm;
