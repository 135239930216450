import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import BusinessUnitResource from 'api/businessUnit';
import CapitalEquipmentReportResource from 'api/capitalEquipmentReport';
import OperationResource from 'api/operation';
import {
  default as OtherEquipmentResource,
  default as ReportResource,
} from 'api/report';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useDebounceFunc from 'hooks/useDebounceFunc';
import useReportDropDown from 'hooks/useReportDropDown';
import { ReportProps } from 'interface/report/reportSchema';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import { formatNumber } from 'utils';
import { v4 as uuid } from 'uuid';

const CapitalEquipmentReport: React.FC<ReportProps> = (props) => {
  const { previousReportId, setReportBody, updateQueue, reportBody } =
    useContext(OfflineContext);
  const offlineBusinessUnitList = useSelector(
    (state: any) => state.data.offline.business_units
  );
  const offlineOperationList = useSelector(
    (state: any) => state.data.offline.operations
  );
  const offlineEquipmentList = useSelector(
    (state: any) => state.data.offline.equipments
  );
  const [
    offlineCapitalEquipmentFilteredByPreviousReportId,
    setOfflineCapitalEquipmentFilteredByPreviousReportId,
  ] = useState<any>([]);
  const isOnline = useConnectionStatus();
  const { reportId } = props;
  const containerRef = useRef<any>();
  const capitalEquipmentAPI = new CapitalEquipmentReportResource();
  const queryClient = useQueryClient();
  const [totalCost, setTotalCost] = useState(0);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [errorMessage, setErrorMessage] = useState<any>();
  const [tagData, setTagData] = useState(0);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const methods = useForm<any>();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);
  const reportAPI = new ReportResource();

  const { isActivityLoading, activityOptions } = useReportDropDown();

  const { data: reportDetails } = useQuery(
    [`reportDetails`, { reportId }],
    async () => {
      const response = await reportAPI.get(reportId);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  // Equipment List
  const equipmentAPI = new OtherEquipmentResource();
  const { data: equipmentQuery, isLoading: isEquipmentLoading } = useQuery(
    [`other-equipment-dropdown-list`],
    () =>
      equipmentAPI
        .getOtherEquipment(reportId, {
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const equipmentList = equipmentQuery?.results;
  const equipmentOptions = equipmentList?.map((equipment: any) => ({
    label: equipment.name,
    value: equipment.id,
  }));

  const capitalEquipmentStatusQuery = useQuery(
    [`getCapitalEquipmentStatus`],
    async () => {
      const response = await capitalEquipmentAPI.listCapitalEquipmentReport(
        reportId,
        {
          limit: 5000,
        }
      );
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: capitalEquipmentListQuery,
    isLoading: isCapitalEquipmentListLoading,
  } = useQuery(
    [`capital-equipment-list`],
    () =>
      isOnline &&
      capitalEquipmentAPI
        .listCapitalEquipmentDetailReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          if (res?.data?.equipments) getTotalCost(res?.data?.equipments);
          return res?.data?.equipments;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const equipment = useMemo(
    () =>
      isOnline
        ? capitalEquipmentListQuery &&
          Object.keys(capitalEquipmentListQuery)?.length > 0
          ? capitalEquipmentListQuery
          : []
        : {
            other_equipment: offlineCapitalEquipmentFilteredByPreviousReportId,
          },
    [
      offlineCapitalEquipmentFilteredByPreviousReportId,
      capitalEquipmentListQuery,
    ]
  );

  const businessUnitAPI = new BusinessUnitResource();
  const { data: businessUnitQuery, isLoading: isBusinessUnitLoading } =
    useQuery<any>(
      [`businessunit-list`],
      () => {
        return businessUnitAPI
          .list({
            limit: 10000,
          })
          .then((res) => res?.data);
      },
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );
  const businessUnitList = businessUnitQuery?.results;
  const businessUnitOptions = businessUnitList?.map((businessUnit: any) => ({
    label: businessUnit.name,
    value: businessUnit.id,
  }));

  // Operation List
  const operationAPI = new OperationResource();
  const { data: operationQuery, isLoading: isOperationLoading } = useQuery<any>(
    [`operation-list`],
    () => {
      return operationAPI
        .list({
          limit: 10000,
        })
        .then((res) => res?.data);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const operationList = operationQuery?.results;
  const operationOptions = operationList?.map((operation: any) => ({
    label: operation.name,
    value: operation.id,
  }));

  const getTotalCost = (capitalEquipment: any) => {
    const totalNonOperated = capitalEquipment?.nonoperated_equipment.reduce(
      function (acc: number, obj: any) {
        return acc + +obj.idle_cost + +obj.op_cost;
      },
      0
    );
    const totalOperated = capitalEquipment?.operated_equipment.reduce(function (
      acc: number,
      obj: any
    ) {
      return acc + +obj.idle_cost + +obj.op_cost;
    },
    0);

    const totalOtherOperated = capitalEquipment?.other_equipment.reduce(
      function (acc: number, obj: any) {
        return acc + +obj.idle_cost + +obj.op_cost;
      },
      0
    );
    setTotalCost(
      (totalNonOperated + totalOperated + totalOtherOperated).toFixed(2)
    );
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/capital-equipment')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev,
      capital_equipment: offlineCapitalEquipmentFilteredByPreviousReportId,
    }));
    // update the queue
    updateQueue(reportBody.id, {
      ...reportBody,
      capital_equipment: offlineCapitalEquipmentFilteredByPreviousReportId,
    });
  }, [offlineCapitalEquipmentFilteredByPreviousReportId]);

  const updateData = useMutation(
    (data: any) =>
      capitalEquipmentAPI.updateCapitalEquipmentDetailReport(
        reportId,
        data?.id,
        data?.body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('capital-equipment-list');
      },
    }
  );
  const handleChange = (
    fieldData: any,
    fieldValue: string,
    fieldType: string
  ) => {
    let data: any = {
      body: { op_hour: 0, idle_rate_per_hour: 0, op_rate_per_hour: 0 },
      id: 0,
    };
    data.id = fieldData.id;
    switch (fieldType) {
      case 'op_hours':
        data.body.op_hour = fieldValue;
        data.body.op_rate_per_hour = fieldData.op_rate_per_hour;
        data.body.idle_rate_per_hour = fieldData.idle_rate_per_hour;
        break;
      case 'op_rate_per_hour':
        data.body.op_hour = fieldData.op_hour;
        data.body.op_rate_per_hour = fieldValue;
        data.body.idle_rate_per_hour = fieldData.idle_rate_per_hour;
        break;
      case 'idle_rate_per_hour':
        data.body.op_hour = fieldData.op_hour;
        data.body.op_rate_per_hour = fieldData.op_rate_per_hour;
        data.body.idle_rate_per_hour = fieldValue;
        break;
    }
    if (isOnline) {
      updateData.mutate(data);
    } else {
      offlineCapitalEquipmentFilteredByPreviousReportId((prev: any) =>
        prev.map((r: any) => {
          if (r.id === data.id) {
            return {
              ...r,
              idle_hours: data?.dry_hour || r.dry_hour || 0,
              op_hour: data?.op_hour || r.op_hour || 0,
              op_rate_per_hour:
                data?.op_rate_per_hour || r.op_rate_per_hour || 0,
            };
          } else {
            return r;
          }
        })
      );
    }
  };
  const debounce = useDebounceFunc(handleChange, 3000);

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        ...fieldData,
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineCapitalEquipmentFilteredByPreviousReportId((prev: any) =>
        prev.map((r: any) => {
          if (r.id === data.id) {
            return {
              ...r,
              note: modalValue.note,
            };
          } else {
            return r;
          }
        })
      );
    }
    setNoteDetailModalOpen(false);
  };

  const handleNoteDetailModal = (data: any) => {
    setNoteDetailFieldData(data);
    setNoteDetailModalOpen(true);
  };

  const handleNoteModal = (data: any) => {
    setNoteModalOpen(true);
  };

  const updateCapitalEquipmentData = useMutation(
    (data: any) =>
      capitalEquipmentAPI.updateCapitalEquipmentReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getCapitalEquipmentStatus');
      },
    }
  );
  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      note: modalValue.note,
    };
    updateCapitalEquipmentData.mutate(data);
    setNoteModalOpen(false);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('capital-equipment-list');
    queryClient.invalidateQueries('getCapitalEquipmentStatus');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  const addData = useMutation(
    (data: any) =>
      capitalEquipmentAPI.storeCapitalEquipmentDetailReport(reportId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('capital-equipment-list');
        queryClient.invalidateQueries('getCapitalEquipmentStatus');
        setAddModalOpen(false);
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      op_hour: data?.op_hour || 0,
      op_rate_per_hour: data?.op_rate_per_hour || 0,
      idle_hours: data?.dry_hour || 0,
      equipment: data?.equipment?.value,
      business_unit: data?.business_unit?.value,
      operation: data?.operation?.value,
      note: data?.note,
    };
    if (isOnline) {
      addData.mutate(formData);

      // refresh the page
      queryClient.invalidateQueries('labor-list');
      queryClient.invalidateQueries('labor-detail-list');
      queryClient.invalidateQueries('capital-equipment-list');
      queryClient.invalidateQueries('loadout-list');
      queryClient.invalidateQueries('getLoadoutStatus');
      queryClient.invalidateQueries('loadout-detail-list');
    } else {
      setOfflineCapitalEquipmentFilteredByPreviousReportId((prev: any) => [
        ...prev,
        {
          ...formData,
          business_unit: {
            id: data?.business_unit?.value,
            name: data?.business_unit?.label,
          },
          operation: {
            id: data?.operation?.value,
            name: data?.operation?.label,
          },
          equipment: {
            id: data?.equipment?.value,
            name: data?.equipment?.label,
          },
          id: uuid(),
        },
      ]);
      setAddModalOpen(false);
    }
  };

  const handleDetailDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: number) =>
      capitalEquipmentAPI.destroyCapitalEquipmentDetailReport(
        reportId,
        id + ''
      ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('capital-equipment-list');
        queryClient.invalidateQueries('getCapitalEquipmentStatus');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (isOnline) {
      if (id != 0) deleteData.mutate(id);
    } else {
      setOfflineCapitalEquipmentFilteredByPreviousReportId((prev: any) =>
        prev.filter((r: any) => r.id !== id)
      );
      setDeleteModalOpen(false);
    }
  };

  const updateWetHour = useMutation(
    (data: any) =>
      capitalEquipmentAPI.updateCapitalEquipmentDetailReport(
        reportId,
        data?.id,
        data?.body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('capital-equipment-list');
      },
    }
  );

  const handleWetHour = (capitalEquipmentId: number, wetHour: number) => {
    updateWetHour.mutate({ id: capitalEquipmentId, wet_hour: wetHour });
  };

  const debounceWetHour = useDebounceFunc(handleWetHour, 3000);

  const handleOperationBusiness = (
    capitalEquipment: any,
    type: string,
    value: number
  ) => {
    if (!capitalEquipment.id) return;
    if (!isOnline) {
      setOfflineCapitalEquipmentFilteredByPreviousReportId((prev: any) =>
        prev.map((r: any) => {
          if (r.id === capitalEquipment.id) {
            switch (type) {
              case 'business_unit':
                const b_unit = offlineBusinessUnitList.business_units.find(
                  (elem: any) => elem.value === value
                );
                return {
                  ...r,
                  business_unit: b_unit
                    ? { id: b_unit.value, name: b_unit.label }
                    : r.business_unit,
                };
              case 'operation':
                const _operation = offlineOperationList.operations.find(
                  (elem: any) => elem.value === value
                );
                return {
                  ...r,
                  operation: _operation
                    ? { id: _operation.value, name: _operation.label }
                    : r.operation,
                };
              default:
                return r;
            }
          } else {
            return r;
          }
        })
      );
    }
    // const data = {
    //   body: {
    //     split: product?.split,
    //     pay: product?.pay,
    //     tons: product?.tons,
    //     revenue: product?.revenue,
    //     product: product?.product?.id,
    //     business_unit:
    //       type === 'business_unit' ? value : product?.business_unit?.id,
    //     operation: type === 'operation' ? value : product?.business_unit?.id,
    //   },
    //   productionId: product?.id,
    // };
    // updateData.mutate(data);
    const data = {
      body: {
        ...capitalEquipment,
        business_unit:
          type === 'business_unit'
            ? value
            : capitalEquipment?.business_unit?.id,
        operation:
          type === 'operation' ? value : capitalEquipment?.business_unit?.id,
        activity: type === 'activity' ? value : capitalEquipment?.activity?.id,
      },
      id: capitalEquipment?.id,
    };
    updateData.mutate(data);
  };

  const debounceOperationBusinessUpdate = useDebounceFunc(
    handleOperationBusiness,
    3000
  );

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Capital Equipment</Heading>
          <Stack direction="row" spacing="6">
            <Stack direction={'column'}>
              <Text>Total Cost</Text>
              <Text>${totalCost}</Text>
            </Stack>
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(capitalEquipmentStatusQuery?.data, 'tagStatus')
                }
              />
            </Tooltip>
            {tagData === capitalEquipmentStatusQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={capitalEquipmentStatusQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
              />
            )}
            <Tooltip hasArrow label={capitalEquipmentStatusQuery?.data?.note}>
              <IconButton
                icon={<MdOutlineModeComment />}
                variant="link"
                aria-label={capitalEquipmentStatusQuery?.data?.note}
                color={
                  capitalEquipmentStatusQuery?.data?.note
                    ? 'primary.600'
                    : 'gray.300'
                }
                size="lg"
                onClick={() =>
                  handleNoteModal(capitalEquipmentStatusQuery?.data)
                }
              />
            </Tooltip>
            <NoteModal
              isModalOpen={noteModalOpen}
              setModalOpen={setNoteModalOpen}
              fieldData={capitalEquipmentStatusQuery?.data}
              onModalSubmit={(notes: string) =>
                onNoteModalSubmit(notes, capitalEquipmentStatusQuery?.data)
              }
              formMutation={updateCapitalEquipmentData}
              errorMessage={errorMessage}
            />
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          {!isCapitalEquipmentListLoading && (
            <TableContainer>
              <Table variant={'simple'}>
                <Tbody>
                  <Tr bg={'gray.300'}>
                    <Td>Operated Equipment</Td>
                    <Td>Wet Hours</Td>
                    <Td>Wet Rate/Hr</Td>
                    <Td>Available Idle Hr</Td>
                    <Td>Bank Hr</Td>
                    <Td>Wet Cost</Td>
                    <Td>Dry Hours</Td>
                    <Td>Dry Rate/Hr</Td>
                    <Td>Dry Cost</Td>
                    <Td>Business Unit</Td>
                    <Td>Operation</Td>
                    <Td>Activity</Td>
                    <Td>
                      <Tooltip hasArrow label={'Tag'}>
                        <IconButton
                          icon={<BsTag />}
                          variant="link"
                          aria-label={'TAg'}
                          color="gray.300"
                          size="lg"
                          // onClick={()}
                        />
                      </Tooltip>
                      <Tooltip hasArrow label={'Comment'}>
                        <IconButton
                          icon={<MdOutlineModeComment />}
                          variant="link"
                          aria-label={'Comment'}
                          color="gray.300"
                          size="lg"
                          // onClick={()}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>
                  {equipment?.operated_equipment?.map(
                    (capitalEquipment: any, indexNumber: number) => (
                      <>
                        <React.Fragment key={indexNumber}>
                          <Tr>
                            <Td>{capitalEquipment?.equipment?.name}</Td>
                            <Td>{capitalEquipment?.op_hour}</Td>
                            <Td>{capitalEquipment?.op_rate_per_hour}</Td>
                            <Td>{capitalEquipment?.available_idle_hours}</Td>
                            <Td>
                              {formatNumber(capitalEquipment?.bank_hours)}
                            </Td>
                            {/* <Td>${formatNumber(capitalEquipment?.op_cost)}</Td> */}
                            <Td>${capitalEquipment?.op_cost}</Td>
                            <Td>{capitalEquipment?.idle_hour}</Td>
                            <Td>{capitalEquipment?.idle_rate_per_hour}</Td>
                            <Td>${capitalEquipment?.idle_cost}</Td>
                            <Td>
                              <Box w={'200px'}>
                                <ReactSelect
                                  defaultValue={{
                                    label:
                                      capitalEquipment?.business_unit?.name,
                                    value: capitalEquipment?.business_unit?.id,
                                  }}
                                  placeholder={'Select Business Unit'}
                                  isLoading={
                                    isOnline
                                      ? isBusinessUnitLoading
                                      : offlineBusinessUnitList.isLoading
                                  }
                                  options={
                                    isOnline
                                      ? businessUnitOptions
                                      : offlineBusinessUnitList.business_units
                                  }
                                  styles={reactSelectStyles}
                                  isSearchable={true}
                                  onChange={(e: any) =>
                                    debounceOperationBusinessUpdate(
                                      capitalEquipment,
                                      'business_unit',
                                      e.value
                                    )
                                  }
                                  menuPortalTarget={document.body}
                                />
                              </Box>
                            </Td>
                            <Td>
                              <Box w={'200px'}>
                                <ReactSelect
                                  defaultValue={{
                                    label: capitalEquipment?.operation?.name,
                                    value: capitalEquipment?.operation?.id,
                                  }}
                                  placeholder={'Select Operation'}
                                  isLoading={
                                    isOnline
                                      ? isOperationLoading
                                      : offlineOperationList.isLoading
                                  }
                                  options={
                                    isOnline
                                      ? operationOptions
                                      : offlineOperationList.operations
                                  }
                                  styles={reactSelectStyles}
                                  isSearchable={true}
                                  onChange={(e: any) =>
                                    debounceOperationBusinessUpdate(
                                      capitalEquipment,
                                      'operation',
                                      e.value
                                    )
                                  }
                                  menuPortalTarget={document.body}
                                />
                              </Box>
                            </Td>
                            <Td>
                              <Box w={'200px'}>
                                <ReactSelect
                                  defaultValue={{
                                    label: capitalEquipment?.activity?.name,
                                    value: capitalEquipment?.activity?.id,
                                  }}
                                  placeholder={'Select Activity'}
                                  isLoading={isActivityLoading}
                                  options={activityOptions}
                                  styles={reactSelectStyles}
                                  isSearchable={true}
                                  onChange={(e: any) =>
                                    debounceOperationBusinessUpdate(
                                      capitalEquipment,
                                      'activity',
                                      e.value
                                    )
                                  }
                                  menuPortalTarget={document.body}
                                />
                              </Box>
                            </Td>
                            <Td>
                              <Tooltip hasArrow label={'Tag'}>
                                <IconButton
                                  icon={<BsTag />}
                                  variant="link"
                                  aria-label={'Tag'}
                                  color={'gray.300'}
                                  size="lg"
                                  onClick={() =>
                                    handleTagModal(
                                      capitalEquipment,
                                      'tagDetail'
                                    )
                                  }
                                />
                              </Tooltip>
                              {tagData === capitalEquipment?.tag && (
                                <TagModal
                                  isModalOpen={tagDetailModalOpen}
                                  setModalOpen={setTagDetailModalOpen}
                                  fieldData={tagData}
                                  onModalSubmit={() => onTagModalSubmit()}
                                />
                              )}
                              <Tooltip hasArrow label={capitalEquipment?.note}>
                                <IconButton
                                  icon={<MdOutlineModeComment />}
                                  variant="link"
                                  aria-label={capitalEquipment?.note}
                                  color={
                                    capitalEquipment?.note
                                      ? 'primary.600'
                                      : 'gray.300'
                                  }
                                  size="lg"
                                  onClick={() =>
                                    handleNoteDetailModal(capitalEquipment)
                                  }
                                />
                              </Tooltip>
                              <NoteModal
                                isModalOpen={noteDetailModalOpen}
                                setModalOpen={setNoteDetailModalOpen}
                                fieldData={noteDetailFieldData}
                                onModalSubmit={(notes: string) =>
                                  onNoteDetailModalSubmit(
                                    notes,
                                    noteDetailFieldData
                                  )
                                }
                                formMutation={updateData}
                                errorMessage={errorMessage}
                              />
                            </Td>
                          </Tr>
                        </React.Fragment>
                      </>
                    )
                  )}
                </Tbody>
              </Table>
              <Table variant={'simple'}>
                <Tbody>
                  <Tr bg={'gray.300'}>
                    <Td>Non Operated Equipment</Td>
                    <Td>Wet Hours</Td>
                    <Td>Wet Rate/Hr</Td>
                    <Td>Available Idle Hr</Td>
                    <Td>Bank Hr</Td>
                    <Td>Wet Cost</Td>
                    <Td>Dry Hours</Td>
                    <Td>Dry Rate/Hr</Td>
                    <Td>Dry Cost</Td>
                    <Td>Business Unit</Td>
                    <Td>Operation</Td>
                    <Td>Activity</Td>
                    <Td>
                      <Tooltip hasArrow label={'Tag'}>
                        <IconButton
                          icon={<BsTag />}
                          variant="link"
                          aria-label={'TAg'}
                          color="gray.300"
                          size="lg"
                          // onClick={()}
                        />
                      </Tooltip>
                      <Tooltip hasArrow label={'Comment'}>
                        <IconButton
                          icon={<MdOutlineModeComment />}
                          variant="link"
                          aria-label={'Comment'}
                          color="gray.300"
                          size="lg"
                          // onClick={()}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>
                  {equipment?.nonoperated_equipment?.map(
                    (capitalEquipment: any, indexNumber: number) => (
                      <React.Fragment key={indexNumber}>
                        <Tr>
                          <Td>{capitalEquipment?.equipment?.name}</Td>
                          <Td>
                            {capitalEquipment?.op_hour}
                            {/* <Input
                              type="number"
                              step="0.01"
                              placeholder={'Wet Hour'}
                              defaultValue={capitalEquipment?.op_hour}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                debounceWetHour(
                                  capitalEquipment?.id,
                                  +e?.target?.value
                                )
                              }
                            /> */}
                          </Td>
                          <Td>{capitalEquipment?.op_rate_per_hour}</Td>
                          <Td>{capitalEquipment?.available_idle_hours}</Td>
                          <Td>{formatNumber(capitalEquipment?.bank_hours)}</Td>
                          {/* <Td>${formatNumber(capitalEquipment?.op_cost)}</Td> */}
                          <Td>${capitalEquipment?.op_cost}</Td>
                          <Td>{capitalEquipment?.idle_hour}</Td>
                          <Td>{capitalEquipment?.idle_rate_per_hour}</Td>
                          <Td>${capitalEquipment?.idle_cost}</Td>
                          <Td>
                            <Box w={'200px'}>
                              <ReactSelect
                                defaultValue={{
                                  label: capitalEquipment?.business_unit?.name,
                                  value: capitalEquipment?.business_unit?.id,
                                }}
                                placeholder={'Select Business Unit'}
                                isLoading={
                                  isOnline
                                    ? isBusinessUnitLoading
                                    : offlineBusinessUnitList.isLoading
                                }
                                options={
                                  isOnline
                                    ? businessUnitOptions
                                    : offlineBusinessUnitList.business_units
                                }
                                styles={reactSelectStyles}
                                isSearchable={true}
                                onChange={(e: any) =>
                                  debounceOperationBusinessUpdate(
                                    capitalEquipment,
                                    'business_unit',
                                    e.value
                                  )
                                }
                                menuPortalTarget={document.body}
                              />
                            </Box>
                          </Td>
                          <Td>
                            <Box w={'200px'}>
                              <ReactSelect
                                defaultValue={{
                                  label: capitalEquipment?.operation?.name,
                                  value: capitalEquipment?.operation?.id,
                                }}
                                placeholder={'Select Operation'}
                                isLoading={
                                  isOnline
                                    ? isOperationLoading
                                    : offlineOperationList.isLoading
                                }
                                options={
                                  isOnline
                                    ? operationOptions
                                    : offlineOperationList.operations
                                }
                                styles={reactSelectStyles}
                                isSearchable={true}
                                onChange={(e: any) =>
                                  debounceOperationBusinessUpdate(
                                    capitalEquipment,
                                    'operation',
                                    e.value
                                  )
                                }
                                menuPortalTarget={document.body}
                              />
                            </Box>
                          </Td>
                          <Td>
                            <Box w={'200px'}>
                              <ReactSelect
                                defaultValue={{
                                  label: capitalEquipment?.activity?.name,
                                  value: capitalEquipment?.activity?.id,
                                }}
                                placeholder={'Select Activity'}
                                isLoading={isActivityLoading}
                                options={activityOptions}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                onChange={(e: any) =>
                                  debounceOperationBusinessUpdate(
                                    capitalEquipment,
                                    'activity',
                                    e.value
                                  )
                                }
                                menuPortalTarget={document.body}
                              />
                            </Box>
                          </Td>
                          <Td>
                            <Tooltip hasArrow label={'Tag'}>
                              <IconButton
                                icon={<BsTag />}
                                variant="link"
                                aria-label={'Tag'}
                                color={'gray.300'}
                                size="lg"
                                onClick={() =>
                                  handleTagModal(capitalEquipment, 'tagDetail')
                                }
                              />
                            </Tooltip>
                            {tagData === capitalEquipment?.tag && (
                              <TagModal
                                isModalOpen={tagDetailModalOpen}
                                setModalOpen={setTagDetailModalOpen}
                                fieldData={tagData}
                                onModalSubmit={() => onTagModalSubmit()}
                              />
                            )}
                            <Tooltip hasArrow label={capitalEquipment?.note}>
                              <IconButton
                                icon={<MdOutlineModeComment />}
                                variant="link"
                                aria-label={capitalEquipment?.note}
                                color={
                                  capitalEquipment?.note
                                    ? 'primary.600'
                                    : 'gray.300'
                                }
                                size="lg"
                                onClick={() =>
                                  handleNoteDetailModal(capitalEquipment)
                                }
                              />
                            </Tooltip>
                            <NoteModal
                              isModalOpen={noteDetailModalOpen}
                              setModalOpen={setNoteDetailModalOpen}
                              fieldData={noteDetailFieldData}
                              onModalSubmit={(notes: string) =>
                                onNoteDetailModalSubmit(notes, capitalEquipment)
                              }
                              formMutation={updateData}
                              errorMessage={errorMessage}
                            />
                          </Td>
                        </Tr>
                      </React.Fragment>
                    )
                  )}
                </Tbody>
              </Table>

              {/* For Other Equipment */}
              <Table variant={'simple'}>
                <Tbody>
                  <Tr bg={'gray.300'}>
                    <Td>Other Equipment</Td>
                    <Td>Wet Hours</Td>
                    <Td>Wet Rate/Hr</Td>
                    <Td>Available Idle Hr</Td>
                    <Td>Bank Hr</Td>
                    <Td>Wet Cost</Td>
                    <Td>Dry Hours</Td>
                    <Td>Dry Rate/Hr</Td>
                    <Td>Dry Cost</Td>
                    <Td>Business Unit</Td>
                    <Td>Operation</Td>
                    <Td>Activity</Td>
                    <Td colSpan={2}></Td>
                  </Tr>
                  {equipment?.other_equipment?.map(
                    (capitalEquipment: any, indexNumber: number) => (
                      <React.Fragment key={capitalEquipment?.id}>
                        <Tr>
                          <Td>{capitalEquipment?.equipment?.name}</Td>
                          <Td>{capitalEquipment?.op_hour}</Td>
                          <Td>{capitalEquipment?.op_rate_per_hour}</Td>
                          <Td>{capitalEquipment?.available_idle_hours}</Td>
                          <Td>{formatNumber(capitalEquipment?.bank_hours)}</Td>
                          {/* <Td>${formatNumber(capitalEquipment?.op_cost)}</Td> */}
                          <Td>${capitalEquipment?.op_cost}</Td>
                          <Td>{capitalEquipment?.idle_hour}</Td>
                          <Td>{capitalEquipment?.idle_rate_per_hour}</Td>
                          <Td>${capitalEquipment?.idle_cost}</Td>
                          <Td>
                            <Box w={'200px'}>
                              <ReactSelect
                                defaultValue={{
                                  label: capitalEquipment?.business_unit?.name,
                                  value: capitalEquipment?.business_unit?.id,
                                }}
                                placeholder={'Select Business Unit'}
                                isLoading={
                                  isOnline
                                    ? isBusinessUnitLoading
                                    : offlineBusinessUnitList.isLoading
                                }
                                options={
                                  isOnline
                                    ? businessUnitOptions
                                    : offlineBusinessUnitList.business_units
                                }
                                styles={reactSelectStyles}
                                isSearchable={true}
                                onChange={(e: any) =>
                                  debounceOperationBusinessUpdate(
                                    capitalEquipment,
                                    'business_unit',
                                    e.value
                                  )
                                }
                                menuPortalTarget={document.body}
                              />
                            </Box>
                          </Td>
                          <Td>
                            <Box w={'200px'}>
                              <ReactSelect
                                defaultValue={{
                                  label: capitalEquipment?.operation?.name,
                                  value: capitalEquipment?.operation?.id,
                                }}
                                placeholder={'Select Operation'}
                                isLoading={
                                  isOnline
                                    ? isOperationLoading
                                    : offlineOperationList.isLoading
                                }
                                options={
                                  isOnline
                                    ? operationOptions
                                    : offlineOperationList.operations
                                }
                                styles={reactSelectStyles}
                                isSearchable={true}
                                onChange={(e: any) =>
                                  debounceOperationBusinessUpdate(
                                    capitalEquipment,
                                    'operation',
                                    e.value
                                  )
                                }
                                menuPortalTarget={document.body}
                              />
                            </Box>
                            {/* <Box w={'200px'}>
                              <ReactSelect
                                defaultValue={{
                                  label: capitalEquipment?.business_unit?.name,
                                  value: capitalEquipment?.business_unit?.id,
                                }}
                                placeholder={'Select Business Unit'}
                                isLoading={isBusinessUnitLoading}
                                options={businessUnitOptions}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                onChange={(e: any) =>
                                  debounceOperationBusinessUpdate(
                                    capitalEquipment,
                                    'business_unit',
                                    e.value
                                  )
                                }
                                menuPortalTarget={document.body}
                              />
                            </Box> */}
                          </Td>
                          {/* <Td>
                            <Box w={'200px'}>
                              <ReactSelect
                                defaultValue={{
                                  label: capitalEquipment?.operation?.name,
                                  value: capitalEquipment?.operation?.id,
                                }}
                                placeholder={'Select Operation'}
                                isLoading={isOperationLoading}
                                options={operationOptions}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                onChange={(e: any) =>
                                  debounceOperationBusinessUpdate(
                                    capitalEquipment,
                                    'operation',
                                    e.value
                                  )
                                }
                                menuPortalTarget={document.body}
                              />
                            </Box>
                          </Td> */}
                          <Td>
                            <Box w={'200px'}>
                              <ReactSelect
                                defaultValue={{
                                  label: capitalEquipment?.activity?.name,
                                  value: capitalEquipment?.activity?.id,
                                }}
                                placeholder={'Select Activity'}
                                isLoading={isActivityLoading}
                                options={activityOptions}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                onChange={(e: any) =>
                                  debounceOperationBusinessUpdate(
                                    capitalEquipment,
                                    'activity',
                                    e.value
                                  )
                                }
                                menuPortalTarget={document.body}
                              />
                            </Box>
                          </Td>
                          <Td>
                            <Tooltip hasArrow label={capitalEquipment?.note}>
                              <IconButton
                                icon={<MdOutlineModeComment />}
                                variant="link"
                                aria-label={capitalEquipment?.note}
                                color={
                                  capitalEquipment?.note
                                    ? 'primary.600'
                                    : 'gray.300'
                                }
                                size="lg"
                                onClick={() =>
                                  handleNoteDetailModal(capitalEquipment)
                                }
                              />
                            </Tooltip>
                            <NoteModal
                              isModalOpen={noteDetailModalOpen}
                              setModalOpen={setNoteDetailModalOpen}
                              fieldData={noteDetailFieldData}
                              onModalSubmit={(notes: string) =>
                                onNoteDetailModalSubmit(
                                  notes,
                                  noteDetailFieldData
                                )
                              }
                              formMutation={updateData}
                              errorMessage={errorMessage}
                            />
                            <Tooltip hasArrow label={'Delete'}>
                              <IconButton
                                icon={<BsTrash />}
                                variant="link"
                                aria-label={'Delete'}
                                color="gray.300"
                                size="lg"
                                onClick={() =>
                                  handleDetailDelete(capitalEquipment?.id)
                                }
                              />
                            </Tooltip>
                          </Td>
                          <Modal
                            isOpen={isDeleteModalOpen}
                            isCentered
                            onClose={onDeleteModalClose}>
                            <ModalOverlay />
                            <ModalContent>
                              <ModalHeader>
                                {'Delete Other Equipment'}
                              </ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                {'Are you sure you want to delete this ?'}
                                {deleteMsg && (
                                  <Alert status="error" mb={2}>
                                    <AlertIcon />
                                    {deleteMsg}
                                  </Alert>
                                )}
                              </ModalBody>
                              <ModalFooter>
                                <ButtonGroup>
                                  <Button
                                    colorScheme="red"
                                    onClick={() =>
                                      onDeleteConfirm(confirmDeleteId)
                                    }
                                    isLoading={deleteData.isLoading}>
                                    {'Delete'}
                                  </Button>

                                  <Button
                                    variant="outline"
                                    onClick={onDeleteModalClose}>
                                    {'Cancel'}
                                  </Button>
                                </ButtonGroup>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </Tr>
                      </React.Fragment>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {(isCapitalEquipmentListLoading ||
            (isOnline && !capitalEquipmentListQuery)) && (
            <CenterSpinner type="normal" />
          )}
        </Stack>
        {/* For Other Equipment */}
        <Divider />
        <Flex p={['3', '6']}>
          <Button
            variant={'outline'}
            onClick={() => {
              methods.reset();
              setAddModalOpen(true);
            }}>
            Add Other Equipment
          </Button>
        </Flex>

        {/* Add Modal */}
        <Modal
          isOpen={isAddModalOpen}
          isCentered
          onClose={onAddModalClose}
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Other Equipment'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack direction="column" spacing="4">
                    {addData.isError && (
                      <Alert status="error">
                        <AlertIcon />
                        {errorMessage}
                      </Alert>
                    )}
                    <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods?.formState?.errors?.equipmnet}
                          isRequired>
                          <FormLabel>Equipment</FormLabel>
                          <Controller
                            control={methods.control}
                            name="equipment"
                            rules={{
                              required: 'Equipment',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="equipment"
                                placeholder={'Equipment'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={
                                  isOnline
                                    ? isEquipmentLoading
                                    : offlineEquipmentList.isLoading
                                }
                                options={
                                  isOnline
                                    ? equipmentOptions
                                    : offlineEquipmentList.equipments
                                }
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods?.formState?.errors?.equipment &&
                              !!methods?.formState?.errors?.equipment?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.op_hour}
                          isRequired>
                          <FormLabel>{'Wet Hours'}</FormLabel>
                          <Input
                            type="number"
                            step="0.01"
                            placeholder={'Wet Hours'}
                            {...methods.register('op_hour', {
                              required: false,
                            })}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.op_hour &&
                              !!methods.formState.errors?.op_hour?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={
                            !!methods.formState.errors?.op_rate_per_hour
                          }>
                          <FormLabel>{'Wet Rate/Hr'}</FormLabel>
                          <Input
                            type="number"
                            step="0.01"
                            placeholder={'Wet Rate/Hr'}
                            {...methods.register('op_rate_per_hour', {
                              required: false,
                            })}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.op_rate_per_hour &&
                              !!methods.formState.errors?.op_rate_per_hour
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.business_unit}
                          isRequired>
                          <FormLabel>{'Business Unit'}</FormLabel>
                          <Controller
                            control={methods.control}
                            name="business_unit"
                            rules={{
                              required: 'Business Unit',
                            }}
                            defaultValue={
                              {
                                label: reportDetails?.business_unit?.name,
                                value: reportDetails?.business_unit?.id,
                              } || 'Select'
                            }
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="business_unit"
                                placeholder={'Select Business Unit'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={
                                  isOnline
                                    ? isBusinessUnitLoading
                                    : offlineBusinessUnitList.isLoading
                                }
                                options={
                                  isOnline
                                    ? businessUnitOptions
                                    : offlineBusinessUnitList.business_units
                                }
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.business_unit &&
                              !!methods.formState.errors?.business_unit
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.operation}
                          isRequired>
                          <FormLabel>{'Operation'}</FormLabel>
                          <Controller
                            control={methods.control}
                            name="operation"
                            rules={{
                              required: 'Operation',
                            }}
                            defaultValue={
                              {
                                label: reportDetails?.operation?.name,
                                value: reportDetails?.operation?.id,
                              } || 'Select'
                            }
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="operation"
                                placeholder={'Select Operation'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={
                                  isOnline
                                    ? isOperationLoading
                                    : offlineOperationList.isLoading
                                }
                                options={
                                  isOnline
                                    ? operationOptions
                                    : offlineOperationList.operations
                                }
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.operation &&
                              !!methods.formState.errors?.operation?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      {/* <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.dry_hour}>
                          <FormLabel>{'Dry Hours'}</FormLabel>
                          <Input
                            type="number"
                            step="0.01"
                            placeholder={'Dry Hours'}
                            {...methods.register('dry_hour', {
                              required: false,
                            })}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.dry_hour &&
                              !!methods.formState.errors?.dry_hour?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem> */}
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.note}>
                          <FormLabel>{'Note'}</FormLabel>
                          <Textarea
                            {...methods.register('note', {
                              required: false,
                            })}
                            placeholder="Note..."
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.note &&
                              !!methods.formState.errors?.note?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <ButtonGroup>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={addData.isLoading}>
                        Add
                      </Button>
                      <Button variant="outline" onClick={onAddModalClose}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default CapitalEquipmentReport;
