import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import PlantResource from 'api/plant';
import routes from 'constants/routes';
import { PlantListItemType } from 'interface/plant/plantSchema';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

const PlantListItem: React.FC<PlantListItemType> = (props) => {
  const { plantList, setPlantIds, plantIds } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const api = new PlantResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const [deleteMsg, setDeleteMsg] = useState('');

  const handleCheckBox = () => {
    const index = plantIds.indexOf(plantList.id);
    if (index > -1) {
      setPlantIds([...plantIds.slice(0, index), ...plantIds.slice(index + 1)]);
    } else {
      setPlantIds([...plantIds, plantList.id]);
    }
  };

  const deleteData = useMutation(() => api.destroy(plantList.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('plantList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: 'Plant has been deleted.',
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  return (
    <Tr key={plantList.id}>
      <Td>
        <Checkbox
          isChecked={plantIds.includes(plantList.id)}
          onChange={handleCheckBox}></Checkbox>
      </Td>
      <Td
        _hover={{ cursor: 'pointer' }}
        key={plantList.id}
        onClick={() =>
          navigate(
            routes.manage.plants.view.replace(':id', plantList.id.toString())
          )
        }>
        {plantList?.name}
      </Td>
      <Td>{plantList?.pit?.name}</Td>
      <Td>{plantList?.msha_identification}</Td>
      {/* <Td>{groupData(plantList?.business_unit)}</Td>
      <Td>{groupData(plantList?.operation)}</Td>
      <Td>{groupData(plantList?.shift)}</Td> */}
      <Td>
        <Link
          to={routes.manage.plants.view.replace(
            ':id',
            plantList?.id.toString()
          )}>
          <Tooltip hasArrow label={'View'}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={'View'}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </Link>
        <Link
          to={routes.manage.plants.edit.replace(
            ':id',
            plantList?.id.toString()
          )}>
          <Tooltip hasArrow label={'Edit'}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={'Edit'}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </Link>

        <Tooltip hasArrow label={'Delete'}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={'Delete'}
            color="red.300"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Plant'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete this ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default PlantListItem;
