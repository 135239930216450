import { Box, Flex, FormLabel, List, ListItem, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  plantPermission: [
    {
      label: string;
      value: string;
    }
  ];
  permissions: any;
}
const UserRightsManager: React.FC<Props> = (props) => {
  const { plantPermission, permissions } = props;
  const { register, setValue } = useFormContext();

  useEffect(() => {
    if (!plantPermission) return;
    plantPermission.map((plant: any, index: number) => {
      setValue(`permissions.${index}.plant_id`, plant?.value);
    });
  }, [setValue, plantPermission]);

  const getListItem = (plant: any, index: number) => {
    const permissionList = permissions.filter(
      (res: any) => res?.plant_id === plant?.value
    );

    return (
      <List spacing="6" color="gray.500" w={'150px'}>
        <ListItem>
          <Flex justify={'space-between'}>
            {permissionList[0]?.can_read ? (
              <>
                <input
                  type="checkbox"
                  id={`permissions.${index}.can_read`}
                  {...register(`permissions.${index}.can_read`)}
                  defaultChecked
                />
                <label
                  style={{
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    marginLeft: '0.5rem',
                  }}>
                  Read
                </label>
              </>
            ) : (
              <>
                <input
                  type="checkbox"
                  id={`permissions.${index}.can_read`}
                  {...register(`permissions.${index}.can_read`)}
                />
                <label
                  style={{
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    marginLeft: '0.5rem',
                  }}>
                  Read
                </label>
              </>
            )}

            {permissionList[0]?.can_write ? (
              <>
                <input
                  type="checkbox"
                  id={`permissions.${index}.can_write`}
                  {...register(`permissions.${index}.can_write`)}
                  defaultChecked
                />
                <label
                  style={{
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    marginLeft: '0.5rem',
                  }}>
                  Write
                </label>
              </>
            ) : (
              <>
                <input
                  type="checkbox"
                  id={`permissions.${index}.can_write`}
                  {...register(`permissions.${index}.can_write`)}
                />
                <label
                  style={{
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    marginLeft: '0.5rem',
                  }}>
                  Write
                </label>
              </>
            )}
          </Flex>
        </ListItem>
      </List>
    );
  };

  const plantPermissionRender = () => {
    if(plantPermission?.length > 0)
      return (
        <Box mt="4">
          <FormLabel>Permissions</FormLabel>
          {plantPermission?.map((plant: any, index: number) => {
            return (
              <Stack key={plant?.value}>
                <h3>{plant.label}</h3>
                {getListItem(plant, index)}
              </Stack>
            );
          })}
        </Box>
      )
    return <></>;
  }

  return (
    plantPermissionRender()
  );
};

export default UserRightsManager;
