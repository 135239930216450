import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import SaleResource from 'api/sale';
import { CenterSpinner } from 'components/common/CenterSpinner';
import SaleForm from 'components/sale/SaleForm';
import routes from 'constants/routes';
import { SaleFormSchema } from 'interface/sale/saleSchema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const EditSale: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<any>();
  const methods = useForm<SaleFormSchema>();

  const api = new SaleResource();

  const saleQuery = useQuery(
    [`sale${id}-edit`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updateSale = useMutation((data: any) => api.update(id, data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Sale has been updated.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('saleList');
      navigate(routes.sales.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Sale Update Failed.');
    },
  });

  const onSubmit = (data: any) => {
    const formData = {
      ...data,
      pit: data?.pit?.value,
      product: data?.product?.value,
      trucking_company: data?.trucking_company,
      job_class: data?.job_class?.value,
    };
    updateSale.mutate(formData);
  };

  if (saleQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (saleQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {'Sale Not Found.'}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">{'Edit Sale'}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updateSale.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <SaleForm saleData={saleQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updateSale.isLoading}>
                  Update
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditSale;
