import Resource from 'api/resource';
import http from 'utils/http';

class ShiftDownTimeReportResource extends Resource {
  constructor() {
    super('reports');
  }

  listShiftDownTimeReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/shift-downtime`,
      method: 'get',
      params: query,
    });
  }
  getShiftDownTimeReport(id: string, ShiftDownTimeId: string) {
    return http({
      url: `/reports/${id}/shift-downtime/${ShiftDownTimeId}`,
      method: 'get',
    });
  }
  storeShiftDownTimeReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/shift-downtime`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateShiftDownTimeReport(id: string, resource: any) {
    return http({
      url: `/reports/${id}/shift-downtime`,
      method: 'put',
      data: resource,
    });
  }
  destroyShiftDownTimeReport(id: string, ShiftDownTimeId: string) {
    return http({
      url: `/reports/${id}/shift-downtime/${ShiftDownTimeId}`,
      method: 'delete',
    });
  }

  listShiftDownTimeDetailReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/shift-downtime-detail`,
      method: 'get',
      params: query,
    });
  }
  getShiftDownTimeDetailReport(id: string, ShiftDownTimeDetailId: string) {
    return http({
      url: `/reports/${id}/shift-downtime-detail/${ShiftDownTimeDetailId}`,
      method: 'get',
    });
  }
  storeShiftDownTimeDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/shift-downtime-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateShiftDownTimeDetailReport(
    id: string,
    ShiftDownTimeDetailId: string,
    resource: any
  ) {
    return http({
      url: `/reports/${id}/shift-downtime-detail/${ShiftDownTimeDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyShiftDownTimeDetailReport(id: string, ShiftDownTimeDetailId: string) {
    return http({
      url: `/reports/${id}/shift-downtime-detail/${ShiftDownTimeDetailId}`,
      method: 'delete',
    });
  }
}

export { ShiftDownTimeReportResource as default };
