import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import RegionResource from 'api/region';
import OwnerResource from 'api/user';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CreatedAtInput from 'components/common/CreatedAtInput';
import { REACT_DATE_PICKER_FORMAT_YYYY_MM_DD } from 'constants/common';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { DateFormatMDY } from 'utils';

const PitForm: React.FC<any> = (props) => {
  const { pitData } = props;
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<any>();
  const leastExpirationDate =
    pitData && pitData?.lease_expiration
      ? new Date(pitData?.lease_expiration)
      : null;
  const [createdAt, setCreatedAt] = useState<Date | null>(leastExpirationDate);

  // Region List
  const regionAPI = new RegionResource();
  const { data: regionQuery, isLoading: isRegionLoading } = useQuery(
    ['region-list'],
    () => regionAPI.list({ limit: 50 }).then((res) => res?.data),
    // async () => {
    //   if (!navigator.onLine) {
    //     const cachedResponse = await getCachedResponseFromCache('region-list');
    //     if (cachedResponse) {
    //       return cachedResponse;
    //     }
    //   }
    //   // If not offline or no cached response, fetch the data from the API
    //   const response = await regionAPI.list({ limit: 50 });
    //   return response?.data;
    // },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const regionList = regionQuery?.results;
  const regionOptions = regionList?.map((region: any) => ({
    label: region.name,
    value: region.id,
  }));

  // Owner List
  const ownerAPI = new OwnerResource();
  const { data: ownerQuery, isLoading: isOwnerLoading } = useQuery(
    [`owner-list`],
    () => ownerAPI.list({ limit: 50 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const ownerList = ownerQuery?.results;
  const ownerOptions = ownerList?.map((owner: any) => ({
    label: `${owner?.first_name} ${owner?.last_name}`,
    value: owner.id,
  }));

  const handleDateChange = (date: Date) => {
    setCreatedAt(date);
    setValue('lease_expiration', DateFormatMDY(date));
  };

  useEffect(() => {
    if (!pitData) return;
    setValue('lease_expiration', new Date(pitData?.lease_expiration));
    setValue('region', {
      label: pitData?.region?.name,
      value: pitData?.region?.id,
    });
    if (pitData?.owner?.id)
      setValue('owner', {
        label: `${pitData?.owner?.first_name} ${pitData?.owner?.last_name}`,
        value: pitData?.owner?.id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pitData, setValue]);

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.name} isRequired>
            <FormLabel>Pit</FormLabel>
            <Input
              {...register('name', {
                required: 'Pit Name',
                maxLength: {
                  value: 100,
                  message: 'Too many characters.',
                },
              })}
              type="text"
              defaultValue={pitData?.name}
              placeholder={'Pit Name'}
            />
            <FormErrorMessage>
              {errors?.name && errors?.name?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.address}>
            <FormLabel>{'Address'}</FormLabel>
            <Input
              {...register('address', {
                required: false,
              })}
              type="text"
              defaultValue={pitData?.address}
              placeholder={'Address'}
            />
            <FormErrorMessage>
              {!!errors?.address && !!errors?.address?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.coordinates}>
            <FormLabel>{'Coordinates'}</FormLabel>
            <Input
              {...register('coordinates', {
                required: false,
              })}
              type="text"
              defaultValue={pitData?.coordinates}
              placeholder={'Coordinates'}
            />
            <FormErrorMessage>
              {!!errors?.coordinates && !!errors?.coordinates?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.royalty}>
            <FormLabel>{'Royalty'}</FormLabel>
            <Input
              {...register('royalty', {
                required: false,
              })}
              type="number"
              step="0.01"
              defaultValue={pitData?.royalty}
              placeholder={'Royalty'}
            />
            <FormErrorMessage>
              {!!errors?.royalty && !!errors?.royalty?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.lease_expiration}>
            <FormLabel>{'Lease Expiration'}</FormLabel>
            <ReactDatePicker
              fixedHeight
              isClearable
              dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
              customInput={<CreatedAtInput />}
              onChange={handleDateChange}
              selected={createdAt}
              value={DateFormatMDY(createdAt)}
              shouldCloseOnSelect={true}
              autoComplete="off"
            />
            <FormErrorMessage>
              {!!errors?.lease_expiration &&
                !!errors?.lease_expiration?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.minimum_royalty}>
            <FormLabel>{'Minimum Royalty'}</FormLabel>
            <Input
              {...register('minimum_royalty', {
                required: false,
              })}
              type="number"
              step="0.01"
              defaultValue={pitData?.minimum_royalty.toString()}
              placeholder={'Minimum Royalty'}
            />
            <FormErrorMessage>
              {!!errors?.minimum_royalty && !!errors?.minimum_royalty?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.royalty_payment_terms}>
            <FormLabel>{'Payment Term'}</FormLabel>
            <Input
              {...register('royalty_payment_terms', {
                required: false,
              })}
              type="text"
              defaultValue={pitData?.royalty_payment_terms}
              placeholder={'Payment Term'}
            />
            <FormErrorMessage>
              {!!errors?.royalty_payment_terms &&
                !!errors?.royalty_payment_terms?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.region} isRequired>
            <FormLabel>{'Region'}</FormLabel>
            <Controller
              control={control}
              name="region"
              rules={{
                required: 'Region',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="region"
                  placeholder={'Region'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isRegionLoading}
                  options={regionOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.region && errors?.region?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.owner}>
            <FormLabel>{'Owner'}</FormLabel>
            <Controller
              control={control}
              name="owner"
              rules={{
                required: false,
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="owner"
                  placeholder={'Owner'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isOwnerLoading}
                  options={ownerOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.owner && errors?.owner?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default PitForm;
