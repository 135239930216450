import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import BusinessUnitResource from 'api/businessUnit';
import EquipmentResource from 'api/equipment';
import LaborDetailReportResource from 'api/laborDetail';
import LaborReportResource from 'api/laborReport';
import OperationResource from 'api/operation';
import ReportResource from 'api/report';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import TagModal from 'components/common/TagModal';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useDebounceFunc from 'hooks/useDebounceFunc';
import useReportDropDown from 'hooks/useReportDropDown';
import { ReportSearchInterface } from 'interface/report/reportSchema';
import React, { useContext, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';

interface Props {
  laborId: number;
  reportId: string;
}

const LaborDetailReport: React.FC<Props> = (props) => {
  const { laborId, reportId } = props;

  const toast = useToast();
  const methods = useForm<any>();
  const laborDetailAPI = new LaborDetailReportResource();
  const reportAPI = new ReportResource();
  const laborAPI = new LaborReportResource();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [tagData, setTagData] = useState(0);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);
  const { previousReportId, setReportBody, reportBody } = useContext(OfflineContext);
  const offlineBusinessUnitList = useSelector((state: any) => state.data.offline.business_units)
  const offlineLabourActivitiesList = useSelector((state: any) => state.data.offline.activities)
  const offlineEquipmentList = useSelector((state: any) => state.data.offline.equipments)
  const offlineOperationList = useSelector((state: any) => state.data.offline.operations)
  const [filterParams, setFilterParams] = useState<ReportSearchInterface>({
    searchBusinessUnit: '',
    searchOperation: '',
  });

  const {
    isEmployeeLoading,
    // isEquipmentLoading,
    // equipmentOptions,
    isAfeLoading,
    afeOptions,
    isActivityLoading,
    activityOptions,
  } = useReportDropDown();

  const isOnline = useConnectionStatus();

  // BusinessUnit List
  const businessUnitAPI = new BusinessUnitResource();
  const { data: businessUnitQuery, isLoading: isBusinessUnitLoading } =
    useQuery<any>(
      [
        `businessunit-list`,
        {
          search: filterParams.searchBusinessUnit,
        },
      ],
      () => {
        return businessUnitAPI
          .list({
            search: filterParams.searchBusinessUnit,
            limit: 10000,
          })
          .then((res) => res?.data);
      },
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );
  const businessUnitList = businessUnitQuery?.results;
  const businessUnitOptions = businessUnitList?.map((businessUnit: any) => ({
    label: businessUnit.name,
    value: businessUnit.id,
  }));

  // Operation List
  const operationAPI = new OperationResource();
  const { data: operationQuery, isLoading: isOperationLoading } = useQuery<any>(
    [
      `operation-list`,
      {
        search: filterParams.searchOperation,
      },
    ],
    () => {
      return operationAPI
        .list({
          search: filterParams.searchOperation,
          limit: 10000,
        })
        .then((res) => res?.data);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const operationList = operationQuery?.results;
  const operationOptions = operationList?.map((operation: any) => ({
    label: operation.name,
    value: operation.id,
  }));

  // Equipment List
  const equipmentAPI = new EquipmentResource();
  const { data: equipmentQuery, isLoading: isEquipmentLoading } = useQuery(
    [`getLaborActivityEquipment`],
    () =>
      laborAPI
        .getLaborActivityEquipment(reportId, {
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const equipmentList = equipmentQuery?.results;
  const equipmentOptions = equipmentList
    ? [
      { label: '', value: null },
      ...equipmentList.map((equipment: any) => ({
        label: equipment.name,
        value: equipment.id,
      })),
    ]
    : [];

  // Labor activities
  const { data: laborListQuery, isLoading: isLaborListLoading } = useQuery(
    [`labor-detail-list`, { laborId }],
    () =>
      laborDetailAPI
        .listLaborDetailReport(laborId, {
          limit: 5000,
        })
        .then((res) => {
          return res?.data;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: reportDetails } = useQuery(
    [`reportDetails`, { reportId }],
    async () => {
      const response = await reportAPI.get(reportId);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const addData = useMutation(
    (data: any) => laborDetailAPI.storeLaborDetailReport(laborId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['labor-detail-list', { laborId }]);
        queryClient.invalidateQueries('labor-list');
        queryClient.invalidateQueries('capital-equipment-list');
        queryClient.invalidateQueries('loadout-detail-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-list');
        setAddModalOpen(false);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey && objKey[0] === 'error') {
          setErrorMessage(error?.response?.data?.error);
        } else if (objKey) {
          setErrorMessage(error?.response?.data[objKey[0]][0]);
        } else {
          setErrorMessage('Create Failed.');
        }
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      afe: data?.afe?.value,
      activity: data?.activity?.value,
      equipment: data?.equipment?.value || null,
      business_unit: data?.business_unit?.value,
      operation: data?.operation?.value,
      hours: data?.hours,
    };
    addData.mutate(formData);
  };

  const updateData = useMutation(
    (data: any) =>
      laborDetailAPI.updateLaborDetailReport(laborId, data?.id, data?.body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['labor-detail-list', { laborId }]);
        queryClient.invalidateQueries('labor-list');
        queryClient.invalidateQueries('loadout-detail-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-list');
        queryClient.invalidateQueries('capital-equipment-list');
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        let errMsg = 'Create Failed.';
        if (objKey && objKey[0] === 'error') {
          errMsg = error?.response?.data?.error;
        } else if (objKey) {
          errMsg = error?.response?.data[objKey[0]][0];
        }
        queryClient.invalidateQueries(['labor-detail-list', { laborId }]);
        queryClient.invalidateQueries('labor-list');
        queryClient.invalidateQueries('loadout-detail-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-list');
        queryClient.invalidateQueries('capital-equipment-list');

        toast({
          title: errMsg,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
  const handleChange = (
    fieldData: any,
    fieldValue: string,
    fieldType: string
  ) => {
    let data: any = {
      body: { afe: 0, activity: 0, equipment: 0, hours: 0 },
      id: 0,
    };
    data.id = fieldData.id;
    switch (fieldType) {
      case 'afe':
        data.body.afe = +fieldValue;
        data.body.activity = fieldData?.activity.id;
        data.body.equipment = fieldData?.equipment.id;
        data.body.business_unit = fieldData?.business_unit.id;
        data.body.operation = fieldData?.operation.id;
        data.body.hours = fieldData?.hours;
        break;
      case 'activity':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = +fieldValue;
        data.body.equipment = fieldData?.equipment.id;
        data.body.business_unit = fieldData?.business_unit.id;
        data.body.operation = fieldData?.operation.id;
        data.body.hours = fieldData?.hours;
        break;
      case 'equipment':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = fieldData?.activity.id;
        data.body.equipment = fieldValue === '0' ? null : +fieldValue;
        data.body.business_unit = fieldData?.business_unit.id;
        data.body.operation = fieldData?.operation.id;
        data.body.hours = fieldData?.hours;
        break;
      case 'business_unit':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = fieldData?.activity.id;
        data.body.equipment = fieldData?.equipment.id;
        data.body.business_unit = +fieldValue;
        data.body.operation = fieldData?.operation.id;
        data.body.hours = fieldData?.hours;
        break;
      case 'operation':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = fieldData?.activity.id;
        data.body.equipment = fieldData?.equipment.id;
        data.body.business_unit = fieldData?.business_unit.id;
        data.body.operation = +fieldValue;
        data.body.hours = fieldData?.hours;
        break;
      case 'hours':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = fieldData?.activity.id;
        data.body.equipment = fieldData?.equipment.id;
        data.body.business_unit = fieldData?.business_unit.id;
        data.body.operation = fieldData?.operation.id;
        data.body.hours = fieldValue;
        break;
    }
    updateData.mutate(data);
  };
  const debounce = useDebounceFunc(handleChange, 3000);

  const handleDetailDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: number) => laborDetailAPI.destroyLaborDetailReport(laborId, id + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(['labor-detail-list', { laborId }]);
        queryClient.invalidateQueries('labor-list');
        queryClient.invalidateQueries('loadout-detail-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-list');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (id != 0) deleteData.mutate(id);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    queryClient.invalidateQueries(['labor-detail-list', { laborId }]);
    queryClient.invalidateQueries('labor-list');
    queryClient.invalidateQueries('loadout-detail-list');
    queryClient.invalidateQueries('getLoadoutStatus');
    queryClient.invalidateQueries('loadout-list');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      return;
    } else if (type === 'tagDetail') {
      setTagData(tagData?.tag);
      setTagModalOpen(true);
      return;
    }
    // }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <Tbody>
            <Tr bg={'gray300'}>
              <Td>AFE</Td>
              <Td>Activity</Td>
              <Td>Equipment</Td>
              <Td>Business Unit</Td>
              <Td>Operation</Td>
              <Td>Hours</Td>
              {/* <Td>Change Details</Td> */}
              <Td></Td>
            </Tr>
            {laborListQuery &&
              laborListQuery?.results?.map(
                (labor: any, indexNumber: number) => (
                  <React.Fragment key={labor?.id}>
                    <Tr>
                      <Td>
                        <ReactSelect
                          defaultValue={
                            {
                              label: labor?.afe?.name,
                              value: labor?.afe?.id,
                            } || 'Select'
                          }
                          placeholder={'Select Afe'}
                          isLoading={isAfeLoading}
                          options={afeOptions}
                          styles={reactSelectStyles}
                          isSearchable={true}
                          onChange={(e: any) =>
                            handleChange(labor, e.value, 'afe')
                          }
                          menuPortalTarget={document.body}
                        />
                      </Td>
                      <Td>
                        {/* <ReactSelect
                      defaultValue={{
                        label: labor?.activity?.name,
                        value: labor?.activity?.id,
                      }}
                      placeholder={'Select Activity'}
                      isLoading={isActivityLoading}
                      options={activityOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onChange={(e: any) => {
                        handleChange(labor, e.value, 'activity');
                      }}
                      menuPortalTarget={document.body}
                    /> */}
                        {labor?.activity?.name}
                      </Td>
                      <Td>
                        <ReactSelect
                          defaultValue={{
                            label: labor?.equipment?.name,
                            value: labor?.equipment?.id,
                          }}
                          placeholder={'Select Equipment'}
                          isLoading={isOnline ? isEquipmentLoading : offlineEquipmentList.isLoading}
                          options={isOnline ? equipmentOptions : offlineEquipmentList.equipments}
                          styles={reactSelectStyles}
                          isSearchable={true}
                          onChange={(e: any) =>
                            handleChange(
                              labor,
                              e.value === null ? '0' : e.value,
                              'equipment'
                            )
                          }
                          menuPortalTarget={document.body}
                        />
                      </Td>
                      <Td>
                        <ReactSelect
                          defaultValue={{
                            label: labor?.business_unit?.name,
                            value: labor?.business_unit?.id,
                          }}
                          placeholder={'Select Business Unit'}
                          isLoading={isOnline ? isBusinessUnitLoading : offlineBusinessUnitList.isLoading}
                          options={isOnline ? businessUnitOptions : offlineBusinessUnitList.business_units}
                          styles={reactSelectStyles}
                          isSearchable={true}
                          onChange={(e: any) =>
                            handleChange(labor, e.value, 'business_unit')
                          }
                          menuPortalTarget={document.body}
                        />
                      </Td>
                      <Td>
                        <ReactSelect
                          defaultValue={{
                            label: labor?.operation?.name,
                            value: labor?.operation?.id,
                          }}
                          placeholder={'Select Operation'}
                          isLoading={isOnline ? isOperationLoading : offlineOperationList.isLoading}
                          options={isOnline ? operationOptions : offlineOperationList.operations}
                          styles={reactSelectStyles}
                          isSearchable={true}
                          onChange={(e: any) =>
                            handleChange(labor, e.value, 'operation')
                          }
                          menuPortalTarget={document.body}
                        />
                      </Td>
                      <Td>
                        <Input
                          defaultValue={labor?.hours}
                          type="number"
                          onChange={(e: any) =>
                            debounce(labor, e.target.value, 'hours')
                          }
                        />
                      </Td>
                      {/* <Td>+</Td> */}
                      <Td>
                        <Tooltip hasArrow label={'Tag'}>
                          <IconButton
                            icon={<BsTag />}
                            variant="link"
                            aria-label={'Tag'}
                            color={'gray.300'}
                            size="lg"
                            onClick={() => handleTagModal(labor, 'tagDetail')}
                          />
                        </Tooltip>
                        {tagData === labor?.tag && (
                          <TagModal
                            isModalOpen={tagModalOpen}
                            setModalOpen={setTagModalOpen}
                            fieldData={tagData}
                            onModalSubmit={() => onTagModalSubmit()}
                          />
                        )}
                        <Tooltip hasArrow label={'Delete'}>
                          <IconButton
                            icon={<BsTrash />}
                            variant="link"
                            aria-label={'Delete'}
                            color="gray.300"
                            size="lg"
                            onClick={() => handleDetailDelete(labor?.id)}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>

                    {/* Delete Modal */}
                    <Modal
                      isOpen={isDeleteModalOpen}
                      isCentered
                      onClose={onDeleteModalClose}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>{'Delete Activity'}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          {'Are you sure you want to delete this ?'}
                          {deleteMsg && (
                            <Alert status="error" mb={2}>
                              <AlertIcon />
                              {deleteMsg}
                            </Alert>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <ButtonGroup>
                            <Button
                              colorScheme="red"
                              onClick={() => onDeleteConfirm(confirmDeleteId)}
                              isLoading={deleteData.isLoading}>
                              {'Delete'}
                            </Button>

                            <Button
                              variant="outline"
                              onClick={onDeleteModalClose}>
                              {'Cancel'}
                            </Button>
                          </ButtonGroup>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </React.Fragment>
                )
              )}
          </Tbody>
          <Tfoot>
            <Flex p={['3', '6']}>
              <Button
                variant={'outline'}
                onClick={() => {
                  methods.reset();
                  setAddModalOpen(true);
                }}>
                Add Activity
              </Button>
            </Flex>
          </Tfoot>
        </Table>
        {isOnline && !laborListQuery && <CenterSpinner type="normal" />}
      </TableContainer>

      {/* Add Modal */}
      <Modal
        isOpen={isAddModalOpen}
        isCentered
        onClose={onAddModalClose}
        closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Add Activity'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack direction="column" spacing="4">
                  {addData.isError && (
                    <Alert status="error">
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                  <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.type}
                        isRequired>
                        <FormLabel>AFE</FormLabel>
                        <Controller
                          control={methods.control}
                          name="afe"
                          rules={{
                            required: 'AFE',
                          }}
                          defaultValue={
                            {
                              label: reportDetails?.afe?.name,
                              value: reportDetails?.afe?.id,
                            } || 'Select'
                          }
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="afe"
                              placeholder={'Select AFE'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isAfeLoading}
                              options={afeOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods?.formState?.errors?.afe &&
                            !!methods?.formState?.errors?.afe?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.activity}
                        isRequired>
                        <FormLabel>{'Activity'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="activity"
                          rules={{
                            required: 'Activity',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="activity"
                              placeholder={'Select Activity'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isOnline ? isActivityLoading : offlineLabourActivitiesList.isLoading}
                              options={isOnline ? activityOptions : offlineLabourActivitiesList.activities}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.activity &&
                            !!methods.formState.errors?.activity?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.equipment}>
                        <FormLabel>{'Equipment'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="equipment"
                          rules={{
                            required: false,
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="equipment"
                              placeholder={'Select Equipment'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isOnline ? isEquipmentLoading : offlineEquipmentList.isLoading}
                              options={isOnline ? equipmentOptions : offlineEquipmentList.equipments}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.equipment &&
                            !!methods.formState.errors?.equipment?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.business_unit}
                        isRequired>
                        <FormLabel>{'Business Unit'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="business_unit"
                          rules={{
                            required: 'Business Unit',
                          }}
                          defaultValue={
                            {
                              label: reportDetails?.business_unit?.name,
                              value: reportDetails?.business_unit?.id,
                            } || 'Select'
                          }
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="business_unit"
                              placeholder={'Select Business Unit'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isOnline ? isBusinessUnitLoading : offlineBusinessUnitList.isLoading}
                              options={isOnline ? businessUnitOptions : offlineBusinessUnitList.business_units}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.business_unit &&
                            !!methods.formState.errors?.business_unit?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.operation}
                        isRequired>
                        <FormLabel>{'Operation'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="operation"
                          rules={{
                            required: 'Operation',
                          }}
                          defaultValue={
                            {
                              label: reportDetails?.operation?.name,
                              value: reportDetails?.operation?.id,
                            } || 'Select'
                          }
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="operation"
                              placeholder={'Select Operation'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isOnline ? isOperationLoading : offlineOperationList.isLoading}
                              options={isOnline ? operationOptions : offlineOperationList.operations}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.operation &&
                            !!methods.formState.errors?.operation?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.hours}
                        isRequired>
                        <FormLabel>{'Hours'}</FormLabel>
                        <Input
                          {...methods.register('hours', {
                            required: 'Hours',
                          })}
                          placeholder="Hours"
                        />
                      </FormControl>
                      <FormErrorMessage>
                        {!!methods.formState.errors?.hours &&
                          !!methods.formState.errors?.hours?.message}
                      </FormErrorMessage>
                    </GridItem>
                  </Grid>
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={addData.isLoading}>
                      Add
                    </Button>
                    <Button variant="outline" onClick={onAddModalClose}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Stack>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LaborDetailReport;
