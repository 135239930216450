import Resource from 'api/resource';
import http from 'utils/http';

class AssignedEquipmentReportResource extends Resource {
  constructor() {
    super('reports');
  }

  mechanicListAssignedEquipmentReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment`,
      method: 'get',
      params: query,
    });
  }
  mechanicGetAssignedEquipmentReport(id: string, assignedEquipmentId: string) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment/${assignedEquipmentId}`,
      method: 'get',
    });
  }
  mechanicStoreAssignedEquipmentReport(
    id: string,
    resource: any,
    headers?: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicUpdateAssignedEquipmentReport(id: string, resource: any) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment`,
      method: 'put',
      data: resource,
    });
  }
  mechanicDestroyAssignedEquipmentReport(
    id: string,
    assignedEquipmentId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment/${assignedEquipmentId}`,
      method: 'delete',
    });
  }

  mechanicListAssignedEquipmentDetailReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment-detail`,
      method: 'get',
      params: query,
    });
  }
  mechanicGetAssignedEquipmentDetailReport(
    id: string,
    assignedEquipmentDetailId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment-detail/${assignedEquipmentDetailId}`,
      method: 'get',
    });
  }
  mechanicStoreAssignedEquipmentDetailReport(
    id: string,
    resource: any,
    headers?: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicUpdateAssignedEquipmentDetailReport(
    id: string,
    assignedEquipmentDetailId: string,
    resource: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment-detail/${assignedEquipmentDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  mechanicDestroyAssignedEquipmentDetailReport(
    id: string,
    assignedEquipmentDetailId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/assigned-equipment-detail/${assignedEquipmentDetailId}`,
      method: 'delete',
    });
  }
}

export { AssignedEquipmentReportResource as default };
