import { Checkbox, IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import routes from 'constants/routes';
import { InventorySchemaType } from 'interface/inventory/inventorySchema';
import React from 'react';
import { BiShow } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { formatNumber } from 'utils';

const ProductionListItem: React.FC<InventorySchemaType> = (props) => {
  const { listData, index, setInventoryIds, inventoryIds } = props;
  const navigate = useNavigate();

  const handleCheckBox = () => {
    const index = inventoryIds.indexOf(listData.id);
    if (index > -1) {
      setInventoryIds([
        ...inventoryIds.slice(0, index),
        ...inventoryIds.slice(index + 1),
      ]);
    } else {
      setInventoryIds([...inventoryIds, listData.id]);
    }
  };

  return (
    <Tr key={listData.id}>
      <Td>
        <Checkbox
          isChecked={inventoryIds.includes(listData.id)}
          onChange={handleCheckBox}></Checkbox>
      </Td>
      <Td
        _hover={{ cursor: 'pointer' }}
        key={listData.id}
        onClick={() =>
          navigate(
            routes.productions.view.replace(':id', listData.id.toString())
          )
        }>
        {listData?.product_name}
      </Td>
      <Td>{listData.date}</Td>
      <Td>{listData.product_type}</Td>
      <Td>{listData.pit}</Td>
      <Td>{listData.plant}</Td>
      <Td>{listData.shift}</Td>
      <Td>{listData.pay}</Td>
      <Td>{formatNumber(+listData.reported_production)}</Td>
      <Td>{formatNumber(+listData.adjusted_production)}</Td>
      <Td>{formatNumber(+listData.net_production)}</Td>
      <Td>
        <Link
          to={routes.productions.view.replace(':id', listData?.id.toString())}>
          <Tooltip hasArrow label={'View'}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={'View'}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </Link>
      </Td>
    </Tr>
  );
};

export default ProductionListItem;
