import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import InventoryResource from 'api/production';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import { InventorySchema } from 'interface/inventory/inventorySchema';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { formatNumber } from 'utils';

const ViewProduction: React.FC = () => {
  const { id }: any = useParams();
  const api = new InventoryResource();
  const navigate = useNavigate();

  const dataQuery = useQuery<InventorySchema>(
    [`inventory${id}`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.productions.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            Production Details
          </Heading>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Product</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.product_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Date</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.date || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Product Type</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.product_type || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Pit</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.pit || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Plant</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.plant || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Shift</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.shift || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Pay</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.pay || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Reported Production</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.reported_production &&
                    formatNumber(+dataQuery?.data?.reported_production, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Adjusted Production</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.adjusted_production &&
                    formatNumber(+dataQuery?.data?.adjusted_production, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Adjusted value</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.adjusted_value &&
                    formatNumber(+dataQuery?.data?.adjusted_value, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Adjusted Type</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.adjustment_type || '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewProduction;
