import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import EquipmentResource from 'api/equipment';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import LogListItem from 'components/equipment/LogListItem';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  OperatedStatusEnum,
  OwnerOrRent,
} from 'constants/common';
import routes from 'constants/routes';
import { FilterParams } from 'interface/common/filterParam';
import { EquipmentSchema } from 'interface/equipment/equipmentSchema';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DateFormatMDY, formatNumber } from 'utils';

const ViewEquipment: React.FC = () => {
  const { id }: any = useParams();
  const api = new EquipmentResource();
  const navigate = useNavigate();
  const ownerRentEnum: any = OwnerOrRent;
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<string | null>();
  const operatedStatusEnum: any = OperatedStatusEnum;
  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'equipment-log-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const response = await api.logs(id, queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const dataQuery = useQuery<EquipmentSchema>(
    [`equipment${id}`, id],
    () =>
      api.get(id).then((res) => {
        return res?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.manage.equipments.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            Equipment Details
          </Heading>
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() =>
              navigate(routes.manage.equipments.edit.replace(':id', id))
            }>
            {'Edit Equipment'}
          </Button>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Name</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Description</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.description || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Equipment class</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.equipment_class?.class_code || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Code</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.code || '-'}</Box>
              </FormControl>
            </GridItem>
            {/* <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Optional</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.optional &&
                    (dataQuery?.data?.optional ? 'Yes' : 'No')) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem> */}
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Owner, Rent, or RPO</FormLabel>
                <Box sx={infoStyles}>
                  {ownerRentEnum[dataQuery?.data?.owner_or_rent] || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Owner</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.owner || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Physical Location</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.location || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Party</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.party || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Wet rate</FormLabel>
                <Box sx={infoStyles}>
                  {' '}
                  {(dataQuery?.data?.wet_rate &&
                    formatNumber(+dataQuery?.data?.wet_rate, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Dry rate</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.dry_rate &&
                    formatNumber(+dataQuery?.data?.dry_rate, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Beginning date</FormLabel>
                <Box sx={infoStyles}>
                  {DateFormatMDY(dataQuery?.data?.beginning_date) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>End date</FormLabel>
                <Box sx={infoStyles}>
                  {DateFormatMDY(dataQuery?.data?.end_date) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Serial number</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.serial_number || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Fuel/hr</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.fuel_per_hour &&
                    formatNumber(+dataQuery?.data?.fuel_per_hour, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Monthly rental</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.monthly_rental &&
                    formatNumber(+dataQuery?.data?.monthly_rental, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Equipment Cost</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.cost &&
                    formatNumber(+dataQuery?.data?.cost, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Wear/hr. Insurance</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.wear_per_hour_insurance &&
                    formatNumber(
                      +dataQuery?.data?.wear_per_hour_insurance,
                      2
                    )) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Yard Accrual</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.yard_accural &&
                    formatNumber(+dataQuery?.data?.yard_accural, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>R+M/hr</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.r_m_per_hour &&
                    formatNumber(+dataQuery?.data?.r_m_per_hour, 2)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Budgeted Operating Hours</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.budgeted_operating_hours &&
                    formatNumber(
                      +dataQuery?.data?.budgeted_operating_hours,
                      2
                    )) ||
                    '0'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Budget Idle Hours Month</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.budgeted_idle_hours_month &&
                    formatNumber(
                      +dataQuery?.data?.budgeted_idle_hours_month,
                      2
                    )) ||
                    '0'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Note</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.note || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Plant</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.plant?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Operated/Non Operated</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.operated_status &&
                    operatedStatusEnum[
                      dataQuery?.data?.operated_status
                    ].toString()}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>

      <Stack direction="column" spacing="4" mt={5}>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            Equipment Log Details
          </Heading>
        </Flex>
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Equipment</Th>
                  <Th>Plant</Th>
                  <Th>Pit</Th>
                  <Th>Beginning Date</Th>
                  <Th>End Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map((listData: any, index: number) => (
                    <LogListItem listData={listData} key={listData.id} />
                  ))}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default ViewEquipment;
