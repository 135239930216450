import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import AFEResource from 'api/afe';
import AFEProductPriceForm from 'components/afe/AFEProductPriceForm';
import AFEProductPriceListItem from 'components/afe/AFEProductPriceListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const AFEProductPriceList: React.FC<any> = (props) => {
  const { afeId } = props;
  const [isLoading, setIsLoading] = useState<string | null>();
  const api = new AFEResource();
  const navigate = useNavigate();
  const methods = useForm<any>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errMsgAdd, setErrMsgAdd] = useState<any>();

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'afeProductPriceList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        afeId,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
      };

      const response = await api.getProductPrice(afeId, queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const addProductPrice = useMutation(
    (data: any) => api.storeProductPrice(afeId, data),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Product Price has been created.',
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('afeProductPriceList');
        setErrMsgAdd('');
        setAddModalOpen(false);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrMsgAdd(error?.response?.data[objKey[0]][0]);
        else setErrMsgAdd('Product Price Creation Failed.');
      },
    }
  );

  const onSubmit = async (data: any) => {
    const formData = {
      product: data?.product?.value,
      price: data?.price || 0,
    };
    addProductPrice.mutate(formData);
  };

  const onAddPress = () => {
    setErrMsgAdd('');
    methods.reset();
    setAddModalOpen(true);
  };

  const onAddModalClose = () => {
    setErrMsgAdd('');
    setAddModalOpen(false);
  };

  return (
    <>
      <Stack direction="column" spacing="4" mt={5} mb={5}>
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">AFE Product Price List</Heading>
          </Stack>

          <Stack direction="row">
            <Button size="sm" colorScheme="primary" onClick={onAddPress}>
              Add AFE Product Price
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Product</Th>
                  <Th>Price</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map((listData: any, index: number) => (
                    <AFEProductPriceListItem
                      listData={listData}
                      key={listData.id}
                      afeId={afeId}
                    />
                  ))}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={3} />
                )}
              </Tbody>
            </Table>
          </TableContainer>

          <Modal isOpen={isAddModalOpen} isCentered onClose={onAddModalClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{'Add AFE Product Price'}</ModalHeader>
              <ModalCloseButton />
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack direction="column" spacing="4">
                    {errMsgAdd && (
                      <Alert status="error">
                        <AlertIcon />
                        {errMsgAdd}
                      </Alert>
                    )}
                    <ModalBody>
                      <AFEProductPriceForm />
                    </ModalBody>
                    <ModalFooter>
                      <ButtonGroup>
                        <Button
                          colorScheme="blue"
                          type="submit"
                          isLoading={addProductPrice.isLoading}>
                          {'Submit'}
                        </Button>

                        <Button variant="outline" onClick={onAddModalClose}>
                          {'Cancel'}
                        </Button>
                      </ButtonGroup>
                    </ModalFooter>
                  </Stack>
                </form>
              </FormProvider>
            </ModalContent>
          </Modal>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default AFEProductPriceList;
