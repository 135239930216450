import { Box, Divider, Heading, Stack } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  reportId?: number;
}

const MechanicalReportSidebar: React.FC<Props> = (props) => {
  const { title, reportId = 0 } = props;
  return (
    <>
      <Stack direction={'column'} border={'1px solid #C5C6C9'} bg={'#fff'}>
        <Box p={4}>
          <Heading size="md">{title}</Heading>
          <Heading size="md">Daily Report</Heading>
        </Box>
        <Divider />
        <Box p={4}>
          <Stack
            direction={'column'}
            spacing={'24px'}
            fontWeight={500}
            color={'#737373'}>
            <Link
              to={
                reportId > 0
                  ? routes.reports.mechanicalReport.operationDetailReport +
                    `?report_id=${reportId}`
                  : routes.reports.mechanicalReport.operationDetailReport
              }>
              Operational Details
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.mechanicalReport.laborReport +
                    `?report_id=${reportId}`
                  : routes.reports.mechanicalReport.laborReport
              }>
              Labor
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.mechanicalReport.partsReport +
                    `?report_id=${reportId}`
                  : routes.reports.mechanicalReport.partsReport
              }>
              Parts
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.mechanicalReport.equipmentRepairReport +
                    `?report_id=${reportId}`
                  : routes.reports.mechanicalReport.equipmentRepairReport
              }>
              Equipment Repair/Maintenance Log
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.mechanicalReport.assignedEquipmentReport +
                    `?report_id=${reportId}`
                  : routes.reports.mechanicalReport.assignedEquipmentReport
              }>
              Assigned Equipment
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.mechanicalReport.rentalReport +
                    `?report_id=${reportId}`
                  : routes.reports.mechanicalReport.rentalReport
              }>
              Purchases/Short Term Rental
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.mechanicalReport.equipmentDamageReport +
                    `?report_id=${reportId}`
                  : routes.reports.mechanicalReport.equipmentDamageReport
              }>
              Equipment Damage/MOB/Service Callout
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.mechanicalReport.noteReport +
                    `?report_id=${reportId}`
                  : routes.reports.mechanicalReport.noteReport
              }>
              Mechanics Journal
            </Link>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default MechanicalReportSidebar;
