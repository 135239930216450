import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import SaleResource from 'api/sale';
import SaleForm from 'components/sale/SaleForm';
import routes from 'constants/routes';
import { SaleSchema } from 'interface/sale/saleSchema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const CreateSale: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const navigate = useNavigate();
  const methods = useForm<SaleSchema>();
  const api = new SaleResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addSale = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Sale has been created.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('saleList');
      navigate(routes.sales.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Sale Creation Failed.');
    },
  });

  const onSubmit = (data: any) => {
    const formData = {
      ...data,
      pit: data?.pit?.value,
      product: data?.product?.value,
      trucking_company: data?.trucking_company,
      job_class: data?.job_class?.value,
    };
    addSale.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">Add Sale</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addSale.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <SaleForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addSale.isLoading}>
                  Add Sale
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateSale;
