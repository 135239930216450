import {
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import AdjustmentResource from 'api/adjustment';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CreatedAtInput from 'components/common/CreatedAtInput';
import { AdjustmentTypeOptions } from 'constants/common';
import { AdjustmentSchema } from 'interface/adjustment/adjustmentSchema';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import ReactSelect from 'react-select';
import { changeToNumber, DateFormatMDY, formatNumber } from 'utils';
import http from 'utils/http';

interface AdjustmentFormProps {
  isProductLoading: boolean;
  productOptions: any;
  isPitLoading: boolean;
  pitOptions: any;

  adjustmentData?: AdjustmentSchema;
  deleteAdjustmentHandler: () => void;
  cancelEditHandler: () => void;
}

const AdjustmentForm: React.FC<AdjustmentFormProps> = (props) => {
  const { adjustmentData } = props;

  const {
    register,
    control,
    setValue,
    getValues,
    reset,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useFormContext<AdjustmentSchema>();

  const toast = useToast();
  const queryClient = useQueryClient();
  const api = new AdjustmentResource();
  const [errorMessage, setErrorMessage] = useState<any>();

  const addAdjustment = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Adjustment has been created.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('adjustmentList');
      reset();
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) {
        setErrorMessage(error?.response?.data[objKey[0]][0]);
        toast({
          title: error?.response?.data[objKey[0]],
          status: 'error',
          isClosable: true,
        });
      } else setErrorMessage('Adjustment Create Failed.');
    },
  });

  const deleteAdjustment = useMutation(() => api.destroy(adjustmentData?.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('adjustmentList');

      toast({
        title: 'Adjustment has been deleted.',
        status: 'success',
        isClosable: true,
      });
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) {
        setErrorMessage(error?.response?.data[objKey[0]][0]);
        toast({
          title: error?.response?.data[objKey[0]],
          status: 'error',
          isClosable: true,
        });
      } else setErrorMessage('Adjustment Delete Failed.');
    },
  });

  const updateAdjustment = useMutation(
    (data: any) => api.update(adjustmentData?.id, data),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Adjustment has been updated.',
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('adjustmentList');
        reset();
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) {
          setErrorMessage(error?.response?.data[objKey[0]][0]);
          toast({
            title: error?.response?.data[objKey[0]],
            status: 'error',
            isClosable: true,
          });
        } else setErrorMessage('Adjustment Update Failed.');
      },
    }
  );

  const onSubmit = (data: any) => {
    if (adjustmentData?.id) {
      const formData = {
        id: adjustmentData?.id,
        product: data?.product?.value,
        pit: data?.pit?.value,
        start_date: DateFormatMDY(data?.start_date),
        end_date: DateFormatMDY(data?.end_date),
        adjusted_production: parseFloat(
          data?.adjusted_production.replace(/,/g, '')
        ),
        reported_production: data?.reported_production,
        adjustment_type: data?.adjustment_type?.value,
        adjusted_value: data?.adjusted_value,
        note: '',
      };

      updateAdjustment.mutate(formData);
    } else {
      const formData = {
        product: data?.product?.value,
        pit: data?.pit?.value,
        start_date: DateFormatMDY(data?.start_date),
        end_date: DateFormatMDY(data?.end_date),
        adjusted_production: parseFloat(
          data?.adjusted_production.replace(/,/g, '')
        ),
        reported_production: data?.reported_production,
        adjustment_type: data?.adjustment_type?.value,
        adjusted_value: data?.adjusted_value,
        note: '',
      };

      addAdjustment.mutate(formData);
    }
  };

  const onDeleteAdjustment = () => {
    reset();
    deleteAdjustment.mutate();
    props.deleteAdjustmentHandler?.();
  };

  const onCancel = () => {
    reset();
    props.cancelEditHandler?.();
  };

  const findOptionByValue = (options: any[], optionValue: any) => {
    if (!options) return undefined;

    const filteredOption = options.find(
      (option: any) => option.value == optionValue
    );

    if (!filteredOption) return undefined;

    return filteredOption;
  };

  const apiLatestAdjustmentEndDate = (data: any) => {
    return http({
      url: '/latest-adjustment-enddate',
      method: 'post',
      data: JSON.stringify({
        product_id: data.productId,
        pit_id: data.pitId,
      }),
    });
  };

  const apiReportedProduction = (data: any) => {
    return http({
      url: '/get-reported-production',
      method: 'post',
      data: JSON.stringify({
        product_id: data.productId,
        pit_id: data.pitId,
        start_date: DateFormatMDY(data.startDate),
        end_date: DateFormatMDY(data.endDate),
      }),
    });
  };

  const [isStartDateReadOnly, setIsStartDateReadOnly] = useState(true);

  const postLatestAdjustmentEndDate = useMutation(
    (data: any) => apiLatestAdjustmentEndDate(data),
    {
      onSuccess: (res: any) => {
        if (res?.data?.latest_end_date !== undefined) {
          resetField('start_date');
          setValue(
            'start_date',
            convertDateStringToUTC(res?.data?.latest_end_date) as any
          );
          setIsStartDateReadOnly(true);
        } else {
          toast({
            title: res?.data?.message,
            status: 'error',
            isClosable: true,
          });
          resetField('start_date');
          setIsStartDateReadOnly(false);
        }
        queryClient.invalidateQueries('latestAdjustmentEndDate');
      },
    }
  );

  const postReportedProduction = useMutation(
    (data: any) => apiReportedProduction(data),
    {
      onSuccess: (res: any) => {
        if (res?.data?.reported_tons !== undefined) {
          setValue('reported_production', res?.data?.reported_tons);
          computeAdjustedProduction();
        }
        queryClient.invalidateQueries('reportedProduction');
      },
    }
  );

  const submitLatestAdjustmentEndDate = () => {
    resetField('start_date');
    resetField('end_date');
    resetField('reported_production');
    resetField('adjusted_production');

    if (!getValues('product') || !getValues('pit')) return;

    const data = {
      productId: getValues('product')?.value,
      pitId: getValues('pit')?.value,
    };
    postLatestAdjustmentEndDate.mutate(data);
  };

  const submitReportedProduction = () => {
    resetField('reported_production');
    resetField('adjusted_production');

    if (
      !getValues('product') ||
      !getValues('pit') ||
      !getValues('start_date') ||
      !getValues('end_date')
    ) {
      return;
    }

    const data = {
      productId: getValues('product')?.value,
      pitId: getValues('pit')?.value,
      startDate: DateFormatMDY(getValues('start_date')),
      endDate: DateFormatMDY(getValues('end_date')),
    };
    postReportedProduction.mutate(data);
  };

  const computeAdjustedProduction = () => {
    resetField('adjusted_production');

    if (!getValues('adjustment_type') || !getValues('adjusted_value')) {
      return;
    }

    const adjustmentType = getValues('adjustment_type');
    const adjustedValue = changeToNumber(getValues('adjusted_value'));

    if (getValues('reported_production') === undefined) {
      return;
    }

    const reportedProduction = changeToNumber(getValues('reported_production'));

    const adjustmentTypeOption = findOptionByValue(
      AdjustmentTypeOptions,
      adjustmentType.value
    );

    let computedValue = 0;

    if (adjustmentTypeOption.label === 'Percent') {
      const value = (adjustedValue / 100.0) * reportedProduction;
      computedValue = value + reportedProduction;
    } else if (adjustmentTypeOption.label === 'Quantity') {
      computedValue = adjustedValue + reportedProduction;
    } else {
      throw new Error(
        `Undefined adjustment type: ${adjustmentTypeOption.label}`
      );
    }

    const adjusted_production = computedValue;
    setValue('adjusted_production', formatNumber(+adjusted_production) + '');
  };

  const convertDateStringToUTC = (dateString: string) => {
    const date = new Date(dateString);
    const newDate = new Date(
      `${date.getUTCFullYear()}/${date.getUTCMonth() + 1}/${
        date.getUTCDate() + 1
      }`
    );
    return newDate;
  };

  useEffect(() => {
    if (adjustmentData === undefined) {
      reset();
      setValue('product', undefined);
      setValue('pit', undefined);
      return;
    }

    setValue('product', {
      label: adjustmentData?.product?.name,
      value: adjustmentData?.product?.id,
    });

    setValue('pit', {
      label: adjustmentData?.pit?.name,
      value: adjustmentData?.pit?.id,
    });
    setValue('start_date', adjustmentData?.start_date);

    setValue('end_date', adjustmentData?.end_date);

    setValue(
      'adjustment_type',
      findOptionByValue(AdjustmentTypeOptions, adjustmentData?.adjustment_type)
    );

    setValue('adjusted_value', adjustmentData?.adjusted_value);

    setValue(
      'reported_production',
      formatNumber(+adjustmentData?.reported_production) + ''
    );

    setValue(
      'adjusted_production',
      formatNumber(+adjustmentData?.adjusted_production) + ''
    );
  }, [adjustmentData, reset, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form">
      <Stack direction="column">
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Heading size="md" m={'10px'}>
              {adjustmentData && 'Edit Adjustment'}
              {!adjustmentData && 'New Adjustment'}
            </Heading>
            <Table>
              <Thead>
                <Tr>
                  <Th>Product Name</Th>
                  <Th>Pit</Th>
                  <Th>Start Date</Th>
                  <Th>End Date</Th>
                  <Th>Adj. Type</Th>
                  <Th>Adj. Value</Th>
                  <Th>Reported Prod</Th>
                  <Th>Adjusted Prod</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <FormControl isInvalid={!!errors?.product} isRequired>
                      <Controller
                        control={control}
                        name="product"
                        rules={{
                          required: 'Product',
                        }}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            id="product"
                            placeholder={'Product'}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              submitLatestAdjustmentEndDate();
                              submitReportedProduction();
                              computeAdjustedProduction();
                            }}
                            isLoading={props.isProductLoading}
                            options={props.productOptions}
                            menuPortalTarget={document.body}
                            styles={{
                              ...reactSelectStyles,
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            isSearchable={true}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {!!errors?.product && !!errors?.product?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl isInvalid={!!errors?.pit} isRequired>
                      <Controller
                        control={control}
                        name="pit"
                        rules={{
                          required: 'Pit',
                        }}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            id="pit"
                            placeholder={'Pit'}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              submitLatestAdjustmentEndDate();
                              submitReportedProduction();
                              computeAdjustedProduction();
                            }}
                            isLoading={props.isPitLoading}
                            options={props.pitOptions}
                            menuPortalTarget={document.body}
                            styles={{
                              ...reactSelectStyles,
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            isSearchable={true}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {!!errors?.pit && !!errors?.pit?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl isInvalid={!!errors?.start_date}>
                      <Controller
                        control={control}
                        name="start_date"
                        rules={{
                          required: 'Start Date',
                        }}
                        render={({ field }) => (
                          <ReactDatePicker
                            fixedHeight
                            id="start_date"
                            dateFormat={'MM/dd/y'}
                            customInput={<CreatedAtInput />}
                            value={DateFormatMDY(field.value)}
                            onChange={(selected: Date) => {
                              const selectedString = `${selected.getFullYear()}/${
                                selected.getMonth() + 1
                              }/${selected.getDate()}`;
                              field.onChange(selectedString);
                              submitReportedProduction();
                              computeAdjustedProduction();
                            }}
                            shouldCloseOnSelect={true}
                            autoComplete="off"
                            portalId="form"
                            readOnly={isStartDateReadOnly}
                            disabled={isStartDateReadOnly}
                            placeholderText="Start Date"
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {!!errors?.start_date && !!errors?.start_date?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl isInvalid={!!errors?.end_date}>
                      <Controller
                        control={control}
                        name="end_date"
                        rules={{
                          required: 'End Date',
                        }}
                        render={({ field }) => (
                          <ReactDatePicker
                            fixedHeight
                            id="end_date"
                            dateFormat={'MM/dd/y'}
                            customInput={<CreatedAtInput />}
                            value={DateFormatMDY(field.value)}
                            onChange={(selected: Date) => {
                              const selectedString = `${selected.getFullYear()}/${
                                selected.getMonth() + 1
                              }/${selected.getDate()}`;
                              field.onChange(selectedString);
                              submitReportedProduction();
                              computeAdjustedProduction();
                            }}
                            shouldCloseOnSelect={true}
                            autoComplete="off"
                            portalId="form"
                            placeholderText="End Date"
                          />
                          // <CreatedAtInput
                          //   {...field}
                          //   id="end_date"
                          //   type="date"
                          //   value={field.value}
                          //   onChange={(selected: any) => {
                          //     field.onChange(selected);
                          //     submitReportedProduction();
                          //     computeAdjustedProduction();
                          //   }}
                          //   placeholder="End Date"
                          // />
                        )}
                      />
                      <FormErrorMessage>
                        {!!errors?.end_date && !!errors?.end_date?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl
                      isInvalid={!!errors?.adjustment_type}
                      isRequired>
                      <Controller
                        control={control}
                        name="adjustment_type"
                        rules={{
                          required: 'Adj. Type',
                        }}
                        render={({ field }) => (
                          <ReactSelect
                            {...field}
                            id="adjustment_type"
                            placeholder={'Adj. Type'}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              computeAdjustedProduction();
                            }}
                            options={AdjustmentTypeOptions}
                            menuPortalTarget={document.body}
                            styles={{
                              ...reactSelectStyles,
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            isSearchable={true}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {!!errors?.adjustment_type &&
                          !!errors?.adjustment_type?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl
                      isInvalid={!!errors?.adjusted_value}
                      isRequired>
                      <Input
                        {...register('adjusted_value', {
                          required: 'Adj. Value',
                        })}
                        type="text"
                        placeholder={'Adj. Value'}
                        onChange={(field) => {
                          setValue('adjusted_value', field.currentTarget.value);
                          computeAdjustedProduction();
                        }}
                      />
                      <FormErrorMessage>
                        {!!errors?.adjusted_value &&
                          !!errors?.adjusted_value?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl
                      isInvalid={!!errors?.reported_production}
                      isRequired>
                      <Input
                        {...register('reported_production', {
                          required: 'Reported Prod',
                        })}
                        type="text"
                        placeholder={'Reported Prod'}
                        readOnly={true}
                        disabled={true}
                      />
                      <FormErrorMessage>
                        {!!errors?.reported_production &&
                          !!errors?.reported_production?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>

                  <Td>
                    <FormControl
                      isInvalid={!!errors?.adjusted_production}
                      isRequired>
                      <Input
                        {...register('adjusted_production', {
                          required: 'Adjusted Prod',
                        })}
                        type="text"
                        placeholder={'Adjusted Prod'}
                        readOnly={true}
                        disabled={true}
                      />
                      <FormErrorMessage>
                        {!!errors?.adjusted_production &&
                          !!errors?.adjusted_production?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Td>

                  <Td>
                    {adjustmentData && (
                      <Tooltip hasArrow label={'Delete'}>
                        <IconButton
                          icon={<BiTrash />}
                          variant="link"
                          aria-label={'View'}
                          color="red.300"
                          size="lg"
                          onClick={onDeleteAdjustment}
                        />
                      </Tooltip>
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <Stack direction="row">
            <Button size="lg" colorScheme="primary" type="submit">
              Submit
            </Button>
            {adjustmentData && (
              <Button size="lg" variant="outline" onClick={onCancel}>
                Cancel
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default AdjustmentForm;
