import React from 'react';
import { connect } from 'react-redux';

interface Props {
  hideFor: string;
  children?: any;
  renderNoAccess?: any;
  users: any;
}

const HideControl: React.FC<Props> = ({
  hideFor,
  children,
  renderNoAccess,
  users,
}) => {
  switch (hideFor) {
    case 'company-list':
      if (users.role === 'super_admin') {
        return renderNoAccess(children);
      }
      break;
    case 'dashboard-list':
      switch (users.role.toLowerCase()) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          return renderNoAccess(children);
      }
      break;
    case 'manage-list':
      switch (users.role.toLowerCase()) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          return renderNoAccess(children);
      }
      break;
    case 'inventory-list':
      switch (users.role.toLowerCase()) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          return renderNoAccess(children);
      }
      break;
    case 'production-list':
      switch (users.role.toLowerCase()) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          return renderNoAccess(children);
      }
      break;
    case 'sale-list':
      switch (users.role.toLowerCase()) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          return renderNoAccess(children);
      }
      break;
    case 'afe-list':
      switch (users.role.toLowerCase()) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          return renderNoAccess(children);
      }
      break;
    case 'report-list':
      switch (users.role.toLowerCase()) {
        case 'foreman':
        case 'mechanic':
        case 'company_admin':
        case 'labor':
        case 'operator':
        case 'operator':
        case 'sales':
        case 'administration':
          return renderNoAccess(children);
      }

      break;
    case 'user-information':
      if (users.role !== 'super_admin') {
        return renderNoAccess(children);
      }
      break;
  }

  return renderNoAccess();
};

HideControl.defaultProps = {
  renderNoAccess: () => null,
};

export default connect((state: any) => ({
  users: state?.data?.auth?.user,
}))(HideControl);
