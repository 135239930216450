import ActivityResource from 'api/activity';
import AfeResource from 'api/afe';
import EquipmentResource from 'api/equipment';
import OperationDownTimeCodeResource from 'api/operationDownTime';
import ProductResource from 'api/product';
import ShiftDownTimeCodeResource from 'api/shiftDownTime';
import {
  default as CustomerResource,
  default as EmployeeResource,
  default as LaborResource,
  default as VendorResource,
} from 'api/user';
import { useQuery } from 'react-query';

function useReportDropDown() {
  // Vendor List
  const vendorAPI = new VendorResource();
  const { data: vendorQuery, isLoading: isVendorLoading } = useQuery(
    [`vendor-dropdown-list`],
    () => vendorAPI.vendor().then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const vendorList = vendorQuery?.users;
  const vendorOptions = vendorList?.map((vendor: any) => ({
    label: `${vendor.first_name} ${vendor.last_name}`,
    value: vendor.id,
  }));

  // Equipment List
  const equipmentAPI = new EquipmentResource();
  const { data: equipmentQuery, isLoading: isEquipmentLoading } = useQuery(
    [`equipment-dropdown-list`],
    () =>
      equipmentAPI
        .list({
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const equipmentList = equipmentQuery?.results;
  const equipmentOptions = equipmentList?.map((equipment: any) => ({
    label: equipment.name,
    value: equipment.id,
  }));

  // Equipment Type
  const { data: equipmentTypeQuery, isLoading: isEquipmentTypeLoading } =
    useQuery(
      [`equipment-type-dropdown-list`],
      () =>
        equipmentAPI
          .type({
            limit: 5000,
          })
          .then((res) => res?.data),
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );
  const equipmentTypeList = equipmentTypeQuery?.results;
  const equipmentTypeOptions = equipmentTypeList?.map((equipmentType: any) => ({
    label: equipmentType.name,
    value: equipmentType.id,
  }));

  // Afe List
  const afeAPI = new AfeResource();
  const { data: afeQuery, isLoading: isAfeLoading } = useQuery(
    [`afe-list`],
    () =>
      afeAPI
        .list({
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const afeList = afeQuery?.results;
  const afeOptions = afeList?.map((afe: any) => ({
    label: afe.name,
    value: afe.id,
  }));

  // Customer List
  const customerAPI = new CustomerResource();
  const { data: customerQuery, isLoading: isCustomerLoading } = useQuery(
    [`customer-list`],
    () => customerAPI.customer().then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const customerList = customerQuery?.users;
  const customerOptions = customerList?.map((customer: any) => ({
    label: `${customer?.first_name} ${customer?.last_name}`,
    value: customer.id,
  }));

  // Product List
  const productAPI = new ProductResource();
  const { data: productQuery, isLoading: isProductLoading } = useQuery(
    [`product-list`],
    () =>
      productAPI
        .list({
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const productList = productQuery?.results;
  const productOptions = productList?.map((product: any) => ({
    label: `${product.class_code} - ${product.name}`,
    value: product.id,
  }));

  // Operation DownTimeCode List
  const operationDownTimeCodeAPI = new OperationDownTimeCodeResource();
  const {
    data: operationDownTimeCodeQuery,
    isLoading: isOperationDownTimeCodeLoading,
  } = useQuery(
    [`operation-downtime-code-dropdown-list`],
    () =>
      operationDownTimeCodeAPI
        .list({
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const operationDownTimeCodeList = operationDownTimeCodeQuery?.results;
  const operationDownTimeCodeOptions = operationDownTimeCodeList?.map(
    (operationDownTimeCode: any) => ({
      label: operationDownTimeCode.name,
      value: operationDownTimeCode.id,
    })
  );

  // Shift DownTimeCode List
  const shiftDownTimeCodeAPI = new ShiftDownTimeCodeResource();
  const {
    data: shiftDownTimeCodeQuery,
    isLoading: isShiftDownTimeCodeLoading,
  } = useQuery(
    [`downtime-dropdown-list`],
    () =>
      shiftDownTimeCodeAPI
        .list({
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const shiftDownTimeCodeList = shiftDownTimeCodeQuery?.results;
  const shiftDownTimeCodeOptions = shiftDownTimeCodeList?.map(
    (shiftDownTimeCode: any) => ({
      label: shiftDownTimeCode.name,
      value: shiftDownTimeCode.id,
    })
  );

  // Employee List
  const employeeAPI = new EmployeeResource();
  const { data: employeeQuery, isLoading: isEmployeeLoading } = useQuery(
    [`employee-dropdown-list`],
    () =>
      employeeAPI
        .list({
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const employeeList = employeeQuery?.results;
  const employeeOptions = employeeList?.map((employee: any) => ({
    label: employee.name,
    value: employee.id,
  }));

  // Activity List
  const activityAPI = new ActivityResource();
  const { data: activityQuery, isLoading: isActivityLoading } = useQuery(
    [`activity-dropdown-list`],
    () =>
      activityAPI
        .list({
          limit: 5000,
        })
        .then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const activityList = activityQuery?.results;
  const activityOptions = activityList?.map((activity: any) => ({
    label: activity.name,
    value: activity.id,
  }));

  // Labor List
  const laborAPI = new LaborResource();
  const { data: laborQuery, isLoading: isLaborLoading } = useQuery(
    [`labor-dropdown-list`],
    () => laborAPI.labor().then((res) => res?.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const laborList = laborQuery?.users;
  const laborOptions = laborList?.map((labor: any) => ({
    label: `${labor.first_name} ${labor.last_name}`,
    value: labor.id,
  }));

  return {
    isVendorLoading,
    vendorOptions,
    isEquipmentLoading,
    equipmentOptions,
    isEquipmentTypeLoading,
    equipmentTypeOptions,
    isAfeLoading,
    afeOptions,
    isCustomerLoading,
    customerOptions,
    isProductLoading,
    productOptions,
    isOperationDownTimeCodeLoading,
    operationDownTimeCodeOptions,
    isShiftDownTimeCodeLoading,
    shiftDownTimeCodeOptions,
    isEmployeeLoading,
    employeeOptions,
    isActivityLoading,
    activityOptions,
    isLaborLoading,
    laborOptions,
  };
}

export default useReportDropDown;
