import Resource from 'api/resource';
import http from 'utils/http';

class PeopleResource extends Resource {
  constructor() {
    super('peoples');
  }

  bulkDelete(resource: any) {
    return http({
      url: '/bulk-destroy/peoples',
      method: 'post',
      data: resource,
    });
  }

  reinvite(id: number) {
    return http({
      url: `/users/${id}/reinvite`,
      method: 'post',
    });
  }
}

export { PeopleResource as default };
