import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import PeopleResource from 'api/user';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import {
  PeopleSchema,
  PermissionSchema,
} from 'interface/people/peopleListType';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  capitalizeEachWord,
  capitalizeFirstLetter,
  DateFormatMDY,
  groupData,
} from 'utils';
import PaymentList from '../users/PaymentList';

const ViewPeople: React.FC = () => {
  const { id }: any = useParams();
  const api = new PeopleResource();
  const navigate = useNavigate();

  const dataQuery = useQuery<PeopleSchema>(
    [`people${id}`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.manage.peoples.list);
  }

  const getPermissions = () => {
    return dataQuery?.data?.plants.map((plant: any) => {
      return dataQuery?.data?.permissions.map(
        (permission: PermissionSchema) => {
          if (plant?.id === permission?.plant_id) {
            return (
              <>
                <Stack mb={3} key={plant?.id}>
                  <h3>{plant?.name}</h3>
                  <List spacing="6" color="gray.500" w={'150px'}>
                    <ListItem>
                      <Flex justify={'space-between'}>
                        <Text as={permission?.can_read ? 'b' : 's'}>
                          Can Read
                        </Text>
                        <Text as={permission?.can_write ? 'b' : 's'}>
                          Can Write
                        </Text>
                      </Flex>
                    </ListItem>
                  </List>
                </Stack>
              </>
            );
          }
        }
      );
    });
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            People Details
          </Heading>
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() =>
              navigate(routes.manage.peoples.edit.replace(':id', id))
            }>
            {'Edit People'}
          </Button>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Name</FormLabel>
                <Box sx={infoStyles}>
                  {`${dataQuery?.data?.first_name} ${dataQuery?.data?.last_name}` ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Type</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.user_type &&
                    capitalizeFirstLetter(dataQuery?.data?.user_type)) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Company Name</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.company_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>EIN/SSN</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.ein_ssn || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Address</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.address || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Phone</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.phone || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Email</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.email || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Region</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.region?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Notes</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.note || '-'}</Box>
              </FormControl>
            </GridItem>
            {dataQuery?.data?.user_type === 'employee' && (
              <>
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>Role</FormLabel>
                    <Box sx={infoStyles}>
                      {capitalizeEachWord(dataQuery?.data?.role?.name) || '-'}
                    </Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>Start Date</FormLabel>
                    <Box sx={infoStyles}>
                      {DateFormatMDY(dataQuery?.data?.start_date) || '-'}
                    </Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>End Date</FormLabel>
                    <Box sx={infoStyles}>
                      {DateFormatMDY(dataQuery?.data?.end_date) || '-'}
                    </Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>Status</FormLabel>
                    <Box sx={infoStyles}>
                      {dataQuery?.data?.is_active_employee
                        ? 'Active'
                        : 'Inactive'}
                    </Box>
                  </FormControl>
                </GridItem>
              </>
            )}
          </Grid>
          <Grid gap={'4'}>
            {dataQuery?.data?.role?.name !== 'company_admin' && (
              <>
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>Plants</FormLabel>
                    <Box sx={infoStyles}>
                      {groupData(dataQuery?.data?.plants) || '-'}
                    </Box>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel sx={labelStyles}>Permissions</FormLabel>
                    <Box sx={infoStyles}>{getPermissions()}</Box>
                  </FormControl>
                </GridItem>
              </>
            )}
          </Grid>
        </Stack>
      </Stack>

      <PaymentList userId={id} />
    </>
  );
};

export default ViewPeople;
