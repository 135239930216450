import { Box, SimpleGrid } from '@chakra-ui/react';
import { setForemanReport, setMechanicalReport } from 'actions/data/offline';
import {
  setAfeList,
  setBusinessUnitList,
  setForemanList,
  setManagerList,
  setOperationList,
  setPitList,
  setPlantList,
  setShiftList,
} from 'actions/data/report';
import AFEResource from 'api/afe';
import BusinessUnitResource from 'api/businessUnit';
import OperationResource from 'api/operation';
import PitResource from 'api/pit';
import PlantResource from 'api/plant';
import ReportResource from 'api/report';
import ShiftResource from 'api/shift';
import UserResource from 'api/user';
import CalendarComponent from 'components/dashboard/CalendarComponent';
import DailyReport from 'components/dashboard/DailyReport';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { DateFormatMDY } from 'utils';

const Dashboard: React.FC = () => {
  const [changeDate, setChangeDate] = useState(DateFormatMDY(new Date()));
  const dispatch = useDispatch();
  const api = new ReportResource();

  // useEffect(() => {
  //   (async () => {
  //     const { data } = await api.allDateReport();
  //     dispatch(setForemanReport(data.foreman_reports));
  //     dispatch(setMechanicalReport(data.mechanic_reports));
  //   })();
  // }, []);
  
  // Fetch AFE list
  const afeAPI = new AFEResource();
  const { data: afeQuery, isLoading: isAfeLoading } = useQuery(
    'afe-list',
    async () => {
      const res = await afeAPI.list({
        limit: 10000,
      });
      return res?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const afeList = afeQuery?.results;
  const afeOptions = afeList?.map((afe: any) => ({
    label: afe.name,
    value: afe.id,
    plant: afe.plant,
  }));

  // Fetch Plant list
  const plantAPI = new PlantResource();
  const { data: plantQuery, isLoading: isPlantLoading } = useQuery(
    'plant-list',
    async () => {
      const res = await plantAPI.list({
        limit: 10000,
      });
      return res?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const plantList = plantQuery?.results;
  const plantOptions = plantList?.map((plant: any) => ({
    label: plant.name,
    value: plant.id,
    pit: plant.pit,
  }));

  // Fetch Pit list
  const pitAPI = new PitResource();
  const { data: pitQuery, isLoading: isPitLoading } = useQuery(
    'pit-list',
    async () => {
      const res = await pitAPI.list({
        limit: 10000,
      });
      return res?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const pitList = pitQuery?.results;
  const pitOptions = pitList?.map((pit: any) => ({
    label: pit.name,
    value: pit.id,
  }));

  // Fetch Business Unit list
  const businessUnitAPI = new BusinessUnitResource();
  const { data: businessUnitQuery, isLoading: isBusinessUnitLoading } =
    useQuery(
      'businessunit-list',
      async () => {
        const res = await businessUnitAPI.list({
          limit: 10000,
        });
        return res?.data;
      },
      {
        refetchOnWindowFocus: false,
      }
    );
  const businessUnitList = businessUnitQuery?.results;
  const businessUnitOptions = businessUnitList?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  // Fetch Operation list
  const operationAPI = new OperationResource();
  const { data: operationQuery, isLoading: isOperationLoading } = useQuery(
    'operation-list',
    async () => {
      const res = await operationAPI.list({
        limit: 10000,
      });
      return res?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const operationList = operationQuery?.results;
  const operationOptions = operationList?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  // Fetch Shift list
  const shiftAPI = new ShiftResource();
  const { data: shiftQuery, isLoading: isShiftLoading } = useQuery(
    'shift-list',
    async () => {
      const res = await shiftAPI.list({
        limit: 10000,
      });
      return res?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const shiftList = shiftQuery?.results;
  const shiftOptions = shiftList?.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  // Fetch Foreman list
  const foremanAPI = new UserResource();
  const { data: foremanQuery, isLoading: isForemanLoading } = useQuery(
    'foreman-list',
    async () => {
      const res = await foremanAPI.foreman();
      return res?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const foremanList = foremanQuery?.users;
  const foremanOptions = foremanList?.map((foreman: any) => ({
    ...foreman,
    label: `${foreman.first_name} ${foreman.last_name}`,
    value: foreman.id,
  }));

  // Fetch Manager list
  const managerAPI = new UserResource();
  const { data: managerQuery, isLoading: isManagerLoading } = useQuery(
    'manager-list',
    async () => {
      const res = await managerAPI.manager();
      return res?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const managerList = managerQuery?.users;
  const managerOptions = managerList?.map((manager: any) => ({
    ...manager,
    label: `${manager.first_name} ${manager.last_name}`,
    value: manager.id,
  }));

  useEffect(() => {
    dispatch(setAfeList({ isAfeLoading, afeOptions }));
    dispatch(setPitList({ isPitLoading, pitOptions }));
    dispatch(setPlantList({ isPlantLoading, plantOptions }));
    dispatch(
      setBusinessUnitList({ isBusinessUnitLoading, businessUnitOptions })
    );
    dispatch(setOperationList({ isOperationLoading, operationOptions }));
    dispatch(setShiftList({ isShiftLoading, shiftOptions }));
    dispatch(setForemanList({ isForemanLoading, foremanOptions }));
    dispatch(setManagerList({ isManagerLoading, managerOptions }));
  });

  return (
    <>
      <SimpleGrid
        columns={[1, null, 2]}
        spacing="40px"
        gridTemplateColumns={{ base: 'repeat(1,1fr)', lg: '0.3fr auto' }}>
        <Box>
          <CalendarComponent setChangeDate={setChangeDate} />
        </Box>
        <Box>
          <DailyReport changeDate={changeDate} />
        </Box>
      </SimpleGrid>
    </>
  );
};

export default Dashboard;
