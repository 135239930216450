import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import EquipmentDamageReportResource from 'api/equipmentDamageReport';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useDebounceFunc from 'hooks/useDebounceFunc';
import { ReportProps } from 'interface/report/reportSchema';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import { v4 as uuid } from "uuid";

const EquipmentReport: React.FC<ReportProps> = (props) => {
  const { setReportBody, updateQueue, reportBody } = useContext(OfflineContext);
  const offlineEquipmentList = useSelector((state: any) => state.data.offline.equipments)
  const offlineEquipmentTypeList = useSelector((state: any) => state.data.offline.t_equipment)
  const offlineVendorList = useSelector((state: any) => state.data.offline.vendors)
  const [offlineEquipmentReportFilteredByPreviousReportId, setOfflineEquipmentReportFilteredByPreviousReportId] = useState<any>([]);
  const isOnline = useConnectionStatus();
  const { reportId } = props;
  const containerRef = useRef<any>();
  const api = new EquipmentDamageReportResource();
  const methods = useForm<any>();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [tagData, setTagData] = useState(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);

  const { vendorList, equipmentList, equipmentTypeList } = useSelector(
    (state: any) => ({
      vendorList: state?.data?.report?.vendorList,
      equipmentList: state?.data?.report?.equipmentList,
      equipmentTypeList: state?.data?.report?.equipmentTypeList,
    }),
    shallowEqual
  );

  const equipmentDamageQuery = useQuery(
    [`getEquipmentStatus`],
    async () => {
      const response = await api.listEquipmentReport(reportId, {
        limit: 5000,
      });
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: equipmentDamageListQuery,
    isLoading: isequipmentDamageListLoading,
  } = useQuery(
    [`equipment-damage-list`],
    () =>
      api
        .listEquipmentDetailReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          return res?.data.results;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const damage = useMemo(() => isOnline
    ? equipmentDamageListQuery?.length > 0
      ? equipmentDamageListQuery
      : []
    : offlineEquipmentReportFilteredByPreviousReportId, [offlineEquipmentReportFilteredByPreviousReportId, equipmentDamageListQuery])

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/equipment-damage')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev, equipment: offlineEquipmentReportFilteredByPreviousReportId
    }))
    // update the queue
    updateQueue(reportBody.id, { ...reportBody, equipment: offlineEquipmentReportFilteredByPreviousReportId })
  }, [offlineEquipmentReportFilteredByPreviousReportId])

  const addData = useMutation(
    (data: any) => api.storeEquipmentDetailReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('equipment-damage-list');
        setAddModalOpen(false);
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      type: data?.type?.value,
      vendor: data?.vendor?.value,
      equipment: data?.equipment?.value,
      cost_estimate: data?.cost_estimate,
    };
    if (isOnline) {
      addData.mutate(formData);

      // refresh the page
      queryClient.invalidateQueries('labor-list');
      queryClient.invalidateQueries('labor-detail-list');
      queryClient.invalidateQueries('capital-equipment-list');
      queryClient.invalidateQueries('loadout-list');
      queryClient.invalidateQueries('getLoadoutStatus');
      queryClient.invalidateQueries('loadout-detail-list');
    } else {
      setOfflineEquipmentReportFilteredByPreviousReportId((prev: any) => ([...prev, {
        ...formData,
        type: { id: data?.type?.value, name: data?.type?.label },
        vendor: {
          id: data?.vendor?.value,
          name: data?.vendor?.label,
          first_name: data?.vendor?.first_name,
          last_name: data?.vendor?.last_name
        },
        equipment: { id: data?.equipment?.value, name: data?.equipment?.label },
        id: uuid()
      }]))
    }
    onAddModalClose();
  };

  const updateData = useMutation(
    (data: any) =>
      api.updateEquipmentDetailReport(reportId, data?.id, data?.body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('equipment-damage-list');
      },
    }
  );
  const handleType = (fieldData: any, type: string) => {
    if (!fieldData.id) return;
    const data = {
      body: {
        type: +type,
        vendor: fieldData?.vendor?.id,
        equipment: fieldData?.equipment?.id,
        cost_estimate: fieldData?.cost_estimate,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineEquipmentReportFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          const t = offlineEquipmentTypeList.t_equipment.find((elem: any) => elem.value === type);
          return {
            ...r,
            type: t ? { id: t.value, name: t.label } : r.type
          }
        } else {
          return r;
        }
      }))
    }
  };
  const handleVendor = (fieldData: any, vendor: string) => {
    if (!fieldData.id) return;
    const data = {
      body: {
        type: fieldData?.type?.id,
        vendor: +vendor,
        equipment: fieldData?.equipment?.id,
        cost_estimate: fieldData?.cost_estimate,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineEquipmentReportFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          const _vendor = offlineVendorList.vendors.find((elem: any) => elem.value === vendor);
          return {
            ...r,
            vendor: _vendor ? { id: _vendor.value, name: _vendor.label } : r.vendor
          }
        } else {
          return r;
        }
      }))
    }
  };
  const handleEquipment = (fieldData: any, equipment: string) => {
    if (!fieldData.id) return;
    const data = {
      body: {
        type: fieldData?.type?.id,
        vendor: fieldData?.vendor?.id,
        equipment: +equipment,
        cost_estimate: fieldData?.cost_estimate,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineEquipmentReportFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          const _equipment = offlineEquipmentList.equipments.find((elem: any) => elem.value === equipment);
          return {
            ...r,
            equipment: _equipment ? { id: _equipment.value, name: _equipment.label } : r.equipment
          }
        } else {
          return r;
        }
      }))
    }
  };
  const handleCost = (fieldData: any, cost_estimate: string) => {
    if (!fieldData.id) return;
    const data = {
      body: {
        type: fieldData?.type?.id,
        vendor: fieldData?.vendor?.id,
        equipment: fieldData?.equipment?.id,
        cost_estimate: +cost_estimate,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineEquipmentReportFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          return {
            ...r,
            cost_estimate
          }
        } else {
          return r;
        }
      }))
    }
  };
  const debounce = useDebounceFunc(handleCost, 3000);

  const handleDetailDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: number) => api.destroyEquipmentDetailReport(reportId, id + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('equipment-damage-list');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (isOnline) {
      if (id != 0) deleteData.mutate(id);
    } else {
      setOfflineEquipmentReportFilteredByPreviousReportId((prev: any) => prev.filter((r: any) => r.id !== id))
      setDeleteModalOpen(false);
    }
  };

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        type: fieldData?.type?.id,
        vendor: fieldData?.vendor?.id,
        equipment: fieldData?.equipment?.id,
        cost_estimate: fieldData?.cost_estimate,
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineEquipmentReportFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          return {
            ...r,
            note: modalValue.note
          }
        } else {
          return r;
        }
      }))
    }
    setNoteDetailModalOpen(false);
  };

  const handleNoteDetailModal = (equipmentDamage: any) => {
    setNoteDetailFieldData(equipmentDamage);
    setNoteDetailModalOpen(true);
  };

  const handleNoteModal = (equipmentDamage: any) => {
    setNoteModalOpen(true);
  };

  const updateEquipmentData = useMutation(
    (data: any) => api.updateEquipmentReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getEquipmentStatus');
      },
    }
  );

  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      note: modalValue.note,
    };
    updateEquipmentData.mutate(data);
    setNoteModalOpen(false);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('equipment-damage-list');
    queryClient.invalidateQueries('getEquipmentStatus');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Equipment damage/MOB/Service Callout</Heading>
          <Stack direction="row" spacing="6">
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(equipmentDamageQuery?.data, 'tagStatus')
                }
              />
            </Tooltip>
            {tagData === equipmentDamageQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={equipmentDamageQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
                formMutation={addData}
                errorMessage={errorMessage}
              />
            )}
            <Tooltip hasArrow label={equipmentDamageQuery?.data?.note}>
              <IconButton
                icon={<MdOutlineModeComment />}
                variant="link"
                aria-label={'Note'}
                color={
                  equipmentDamageQuery?.data?.note ? 'primary.600' : 'gray.300'
                }
                size="lg"
                onClick={() => handleNoteModal(equipmentDamageQuery?.data)}
              />
            </Tooltip>
            <NoteModal
              isModalOpen={noteModalOpen}
              setModalOpen={setNoteModalOpen}
              fieldData={equipmentDamageQuery?.data}
              onModalSubmit={(notes: string) =>
                onNoteModalSubmit(notes, equipmentDamageQuery?.data)
              }
              formMutation={addData}
              errorMessage={errorMessage}
            />
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant={'simple'}>
              <Tbody>
                <Tr bg={'gray.300'}>
                  <Td>Type</Td>
                  <Td>Vendor</Td>
                  <Td>Equipment</Td>
                  <Td>Estimate Cost</Td>
                  <Td></Td>
                </Tr>
                {damage?.map(
                  (equipmentDamage: any) => (
                    <React.Fragment key={equipmentDamage?.id}>
                      <Tr>
                        <Td>
                          <ReactSelect
                            defaultValue={{
                              value: equipmentDamage?.type?.id,
                              label: equipmentDamage?.type?.name,
                            }}
                            placeholder={'Select Type'}
                            onChange={(e: any) => {
                              return handleType(equipmentDamage, e.value);
                            }}
                            isLoading={isOnline ? equipmentTypeList.isEquipmentTypeLoading : offlineEquipmentTypeList.isLoading}
                            options={isOnline ? equipmentTypeList.equipmentTypeOptions : offlineEquipmentTypeList.t_equipment}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                          />
                        </Td>
                        <Td>
                          <ReactSelect
                            defaultValue={{
                              value: equipmentDamage?.vendor?.id,
                              label: `${equipmentDamage?.vendor?.first_name} ${equipmentDamage?.vendor?.last_name}`,
                            }}
                            placeholder={'Select Vendor'}
                            onChange={(e: any) => {
                              return handleVendor(equipmentDamage, e.value);
                            }}
                            isLoading={isOnline ? vendorList.isVendorLoading : offlineVendorList.isLoading}
                            options={isOnline ? vendorList.vendorOptions : offlineVendorList.vendors}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                          />
                        </Td>
                        <Td>
                          <ReactSelect
                            defaultValue={{
                              value: equipmentDamage?.equipment?.id,
                              label: equipmentDamage?.equipment?.name,
                            }}
                            placeholder={'Select Equipment'}
                            onChange={(e: any) => {
                              return handleEquipment(equipmentDamage, e.value);
                            }}
                            isLoading={isOnline ? equipmentList.isEquipmentLoading : offlineEquipmentList.isLoading}
                            options={isOnline ? equipmentList.equipmentOptions : offlineEquipmentList.equipments}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                          />
                        </Td>
                        <Td>
                          <Input
                            defaultValue={equipmentDamage?.cost_estimate}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => debounce(equipmentDamage, e.target.value)}
                            type="number"
                            step="0.01"
                            placeholder="Estimate Cost"
                          />
                        </Td>
                        <Td>
                          <Tooltip hasArrow label={'Tag'}>
                            <IconButton
                              icon={<BsTag />}
                              variant="link"
                              aria-label={'Tag'}
                              color={'gray.300'}
                              size="lg"
                              onClick={() =>
                                handleTagModal(equipmentDamage, 'tagDetail')
                              }
                            />
                          </Tooltip>
                          {tagData === equipmentDamage?.tag && (
                            <TagModal
                              isModalOpen={tagDetailModalOpen}
                              setModalOpen={setTagDetailModalOpen}
                              fieldData={tagData}
                              onModalSubmit={() => onTagModalSubmit()}
                              formMutation={addData}
                              errorMessage={errorMessage}
                            />
                          )}
                          <Tooltip hasArrow label={equipmentDamage?.note}>
                            <IconButton
                              icon={<MdOutlineModeComment />}
                              variant="link"
                              aria-label={'Note'}
                              color={
                                equipmentDamage?.note
                                  ? 'primary.600'
                                  : 'gray.300'
                              }
                              size="lg"
                              onClick={() =>
                                handleNoteDetailModal(equipmentDamage)
                              }
                            />
                          </Tooltip>
                          <NoteModal
                            isModalOpen={noteDetailModalOpen}
                            setModalOpen={setNoteDetailModalOpen}
                            fieldData={noteDetailFieldData}
                            onModalSubmit={(notes: string) =>
                              onNoteDetailModalSubmit(
                                notes,
                                noteDetailFieldData
                              )
                            }
                            formMutation={updateData}
                            errorMessage={errorMessage}
                          />
                          <Tooltip hasArrow label={'Delete'}>
                            <IconButton
                              icon={<BsTrash />}
                              variant="link"
                              aria-label={'Delete'}
                              color="gray.300"
                              size="lg"
                              onClick={() =>
                                handleDetailDelete(equipmentDamage?.id)
                              }
                            />
                          </Tooltip>
                        </Td>
                      </Tr>

                      <Modal
                        isOpen={isDeleteModalOpen}
                        isCentered
                        onClose={onDeleteModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>
                            {'Delete Equipment damage/MOB/Service Callout'}
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {'Are you sure you want to delete this ?'}
                            {deleteMsg && (
                              <Alert status="error" mb={2}>
                                <AlertIcon />
                                {deleteMsg}
                              </Alert>
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <ButtonGroup>
                              <Button
                                colorScheme="red"
                                onClick={() => onDeleteConfirm(confirmDeleteId)}
                                isLoading={deleteData.isLoading}>
                                {'Delete'}
                              </Button>

                              <Button
                                variant="outline"
                                onClick={onDeleteModalClose}>
                                {'Cancel'}
                              </Button>
                            </ButtonGroup>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </React.Fragment>
                  )
                )}
              </Tbody>
            </Table>
            {isOnline && !equipmentDamageListQuery && <CenterSpinner type="normal" />}
          </TableContainer>
        </Stack>

        {/* Add Modal */}
        <Modal
          isOpen={isAddModalOpen}
          isCentered
          onClose={onAddModalClose}
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {'Add Equipment damage/MOB/Service Callout'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack direction="column" spacing="4">
                    {addData.isError && (
                      <Alert status="error">
                        <AlertIcon />
                        {errorMessage}
                      </Alert>
                    )}
                    <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods?.formState?.errors?.type}
                          isRequired>
                          <FormLabel>Type</FormLabel>
                          <Controller
                            control={methods.control}
                            name="type"
                            rules={{
                              required: 'Type',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="type"
                                placeholder={'Type'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline ? equipmentTypeList.isEquipmentTypeLoading : offlineEquipmentTypeList.isLoading}
                                options={isOnline ? equipmentTypeList.equipmentTypeOptions : offlineEquipmentTypeList.t_equipment}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods?.formState?.errors?.type &&
                              !!methods?.formState?.errors?.type?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.vendor}
                          isRequired>
                          <FormLabel>{'Vendor'}</FormLabel>
                          <Controller
                            control={methods.control}
                            name="vendor"
                            rules={{
                              required: 'Vendor',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="vendor"
                                placeholder={'Vendor'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline ? vendorList.isVendorLoading : offlineVendorList.isLoading}
                                options={isOnline ? vendorList.vendorOptions : offlineVendorList.vendors}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.vendor &&
                              !!methods.formState.errors?.vendor?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.equipment}>
                          <FormLabel>{'Equipment'}</FormLabel>
                          <Controller
                            control={methods.control}
                            name="equipment"
                            rules={{
                              required: 'Equipment',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="equipment"
                                placeholder={'Equipment'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline ? equipmentList.isEquipmentLoading : offlineEquipmentList.isLoading}
                                options={isOnline ? equipmentList.equipmentOptions : offlineEquipmentList.equipments}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.equipment &&
                              !!methods.formState.errors?.equipment?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.cost_estimate}>
                          <FormLabel>{'Estimate Cost'}</FormLabel>
                          <Input
                            type="number"
                            step="0.01"
                            {...methods.register('cost_estimate', {
                              required: 'Estimate Cost',
                            })}
                            placeholder="Estimate Cost"
                          />
                        </FormControl>
                        <FormErrorMessage>
                          {!!methods.formState.errors?.cost_estimate &&
                            !!methods.formState.errors?.cost_estimate?.message}
                        </FormErrorMessage>
                      </GridItem>
                    </Grid>
                    <ButtonGroup>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={addData.isLoading}>
                        Add
                      </Button>
                      <Button variant="outline" onClick={onAddModalClose}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Flex p={['3', '6']}>
          <Button
            variant={'outline'}
            onClick={() => {
              methods.reset();
              setAddModalOpen(true);
            }}>
            Add Another
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default EquipmentReport;
