import Resource from 'api/resource';
import http from 'utils/http';

class EquipmentClassResource extends Resource {
  constructor() {
    super('equipment-class');
  }
}

export { EquipmentClassResource as default };
