import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import AdjustmentResource from 'api/adjustment';
import PitResource from 'api/pit';
import ProductResource from 'api/product';
import AdjustmentListItem, {
  AdjustmentListItemProps,
} from 'components/adjustment/AdjustmentListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { AdjustmentSchema } from 'interface/adjustment/adjustmentSchema';
import { FilterParams } from 'interface/common/filterParam';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';
import AdjustmentForm from './AdjustmentForm';

const AdjustmentList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [inventoryIds, setInventoryIds] = useState<any>([]);
  const api = new AdjustmentResource();

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'adjustmentList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
      };
      const response = await api.list(queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    }
  );

  const methods = useForm<any>();
  const newAdjustmentMethods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      search: data.name,
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  // Product List
  const productAPI = new ProductResource();
  const { data: productQuery, isLoading: isProductLoading } = useQuery(
    [`product-list`],
    () => productAPI.list({ limit: 10000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const productList = productQuery?.results;
  const productOptions = productList?.map((product: any) => ({
    label: `${product.class_code} - ${product.name}`,
    value: product.id,
  }));

  // Pit List
  const pitAPI = new PitResource();
  const { data: pitQuery, isLoading: isPitLoading } = useQuery(
    [`pit-list`],
    () => pitAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const pitList = pitQuery?.results;
  const pitOptions = pitList?.map((pit: any) => ({
    label: pit.name,
    value: pit.id,
  }));

  let navigate = useNavigate();

  const [selectedAdjustment, setSelectedAdjustment] = useState<
    AdjustmentSchema | undefined
  >(undefined);

  const onEditAdjustmentClick = (adjustment: AdjustmentSchema) => {
    setSelectedAdjustment(adjustment);
  };

  const mapAdjustmentToAdjustmentListItemProps = (
    adjustment: AdjustmentSchema,
    index: number
  ) => {
    const adjustmentListItemProps: AdjustmentListItemProps = {
      adjustmentSchemaType: {
        listData: adjustment,
        index: startingSN + index,
        setInventoryIds: setInventoryIds,
        inventoryIds: inventoryIds,
      },
      editHandler: onEditAdjustmentClick,
    };
    return adjustmentListItemProps;
  };

  const onDeleteAdjustmentClick = () => {
    setSelectedAdjustment(undefined);
  };

  const onCancelEditAdjustmentClick = () => {
    setSelectedAdjustment(undefined);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Button
            size="sm"
            colorScheme="primary"
            variant="outline"
            leftIcon={<Icon as={ArrowBackIcon} w="6" h="6" />}
            onClick={() => navigate(routes.productions.list)}
            style={{ marginBottom: '30px' }}>
            Production
          </Button>
        </Flex>
      </Stack>

      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Adjustment</Heading>
          </Stack>
        </Flex>

        <FormProvider {...newAdjustmentMethods}>
          <AdjustmentForm
            isProductLoading={isProductLoading}
            productOptions={productOptions}
            isPitLoading={isPitLoading}
            pitOptions={pitOptions}
            adjustmentData={selectedAdjustment}
            deleteAdjustmentHandler={onDeleteAdjustmentClick}
            cancelEditHandler={onCancelEditAdjustmentClick}
          />
        </FormProvider>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Flex justify="space-between">
            <Stack direction="row">
              <Heading size="md">Adjustment Change Log</Heading>
            </Stack>
          </Flex>

          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Product</Th>
                  <Th>Pit</Th>
                  <Th>Start Date</Th>
                  <Th>End Date</Th>
                  <Th>Adjusted Production</Th>
                  <Th>Reported Production</Th>
                  <Th>Adjusted Value</Th>
                  <Th>Notes</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map(
                    (adjustment: AdjustmentSchema, index: number) => (
                      <AdjustmentListItem
                        {...mapAdjustmentToAdjustmentListItemProps(
                          adjustment,
                          index
                        )}
                        key={index}
                      />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={9} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default AdjustmentList;
