import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import ReportResource from 'api/operationDownTimeReport';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import {
  ReportProps,
  ReportSearchInterface,
} from 'interface/report/reportSchema';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import { v4 as uuid } from "uuid";

const OperationsDownTimeReport: React.FC<ReportProps> = (props) => {
  const { previousReportId, setReportBody, updateQueue, reportBody } = useContext(OfflineContext);
  const offlineOperationDowntimeCodeList = useSelector((state: any) => state.data.offline.operation_downtime_codes)
  const [offlineOperationDownTimeCodesFilteredByPreviousReportId, setOfflineOperationDownTimeCodesFilteredByPreviousReportId] = useState(offlineOperationDowntimeCodeList.operation_downtime_codes
    ? [...offlineOperationDowntimeCodeList.operation_downtime_codes?.filter(
      (item: any) => item.report_id === previousReportId
    )]
    : []);
  const offlineEquipmentList = useSelector((state: any) => state.data.offline.equipments)
  const isOnline = useConnectionStatus();

  const { reportId } = props;
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const api = new ReportResource();
  const methods = useForm<any>();
  const [totalHours, setTotalHours] = useState(0);
  const containerRef = useRef<any>();
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [tagData, setTagData] = useState(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);
  const { operationDowntimeCodeList, equipmentList } = useSelector(
    (state: any) => ({
      operationDowntimeCodeList: state?.data?.report?.operationDowntimeCodeList,
      equipmentList: state?.data?.report?.equipmentList,
    }),
    shallowEqual
  );

  const [filterParams, setFilterParams] = useState<ReportSearchInterface>({
    searchDownTimeCode: '',
  });

  const operationDowntimeStatusQuery = useQuery(
    [`getoperationDowntimeStatus`],
    async () => {
      const response = await api.listOperationDownTimeReport(reportId, {
        limit: 5000,
      });
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: downTimeListQuery, isLoading: isDownTimeListLoading } =
    useQuery(
      [`operation-downtime-list`],
      () =>
        isOnline && api
          .listOperationDownTimeDetailReport(reportId, {
            limit: 5000,
          })
          .then((res) => {
            return res?.data?.results;
          }),
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const downtime = useMemo(() => isOnline
    ? downTimeListQuery?.length > 0
      ? downTimeListQuery
      : []
    : offlineOperationDownTimeCodesFilteredByPreviousReportId, [offlineOperationDownTimeCodesFilteredByPreviousReportId, downTimeListQuery])

  const addData = useMutation(
    (data: any) => api.storeOperationDownTimeDetailReport(reportId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('operation-downtime-list');
        setAddModalOpen(false);
        getTotal();
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      hours: data?.hours,
      downtime: data?.downtime?.value,
      equipment: data?.equipment?.value,
    };
    if (isOnline) {
      addData.mutate(formData);

      // refresh the page
      queryClient.invalidateQueries('labor-list');
      queryClient.invalidateQueries('labor-detail-list');
      queryClient.invalidateQueries('capital-equipment-list');
      queryClient.invalidateQueries('loadout-list');
      queryClient.invalidateQueries('getLoadoutStatus');
      queryClient.invalidateQueries('loadout-detail-list');
    } else {
      setOfflineOperationDownTimeCodesFilteredByPreviousReportId((prev: any) => ([...prev, {
        ...formData,
        downtime: { id: data?.downtime?.value, name: data?.downtime?.label },
        equipment: { id: data?.equipment?.value, name: data?.equipment?.label },
        id: uuid()
      }]))
      setAddModalOpen(false);
    }
  };

  const updateData = useMutation(
    (data: any) =>
      api.updateOperationDownTimeDetailReport(
        reportId,
        data?.downTimeId,
        data?.body
      ),
    {
      onSuccess: () => {
        getTotal();
        queryClient.invalidateQueries('operation-downtime-list');
      },
    }
  );
  const handleDownTimeCode = (downTime: any, downTimeCode: string) => {
    if (!downTime.id) return;
    const data = {
      body: {
        hours: downTime?.hours,
        downtime: +downTimeCode,
        equipment: downTime?.equipment?.id,
      },
      downTimeId: downTime?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineOperationDownTimeCodesFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === downTime?.id) {
          const found = offlineOperationDowntimeCodeList.operation_downtime_codes.find((code: any) => code.value === +downTimeCode);
          return {
            ...r,
            downtime: found ? { id: found.value, name: found.label } : r.downtime
          }
        } else {
          return r;
        }
      }))
    }
  };
  const handleEquipment = (fieldData: any, equipment: string) => {
    if (!fieldData.id) return;
    const data = {
      body: {
        hours: fieldData?.hours,
        downtime: fieldData?.downtime?.id,
        equipment: +equipment,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineOperationDownTimeCodesFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          const _equipment = offlineEquipmentList.equipments.find((elem: any) => elem.value === equipment);
          return {
            ...r,
            equipment: _equipment ? { id: _equipment.value, name: _equipment.label } : r.equipment
          }
        } else {
          return r;
        }
      }))
    }
  };
  const handleDownTimeHours = (downTime: any, downTimeHour: string) => {
    if (!downTime.id) return;
    const data = {
      body: {
        hours: +downTimeHour,
        downtime: downTime?.downtime?.id,
        equipment: downTime?.equipment?.id,
      },
      downTimeId: downTime?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineOperationDownTimeCodesFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === downTime?.id) {
          return {
            ...r,
            hours: +downTimeHour
          }
        } else {
          return r;
        }
      }))
    }
  };

  const handleDetailDelete = (id: number) => {
    setDeleteModalOpen(true);
    setConfirmDeleteId(id);
    queryClient.invalidateQueries('operation-downtime-list');
  };

  const deleteData = useMutation(
    (downtimeId: number) =>
      api.destroyOperationDownTimeDetailReport(reportId, downtimeId + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('operation-downtime-list');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (isOnline) {
      if (id != 0) deleteData.mutate(id);
    } else {
      setOfflineOperationDownTimeCodesFilteredByPreviousReportId((prev: any) => prev.filter((r: any) => r.id !== id))
      setDeleteModalOpen(false);
    }
  };

  const getTotal = () => {
    api
      .listOperationDownTimeDetailReport(reportId, {
        limit: 5000,
      })
      .then((res) => {
        const total = res?.data?.results?.reduce(function (
          acc: number,
          obj: any
        ) {
          return acc + +obj.hours;
        },
          0);
        setTotalHours(total);
      });
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/operation-downtime')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev, operation_downtime: offlineOperationDownTimeCodesFilteredByPreviousReportId
    }))
    // update the queue
    updateQueue(reportBody.id, { ...reportBody, operation_downtime: offlineOperationDownTimeCodesFilteredByPreviousReportId })
  }, [offlineOperationDownTimeCodesFilteredByPreviousReportId])

  useEffect(() => {
    getTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalHours]);

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        ...fieldData,
        downtime: fieldData?.downtime?.id,
        equipment: fieldData?.equipment?.id,
        note: modalValue.note,
      },
      downTimeId: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineOperationDownTimeCodesFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.downTimeId) {
          return {
            ...r,
            note: modalValue.note
          }
        } else {
          return r;
        }
      }))
    }
    setNoteDetailModalOpen(false);
  };

  const handleNoteDetailModal = (data: any) => {
    setNoteDetailFieldData(data);
    setNoteDetailModalOpen(true);
  };

  const handleNoteModal = (data: any) => {
    setNoteModalOpen(true);
  };

  const updateOperationDowntimeData = useMutation(
    (data: any) => api.updateOperationDownTimeReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getOperationDowntimeStatus');
      },
    }
  );
  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      note: modalValue.note,
    };
    updateOperationDowntimeData.mutate(data);
    setNoteModalOpen(false);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('operation-downtime-list');
    queryClient.invalidateQueries('getOperationDowntimeStatus');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Operations Downtime</Heading>
          <Stack direction="row" spacing="6">
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(
                    operationDowntimeStatusQuery?.data,
                    'tagStatus'
                  )
                }
              />
            </Tooltip>
            {tagData === operationDowntimeStatusQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={operationDowntimeStatusQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
                formMutation={addData}
                errorMessage={errorMessage}
              />
            )}
            <Tooltip hasArrow label={operationDowntimeStatusQuery?.data?.note}>
              <IconButton
                icon={<MdOutlineModeComment />}
                variant="link"
                aria-label={operationDowntimeStatusQuery?.data?.note}
                color={
                  operationDowntimeStatusQuery?.data?.note
                    ? 'primary.600'
                    : 'gray.300'
                }
                size="lg"
                onClick={() =>
                  handleNoteModal(operationDowntimeStatusQuery?.data)
                }
              />
            </Tooltip>
            <NoteModal
              isModalOpen={noteModalOpen}
              setModalOpen={setNoteModalOpen}
              fieldData={operationDowntimeStatusQuery?.data}
              onModalSubmit={(notes: string) =>
                onNoteModalSubmit(notes, operationDowntimeStatusQuery?.data)
              }
              formMutation={addData}
              errorMessage={errorMessage}
            />
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant={'simple'}>
              <Tbody>
                <Tr bg={'gray.300'}>
                  <Td>Downtime Code</Td>
                  <Td>Equipment</Td>
                  <Td>Hours {totalHours}hr</Td>
                  <Td></Td>
                </Tr>
                {downtime?.map(
                  (downTime: any, index: number) => (
                    <React.Fragment key={downTime?.id}>
                      <Tr>
                        <Td>
                          <ReactSelect
                            defaultValue={{
                              value: downTime?.downtime?.id,
                              label: downTime?.downtime?.name,
                            }}
                            placeholder={'DownTime Code'}
                            onChange={(e: any) => {
                              handleDownTimeCode(downTime, e.value)
                            }}
                            isLoading={isOnline
                              ? operationDowntimeCodeList.isOperationDownTimeCodeLoading
                              : offlineOperationDowntimeCodeList.isLoading
                            }
                            options={isOnline
                              ? operationDowntimeCodeList.operationDownTimeCodeOptions
                              : offlineOperationDowntimeCodeList.operation_downtime_codes
                            }
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                          />
                        </Td>
                        <Td>
                          <ReactSelect
                            defaultValue={{
                              value: downTime?.equipment?.id,
                              label: downTime?.equipment?.name,
                            }}
                            onChange={(e: any) =>
                              handleEquipment(downTime, e.value)
                            }
                            placeholder={'Equipment'}
                            isLoading={isOnline ? equipmentList.isEquipmentLoading : offlineEquipmentList.isLoading}
                            options={isOnline ? equipmentList.equipmentOptions : offlineEquipmentList.equipments}
                            styles={reactSelectStyles}
                            isSearchable={true}
                          />
                        </Td>
                        <Td>
                          <Input
                            defaultValue={downTime?.hours}
                            type="number"
                            step="0.01"
                            placeholder={'Hours'}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleDownTimeHours(downTime, e.target.value)}
                          />
                        </Td>
                        <Td>
                          <Tooltip hasArrow label={'Tag'}>
                            <IconButton
                              icon={<BsTag />}
                              variant="link"
                              aria-label={'Tag'}
                              color={'gray.300'}
                              size="lg"
                              onClick={() =>
                                handleTagModal(downTime, 'tagDetail')
                              }
                            />
                          </Tooltip>
                          {tagData === downTime?.tag && (
                            <TagModal
                              isModalOpen={tagDetailModalOpen}
                              setModalOpen={setTagDetailModalOpen}
                              fieldData={tagData}
                              onModalSubmit={() => onTagModalSubmit()}
                              formMutation={addData}
                              errorMessage={errorMessage}
                            />
                          )}
                          <Tooltip hasArrow label={downTime?.note}>
                            <IconButton
                              icon={<MdOutlineModeComment />}
                              variant="link"
                              aria-label={downTime?.note}
                              color={
                                downTime?.note ? 'primary.600' : 'gray.300'
                              }
                              size="lg"
                              onClick={() => handleNoteDetailModal(downTime)}
                            />
                          </Tooltip>
                          <NoteModal
                            isModalOpen={noteDetailModalOpen}
                            setModalOpen={setNoteDetailModalOpen}
                            fieldData={noteDetailFieldData}
                            onModalSubmit={(notes: string) =>
                              onNoteDetailModalSubmit(
                                notes,
                                noteDetailFieldData
                              )
                            }
                            formMutation={updateData}
                            errorMessage={errorMessage}
                          />
                          <Tooltip hasArrow label={'Delete'}>
                            <IconButton
                              icon={<BsTrash />}
                              variant="link"
                              aria-label={'Delete'}
                              color="gray.300"
                              size="lg"
                              onClick={() => handleDetailDelete(downTime?.id)}
                            />
                          </Tooltip>
                        </Td>
                      </Tr>
                      <Modal
                        isOpen={isDeleteModalOpen}
                        isCentered
                        onClose={onDeleteModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>
                            {'Delete Operation Downtime'}
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {'Are you sure you want to delete this ?'}
                            {deleteMsg && (
                              <Alert status="error" mb={2}>
                                <AlertIcon />
                                {deleteMsg}
                              </Alert>
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <ButtonGroup>
                              <Button
                                colorScheme="red"
                                onClick={() => onDeleteConfirm(confirmDeleteId)}
                                isLoading={deleteData.isLoading}>
                                {'Delete'}
                              </Button>

                              <Button
                                variant="outline"
                                onClick={onDeleteModalClose}>
                                {'Cancel'}
                              </Button>
                            </ButtonGroup>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </React.Fragment>
                  )
                )}
              </Tbody>
            </Table>
            {isOnline && !downTimeListQuery && <CenterSpinner type="normal" />}
          </TableContainer>
        </Stack>

        {/* Add Modal */}
        <Modal
          isOpen={isAddModalOpen}
          isCentered
          onClose={onAddModalClose}
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Add Operations Downtime'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack direction="column" spacing="4">
                    {addData.isError && (
                      <Alert status="error">
                        <AlertIcon />
                        {errorMessage}
                      </Alert>
                    )}
                    <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods?.formState?.errors?.downtime}
                          isRequired>
                          <FormLabel>Downtime Code</FormLabel>
                          <Controller
                            control={methods.control}
                            name="downtime"
                            rules={{
                              required: 'downtime',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="downtime"
                                placeholder={'Downtime Code'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline
                                  ? operationDowntimeCodeList.isOperationDownTimeCodeLoading
                                  : offlineOperationDowntimeCodeList.isLoading
                                }
                                options={isOnline
                                  ? operationDowntimeCodeList.operationDownTimeCodeOptions
                                  : offlineOperationDowntimeCodeList.operation_downtime_codes
                                }
                                styles={reactSelectStyles}
                                isSearchable={true}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods?.formState?.errors?.downTime &&
                              !!methods?.formState?.errors?.downTime?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods?.formState?.errors?.equipment}
                          isRequired>
                          <FormLabel>Equipment</FormLabel>
                          <Controller
                            control={methods.control}
                            name="equipment"
                            rules={{
                              required: 'Equipment',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="equipment"
                                placeholder={'Equipment'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline ? equipmentList.isEquipmentLoading : offlineEquipmentList.isLoading}
                                options={isOnline ? equipmentList.equipmentOptions : offlineEquipmentList.equipments}
                                styles={reactSelectStyles}
                                isSearchable={true}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods?.formState?.errors?.equipment &&
                              !!methods?.formState?.errors?.equipment?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.split}
                          isRequired>
                          <FormLabel>{'Hours'}</FormLabel>
                          <Input
                            type="number"
                            step="0.01"
                            placeholder={'Hours'}
                            {...methods.register('hours', {
                              required: 'Hours ',
                            })}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.split &&
                              !!methods.formState.errors?.split?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <ButtonGroup>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={addData.isLoading}>
                        Add
                      </Button>
                      <Button variant="outline" onClick={onAddModalClose}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Flex p={['3', '6']}>
          <Button
            variant={'outline'}
            onClick={() => {
              methods.reset();
              setAddModalOpen(true);
            }}>
            Add Downtime
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default OperationsDownTimeReport;
