import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PeopleResource from 'api/user';
import { CenterSpinner } from 'components/common/CenterSpinner';
import PeopleForm from 'components/people/PeopleForm';
import routes from 'constants/routes';
import { PeopleFormSchema } from 'interface/people/peopleListType';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const EditPeople: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<any>();
  const methods = useForm<PeopleFormSchema>();

  const api = new PeopleResource();

  const peopleQuery = useQuery(
    [`people${id}-edit`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updatePeople = useMutation((data: any) => api.update(id, data), {
    onSuccess: (res: any) => {
      toast({
        title: 'People has been updated.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('peopleList');
      navigate(routes.manage.peoples.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('People Update Failed.');
    },
  });

  const onSubmit = (data: any) => {
    let formData: any = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      user_type: data?.user_type?.value,
      ein_ssn: data?.ein_ssn,
      address: data?.address,
      phone: data?.phone,
      region: data?.region?.value,
      note: data?.note || '',
      company_name: data?.company_name,
      is_active_employee: data?.is_active_employee?.value,
    };

    if (data?.user_type?.value === 'employee') {
      formData['role'] = data?.role?.value;
      formData['start_date'] = data?.start_date;
      formData['end_date'] = data?.end_date;
    }

    if (data?.user_type?.value === 'employee' && data?.role?.value !== 1) {
      formData['plants'] = data?.plants?.map((res: any) => +res?.value);
      formData['permissions'] = data?.permissions;
    }
    updatePeople.mutate(formData);
  };

  if (peopleQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (peopleQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {'People Not Found.'}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">{'Edit People'}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updatePeople.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <PeopleForm peopleData={peopleQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updatePeople.isLoading}>
                  Update
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditPeople;
