// Auth
export const ACCESS_TOKEN = 'token';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_IN = 'expires';

// Pagination

export const DEFAULT_PAGE_SIZE = 15;

export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = ['5', '15', '30', '50', '100', 'See All'];

export const CSV_EXTENSION =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx,.xls';

export const USER_HEADER = [
  'First Name',
  'Middle Name (optional)',
  'Last Name',
  'Email',
  'Username (optional)',
  'Work Email',
  'Phone',
  'Company Name',
  'Role (Admin Y/N)',
];

export const PIT_HEADER = [
  'Serial Number',
  'Pit',
  'Address',
  'Coordinates',
  'Royalty',
  'Lease Expiration',
  'Minimum Royalty',
  'Royalty Payment Terms',
  'Region',
  'Owner',
];
export const PRODUCT_HEADER = [
  'Serial Number',
  'Product',
  'Product Code',
  'Product Group',
  'Rack Price',
];
export const EQUIPMENT_HEADER = [
  'Serial Number',
  'Name',
  'Description',
  'Code',
  'Equipment class',
  'Owner or rent',
  'Owner',
  'Physical Location',
  'Party',
  'Wet rate',
  'Dry rate',
  'Beginning date',
  'End date',
  'Equipment Serial number',
  'Fuel/hour',
  'Monthly rental',
  'Equipment Cost',
  'Wear/hr. Insurance',
  'Yard Accrual',
  'R+M/hr',
  'Note',
  'Plant',
];
export const PEOPLE_HEADER = [
  'Serial Number',
  'First Name',
  'Last Name',
  'Company Name',
  'Email',
  'Type',
  'EIN/SSN',
  'Address',
  'Phone',
  'Region',
  'Notes',
];
export const PLANT_HEADER = [
  'Serial Number',
  'Plant',
  'Pit',
  'MSHA Identification',
];
export const SALE_HEADER = [
  'Serial Number',
  'Pit',
  'Product',
  'Customer',
  'Trucking Company',
  'Ticket Number',
  'Invoice Number',
  'Invoice Date',
  'Date',
  'PO',
  'Truck Driver',
  'Vechicle ID',
  'Job Name',
  'Job Location',
  'Order Info',
  'Loader Scale Operator',
  'Gross Wt. Tons',
  'Tare Wt. Tons',
  'Net Wt. Tons',
  'Sale Type',
  'Price Per Ton',
  'Delivery Point',
  'Sales Tax Rate',
  'Trucking Cost Ton',
  'Trucking Price Ton',
  'Trucking Revenue Ton',
  'Trucking Margin Ton',
  'Other Revenue',
  'Product Revenue',
  'Total Sales Tax',
  'Notes',
  'Status',
];
export const AFE_HEADER = [
  'Serial Number',
  'AFE Number',
  'Job Name & Description',
  'Plant',
  'Start Date',
  'End Date',
  'Shift Hour',
  'Shift/Week',
  'Op Efficiency',
  'Op Hour',
  'Work Hour',
  'Op Hour/Shift',
  'Total Shift',
  'Tons/Shift',
  'Feed Rate',
  'Total Feed',
];

export const INVENTORY_HEADER = [
  'Serial Number',
  'Product',
  'Date',
  'Product Type',
  'Pit',
  'Plant',
  'Shift',
  'Pay',
  'Reported Production',
  'Adjusted Pproduction',
  'Adjusted Value',
  'Adjusted Type',
];

export const imageCompressionOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export enum ShiftDays {
  'day' = 'Day',
  'night' = 'Night',
  'shift' = 'Shift',
}

export const ShiftList = [
  { value: 1, label: 'Day' },
  { value: 2, label: 'Night' },
  { value: 3, label: 'Swing' },
];

export enum OwnerOrRent {
  'own' = 'Own',
  'rent' = 'Rent',
  'rpo' = 'RPO',
}

export const OwnerOrRentList = [
  { value: 'own', label: 'Own' },
  { value: 'rent', label: 'Rent' },
  { value: 'rpo', label: 'RPO' },
];

export enum OperatedStatusEnum {
  'operated' = 'Operated',
  'non_operated' = 'Non Operated',
}

export const OperatedStatusList = [
  { value: 'operated', label: 'Operated' },
  { value: 'non_operated', label: 'Non Operated' },
];

export const ownerOrRentMapping: { [value: string]: string } = {};
ownerOrRentMapping['own'] = 'Own';
ownerOrRentMapping['rent'] = 'Rent';
ownerOrRentMapping['rpo'] = 'RPO';

export enum YesNoEnum {
  'Yes' = 1,
  'No' = 2,
}

export const YesNoList = [
  { value: '1', label: 'Yes' },
  { value: '2', label: 'No' },
];

export enum EquipmentDamageReportEnum {
  'Equipment Damage' = 1,
  'MOB' = 2,
  'Service Callout' = 3,
}

export const EquipmentDamageReportList = [
  { value: '1', label: 'Equipment Damage' },
  { value: '2', label: 'MOB' },
  { value: '3', label: 'Service Callout' },
];

export enum RentalEnum {
  'purchase' = 'Purchase',
  'short_term_rental' = 'Short Term Rental',
}

export const RentalList = [
  { value: 'purchase', label: 'Purchase' },
  { value: 'short_term_rental', label: 'Short Term Rental' },
];

export enum PMTTypeEnum {
  'cash' = 'Cash',
  'credit_card' = 'Credit Card',
  'on_account' = 'On Account',
}

export const PMTTypeList = [
  { value: 'cash', label: 'Cash' },
  { value: 'credit_card', label: 'Credit Card' },
  { value: 'on_account', label: 'On Account' },
];

export enum FuelTypeEnum {
  'propane' = 'Propane',
  'gasoline' = 'Gasoline',
  'diesel' = 'Diesel',
}

export const FuelTypeList = [
  { value: 'propane', label: 'Propane' },
  { value: 'gasoline', label: 'Gasoline' },
  { value: 'diesel', label: 'Diesel' },
];

export enum PeopleTypeEnum {
  'employee' = 'Employee',
  'vendor' = 'Vendor',
  'customer' = 'Customer',
  'vendor_and_customer' = 'Vendor and Customer',
  'other' = 'Other',
}

export const PeopleTypeList = [
  { value: 'employee', label: 'Employee' },
  { value: 'vendor', label: 'Vendor' },
  { value: 'customer', label: 'Customer' },
  { value: 'vendor_and_customer', label: 'Vendor and Customer' },
  { value: 'other', label: 'Other' },
];

export enum RoleTypeEnum {
  'manager' = 'Manager',
  'foreman' = 'Foreman',
  'administration' = 'Administration',
  'sales' = 'Sales',
  'mechanic' = 'Mechanic',
  'operator' = 'Operator',
}

export const RoleTypeList = [
  { value: 'manager', label: 'Manager' },
  { value: 'foreman', label: 'Foreman' },
  { value: 'administration', label: 'Administration' },
  { value: 'sales', label: 'OSalesther' },
  { value: 'mechanic', label: 'Mechanic' },
  { value: 'operator', label: 'Operator' },
];

export enum AdditionalPayTypeEnum {
  'salary' = 'Salary',
  'hourly' = 'Hourly',
}

export const AdditionalPayTypeList = [
  { value: 'salary', label: 'Salary' },
  { value: 'hourly', label: 'Hourly' },
];

export const AdjustmentTypeOptions = [
  { value: 'percent', label: 'Percent' },
  { value: 'quantity', label: 'Quantity' },
];

export const REACT_DATE_PICKER_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const REACT_DATE_PICKER_TIME_FORMAT = 'h:mm aa';

export const status = Object.freeze({
  unchecked: 0,
  checked: 1,
  indeterminate: -1,
});

export enum SaleJobClassEnum {
  'Oilfield_Pad' = 'Oilfield Pad',
  'Oilfield_Roads' = 'Oilfield Roads',
  'Oilfield_Construction' = 'Oilfield Construction',
  'TxDOT' = 'TxDOT',
  'County' = 'County',
  'Municipa' = 'Municipa',
}

export const SaleJobClassList = [
  { value: 'Oilfield_Pad', label: 'Oilfield Pad' },
  { value: 'Oilfield_Roads', label: 'Oilfield Roads' },
  { value: 'Oilfield_Construction', label: 'Oilfield Construction' },
  { value: 'TxDOT', label: 'TxDOT' },
  { value: 'County', label: 'County' },
  { value: 'Municipa', label: 'Municipa' },
];
