import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PitResource from 'api/pit';
import PitForm from 'components/pit/PitForm';
import routes from 'constants/routes';
import { PitSchema } from 'interface/pit/pitSchema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DateFormatMDY } from 'utils';

const CreatePit: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const navigate = useNavigate();
  const methods = useForm<PitSchema>();
  const api = new PitResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addPit = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Pit has been created.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('pitList');
      navigate(routes.manage.pits.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Pit Creation Failed.');
    },
  });

  const onSubmit = (data: any) => {
    const formData = {
      address: data?.address,
      coordinates: data?.coordinates || '',
      lease_expiration: data?.lease_expiration || null,
      minimum_royalty: data?.minimum_royalty || 0,
      name: data?.name,
      owner: data?.owner?.value || '',
      region: data?.region?.value,
      royalty: data?.royalty || 0,
      royalty_payment_terms: data?.royalty_payment_terms,
    };
    addPit.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">Add Pit</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addPit.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <PitForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addPit.isLoading}>
                  Add Pit
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreatePit;
