import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import AFEResource from 'api/afe';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { AFEProductSchema, AFESchema } from 'interface/afe/afeSchema';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DateFormatMDY, formatNumber } from 'utils';
import AFEProductPriceList from './AFEProductPriceList';

const ViewAFE: React.FC = () => {
  const { id }: any = useParams();
  const api = new AFEResource();
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const dataQuery = useQuery<AFESchema>(
    [`afe${id}`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'afe-product-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const response = await api.product(id, queryParams);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.afe.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            AFE Details
          </Heading>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>AFE Number</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.number || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Job Name & Description</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Plant</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.plant?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Start Date</FormLabel>
                <Box sx={infoStyles}>
                  {DateFormatMDY(dataQuery?.data?.start_date) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>End Date</FormLabel>
                <Box sx={infoStyles}>
                  {DateFormatMDY(dataQuery?.data?.end_date) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Shift Hr</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.shift_hr &&
                    formatNumber(+dataQuery?.data?.shift_hr, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Shifts/Week</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.shift_per_week &&
                    formatNumber(+dataQuery?.data?.shift_per_week, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Op Efficiency</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.op_efficiency &&
                    formatNumber(+dataQuery?.data?.shift_hr, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Op Hour</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.op_hour &&
                    formatNumber(+dataQuery?.data?.op_hour, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Work Hour</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.work_hours &&
                    formatNumber(+dataQuery?.data?.work_hours, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Op Hour/Shift</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.op_hr_per_shift &&
                    formatNumber(+dataQuery?.data?.op_hr_per_shift, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Total Shift</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.total_shifts &&
                    formatNumber(+dataQuery?.data?.total_shifts, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Feed Rate</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.feed_rate &&
                    formatNumber(+dataQuery?.data?.feed_rate, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Tons/Shift</FormLabel>
                <Box sx={infoStyles}>
                  {(dataQuery?.data?.tons_per_shift &&
                    formatNumber(+dataQuery?.data?.tons_per_shift, 2)) ||
                    0}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>

      <AFEProductPriceList afeId={id} />

      <Stack direction="column" spacing="4" mt={5}>
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            AFE Product Details
          </Heading>
        </Flex>
        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Price</Th>
                  <Th>Tons</Th>
                  <Th>Splits</Th>
                  <Th>Pay</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map(
                    (listData: AFEProductSchema, index: number) => (
                      <Tr key={index}>
                        <Td>{listData?.product_name}</Td>
                        <Td>{formatNumber(+listData?.sale_price, 2)}</Td>
                        <Td>{formatNumber(+listData?.tons, 2)}</Td>
                        <Td>{formatNumber(+listData?.splits, 2)}</Td>
                        <Td>{formatNumber(+listData?.pay, 2)}</Td>
                      </Tr>
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default ViewAFE;
