import Resource from 'api/resource';
import http from 'utils/http';

class AFEResource extends Resource {
  constructor() {
    super('afe');
  }

  bulkDelete(resource: any) {
    return http({
      url: '/bulk-destroy/afe',
      method: 'post',
      data: resource,
    });
  }

  product(id: any, query?: any) {
    return http({
      url: `/afe/${id}/afe-product`,
      method: 'get',
      params: query,
    });
  }

  getProductPrice(id: any, query?: any) {
    return http({
      url: `/afe/${id}/product-price`,
      method: 'get',
      params: query,
    });
  }

  storeProductPrice(id: number, resource: any, headers?: any) {
    return http({
      url: `/afe/${id}/product-price`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  destroyProductPrice(id: any, productPriceId: any) {
    return http({
      url: `/afe/${id}/product-price/${productPriceId}`,
      method: 'delete',
    });
  }

  toggleStatus(id: any) {
    return http({
      url: `/toggle-afe-completed/${id}/`,
      method: 'post',
    });
  }

  updateProductPrice(id: any, productPriceId: any, resource: any) {
    return http({
      url: `/afe/${id}/product-price/${productPriceId}`,
      method: 'put',
      data: resource,
    });
  }
}

export { AFEResource as default };
