import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import TagResource from 'api/tag';
import { reactSelectStyles } from 'assets/css/commonStyles';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const TagForm: React.FC = () => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<any>();

  // Tag List
  const tagAPI = new TagResource();
  const { data: tagQuery, isLoading: isTagLoading } = useQuery(
    [`tag-dropdown-list`],
    () => tagAPI.listTagText({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const tagList = tagQuery?.results;
  const tagOptions = tagList?.map((tag: any) => ({
    label: tag.text,
    value: tag.id,
  }));

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.parent}>
            <FormLabel>Parent</FormLabel>
            <Controller
              control={control}
              name="parent"
              rules={{
                required: false,
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="parent"
                  placeholder={'Parent'}
                  defaultValue={''}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isTagLoading}
                  options={tagOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              )}
            />
            <FormErrorMessage>
              {!!errors?.parent && !!errors?.parent?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.text} isRequired>
            <FormLabel>{'Name'}</FormLabel>
            <Input
              {...register('text', {
                required: 'name',
              })}
              type="text"
              defaultValue={''}
              placeholder={'Name'}
            />
            <FormErrorMessage>
              {!!errors?.text && !!errors?.text?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default TagForm;
