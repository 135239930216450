import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PitResource from 'api/pit';
import { CenterSpinner } from 'components/common/CenterSpinner';
import PitForm from 'components/pit/PitForm';
import routes from 'constants/routes';
import { PitSchema } from 'interface/pit/pitSchema';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DateFormatMDY } from 'utils';

const EditPit: React.FC = () => {
  const { id }: any = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<any>();
  const methods = useForm<PitSchema>();

  const api = new PitResource();

  const pitQuery = useQuery(
    [`pit${id}-edit`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const updatePit = useMutation((data: any) => api.update(id, data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Pit has been updated.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('pitList');
      navigate(routes.manage.pits.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Pit Update Failed.');
    },
  });

  const onSubmit = (data: any) => {
    const formData = {
      address: data?.address,
      coordinates: data?.coordinates || '',
      lease_expiration: DateFormatMDY(data?.lease_expiration) || null,
      minimum_royalty: data?.minimum_royalty || 0,
      name: data?.name,
      owner: data?.owner?.value || '',
      region: data?.region?.value,
      royalty: data?.royalty || 0,
      royalty_payment_terms: data?.royalty_payment_terms,
    };
    updatePit.mutate(formData);
  };

  if (pitQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (pitQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {'Pit Not Found.'}
        </Alert>
      </Box>
    );
  }

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">{'Edit Pit'}</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {updatePit.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <PitForm pitData={pitQuery.data} />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={updatePit.isLoading}>
                  Update
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default EditPit;
