import Resource from 'api/resource';
import http from 'utils/http';

class LoadoutReportResource extends Resource {
  constructor() {
    super('reports');
  }

  listLoadoutReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/loadout`,
      method: 'get',
      params: query,
    });
  }
  getLoadoutReport(id: string, loadoutId: string) {
    return http({
      url: `/reports/${id}/loadout/${loadoutId}`,
      method: 'get',
    });
  }
  storeLoadoutReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/loadout`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateLoadoutReport(id: string, resource: any) {
    return http({
      url: `/reports/${id}/loadout`,
      method: 'put',
      data: resource,
    });
  }
  destroyLoadoutReport(id: string, loadoutId: string) {
    return http({
      url: `/reports/${id}/loadout/${loadoutId}`,
      method: 'delete',
    });
  }

  listLoadoutDetailReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/loadout-report`,
      method: 'get',
      params: query,
    });
  }
  getLoadoutDetailReport(id: string, loadoutDetailId: string) {
    return http({
      url: `/reports/${id}/loadout-report/${loadoutDetailId}`,
      method: 'get',
    });
  }
  storeLoadoutDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/loadout-report`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateLoadoutDetailReport(
    id: string,
    loadoutDetailId: string,
    resource: any
  ) {
    return http({
      url: `/reports/${id}/loadout-report/${loadoutDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyLoadoutDetailReport(id: string, loadoutDetailId: string) {
    return http({
      url: `/reports/${id}/loadout-report/${loadoutDetailId}`,
      method: 'delete',
    });
  }
}

export { LoadoutReportResource as default };
