import {
  Flex,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import InventoryResource from 'api/inventory';
import PitResource from 'api/pit';
import ProductResource from 'api/product';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CreatedAtInput from 'components/common/CreatedAtInput';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import InventoryListItem from 'components/inventory/InventoryListItem';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { DateFormatYMD } from 'utils';

const InventoryList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const [inventoryIds, setInventoryIds] = useState<any>([]);
  const api = new InventoryResource();
  const searchRef = useRef<any>('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
    start_date: '',
    end_date: '',
    pit: '',
    product: '',
  });

  const methods = useForm<any>();

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'inventoryList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        date_after: filterParams.start_date,
        date_before: filterParams.end_date,
        pits: filterParams.pit,
        products: filterParams.product,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
        date_after: filterParams.start_date,
        date_before: filterParams.end_date,
        pits: filterParams.pit,
        products: filterParams.product,
      };
      const response = await api.list(queryParams);
      setTotalData(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    }
  );

  const filterList = async (data: any) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };

  const handleFilter = () => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      product: methods.getValues('product')?.value,
      pit: methods.getValues('pit')?.value,
    }));
  };

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedPits, setSelectedPits] = useState([]);
  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts, selectedPits]);

  const handleSearch = () => {
    filterList({ keyword: searchRef.current.value });
  };

  // Pit List
  const pitAPI = new PitResource();
  const { data: pitQuery, isLoading: isPitLoading } = useQuery(
    [`pit-list`],
    () => pitAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const pitList = pitQuery?.results;
  const pitOptions = pitList?.map((pit: any) => ({
    label: pit.name,
    value: pit.id,
  }));

  // Product List
  const productAPI = new ProductResource();
  const { data: productQuery, isLoading: isProductLoading } = useQuery(
    [`product-list`],
    () => productAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const productList = productQuery?.results;
  const productOptions = productList?.map((product: any) => ({
    label: `${product.class_code} - ${product.name}`,
    value: product.id,
  }));

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Inventory</Heading>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                ref={searchRef}
                onKeyUp={handleSearch}
              />
            </InputGroup>
          </Stack>
        </Flex>

        <Flex justify="space-between">
          <Stack direction="row">
            <FormProvider {...methods}>
              <form>
                <Grid
                  gap="4"
                  templateColumns={['repeat(1, 1fr)', 'repeat(6, 1fr)']}
                  bg={'white'}
                  flex="1">
                  <GridItem>
                    <FormControl>
                      <Controller
                        control={methods.control}
                        name="sale_date"
                        rules={{
                          required: 'Report Date',
                        }}
                        render={({ field }) => (
                          <ReactDatePicker
                            fixedHeight
                            id="sale_date"
                            customInput={<CreatedAtInput />}
                            onChange={(selected: any) => {
                              setDateRange(selected);
                              setFilterParams((prevState: any) => ({
                                ...prevState,
                                currentPage: INITIAL_CURRENT_PAGE,
                                start_date: DateFormatYMD(selected[0]),
                                end_date: DateFormatYMD(selected[1]),
                              }));
                            }}
                            isClearable={true}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            selected={field.value}
                            shouldCloseOnSelect={true}
                            autoComplete="off"
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem w={'300px'}>
                    <FormControl>
                      <Controller
                        control={methods.control}
                        name="product"
                        render={({ field }) => (
                          <ReactSelect
                            id="product"
                            placeholder={'Select Product'}
                            onChange={(
                              selected: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              field.onChange(selected);
                              handleFilter();
                            }}
                            isLoading={isProductLoading}
                            options={productOptions}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                            value={field.value}
                            isClearable
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <Controller
                        control={methods.control}
                        name="pit"
                        render={({ field }) => (
                          <ReactSelect
                            id="pit"
                            placeholder={'Select Pit'}
                            onChange={(
                              selected: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              field.onChange(selected);
                              handleFilter();
                            }}
                            isLoading={isPitLoading}
                            options={pitOptions}
                            styles={reactSelectStyles}
                            isSearchable={true}
                            menuPortalTarget={document.body}
                            value={field.value}
                            isClearable
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </form>
            </FormProvider>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Product</Th>
                  <Th>Product Type</Th>
                  <Th>Pit</Th>
                  <Th>Net Tons</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map((listData: any, index: number) => (
                    <InventoryListItem
                      listData={listData}
                      key={listData.id}
                      setInventoryIds={setInventoryIds}
                      inventoryIds={inventoryIds}
                    />
                  ))}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default InventoryList;
