import Resource, { IResource } from 'api/resource';
import http from 'utils/http';

interface IReportResource extends IResource {
}
class ReportResource extends Resource {
  constructor() {
    super('reports');
  }

  getPreviousReport(resource: any, headers?: any) {
    return http({
      url: `/reports/previous-report/`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  getOtherEquipment(id: any, query?: any) {
    return http({
      url: `/report/${id}/other-equipments`,
      method: 'get',
      params: query,
    });
  }

  getForeman(id: any) {
    return http({
      url: '/reports/' + id,
      method: 'get',
    });
  }

  getMechanic(id: any) {
    return http({
      url: '/mechanic-reports/' + id,
      method: 'get',
    });
  }

  allDateReport(query?: any) {
    return http({
      url: 'foreman-mechanic-report',
      method: 'get',
      params: query,
    });
  }

  allReport(resource: any, headers?: any) {
    return http({
      url: 'foreman-mechanic-report-date-status',
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  getMechanicReport(id: any) {
    return http({
      url: '/mechanic-reports/' + id,
      method: 'get',
    });
  }
  storeMechanicReport(resource: any, headers?: any) {
    return http({
      url: '/mechanic-reports',
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  storeReportSave(reportId: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${reportId}/save`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  storeReportComplete(reportId: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${reportId}/complete`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  getLaborReport(query: any, headers?: any){ 
    return http({
      url: `/labor-reports`,
      method: "get",
      params: query
    })
  }

  mechanicalStoreReportSave(reportId: string, resource: any, headers?: any) {
    return http({
      url: `/mechanic-reports/${reportId}/save`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  mechanicalStoreReportComplete(
    reportId: string,
    resource: any,
    headers?: any
  ) {
    return http({
      url: `/mechanic-reports/${reportId}/complete`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  dailyTons(resource: any, query?: any, headers?: any) {
    return http({
      url: `/daily-tons`,
      method: 'post',
      data: resource,
      headers: headers,
      params: query,
    });
  }

  cumulativeTons(query?: any) {
    return http({
      url: `/get-cumulative-tons/`,
      method: 'get',
      params: query,
    });
  }

  delete(id: any, type: string) {
    return http({
      url: '/' + type + '/' + id,
      method: 'delete',
    });
  }

  storeMechanicReportBackOnline(resource: any, headers?: any) {
    return http({
      url: '/mechanic_reports/offline-report/',
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  storeForemanReportBackOnline(resource: any, headers?: any) {
    return http({
      url: '/reports/offline-report/',
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
}

export { ReportResource as default };
