import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import PitResource from 'api/pit';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DateFormatMDY, formatNumber } from 'utils';

const ViewPit: React.FC = () => {
  const { id }: any = useParams();
  const api = new PitResource();
  const navigate = useNavigate();

  const dataQuery = useQuery(
    [`pit${id}`, id],
    () =>
      api.get(id).then((res) => {
        return res?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.manage.pits.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            Pit Details
          </Heading>
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() =>
              navigate(routes.manage.pits.edit.replace(':id', id))
            }>
            {'Edit Pit'}
          </Button>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Name</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Address</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.address || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Coordinates</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.coordinates || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Royalty</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.royalty, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Lease Expiration</FormLabel>
                <Box sx={infoStyles}>
                  {DateFormatMDY(dataQuery?.data?.lease_expiration) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Minimum Royalty</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.minimum_royalty, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Royalty Payment Terms</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.royalty_payment_terms, 2) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Region</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.region?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Owner</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.owner?.first_name
                    ? `${dataQuery?.data?.owner?.first_name} ${dataQuery?.data?.owner?.last_name}`
                    : '-'}
                </Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewPit;
