import { Button } from '@chakra-ui/button';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Icon,
  Progress,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { CSV_EXTENSION } from 'constants/common';
import useFileUpload from 'hooks/useFileUpload';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { BiExport } from 'react-icons/bi';
import { useQueryClient } from 'react-query';
import checkHeaders from 'utils/checkHeaders';
import * as XLSX from 'xlsx';

interface Props {
  url: string;
  setRefetch?: Function;
  headerSchema: string[];
  label?: string;
  icon?: boolean;
  modal?: string;
}

const CsvUpload: React.FC<Props> = (props) => {
  let {
    url,
    setRefetch,
    headerSchema,
    label = 'Upload CSV',
    icon = true,
    modal,
  } = props;

  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [file, setFile] = useState<any>(null);
  const uploadRef = useRef<any>(null);
  const { loading, result, uploadPercent, error, setFormdata, setUploadFile } =
    useFileUpload(url);

  useEffect(() => {
    if (!isOpen && uploadRef) {
      if (uploadRef.current) uploadRef.current.value = null;
    }
  }, [isOpen]);

  useEffect(() => {
    if (!result) return;
    if (result.status === 200) {
      onClose();
      if (setRefetch) setRefetch((prev: number) => prev + 1);
      toast({
        title: 'File uploaded successfullly.',
        status: 'success',
        isClosable: true,
      });
    }
  }, [onClose, result, setRefetch, toast]);

  const handleChange = (e: any) => {
    let fs = e.target.files[0];
    if (!fs) return;
    const fileSize = fs.size / 1024 / 1024;
    if (fileSize > 15) {
      toast({
        title: 'Maximum upload attachments size upto 15 MB.',
        status: 'error',
        isClosable: true,
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = async function () {
      const fileData = reader.result;
      const workbook = XLSX.read(fileData, {
        type: 'binary',
        codepage: Number(process.env.REACT_APP_CODEPAGE),
        cellDates: true,
      });
      const sheetNameList = workbook.SheetNames;

      // check if the uploaded filess contains required headers (columns)
      const isValid = checkHeaders(
        workbook.Sheets[sheetNameList[0]],
        headerSchema
      );
      if (!isValid) {
        toast({
          title: 'Please upload valid data.',
          status: 'error',
          isClosable: true,
        });
      } else {
        let fileExtension = fs.name.split('.')[1];
        let fileName = fs.name.split('.')[0];
        let file = new File(
          [fs],
          fileName + '_' + Date.now().toString() + '.' + fileExtension,
          {
            type: fs.type,
          }
        );
        setFile(file);
        setUploadFile(file);
        uploadRef.current.value = null;
        let formdata = new FormData();
        if (file) formdata.append('csv_file', file);
        setFormdata(formdata);
        onOpen();
      }
    };
    reader.readAsBinaryString(fs);
  };
  const openUploadDialogue = () => {
    if (uploadRef) {
      uploadRef.current.click();
    }
  };
  const handleModalClose = () => {
    queryClient.invalidateQueries(modal + 'List');
    onClose();
  };

  return (
    <Fragment>
      <div>
        {icon && (
          <Button
            colorScheme="primary"
            leftIcon={<Icon as={BiExport} w="6" h="6" />}
            onClick={openUploadDialogue}
            size="sm">
            {label}
          </Button>
        )}
        {!icon && (
          <Button colorScheme="primary" onClick={openUploadDialogue} size="sm">
            {label}
          </Button>
        )}
        <form>
          <input
            type="file"
            name="uploadCSV"
            hidden
            ref={uploadRef}
            accept={CSV_EXTENSION}
            onChange={handleChange}
            onClick={() => (uploadRef.current.value = null)}
          />
        </form>
      </div>

      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Files</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify="space-between">
              <Text>{file && file.name}</Text>
            </Flex>
            <Progress hasStripe value={uploadPercent} />
          </ModalBody>

          <ModalFooter justifyContent="center" display={'block'}>
            {loading && uploadPercent !== 100 && <Text>Uploading...</Text>}
            {loading && uploadPercent === 100 && (
              <Stack direction="row" align="center">
                <Spinner />
                <Text>Processing...</Text>
              </Stack>
            )}
            {!loading && result?.status === 201 && <Text>Done</Text>}
            {!loading && error && (
              <>
                <Text color="red">
                  !Error:
                  {error.status >= 500
                    ? 'Internal Server Error'
                    : error?.data?.message}
                </Text>
                {error?.data?.error && (
                  <Text color="red">{error?.data?.error}</Text>
                )}
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default CsvUpload;
