import {
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ReportResource from 'api/report';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ReportListItem from 'components/report/ReportListItem';
import routes from 'constants/routes';
import { ReportSchema } from 'interface/report/reportSchema';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DateFormatMDY } from 'utils';

const ReportList: React.FC = () => {
  const api = new ReportResource();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [filterParams, setFilterParams] = useState<{
    currentDate: string;
  }>({
    currentDate: params.get('report_date') || DateFormatMDY(new Date()),
  });

  const { data: dateReportList, isLoading: isDateReportListLoading } = useQuery(
    [
      'total-report-list',
      {
        currentDate: filterParams.currentDate,
      },
    ],
    async () => {
      const queryParams: any = {
        date: filterParams.currentDate,
      };

      return api.allDateReport(queryParams).then((res) => {
        const foremanList = res?.data?.foreman_reports?.map((foreman: any) => {
          return {
            ...foreman,
            report: 'foreman',
          };
        });
        const mechanicList = res?.data?.mechanic_reports?.map(
          (mechanic: any) => {
            return {
              ...mechanic,
              report: 'mechanic',
            };
          }
        );
        const totalReport = [...foremanList, ...mechanicList];
        return totalReport;
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleReportPagination = (type: string) => {
    let newDate = DateFormatMDY(
      moment(filterParams.currentDate).add(1, 'days')
    );
    if (type === 'backward') {
      newDate = DateFormatMDY(
        moment(filterParams.currentDate).subtract(1, 'days')
      );
    }
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentDate: newDate,
    }));
    navigate(`${routes.dailyreport.base}/?report_date=${newDate}`);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Report of {filterParams.currentDate}</Heading>
          </Stack>
          <Stack direction="row">
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => handleReportPagination('backward')}>
              Previous
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => handleReportPagination('forward')}>
              Next
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Plant</Th>
                  <Th>Pit</Th>
                  <Th>Shift</Th>
                  <Th>Shift Hours</Th>
                  <Th>Status</Th>
                  <Th>Report Type</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isDateReportListLoading &&
                  dateReportList?.map((listData: ReportSchema) => {
                    return (
                      <ReportListItem listData={listData} key={listData.id} />
                    );
                  })}
                {isDateReportListLoading && (
                  <TableSkeletonLoader rows={5} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </>
  );
};

export default ReportList;
