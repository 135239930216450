import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  StackDivider,
  Tag,
  TagLabel,
  Text,
  VStack,
} from '@chakra-ui/react';
import ReportResource from 'api/report';
import { ShiftList } from 'constants/common';
import routes from 'constants/routes';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import React, { useContext, useState } from 'react';
import { Calendar } from 'react-multi-date-picker';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DateFormatMDY, truncateString } from 'utils';

interface Props {
  setChangeDate: Function;
}

const CalendarComponent: React.FC<Props> = (props) => {
  const { queue } = useContext(OfflineContext);
  const isOnline = useConnectionStatus();

  const { setChangeDate } = props;
  const [value, setValue] = useState(new Date());
  const [isModalOpen, setModalOpen] = useState(false);
  const [report, setReport] = useState('');
  const navigate = useNavigate();
  const [allReport, setAllReport] = useState<any>([]);
  const [reportId, setReportId] = useState({
    mechanicId: 0,
    foremanId: 0,
  });
  const [filterParams, setFilterParams] = useState<{
    date: string;
    currentDate: string;
  }>({
    date: DateFormatMDY(new Date()),
    currentDate: DateFormatMDY(new Date()),
  });

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const handleReport = () => {
    switch (loggedInUser?.role) {
      case 'company_admin':
        setModalOpen(true);
        break;
      case 'mechanic':
        navigate(routes.reports.mechanicalReport.list);
        break;
      case 'foreman':
        navigate(routes.reports.foremanReport.list);
        break;
      default:
        setModalOpen(true);
    }
  };

  const onModalSubmit = () => {
    switch (report) {
      case 'mechanical':
        setReport('');
        setModalOpen(false);
        navigate(
          reportId.mechanicId > 0
            ? routes.reports.mechanicalReport.list +
            '?report_id=' +
            reportId.mechanicId
            : routes.reports.mechanicalReport.list
        );
        break;
      case 'foreman':
        setReport('');
        setModalOpen(false);
        navigate(
          reportId.foremanId > 0
            ? routes.reports.foremanReport.list +
            '?report_id=' +
            reportId.foremanId
            : routes.reports.foremanReport.list
        );
        break;
      default:
        setModalOpen(true);
    }
  };

  const onChange = (val: any) => {
    setChangeDate(DateFormatMDY(val));
    setFilterParams((prevState) => ({
      ...prevState,
      date: DateFormatMDY(val),
    }));
  };

  const api = new ReportResource();
  useQuery(
    [
      'report-in-calendar',
      {
        currentDate: filterParams.currentDate,
      },
    ],
    async () => {
      const queryParams: any = {
        date: filterParams.currentDate,
      };

      return api.allReport(queryParams).then((res) => {
        const foremanList = res?.data?.foreman_report?.map((foreman: any) => {
          return {
            ...foreman,
            report: 'foreman',
          };
        });
        const mechanicList = res?.data?.mechanic_reports?.map(
          (mechanic: any) => {
            return {
              ...mechanic,
              report: 'mechanic',
            };
          }
        );
        const totalReport = [...foremanList, ...mechanicList];
        setAllReport(totalReport);
        return totalReport;
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dateReportList, isLoading: isDateReportListLoading } = useQuery(
    [
      'date-report-list',
      {
        date: filterParams.date,
      },
    ],
    () => {
      const queryParams: any = {
        date: filterParams.date,
      };

      if (isOnline) {
        return api.allDateReport(queryParams).then((res) => {
          const foremanList = res?.data?.foreman_reports?.map((foreman: any) => {
            return {
              ...foreman,
              report: 'foreman',
            };
          });
          const mechanicList = res?.data?.mechanic_reports?.map(
            (mechanic: any) => {
              return {
                ...mechanic,
                report: 'mechanic',
              };
            }
          );
          const totalReport = [...foremanList, ...mechanicList];
          return totalReport;
        });
      } else {
        const totalReport = queue.map((elem) => { return elem.data });
        setAllReport(totalReport);
        return totalReport;
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const isHighlight = (date: any) => {
    const reportDate = DateFormatMDY(date);

    let props: any = {};
    let isWeekend = [0, 6].includes(date.weekDay.index);
    if (isWeekend) props.className = 'highlight highlight-red';

    const isExist = allReport
      ?.filter(
        (res: { report_date: string }) =>
          reportDate === DateFormatMDY(res?.report_date)
      )
      .some((res: { is_submitted: boolean; is_completed: boolean }) => {
        return res?.is_completed;
      });

    if (isExist) {
      props.className = 'highlight custom-green';
    } else if (!isExist) {
      props.className = 'highlight custom-red';
    }

    return props;
  };

  const handleCalendarChange = (calendarDate: string) => {
    setChangeDate(DateFormatMDY(calendarDate));
    setFilterParams((prevState) => ({
      ...prevState,
      currentDate: DateFormatMDY(calendarDate),
    }));
  };

  return (
    <>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        border={'1px solid #C5C6C9'}
        bg={'#fff'}>
        <Center>
          <Calendar
            onMonthChange={(date) => handleCalendarChange(DateFormatMDY(date))}
            onYearChange={(date) => handleCalendarChange(DateFormatMDY(date))}
            value={value}
            onChange={onChange}
            mapDays={({ date }) => isHighlight(date)}
            weekStartDayIndex={1}
          />
        </Center>
        <Center>
          <Button
            borderColor="#406F96"
            color="#406F96"
            variant="outline"
            onClick={handleReport}>
            + Create new report
          </Button>
        </Center>

        {dateReportList?.slice(0, 5).map((res: any) => (
          <>
            <Grid
              width={'100%'}
              padding={'10px'}
              _hover={{ cursor: 'pointer' }}
              templateColumns="repeat(2, 1fr)"
              onClick={() =>
                navigate(
                  res?.report === 'mechanic'
                    ? routes.reports.mechanicalReport.list +
                    '/?report_id=' +
                    res?.id
                    : routes.reports.foremanReport.list +
                    '/?report_id=' +
                    res?.id
                )
              }>
              <Box>
                <Stack direction={'column'} spacing={'4'}>
                  <Flex justify={'space-between'}>
                    <Stack direction={'row'}>
                      <Tag
                        bg={res?.is_submitted ? 'green.300' : 'primary.700'}
                        color={'white'}
                        size={'md'}
                        variant="subtle"
                        colorScheme="cyan">
                        <TagLabel>{`${res?.is_submitted ? 'Complete' : 'In Progress'
                          }`}</TagLabel>
                      </Tag>
                      <Text>{isOnline
                        ? res?.shift?.name
                        : ShiftList.find((type: any) => type.value === res.shift)?.label}</Text>
                    </Stack>
                  </Flex>
                  <Stack direction={'row'}>
                    <Text fontSize="l">{DateFormatMDY(res?.report_date)}</Text>
                    <Text fontSize="l">
                      {truncateString(res?.plant?.name, 8)}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
              <Center>
                <Text color={'primary.700'}>view/edit</Text>
              </Center>
            </Grid>
          </>
        ))}
        {/* {dateReportList && dateReportList?.length > 5 && ( */}
        <Grid mt={5}>
          <Center>
            <Button
              borderColor="#406F96"
              color="#406F96"
              variant="outline"
              onClick={() =>
                navigate(
                  `${routes.dailyreport.base}/?report_date=${filterParams.date}`
                )
              }>
              See All
            </Button>
          </Center>
        </Grid>
        {/* )} */}
        {/* {isDateReportListLoading && <Spinner />} */}
      </VStack>

      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Report'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{'Which report do you want to check ?'}</Text>
            <RadioGroup onChange={setReport}>
              <Stack direction="row">
                <Radio value="mechanical">Mechanic</Radio>
                <Radio value="foreman">Foreman</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="red" onClick={onModalSubmit}>
                {'Ok'}
              </Button>

              <Button
                variant="outline"
                onClick={() => {
                  setReportId({
                    mechanicId: 0,
                    foremanId: 0,
                  });
                  setModalOpen(false);
                }}>
                {'Cancel'}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CalendarComponent;
