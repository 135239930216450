/* eslint-disable import/no-anonymous-default-export */
import { ReportAction, ReportActionTypes } from 'actions/data/report';

const INITIAL_STATE = {
  vendorList: null,
  equipmentList: null,
  equipmentTypeList: null,
  plantList: null,
  pitList: null,
  afeList: null,
  businessUnitList: null,
  operationList: null,
  shiftList: null,
  managerList: null,
  foremanList: null,
  customerList: null,
  productList: null,
  operationDowntimeCodeList: null,
  shiftDowntimeCodeList: null,
  employeeList: null,
  activityList: null,
  laborList: null,
  mechanicReport: null,
  foremanReport: null
};

export default function (reportState = INITIAL_STATE, action: ReportAction) {
  switch (action.type) {
    case ReportActionTypes.SET_VENDORS:
      return {
        ...reportState,
        vendorList: action.data,
      };

    case ReportActionTypes.SET_EQUIPMENT:
      return {
        ...reportState,
        equipmentList: action.data,
      };

    case ReportActionTypes.SET_EQUIPMENT_TYPE:
      return {
        ...reportState,
        equipmentTypeList: action.data,
      };

    case ReportActionTypes.SET_PLANT:
      return {
        ...reportState,
        plantList: action.data,
      };

    case ReportActionTypes.SET_PIT:
      return {
        ...reportState,
        pitList: action.data,
      };

    case ReportActionTypes.SET_AFE:
      return {
        ...reportState,
        afeList: action.data,
      };

    case ReportActionTypes.SET_BUSINESS_UNIT:
      return {
        ...reportState,
        businessUnitList: action.data,
      };

    case ReportActionTypes.SET_OPERATION:
      return {
        ...reportState,
        operationList: action.data,
      };

    case ReportActionTypes.SET_SHIFT:
      return {
        ...reportState,
        shiftList: action.data,
      };

    case ReportActionTypes.SET_FOREMAN:
      return {
        ...reportState,
        foremanList: action.data,
      };

    case ReportActionTypes.SET_MANAGER:
      return {
        ...reportState,
        managerList: action.data,
      };

    case ReportActionTypes.SET_CUSTOMER:
      return {
        ...reportState,
        customerList: action.data,
      };

    case ReportActionTypes.SET_PRODUCT:
      return {
        ...reportState,
        productList: action.data,
      };

    case ReportActionTypes.SET_OPERATION_DOWNTIME_CODE:
      return {
        ...reportState,
        operationDowntimeCodeList: action.data,
      };

    case ReportActionTypes.SET_SHIFT_DOWNTIME_CODE:
      return {
        ...reportState,
        shiftDowntimeCodeList: action.data,
      };

    case ReportActionTypes.SET_EMPLOYEE:
      return {
        ...reportState,
        employeeList: action.data,
      };

    case ReportActionTypes.SET_ACTIVITY:
      return {
        ...reportState,
        activityList: action.data,
      };

    case ReportActionTypes.SET_LABOR:
      return {
        ...reportState,
        laborList: action.data,
      };
    case ReportActionTypes.SET_MECHANIC_REPORT:
      return {
        ...reportState,
        mechanicReport: action.data
      };
    case ReportActionTypes.SET_FOREMAN_REPORT:
      return {
        ...reportState,
        foremanReport: action.data
      }
    default:
      return reportState;
  }
}
