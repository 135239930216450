import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, useForm } from 'react-hook-form';

interface Props {
  isModalOpen: boolean;
  setModalOpen: Function;
  fieldData: any;
  onModalSubmit: Function;
  formMutation: any;
  errorMessage: string;
  setFieldData?: any;
}

const NoteModal: React.FC<Props> = (props) => {
  const {
    isModalOpen,
    setModalOpen,
    fieldData,
    onModalSubmit,
    formMutation,
    errorMessage,
  } = props;
  const methods = useForm<any>();

  const onModalClose = () => {
    setModalOpen(false);
  };

  const onSubmit = async (data: any) => {
    onModalSubmit(data);
  };

  useEffect(() => {
    if (!fieldData) return;
    methods.setValue('note', fieldData?.note);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData, methods]);

  return (
    <>
      <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{fieldData?.id ? 'Edit Note' : 'Add Note'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack direction="column" spacing="4">
                  {formMutation?.isError && (
                    <Alert status="error">
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                  <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.note}
                        isRequired>
                        <Textarea
                          {...methods.register('note', {
                            required: 'Note',
                          })}
                          defaultValue={fieldData?.note}
                          placeholder={'Note...'}
                        />
                        <FormErrorMessage>
                          {!!methods?.formState?.errors?.note &&
                            !!methods?.formState?.errors?.note?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={formMutation?.isLoading}>
                      {fieldData?.id ? 'Save' : 'Add'}
                    </Button>
                    <Button variant="outline" onClick={onModalClose}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Stack>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NoteModal;
