import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import EquipmentResource from 'api/equipment';
import EquipmentForm from 'components/equipment/EquipmentForm';
import routes from 'constants/routes';
import { EquipmentSchema } from 'interface/equipment/equipmentSchema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DateFormatMDY } from 'utils';

const CreateEquipment: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const navigate = useNavigate();
  const methods = useForm<EquipmentSchema>();
  const api = new EquipmentResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addEquipment = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Equipment has been created.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('equipmentList');
      navigate(routes.manage.equipments.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Equipment Creation Failed.');
    },
  });

  const onSubmit = (data: any) => {
    const formData = {
      name: checkNullOrEmpty(data?.name),
      description: checkNullOrEmpty(data?.description),
      code: checkNullOrEmpty(data?.code),
      equipment_class: data?.equipment_class?.value,
      // optional: data?.optional?.value,
      owner_or_rent: data?.owner_or_rent?.value,
      owner: data?.owner,
      location: checkNullOrEmpty(data?.location),
      party: checkNullOrEmpty(data?.party),
      wet_rate: checkNullOrEmpty(data?.wet_rate),
      dry_rate: checkNullOrEmpty(data?.dry_rate),
      beginning_date: DateFormatMDY(checkNullOrEmpty(data?.beginning_date)),
      end_date: DateFormatMDY(checkNullOrEmpty(data?.end_date)),
      serial_number: checkNullOrEmpty(data?.serial_number),
      fuel_per_hour: checkNullOrEmpty(data?.fuel_per_hour),
      monthly_rental: checkNullOrEmpty(data?.monthly_rental),
      budgeted_operating_hours: checkNullOrEmpty(
        data?.budgeted_operating_hours
      ),
      budgeted_idle_hours_month: checkNullOrEmpty(
        data?.budgeted_idle_hours_month
      ),
      note: data?.note,
      plant: data?.plant?.value,
      cost: data.cost,
      wear_per_hour_insurance: data.wear_per_hour_insurance,
      yard_accural: data.yard_accural,
      r_m_per_hour: data.r_m_per_hour,
      operated_status: data?.operated_status?.value,
    };
    addEquipment.mutate(formData);
  };

  const checkNullOrEmpty = (input: any) => {
    if (input?.trim()?.length > 0) {
      return input;
    }
    return undefined;
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md">Add Equipment</Heading>
        </Flex>

        <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {addEquipment.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}
                <EquipmentForm />
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={addEquipment.isLoading}>
                    Add Equipment
                  </Button>
                  <Button variant="outline" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Stack>
    </>
  );
};

export default CreateEquipment;
