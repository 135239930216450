import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import EquipmentRepairResource from 'api/equipmentRepair';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { ReportProps } from 'interface/report/reportSchema';
import React, { useEffect, useRef, useState } from 'react';
import { BsTag, BsTrash } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import EquipmentRepairDetailReport from './EquipmentRepairDetailReport';

const EquipmentRepairReport: React.FC<ReportProps> = (props) => {
  const { reportId } = props;
  const [openIndex, setOpenIndex] = useState(0);
  const containerRef = useRef<any>();
  const api = new EquipmentRepairResource();
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [tagData, setTagData] = useState(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);

  const equipmentRepairStatusQuery = useQuery(
    [`getEquipmentRepairStatus`],
    async () => {
      const response = await api.mechanicalListEquipmentRepairReport(reportId, {
        limit: 5000,
      });
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: equipmentRepairListQuery,
    isLoading: isEquipmentRepairListLoading,
  } = useQuery(
    [`equipment-repair-list`],
    () =>
      api
        .mechanicalListEquipmentRepairDetailReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          return res?.data;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const handleToggle = (index: number) => {
    setOpenIndex(index);
    let display = document.querySelector<HTMLElement>(`#equipment${index}`)!
      .style.display;
    if (display === '') display = 'none';
    document.querySelector<HTMLElement>(`#equipment${index}`)!.style.display =
      display === 'none' ? 'table-row' : 'none';
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/equipment-repair')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  const handleNoteModal = (data: any) => {
    setNoteModalOpen(true);
  };

  const updateEquipmentRepairData = useMutation(
    (data: any) => api.mechanicalUpdateEquipmentRepairReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getEquipmentRepairStatus');
      },
    }
  );
  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      note: modalValue.note,
    };
    updateEquipmentRepairData.mutate(data);
    setNoteModalOpen(false);
  };

  const handleDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: number) =>
      api.mechanicalDestroyEquipmentRepairDetailReport(reportId, id + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('equipment-repair-list');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (id != 0) deleteData.mutate(id);
  };

  const handleNoteDetailModal = (equipmentRepair: any) => {
    setNoteDetailFieldData(equipmentRepair);
    setNoteDetailModalOpen(true);
  };

  const updateData = useMutation(
    (data: any) =>
      api.mechanicalUpdateEquipmentRepairDetailReport(
        reportId,
        data?.id,
        data?.body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('equipment-repair-list');
      },
    }
  );

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        parts_cost: fieldData?.parts_cost,
        labor_cost: fieldData?.labor_cost,
        repair_reasons: fieldData?.repair_reasons?.id,
        operator_error: fieldData?.operator_error,
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    updateData.mutate(data);
    setNoteDetailModalOpen(false);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('equipment-repair-list');
    queryClient.invalidateQueries('getEquipmentRepairStatus');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Equipment Repair/Maintenance Log</Heading>
          <Stack direction="row" spacing="6">
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(equipmentRepairStatusQuery?.data, 'tagStatus')
                }
              />
            </Tooltip>
            {tagData === equipmentRepairStatusQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={equipmentRepairStatusQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
              />
            )}
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant="striped" colorScheme="gray">
              <Tbody>
                <Tr>
                  <Td>Repaired Equipment</Td>
                  <Td>Parts Cost</Td>
                  <Td>Labor Hours</Td>
                  <Td>Labor Cost</Td>
                  <Td></Td>
                </Tr>
                {equipmentRepairListQuery?.results?.map(
                  (equipmentRepair: any, indexNumber: number) => (
                    <React.Fragment key={equipmentRepair?.id}>
                      <Tr>
                        <Td>
                          <Stack direction="row">
                            <Tooltip
                              hasArrow
                              label={openIndex === indexNumber ? 'Down' : 'Up'}>
                              <IconButton
                                icon={
                                  openIndex === indexNumber ? (
                                    <TriangleUpIcon />
                                  ) : (
                                    <TriangleDownIcon />
                                  )
                                }
                                variant="link"
                                aria-label={'Up'}
                                color="gray.300"
                                size="lg"
                                onClick={() => handleToggle(indexNumber)}
                              />
                            </Tooltip>
                            <Text>{equipmentRepair?.equipment?.name}</Text>
                          </Stack>
                        </Td>
                        <Td>${equipmentRepair?.parts_cost}</Td>
                        <Td>{equipmentRepair?.labor_hours}</Td>
                        <Td>${equipmentRepair?.labor_cost}</Td>
                        <Td>
                          <Tooltip hasArrow label={'Tag'}>
                            <IconButton
                              icon={<BsTag />}
                              variant="link"
                              aria-label={'Tag'}
                              color={'gray.300'}
                              size="lg"
                              onClick={() =>
                                handleTagModal(equipmentRepair, 'tagDetail')
                              }
                            />
                          </Tooltip>
                          {tagData === equipmentRepair?.tag && (
                            <TagModal
                              isModalOpen={tagDetailModalOpen}
                              setModalOpen={setTagDetailModalOpen}
                              fieldData={tagData}
                              onModalSubmit={() => onTagModalSubmit()}
                            />
                          )}
                          <Tooltip hasArrow label={equipmentRepair?.note}>
                            <IconButton
                              icon={<MdOutlineModeComment />}
                              variant="link"
                              aria-label={'Note'}
                              color={
                                equipmentRepair?.note
                                  ? 'primary.600'
                                  : 'gray.300'
                              }
                              size="lg"
                              onClick={() =>
                                handleNoteDetailModal(equipmentRepair)
                              }
                            />
                          </Tooltip>
                          <NoteModal
                            isModalOpen={noteDetailModalOpen}
                            setModalOpen={setNoteDetailModalOpen}
                            fieldData={noteDetailFieldData}
                            onModalSubmit={(notes: string) =>
                              onNoteDetailModalSubmit(
                                notes,
                                noteDetailFieldData
                              )
                            }
                            formMutation={updateData}
                            errorMessage={errorMessage}
                          />
                          <Tooltip hasArrow label={'Delete'}>
                            <IconButton
                              icon={<BsTrash />}
                              variant="link"
                              aria-label={'Delete'}
                              color="gray.300"
                              size="lg"
                              onClick={() => handleDelete(equipmentRepair?.id)}
                            />
                          </Tooltip>
                        </Td>
                      </Tr>
                      <Tr
                        id={`equipment${indexNumber}`}
                        style={{
                          display:
                            openIndex === indexNumber ? 'table-row' : 'none',
                        }}>
                        <Td colSpan={5}>
                          <EquipmentRepairDetailReport
                            equipmentRepair={equipmentRepair}
                            reportId={reportId}
                          />
                        </Td>
                      </Tr>

                      {/* Delete Modal */}
                      <Modal
                        isOpen={isDeleteModalOpen}
                        isCentered
                        onClose={onDeleteModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>
                            {'Delete Equipment Repair/Maintenance Log'}
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {'Are you sure you want to delete this ?'}
                            {deleteMsg && (
                              <Alert status="error" mb={2}>
                                <AlertIcon />
                                {deleteMsg}
                              </Alert>
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <ButtonGroup>
                              <Button
                                colorScheme="red"
                                onClick={() => onDeleteConfirm(confirmDeleteId)}
                                isLoading={deleteData.isLoading}>
                                {'Delete'}
                              </Button>

                              <Button
                                variant="outline"
                                onClick={onDeleteModalClose}>
                                {'Cancel'}
                              </Button>
                            </ButtonGroup>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </React.Fragment>
                  )
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Box>
    </>
  );
};

export default EquipmentRepairReport;
