import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import LaborDetailReportResource from 'api/laborDetail';
import ReportResource from 'api/report';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import TagModal from 'components/common/TagModal';
import useDebounceFunc from 'hooks/useDebounceFunc';
import useReportDropDown from 'hooks/useReportDropDown';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  laborId: number;
  reportId: string;
}

const MechanicalLaborDetailReport: React.FC<Props> = (props) => {
  const { laborId, reportId } = props;
  const reportAPI = new ReportResource();
  const toast = useToast();
  const methods = useForm<any>();
  const laborDetailAPI = new LaborDetailReportResource();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [tagData, setTagData] = useState(0);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);
  const {
    isEmployeeLoading,
    isEquipmentLoading,
    equipmentOptions,
    isAfeLoading,
    afeOptions,
    isActivityLoading,
    activityOptions,
  } = useReportDropDown();

  const { data: laborListQuery, isLoading: isLaborListLoading } = useQuery(
    [`mechanical-labor-detail-list`, { laborId }],
    () =>
      laborDetailAPI
        .mechanicalListLaborDetailReport(laborId, {
          limit: 5000,
        })
        .then((res) => {
          return res?.data;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: afeData } = useQuery(
    [`afeMechanicData`, { reportId }],
    async () => {
      const response = await reportAPI.get(reportId);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const addData = useMutation(
    (data: any) =>
      laborDetailAPI.mechanicalStoreLaborDetailReport(laborId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries([
          'mechanical-labor-detail-list',
          { laborId },
        ]);
        queryClient.invalidateQueries('equipment-repair-list');
        setAddModalOpen(false);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey && objKey[0] === 'error') {
          setErrorMessage(error?.response?.data?.error);
        } else if (objKey) {
          setErrorMessage(error?.response?.data[objKey[0]][0]);
        } else {
          setErrorMessage('Create Failed.');
        }
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      afe: data?.afe?.value,
      activity: data?.activity?.value,
      repaired_equipment: data?.repaired_equipment?.value,
      used_equipment: data?.used_equipment?.value,
      hours: data?.hours,
    };
    addData.mutate(formData);
    queryClient.invalidateQueries([
      'mechanical-labor-detail-list',
      { laborId },
    ]);
  };

  const updateData = useMutation(
    (data: any) =>
      laborDetailAPI.mechanicalUpdateLaborDetailReport(
        laborId,
        data?.id,
        data?.body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'mechanical-labor-detail-list',
          { laborId },
        ]);
        queryClient.invalidateQueries('equipment-repair-list');
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        let errMsg = 'Create Failed.';
        if (objKey && objKey[0] === 'error') {
          errMsg = error?.response?.data?.error;
        } else if (objKey) {
          errMsg = error?.response?.data[objKey[0]][0];
        }
        toast({
          title: errMsg,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );
  const handleChange = (
    fieldData: any,
    fieldValue: string,
    fieldType: string
  ) => {
    let data: any = {
      body: {
        afe: 0,
        activity: 0,
        repaired_equipment: 0,
        used_equipment: 0,
        hours: 0,
      },
      id: 0,
    };
    data.id = fieldData.id;
    switch (fieldType) {
      case 'afe':
        data.body.afe = +fieldValue;
        data.body.activity = fieldData?.activity.id;
        data.body.repaired_equipment = fieldData?.repaired_equipment.id;
        data.body.used_equipment = fieldData?.used_equipment.id;
        data.body.hours = fieldData?.hours;
        break;
      case 'activity':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = +fieldValue;
        data.body.repaired_equipment = fieldData?.repaired_equipment.id;
        data.body.used_equipment = fieldData?.used_equipment.id;
        data.body.hours = fieldData?.hours;
        break;
      case 'repaired_equipment':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = fieldData?.activity.id;
        data.body.repaired_equipment = +fieldValue;
        data.body.used_equipment = fieldData?.used_equipment.id;
        data.body.hours = fieldData?.hours;
        break;
      case 'used_equipment':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = fieldData?.activity.id;
        data.body.repaired_equipment = fieldData?.repaired_equipment.id;
        data.body.used_equipment = +fieldValue;
        data.body.hours = fieldData?.hours;
        break;
      case 'hours':
        data.body.afe = fieldData?.afe.id;
        data.body.activity = fieldData?.activity.id;
        data.body.repaired_equipment = fieldData?.repaired_equipment.id;
        data.body.used_equipment = fieldData?.used_equipment.id;
        data.body.hours = fieldValue;
        break;
    }
    updateData.mutate(data);
  };
  const debounce = useDebounceFunc(handleChange, 3000);

  const handleDetailDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: number) =>
      laborDetailAPI.mechanicalDestroyLaborDetailReport(laborId, id + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries([
          'mechanical-labor-detail-list',
          { laborId },
        ]);
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (id != 0) deleteData.mutate(id);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    queryClient.invalidateQueries([
      'mechanical-labor-detail-list',
      { laborId },
    ]);
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      return;
    } else if (type === 'tagDetail') {
      setTagData(tagData?.tag);
      setTagModalOpen(true);
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <Tbody>
            <Tr bg={'gray300'}>
              <Td>AFE</Td>
              <Td>Activity</Td>
              <Td>Repaired Equipment</Td>
              <Td>Used Equipment</Td>
              <Td>Hours</Td>
              <Td>Change Details</Td>
              <Td></Td>
            </Tr>
            {laborListQuery?.results?.map((labor: any, indexNumber: number) => (
              <React.Fragment key={labor?.id}>
                <Tr>
                  <Td>
                    <ReactSelect
                      defaultValue={{
                        label: labor?.afe?.name,
                        value: labor?.afe?.id,
                      }}
                      placeholder={'Select AFE'}
                      isLoading={isAfeLoading}
                      options={afeOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onChange={(e: any) => handleChange(labor, e.value, 'afe')}
                      menuPortalTarget={document.body}
                    />
                  </Td>
                  <Td>
                    {labor?.activity?.name}
                    {/* <ReactSelect
                      defaultValue={{
                        label: labor?.activity?.name,
                        value: labor?.activity?.id,
                      }}
                      placeholder={'Select Activity'}
                      isLoading={isActivityLoading}
                      options={activityOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onChange={(e: any) => {
                        handleChange(labor, e.value, 'activity');
                      }}
                      menuPortalTarget={document.body}
                    /> */}
                  </Td>
                  <Td>
                    <ReactSelect
                      defaultValue={{
                        label: labor?.repaired_equipment?.name,
                        value: labor?.repaired_equipment?.id,
                      }}
                      placeholder={'Select Repaired Equipment'}
                      isLoading={isEquipmentLoading}
                      options={equipmentOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onChange={(e: any) =>
                        handleChange(labor, e.value, 'repaired_equipment')
                      }
                      menuPortalTarget={document.body}
                    />
                  </Td>
                  <Td>
                    <ReactSelect
                      defaultValue={{
                        label: labor?.used_equipment?.name,
                        value: labor?.used_equipment?.id,
                      }}
                      placeholder={'Select Used Equipment'}
                      isLoading={isEquipmentLoading}
                      options={equipmentOptions}
                      styles={reactSelectStyles}
                      isSearchable={true}
                      onChange={(e: any) =>
                        handleChange(labor, e.value, 'used_equipment')
                      }
                      menuPortalTarget={document.body}
                    />
                  </Td>
                  <Td>
                    <Input
                      defaultValue={labor?.hours}
                      onChange={(e: any) =>
                        debounce(labor, e.target.value, 'hours')
                      }
                    />
                  </Td>
                  <Td>+</Td>
                  <Td>
                    <Tooltip hasArrow label={'Tag'}>
                      <IconButton
                        icon={<BsTag />}
                        variant="link"
                        aria-label={'Tag'}
                        color={'gray.300'}
                        size="lg"
                        onClick={() => handleTagModal(labor, 'tagDetail')}
                      />
                    </Tooltip>
                    {tagData === labor?.tag && (
                      <TagModal
                        isModalOpen={tagModalOpen}
                        setModalOpen={setTagModalOpen}
                        fieldData={tagData}
                        onModalSubmit={() => onTagModalSubmit()}
                      />
                    )}
                    <Tooltip hasArrow label={'Delete'}>
                      <IconButton
                        icon={<BsTrash />}
                        variant="link"
                        aria-label={'Delete'}
                        color="gray.300"
                        size="lg"
                        onClick={() => handleDetailDelete(labor?.id)}
                      />
                    </Tooltip>
                  </Td>
                </Tr>

                {/* Delete Modal */}
                <Modal
                  isOpen={isDeleteModalOpen}
                  isCentered
                  onClose={onDeleteModalClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>{'Delete Activity'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      {'Are you sure you want to delete this ?'}
                      {deleteMsg && (
                        <Alert status="error" mb={2}>
                          <AlertIcon />
                          {deleteMsg}
                        </Alert>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <ButtonGroup>
                        <Button
                          colorScheme="red"
                          onClick={() => onDeleteConfirm(confirmDeleteId)}
                          isLoading={deleteData.isLoading}>
                          {'Delete'}
                        </Button>

                        <Button variant="outline" onClick={onDeleteModalClose}>
                          {'Cancel'}
                        </Button>
                      </ButtonGroup>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </React.Fragment>
            ))}
          </Tbody>
          <Tfoot>
            <Flex p={['3', '6']}>
              <Button
                variant={'outline'}
                onClick={() => {
                  methods.reset();
                  setAddModalOpen(true);
                }}>
                Add Activity
              </Button>
            </Flex>
          </Tfoot>
        </Table>
        {!laborListQuery && <CenterSpinner type="normal" />}
      </TableContainer>

      {/* Add Modal */}
      <Modal
        isOpen={isAddModalOpen}
        isCentered
        onClose={onAddModalClose}
        closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Add Activity'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack direction="column" spacing="4">
                  {addData.isError && (
                    <Alert status="error">
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                  <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.type}
                        isRequired>
                        <FormLabel>AFE</FormLabel>
                        <Controller
                          control={methods.control}
                          name="afe"
                          rules={{
                            required: 'AFE',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="afe"
                              defaultValue={
                                {
                                  label: afeData?.afe?.name,
                                  value: afeData?.afe?.id,
                                } || 'Select'
                              }
                              placeholder={'Select AFE'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isAfeLoading}
                              options={afeOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods?.formState?.errors?.afe &&
                            !!methods?.formState?.errors?.afe?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.activity}
                        isRequired>
                        <FormLabel>{'Activity'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="activity"
                          rules={{
                            required: 'Activity',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="activity"
                              placeholder={'Select Activity'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isActivityLoading}
                              options={activityOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.activity &&
                            !!methods.formState.errors?.activity?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={
                          !!methods.formState.errors?.repaired_equipment
                        }
                        isRequired>
                        <FormLabel>{'Repaired Equipment'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="repaired_equipment"
                          rules={{
                            required: 'Repaired Equipment',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="repaired_equipment"
                              placeholder={'Select Repaired Equipment'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isEquipmentLoading}
                              options={equipmentOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.repaired_equipment &&
                            !!methods.formState.errors?.repaired_equipment
                              ?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.used_equipment}
                        isRequired>
                        <FormLabel>{'Used Equipment'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="used_equipment"
                          rules={{
                            required: 'Used Equipment',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="used_equipment"
                              placeholder={'Select Used Equipment'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={isEquipmentLoading}
                              options={equipmentOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.used_equipment &&
                            !!methods.formState.errors?.used_equipment?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.hours}
                        isRequired>
                        <FormLabel>{'Hours'}</FormLabel>
                        <Input
                          {...methods.register('hours', {
                            required: 'Hours',
                          })}
                          placeholder="Hours"
                        />
                      </FormControl>
                      <FormErrorMessage>
                        {!!methods.formState.errors?.hours &&
                          !!methods.formState.errors?.hours?.message}
                      </FormErrorMessage>
                    </GridItem>
                  </Grid>
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={addData.isLoading}>
                      Add
                    </Button>
                    <Button variant="outline" onClick={onAddModalClose}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Stack>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MechanicalLaborDetailReport;
