import Resource from 'api/resource';
import http from 'utils/http';

class EquipmentResource extends Resource {
  constructor() {
    super('equipments');
  }

  allEquipment(query?: any) {
    return http({
      url: '/all-equipments',
      method: 'get',
      params: query,
    });
  }

  bulkDelete(resource: any) {
    return http({
      url: '/bulk-destroy/equipments',
      method: 'post',
      data: resource,
    });
  }

  type(query?: any) {
    return http({
      url: '/equipment-status-type',
      method: 'get',
      params: query,
    });
  }

  logs(id:any,query?: any) {
    return http({
      url: `/equipments/${id}/logs`,
      method: 'get',
      params: query,
    });
  }

 
}

export { EquipmentResource as default };
