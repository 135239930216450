import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import AFEResource from 'api/afe';
import AFEListItem from 'components/afe/AFEListItem';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import {
  AFE_HEADER,
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import { AFESchema } from 'interface/afe/afeSchema';
import { FilterParams } from 'interface/common/filterParam';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiImport, BiSearch } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DateFormat, DateFormatMDY, formatNumber } from 'utils';
import CsvUpload from 'utils/CsvUpload';
import { csvExport } from 'utils/fileExport';

const AFEList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const [afeIds, setAFEIds] = useState<any>([]);
  const api = new AFEResource();
  const navigate = useNavigate();
  const searchRef = useRef<any>('');
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'afeList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
      };
      const response = await api.list(queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    }
  );

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };

  const handleSearch = () => {
    filterList({ keyword: searchRef.current.value });
  };

  const handleExport = async () => {
    let dataArray = [
      {
        ['Serial Number']: '',
        ['AFE Number']: '',
        ['Job Name & Description']: '',
        ['Plant']: '',
        ['Start Date']: '',
        ['End Date']: '',
        ['Shift Hour']: '',
        ['Shift/Week']: '',
        ['Op Efficiency']: '',
        ['Op Hour']: '',
        ['Work Hour']: '',
        ['Op Hour/Shift']: '',
        ['Total Shift']: '',
        ['Tons/Shift']: '',
        ['Feed Rate']: '',
        ['Total Feed']: '',
      },
    ];

    const response = await api.list({ paginated: false });

    if (response.data.length > 0) {
      if (afeIds.length > 0) {
        dataArray = await response?.data
          ?.filter((res: any, index: number) => afeIds.includes(res.id))
          .map((data: any) => {
            return {
              ['Serial Number']: data?.id,
              ['AFE Number']: data?.number,
              ['Job Name & Description']: data?.name,
              ['Plant']: data?.plant?.name || '-',
              ['Start Date']: DateFormatMDY(data?.start_date),
              ['End Date']: DateFormatMDY(data?.end_date),
              ['Shift Hour']: formatNumber(+data?.shift_hr, 2) || 0,
              ['Shift/Week']: formatNumber(+data?.shift_per_week, 2) || 0,
              ['Op Efficiency']: formatNumber(+data?.op_efficiency, 2) || 0,
              ['Op Hour']: formatNumber(+data?.op_hour, 2) || 0,
              ['Work Hour']: formatNumber(+data?.work_hours, 2) || 0,
              ['Op Hour/Shift']: formatNumber(+data?.op_hr_per_shift, 2) || 0,
              ['Total Shift']: formatNumber(+data?.total_shifts, 2) || 0,
              ['Tons/Shift']: formatNumber(+data?.tons_per_shift, 2) || 0,
              ['Feed Rate']: formatNumber(+data?.feed_rate, 2) || 0,
              ['Total Feed']: formatNumber(+data?.total_feed, 2) || 0,
            };
          });
      } else {
        dataArray = await response?.data?.map((res: any, index: number) => {
          return {
            ['Serial Number']: res?.id,
            ['AFE Number']: res?.number,
            ['Job Name & Description']: res?.name,
            ['Plant']: res?.plant?.name || '-',
            ['Start Date']: DateFormatMDY(res?.start_date),
            ['End Date']: DateFormatMDY(res?.end_date),
            ['Shift Hour']: formatNumber(+res?.shift_hr, 2) || 0,
            ['Shift/Week']: formatNumber(+res?.shift_per_week, 2) || 0,
            ['Op Efficiency']: formatNumber(+res?.op_efficiency, 2) || 0,
            ['Op Hour']: formatNumber(+res?.op_hour, 2) || 0,
            ['Work Hour']: formatNumber(+res?.work_hours, 2) || 0,
            ['Op Hour/Shift']: formatNumber(+res?.op_hr_per_shift, 2) || 0,
            ['Total Shift']: formatNumber(+res?.total_shifts, 2) || 0,
            ['Tons/Shift']: formatNumber(+res?.tons_per_shift, 2) || 0,
            ['Feed Rate']: formatNumber(+res?.feed_rate, 2) || 0,
            ['Total Feed']: formatNumber(+res?.total_feed, 2) || 0,
          };
        });
      }
    }

    csvExport(dataArray, `Afe_${DateFormat(new Date())}` + '.xlsx');
  };

  const deleteData = useMutation(() => api.bulkDelete({ afe_ids: afeIds }), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('afeList');
      setDeleteModalOpen(false);
      toast({
        title: `${afeIds.length} Afe has been deleted.`,
        status: 'success',
        isClosable: true,
      });
      setAFEIds([]);
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">AFE</Heading>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                ref={searchRef}
                onKeyUp={handleSearch}
              />
            </InputGroup>
          </Stack>

          <Stack direction="row">
            {afeIds.length > 0 && (
              <Button color="red.300" onClick={onDeletePress} size="sm">
                Delete AFE ({afeIds.length})
              </Button>
            )}
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiImport} w="6" h="6" />}
              onClick={handleExport}
              size="sm">
              Download
            </Button>
            <CsvUpload
              url="upload-afe-csv"
              modal="afe"
              setRefetch={setRefetch}
              headerSchema={AFE_HEADER}
              label="Import"
            />
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Checkbox
                      isChecked={
                        afeIds.length ===
                        dataList?.results?.map((afe: AFESchema) => afe.id)
                          .length
                      }
                      onChange={() => {
                        const afes = dataList?.results?.map(
                          (afe: AFESchema) => afe.id
                        );
                        if (afeIds.length === afes.length) {
                          setAFEIds([]);
                        } else {
                          setAFEIds(afes);
                        }
                      }}></Checkbox>
                  </Th>
                  <Th>AFE Number</Th>
                  <Th>Job Name & Description</Th>
                  <Th>Plant</Th>
                  <Th>Start Date</Th>
                  <Th>End Date</Th>
                  <Th>Shift Hr</Th>
                  <Th>Shifts/Week</Th>
                  <Th>Op Efficiency</Th>
                  <Th>Op Hour</Th>
                  <Th>Work Hour</Th>
                  <Th>Op Hour/Shift</Th>
                  <Th>Total Shift</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map(
                    (listData: AFESchema, index: number) => (
                      <AFEListItem
                        listData={listData}
                        key={listData.id}
                        setAFEIds={setAFEIds}
                        afeIds={afeIds}
                      />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={14} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Afe'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete all ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
};

export default AFEList;
