import {
  Box,
  Flex,
  FormControl,
  Grid,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import AFEResource from 'api/afe';
import PitPResource from 'api/pit';
import PlantResource from 'api/plant';
import ProductResource from 'api/product';
import ReportResource from 'api/report';
import { reactSelectStyles } from 'assets/css/commonStyles';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import CreatedAtInput from 'components/common/CreatedAtInput';
import { REACT_DATE_PICKER_FORMAT_YYYY_MM_DD } from 'constants/common';
import dayjs from 'dayjs';
import { ReportSearchInterface } from 'interface/report/reportSchema';
import moment from 'moment';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { DateFormatMDY, DateFormatYMD, formatNumber } from 'utils';
import CumulativeChart from './CumulativeChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  changeDate: string;
}

const DailyReport: React.FC<Props> = (props) => {
  const { changeDate } = props;

  const [pitState, setPitState] = useState<any>();
  const [plantState, setPlantState] = useState<any>();
  const [afeState, setAfeState] = useState<any>();
  const [shiftState, setShiftState] = useState<any>();
  const [productState, setProductState] = useState<any>();
  const api = new ReportResource();
  const [labels, setLabels] = useState([]);
  const [cumulativeLabel, setCumulativeLabel] = useState([]);

  const beginningDateCreated = moment(changeDate)
    .subtract(3, 'months')
    .toDate();

  const [beginningDateCreatedAt, setBeginningDateCreatedAt] =
    useState<Date | null>(beginningDateCreated);

  const [endDateCreatedAt, setEndDateCreatedAt] = useState<Date | null>(
    new Date(changeDate)
  );

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        align: 'end',
      },
      title: {
        padding: '0',
        display: true,
        text: 'Daily Tons',
        align: 'start',
      },
    },
  };

  const { data: chartList, isLoading: isChartListLoading } = useQuery(
    [
      'chartList',
      {
        date: changeDate,
        pit: pitState?.value,
        afe: afeState?.value,
        product: productState?.value,
        // plant: plantState?.value,
        // shift: shiftState?.value,
      },
    ],
    async () => {
      const queryParams: any = {
        date: changeDate,
      };
      const filterParams: any = {
        pit: pitState?.value,
        afe: afeState?.value,
        product: productState?.value,
      };
      const response = await api.dailyTons(queryParams, filterParams);
      if (response?.data && response?.data?.daily_net_tons.length > 0) {
        const dailyTonsLabel = response?.data?.daily_net_tons.map(
          (res: { date: string }) => dayjs(res?.date).format('MM/DD')
        );
        setLabels(dailyTonsLabel);
      }

      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const budgetReportStyle = {
    border: '1px solid #C5C6C9',
    padding: '10px',
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Net Tons',
        data: chartList?.daily_net_tons.map(
          (res: { tons: string }) => res?.tons
        ),
        borderColor: '#7FC875',
        backgroundColor: '#7FC875',
      },
      // {
      //   label: 'Adj tons',
      //   data: chartList?.daily_adjusted_tons.map(
      //     (res: { tons: string }) => res?.tons
      //   ),
      //   borderColor: '#406F96',
      //   backgroundColor: '#406F96',
      // },
      // {
      //   label: 'Reported tons',
      //   data: chartList?.daily_reported_tons.map(
      //     (res: { tons: string }) => res?.tons
      //   ),
      //   borderColor: '#e35f3b',
      //   backgroundColor: '#e35f3b',
      // },
    ],
  };

  const [filterParams, setFilterParams] = useState<ReportSearchInterface>({
    searchPit: '',
    searchAfe: '',
    searchPlant: '',
  });

  // Pit List
  const pitAPI = new PitPResource();
  const { data: pitQuery, isLoading: isPitLoading } = useQuery(
    [`pit-list`, { search: filterParams.searchPit }],
    () =>
      pitAPI
        .list({ search: filterParams.searchPit, limit: 10000 })
        .then((res) => res?.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const pitList = pitQuery?.results;
  const pitOptions = pitList?.map((pit: any) => ({
    label: pit.name,
    value: pit.id,
  }));

  const productAPI = new ProductResource();
  const { data: productListQuery, isLoading: isProductListLoading } = useQuery(
    [`production-list`],
    () =>
      productAPI
        .list({ search: filterParams.searchProduct, limit: 10000 })
        .then((res) => res?.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const productList = productListQuery?.results;
  const productOptions = productList?.map((product: any) => ({
    label: product.name,
    value: product.id,
  }));

  // Plant List
  const plantAPI = new PlantResource();
  const { data: plantQuery, isLoading: isPlantLoading } = useQuery(
    [`plant-list`, { search: filterParams.searchPlant }],
    () =>
      plantAPI
        .list({ search: filterParams.searchPlant, limit: 10000 })
        .then((res) => res?.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const plantList = plantQuery?.results;
  const plantOptions = plantList?.map((plant: any) => ({
    label: plant.name,
    value: plant.id,
  }));

  // AFE List
  const afeAPI = new AFEResource();
  const { data: afeQuery, isLoading: isAfeLoading } = useQuery<any>(
    [
      `afe-list`,
      {
        search: filterParams.searchAfe,
      },
    ],
    async () => {
      const res = await afeAPI.list({
        search: filterParams.searchAfe,
        completed: false,
        limit: 10000,
      });
      return res?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const afeList = afeQuery?.results;
  const afeOptions = afeList?.map((afe: any) => ({
    label: afe.name,
    value: afe.id,
  }));

  const handlePitChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchPit: value,
    }));
  };

  const handlePlantChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchPlant: value,
    }));
  };

  const handleAfeChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchAfe: value,
    }));
  };

  const handleProductChange = (value: string) => {
    if (!value) return;
    setFilterParams((prevState: any) => ({
      ...prevState,
      searchProduct: value,
    }));
  };

  const handleBeginningDateChange = (date: Date) => {
    setBeginningDateCreatedAt(date);
    // setValue('beginning_date', DateFormatMDY(date));
  };

  const handleEndDateChange = (date: Date) => {
    setEndDateCreatedAt(date);
    // setValue('end_date', DateFormatMDY(date));
  };

  /**
   * For cumulative chart
   */

  const options1: any = {
    responsive: true,
    plugins: {
      legend: {
        align: 'end',
      },
      title: {
        padding: '0',
        display: true,
        text: 'Cumulative Tons',
        align: 'start',
      },
    },
  };

  const { data: chartList1, isLoading: isChartListLoading1 } = useQuery(
    [
      'chartList1',
      {
        pit: pitState?.value,
        afe: afeState?.value,
        product: productState?.value,
        start_date: beginningDateCreatedAt,
        end_date: endDateCreatedAt,
      },
    ],
    async () => {
      const queryParams: any = {
        pit: pitState?.value,
        afe: afeState?.value,
        product: productState?.value,
        start_date: DateFormatYMD(beginningDateCreatedAt),
        end_date: DateFormatYMD(endDateCreatedAt),
      };
      const response = await api.cumulativeTons(queryParams);
      if (response?.data && response?.data?.cumulative_net_tons.length > 0) {
        const dailyTonsLabel = response?.data?.cumulative_net_tons.map(
          (res: { date: string }) => res?.date
        );
        setCumulativeLabel(dailyTonsLabel);
      }

      return response?.data;
    }
  );

  return (
    <>
      <Stack direction="column" spacing="4">
        <Stack
          direction={['column', 'row']}
          spacing="4"
          align={['start', 'end']}>
          <FormControl>
            <ReactSelect
              id="afe"
              placeholder={'Select Afe'}
              onChange={(selectedOption: any) => {
                setAfeState(selectedOption);
              }}
              isLoading={isAfeLoading}
              options={afeOptions}
              styles={reactSelectStyles}
              isSearchable={true}
              onInputChange={handleAfeChange}
              isClearable
            />
          </FormControl>

          <FormControl>
            <ReactSelect
              id="pit"
              placeholder={'Select Pit'}
              onChange={(selectedOption: any) => {
                setPitState(selectedOption);
              }}
              isLoading={isPitLoading}
              options={pitOptions}
              styles={reactSelectStyles}
              isSearchable={true}
              onInputChange={handlePitChange}
              isClearable
            />
          </FormControl>

          <FormControl>
            <ReactSelect
              id="product"
              placeholder={'Select Product'}
              onChange={(selectedOption: any) => {
                setProductState(selectedOption);
              }}
              isLoading={isProductListLoading}
              options={productOptions}
              styles={reactSelectStyles}
              isSearchable={true}
              onInputChange={handleProductChange}
              isClearable
            />
          </FormControl>

          {/* <FormControl>
            <ReactSelect
              id="plant"
              placeholder={'Select Plant'}
              onChange={(selectedOption: any) => {
                setPlantState(selectedOption);
              }}
              isLoading={isPlantLoading}
              options={plantOptions}
              styles={reactSelectStyles}
              isSearchable={true}
              onInputChange={handlePlantChange}
              isClearable
            />
          </FormControl>
          <FormControl>
            <ReactSelect
              id="shift"
              placeholder={'Select Shift'}
              onChange={(selectedOption: any) => {
                setShiftState(selectedOption);
              }}
              options={ShiftList}
              styles={reactSelectStyles}
              isSearchable={true}
              isClearable
            />
          </FormControl> */}
        </Stack>
        <SimpleGrid
          columns={[1, null, 2]}
          spacing="40px"
          gridTemplateColumns={{ base: 'repeat(1,1fr)', lg: '1fr auto' }}>
          <Stack>
            <Box
              w={'full'}
              minW={'full'}
              maxW={'200px'}
              bg={'white'}
              p={'10px'}
              mb-20>
              <Line options={options} data={data} />
            </Box>
            <SimpleGrid columns={[1, null, 2]} spacing="40px">
              <FormControl bg={'white'}>
                <ReactDatePicker
                  fixedHeight
                  placeholderText="Start Date"
                  dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                  customInput={<CreatedAtInput />}
                  onChange={handleBeginningDateChange}
                  selected={beginningDateCreatedAt}
                  value={DateFormatMDY(beginningDateCreatedAt)}
                  // shouldCloseOnSelect={false}
                  autoComplete="off"
                />
              </FormControl>
              <FormControl bg={'white'}>
                <ReactDatePicker
                  fixedHeight
                  placeholderText="End Date"
                  dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                  customInput={<CreatedAtInput />}
                  onChange={handleEndDateChange}
                  selected={endDateCreatedAt}
                  value={DateFormatMDY(endDateCreatedAt)}
                  // shouldCloseOnSelect={false}
                  autoComplete="off"
                />
              </FormControl>
            </SimpleGrid>
            <Box
              w={'full'}
              minW={'full'}
              maxW={'200px'}
              bg={'white'}
              p={'10px'}>
              <CumulativeChart
                chartList={chartList1}
                labels={cumulativeLabel}
              />
            </Box>
          </Stack>
          <Box bg={'white'}>
            <Grid templateColumns="repeat(2, 1fr)">
              <GridItem sx={budgetReportStyle}>
                <Text>Day Cost</Text>
                <Flex>
                  <Text fontWeight={'bold'}>$</Text>
                  <Text fontSize="3xl">
                    {formatNumber(chartList?.day_cost)}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem sx={budgetReportStyle}>
                <Text>MTD Cost</Text>
                <Flex>
                  <Text fontWeight={'bold'}>$</Text>
                  <Text fontSize="3xl">
                    {formatNumber(chartList?.mtd_cost)}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem sx={budgetReportStyle}>
                <Text>Budget Day CPT</Text>
                <Flex>
                  <Text fontWeight={'bold'}>$</Text>
                  <Text fontSize="3xl">{formatNumber(chartList?.day_cpt)}</Text>
                </Flex>
              </GridItem>
              <GridItem sx={budgetReportStyle}>
                <Text>Budget MTH CPT</Text>
                <Flex>
                  <Text fontWeight={'bold'}>$</Text>
                  <Text fontSize="3xl">{formatNumber(chartList?.mth_cpt)}</Text>
                </Flex>
              </GridItem>
              <GridItem sx={budgetReportStyle}>
                <Text>Today P&L</Text>
                <Flex>
                  <Text fontWeight={'bold'}>$</Text>
                  <Text fontSize="3xl">
                    {formatNumber(chartList?.today_profit_and_loss)}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem sx={budgetReportStyle}>
                <Text>MTD P&L</Text>
                <Flex>
                  <Text fontWeight={'bold'}>$</Text>
                  <Text fontSize="3xl">
                    {formatNumber(chartList?.mtd_profit_and_loss)}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem sx={budgetReportStyle}>
                <Text>Day Ton Diff</Text>
                <Flex>
                  <Text fontWeight={'bold'}>$</Text>
                  <Text fontSize="3xl">
                    {formatNumber(chartList?.day_ton_diff)}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem sx={budgetReportStyle}>
                <Text>MTH Ton Diff</Text>
                <Flex>
                  <Text fontWeight={'bold'}>$</Text>
                  <Text fontSize="3xl">
                    {formatNumber(chartList?.mth_ton_diff)}
                  </Text>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
        </SimpleGrid>
      </Stack>
    </>
  );
};

export default DailyReport;
