import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import TagResource from 'api/tag';
import TagForm from 'components/tag/TagForm';
import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface Props {
  isModalOpen: boolean;
  setModalOpen: Function;
  fieldData: any;
  onModalSubmit: Function;
  formMutation?: any;
  errorMessage?: string;
  setFieldData?: any;
}

const TagModal: React.FC<Props> = (props) => {
  const {
    isModalOpen,
    setModalOpen,
    fieldData,
    onModalSubmit,
    formMutation,
    errorMessage,
  } = props;
  const methods = useForm<any>();
  const methodAdd = useForm<any>();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorAddMessage, setErrorAddMessage] = useState<any>();
  const queryClient = useQueryClient();
  const api = new TagResource();
  const [items, setItems] = useState<any>();

  const { data: tagListQuery, isLoading: isTagListLoading } = useQuery(
    [`tag-list`, fieldData],
    () =>
      api.listTagText({ limit: 50 }).then((res) => {
        setItems(res?.data?.results);
        return res?.data;
      }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: selectedTagListQuery, isLoading: isSelectedTagListLoading } =
    useQuery(
      [`selected-tag-list`, fieldData],
      () =>
        fieldData &&
        api.get(fieldData).then((res) => {
          return res?.data;
        }),
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const onModalClose = () => {
    setModalOpen(false);
  };

  const updateTagData = useMutation(
    (data: any) => api.update(fieldData, data),
    {
      onSuccess: () => {
        onModalSubmit();
      },
    }
  );

  const onSubmit = async (data: any) => {
    updateTagData.mutate(data);
  };

  const handleSearchTags = () => {
    window.console.log('Tag Search');
  };

  const onAddModalPress = () => {
    methodAdd.reset();
    setAddModalOpen(true);
  };

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const addTag = useMutation((data: any) => api.storeTagText(data), {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries('tag-list');
      setAddModalOpen(false);
      // onModalSubmit(true);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorAddMessage(error?.response?.data[objKey[0]][0]);
      else setErrorAddMessage('Tag Creation Failed.');
    },
  });

  const onAddSubmit = (data: any) => {
    let formData;
    if (data.parent === undefined) {
      formData = {
        text: data.text,
        is_parent: true,
      };
    } else {
      formData = {
        text: data.text,
        is_parent: false,
        parent: data.parent.value,
      };
    }
    addTag.mutate(formData);
  };

  const handleCheckbox = (checked: boolean) => {
    var els = document.querySelectorAll<HTMLInputElement>('.custom-checkbox');
    for (var i = 0; i < els.length; i++) {
      els[i].checked = checked;
    }
    items.map((root: any, rootIndex: number) => {
      methods.setValue(`texts.${rootIndex}.is_checked`, checked);
      if (root?.childs?.length > 0) {
        root.childs.map((index: number) => {
          methods.setValue(
            `texts.${rootIndex}.childs.${index}.is_checked`,
            checked
          );
        });
      }
    });
  };

  const handleOnChildChange = (
    child: any,
    rootIndex: number,
    index: number
  ) => {
    const checked = document.querySelector<HTMLInputElement>(
      `.checkbox-${rootIndex}-${index}`
    )!.checked;
    methods.setValue(`texts.${rootIndex}.childs.${index}.is_checked`, checked);
    //find index in parent
    const parentIndex = items.findIndex(
      (root: any) => root.id === child.parent_id
    );
    const childLength = items[parentIndex].childs.length;
    const isAllChecked: Array<boolean> = [];
    for (var i = 0; i < childLength; i++) {
      const isChecked = document.querySelector<HTMLInputElement>(
        `.checkbox-${rootIndex}-${i}`
      )!.checked;
      isAllChecked.push(isChecked);
    }
    const everyChecked: boolean = isAllChecked.some(
      (currentValue: any) => currentValue === true
    );
    document.querySelector<HTMLInputElement>(
      `.checkbox-${rootIndex}`
    )!.checked = everyChecked;
    methods.setValue(`texts.${rootIndex}.is_checked`, everyChecked);
  };

  const getChildren = (child: any, rootIndex: number) => {
    return child.map((children: any, index: number) => {
      return (
        <>
          <List spacing={3} ml={5} key={children.id}>
            <ListItem>
              <input
                {...methods.register(`texts.${rootIndex}.childs.${index}.id`)}
                type="hidden"
                value={children.id}
              />
              <input
                defaultChecked={isExist(children)}
                {...methods.register(
                  `texts.${rootIndex}.childs.${index}.is_checked`
                )}
                type="checkbox"
                className={`custom-checkbox checkbox-${rootIndex}-${index}`}
                onChange={() => handleOnChildChange(children, rootIndex, index)}
              />
              <label style={{ marginLeft: '5px' }}>{children.text}</label>
            </ListItem>
          </List>
          {children?.childs?.length > 0 && getChildren(children.childs, index)}
        </>
      );
    });
  };

  const handleOnRootChange = (item: any, index: number) => {
    const checked = document.querySelector<HTMLInputElement>(
      `.checkbox-${index}`
    )!.checked;
    methods.setValue(`texts.${index}.id`, item.id);
    methods.setValue(`texts.${index}.is_checked`, checked);
    if (item?.childs?.length > 0) {
      for (var i = 0; i < item?.childs?.length; i++) {
        document.querySelector<HTMLInputElement>(
          `.checkbox-${index}-${i}`
        )!.checked = checked;
        methods.setValue(`texts.${index}.childs.${i}.id`, item?.childs[i].id);
        methods.setValue(`texts.${index}.childs.${i}.is_checked`, checked);
      }
    }
  };

  const isExist = (root: any) => {
    if (selectedTagListQuery?.texts?.length > 0)
      for (let i = 0; i < selectedTagListQuery.texts.length; i++) {
        window.console.log(
          'selectedTagListQuery.texts[i].id === root.id',
          selectedTagListQuery.texts[i].id === root.id
        );
        window.console.log(
          'selectedTagListQuery.texts[i].is_checked',
          selectedTagListQuery.texts[i].is_checked
        );
        if (
          selectedTagListQuery.texts[i].id === root.id &&
          selectedTagListQuery.texts[i].is_checked
        ) {
          return true;
        }
        if (selectedTagListQuery.texts[i].childs.length > 0) {
          for (
            let j = 0;
            j < selectedTagListQuery.texts[i].childs.length;
            j++
          ) {
            window.console.log(
              'selectedTagListQuery.texts[i].childs[j].id === root.id',
              selectedTagListQuery.texts[i].childs[j].id === root.id
            );
            window.console.log(
              'selectedTagListQuery.texts[i].childs[j].is_checked',
              selectedTagListQuery.texts[i].childs[j].is_checked
            );
            if (
              selectedTagListQuery.texts[i].childs[j].id === root.id &&
              selectedTagListQuery.texts[i].childs[j].is_checked
            ) {
              return true;
            }
          }
        }
      }
    return false;
  };

  const getRoot = (items: any) => {
    if (items.length > 0) {
      return items.map((root: any, index: number) => {
        return (
          <>
            <List spacing={3} key={root.id}>
              <ListItem>
                <input
                  {...methods.register(`texts.${index}.id`)}
                  type="hidden"
                  value={root.id}
                />
                <input
                  {...methods.register(`texts.${index}.is_checked`)}
                  defaultChecked={isExist(root)}
                  type="checkbox"
                  className={`custom-checkbox checkbox-${index}`}
                  onChange={() => handleOnRootChange(root, index)}
                />
                <label style={{ marginLeft: '5px' }}>{root.text}</label>
              </ListItem>
              {root?.childs?.length > 0 && getChildren(root.childs, index)}
            </List>
          </>
        );
      });
    }
    return [];
  };

  return (
    <>
      <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {'Tags'}
            <Button onClick={onAddModalPress} ml={5}>
              +
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <ModalBody>
                <>
                  {/* <Input
                    w={'full'}
                    type="text"
                    placeholder="Search Tags"
                    // ref={searchRef}
                    onKeyUp={handleSearchTags}
                    mb={3}
                  /> */}

                  <Stack direction="column" spacing="4">
                    {formMutation?.isError && (
                      <Alert status="error">
                        <AlertIcon />
                        {errorMessage}
                      </Alert>
                    )}
                    <Grid
                      gap="4"
                      templateColumns={['repeat(1, 1fr)']}
                      flex="1"
                      p={2}
                      border={'1px solid #D2D6DE'}>
                      {items &&
                        !isTagListLoading &&
                        !isSelectedTagListLoading && (
                          <GridItem>{getRoot(items)}</GridItem>
                        )}
                    </Grid>
                    <ButtonGroup>
                      <Button
                        variant="outline"
                        onClick={() => {
                          handleCheckbox(true);
                        }}>
                        {'Select All'}
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => {
                          handleCheckbox(false);
                        }}>
                        {'Clear All'}
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </>
              </ModalBody>
              <ModalFooter style={{ justifyContent: 'unset' }}>
                <ButtonGroup>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={updateTagData?.isLoading}>
                    {'Save'}
                  </Button>
                  <Button variant="outline" onClick={onModalClose}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAddModalOpen} isCentered onClose={onAddModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Add Tag'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methodAdd}>
              <form onSubmit={methodAdd.handleSubmit(onAddSubmit)}>
                {addTag.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorAddMessage}
                  </Alert>
                )}
                <TagForm />
                <ButtonGroup mt={5}>
                  <Button
                    colorScheme="primary"
                    type="submit"
                    isLoading={addTag.isLoading}>
                    Add Tag
                  </Button>
                  <Button variant="outline" onClick={onAddModalClose}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </form>
            </FormProvider>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TagModal;
