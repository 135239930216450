import { IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import {
  AdjustmentSchema,
  AdjustmentSchemaType,
} from 'interface/adjustment/adjustmentSchema';
import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { DateFormatMDY, formatNumber } from 'utils';

export interface AdjustmentListItemProps {
  adjustmentSchemaType: AdjustmentSchemaType;
  editHandler: (adjustment: AdjustmentSchema) => void;
}

const AdjustmentListItem: React.FC<AdjustmentListItemProps> = (props) => {
  const { listData } = props.adjustmentSchemaType;

  return (
    <Tr key={listData.id}>
      <Td>{listData?.product?.name}</Td>
      <Td>{listData?.pit?.name}</Td>
      <Td>{DateFormatMDY(listData?.start_date)}</Td>
      <Td>{DateFormatMDY(listData?.end_date)}</Td>
      <Td>{formatNumber(+listData?.adjusted_production) || '0'}</Td>
      <Td>{formatNumber(+listData?.reported_production) || '0'}</Td>
      <Td>{formatNumber(+listData?.adjusted_value) || '0'}</Td>
      <Td>{listData?.note || '-'}</Td>
      <Td>
        <Tooltip hasArrow label={'Edit'}>
          <IconButton
            icon={<BiEdit />}
            variant="link"
            aria-label={'View'}
            color="blue.300"
            size="lg"
            onClick={() => props.editHandler(listData)}
          />
        </Tooltip>
      </Td>
    </Tr>
  );
};

export default AdjustmentListItem;
