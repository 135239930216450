import http from 'utils/http';

interface UserProfile {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

interface GetAccountProfileResponse {
  user_profile: UserProfile
}

export function getAccountProfile() {
  return http.get<GetAccountProfileResponse>('/account-profile');
};

export interface UpdateProfileNameRequest {
  first_name: string;
  last_name: string;
}

export function updateProfileName(request: UpdateProfileNameRequest) {
  return http({
    url: '/update-profile-name',
    method: 'post',
    data: request,
  });
}

export interface UpdateProfileEmailRequest {
  email: string;
}

export function updateProfileEmail(request: UpdateProfileEmailRequest) {
  return http({
    url: '/update-profile-email',
    method: 'post',
    data: request,
  });
}

export interface UpdateProfilePasswordRequest {
  old_password: string;
  new_password: string;
  confirm_password: string;
}

export function updateProfilePassword(request: UpdateProfilePasswordRequest) {
  return http({
    url: '/update-profile-password',
    method: 'post',
    data: request,
  });
}
