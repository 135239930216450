import Resource from 'api/resource';
import http from 'utils/http';

class UserResource extends Resource {
  constructor() {
    super('users');
  }

  bulkDelete(resource: any) {
    return http({
      url: '/bulk-destroy/users',
      method: 'post',
      data: resource,
    });
  }

  manager() {
    return http({
      url: '/users/managers',
      method: 'get',
    });
  }

  foreman() {
    return http({
      url: '/users/foremans',
      method: 'get',
    });
  }

  vendor() {
    return http({
      url: '/users/vendors',
      method: 'get',
    });
  }

  customer() {
    return http({
      url: '/users/customers',
      method: 'get',
    });
  }

  labor() {
    return http({
      url: '/users/labors',
      method: 'get',
    });
  }

  paymentList(id: string, query?: any) {
    return http({
      url: `/users/${id}/payments`,
      method: 'get',
      params: query,
    });
  }

  storePayment(id: string, resource: any, headers?: any) {
    return http({
      url: `/users/${id}/payments`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }

  reinvite(id: number) {
    return http({
      url: `/users/${id}/reinvite`,
      method: 'post',
    });
  }

  toggleStatus(id: any) {
    return http({
      url: `/toggle-active-users/${id}`,
      method: 'post',
    });
  }
}

export { UserResource as default };
