import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import EquipmentResource from 'api/equipment';
import routes from 'constants/routes';
import { EquipmentSchemaType } from 'interface/equipment/equipmentSchema';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

const EquipmentListItem: React.FC<EquipmentSchemaType> = (props) => {
  const { listData, setEquipmentIds, equipmentIds } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const api = new EquipmentResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');

  const handleCheckBox = () => {
    const index = equipmentIds.indexOf(listData.id);
    if (index > -1) {
      setEquipmentIds([
        ...equipmentIds.slice(0, index),
        ...equipmentIds.slice(index + 1),
      ]);
    } else {
      setEquipmentIds([...equipmentIds, listData.id]);
    }
  };

  const deleteData = useMutation(() => api.destroy(listData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('equipmentList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: 'Equipment has been deleted.',
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  return (
    <Tr key={listData.id}>
      <Td>
        {' '}
        <Checkbox
          isChecked={equipmentIds.includes(listData.id)}
          onChange={handleCheckBox}></Checkbox>
      </Td>
      <Td
        _hover={{ cursor: 'pointer' }}
        key={listData.id}
        onClick={() =>
          navigate(
            routes.manage.equipments.view.replace(':id', listData.id.toString())
          )
        }>
        {listData?.name}
      </Td>
      <Td>{listData?.serial_number || '-'}</Td>
      <Td>{listData?.code || '-'}</Td>
      <Td>{listData?.equipment_class?.class_code}</Td>
      <Td>{listData?.owner || '-'}</Td>
      <Td>{listData?.location}</Td>
      <Td>{listData?.description || '-'}</Td>
      <Td>{listData?.plant?.name}</Td>
      <Td>
        <Link
          to={routes.manage.equipments.view.replace(
            ':id',
            listData?.id.toString()
          )}>
          <Tooltip hasArrow label={'View'}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={'View'}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </Link>
        <Link
          to={routes.manage.equipments.edit.replace(
            ':id',
            listData?.id.toString()
          )}>
          <Tooltip hasArrow label={'Edit'}>
            <IconButton
              icon={<BiEdit />}
              variant="link"
              aria-label={'Edit'}
              color="blue.300"
              size="lg"
            />
          </Tooltip>
        </Link>

        <Tooltip hasArrow label={'Delete'}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={'Delete'}
            color="red.300"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Equipment'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete this ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default EquipmentListItem;
