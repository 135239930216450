import Resource from 'api/resource';
import http from 'utils/http';

class PurchaseRentalReportResource extends Resource {
  constructor() {
    super('purchase-rental');
  }

  listPurchaseRentalReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/purchase-rental`,
      method: 'get',
      params: query,
    });
  }
  getPurchaseRentalReport(id: string, purchaseId: string) {
    return http({
      url: `/reports/${id}/purchase-rental/${purchaseId}`,
      method: 'get',
    });
  }
  storePurchaseRentalReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/purchase-rental`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updatePurchaseRentalReport(id: string, resource: any) {
    return http({
      url: `/reports/${id}/purchase-rental`,
      method: 'put',
      data: resource,
    });
  }
  destroyPurchaseRentalReport(id: string, purchaseId: string) {
    return http({
      url: `/reports/${id}/purchase-rental/${purchaseId}`,
      method: 'delete',
    });
  }

  listPurchaseRentalDetailReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/purchase-rental-detail`,
      method: 'get',
      params: query,
    });
  }
  getPurchaseRentalDetailReport(id: string, purchaseRentalDetailId: string) {
    return http({
      url: `/reports/${id}/purchase-rental-detail/${purchaseRentalDetailId}`,
      method: 'get',
    });
  }
  storePurchaseRentalDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/purchase-rental-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updatePurchaseRentalDetailReport(
    id: string,
    purchaseRentalDetailId: string,
    resource: any
  ) {
    return http({
      url: `/reports/${id}/purchase-rental-detail/${purchaseRentalDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyPurchaseRentalDetailReport(
    id: string,
    purchaseRentalDetailId: string
  ) {
    return http({
      url: `/reports/${id}/purchase-rental-detail/${purchaseRentalDetailId}`,
      method: 'delete',
    });
  }

  // Mechanical Report
  mechanicalListPurchaseRentalReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/purchase-rental`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetPurchaseRentalReport(id: string, purchaseId: string) {
    return http({
      url: `/mechanic-reports/${id}/purchase-rental/${purchaseId}`,
      method: 'get',
    });
  }
  mechanicalStorePurchaseRentalReport(
    id: string,
    resource: any,
    headers?: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/purchase-rental`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdatePurchaseRentalReport(id: string, resource: any) {
    return http({
      url: `/mechanic-reports/${id}/purchase-rental`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyPurchaseRentalReport(id: string, purchaseId: string) {
    return http({
      url: `/mechanic-reports/${id}/purchase-rental/${purchaseId}`,
      method: 'delete',
    });
  }

  mechanicalListPurchaseRentalDetailReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/mechanic-purchase-rental-detail`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetPurchaseRentalDetailReport(
    id: string,
    purchaseRentalDetailId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/mechanic-purchase-rental-detail/${purchaseRentalDetailId}`,
      method: 'get',
    });
  }
  mechanicalStorePurchaseRentalDetailReport(
    id: string,
    resource: any,
    headers?: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/mechanic-purchase-rental-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdatePurchaseRentalDetailReport(
    id: string,
    purchaseRentalDetailId: string,
    resource: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/mechanic-purchase-rental-detail/${purchaseRentalDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyPurchaseRentalDetailReport(
    id: string,
    purchaseRentalDetailId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/mechanic-purchase-rental-detail/${purchaseRentalDetailId}`,
      method: 'delete',
    });
  }
}

export { PurchaseRentalReportResource as default };
