import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import ImageUploader from 'components/common/ImageUploader';
import {
  CompanySchema,
  CompanySchemaType,
} from 'interface/company/companySchema';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { validateName, validEmail, validPhone } from 'utils/validate';

const CompanyForm: React.FC<CompanySchemaType> = (props) => {
  const { companyData } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext<CompanySchema>();

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.name} isRequired>
            <FormLabel>Name</FormLabel>
            <Input
              {...register('name', {
                required: 'Company Name',
                maxLength: {
                  value: 100,
                  message: 'Too many characters.',
                },
              })}
              type="text"
              defaultValue={companyData?.name}
              placeholder={'Company Name'}
            />
            <FormErrorMessage>
              {errors?.name && errors?.name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.address} isRequired>
            <FormLabel>{'Address'}</FormLabel>
            <Input
              {...register('address', {
                required: 'Company Address',
              })}
              type="text"
              defaultValue={companyData?.address}
              placeholder={'Address'}
            />
            <FormErrorMessage>
              {errors?.address && errors?.address?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.company_tenant} isRequired>
            <FormLabel>{'Tenant Name'}</FormLabel>
            <Input
              style={{ textTransform: 'lowercase' }}
              type="text"
              defaultValue={companyData?.company_tenant}
              placeholder={'Tenant Name'}
              {...register('company_tenant', {
                required: 'Tenant Name',
                validate: (value) =>
                  validateName(value) || 'Please provide a valid name.',
              })}
            />
            <FormErrorMessage>
              {errors?.company_tenant && errors?.company_tenant?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.contact} isRequired>
            <FormLabel>{'Compay Contact Phone'}</FormLabel>
            <Input
              {...register('contact', {
                required: 'Company Contact Phone',
                validate: (value) =>
                  validPhone(value) || 'Please provide a valid contact number.',
              })}
              type="text"
              defaultValue={companyData?.contact}
              placeholder={'Company Contact'}
            />
            <FormErrorMessage>
              {errors?.contact && errors?.contact?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl
            isInvalid={!!errors?.company_admin_first_name}
            isRequired>
            <FormLabel>{'Admin First Name'}</FormLabel>
            <Input
              type="text"
              defaultValue={companyData?.company_admin_first_name}
              placeholder={'Company Admin First Name'}
              {...register('company_admin_first_name', {
                required: 'Company Admin First Name',
              })}
            />
            <FormErrorMessage>
              {errors?.company_admin_first_name &&
                errors?.company_admin_first_name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.company_admin_last_name} isRequired>
            <FormLabel>{'Admin Last Name'}</FormLabel>
            <Input
              type="text"
              defaultValue={companyData?.company_admin_last_name}
              placeholder={'Company Admin Last Name'}
              {...register('company_admin_last_name', {
                required: 'Company Admin Last Name',
              })}
            />
            <FormErrorMessage>
              {errors?.company_admin_last_name &&
                errors?.company_admin_last_name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.company_admin_email} isRequired>
            <FormLabel>{'Tenant Email'}</FormLabel>
            <Input
              type="text"
              defaultValue={companyData?.company_admin_email}
              placeholder={'Email'}
              {...register('company_admin_email', {
                required: 'Email',
                validate: (value) => {
                  if (value)
                    return (
                      validEmail(value) || 'Please Provide valid Email address.'
                    );
                },
              })}
            />
            <FormErrorMessage>
              {errors?.company_admin_email &&
                errors?.company_admin_email?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.company_admin_phone} isRequired>
            <FormLabel>{'Phone'}</FormLabel>
            <Input
              type="text"
              defaultValue={companyData?.company_admin_phone}
              placeholder={'Phone'}
              {...register('company_admin_phone', {
                required: 'Phone',
                validate: (value) =>
                  validPhone(value) || 'Please provide a valid contact number.',
              })}
            />
            <FormErrorMessage>
              {errors?.company_admin_phone &&
                errors?.company_admin_phone?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Upload Company Logo</FormLabel>
            <ImageUploader
              description={'Upload Company Logo'}
              uploadedFiles={companyData?.logo}
              accept="image/*"
              fileKey="logo"
            />
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default CompanyForm;
