import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import SaleResource from 'api/sale';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import routes from 'constants/routes';
import { SaleSchema } from 'interface/sale/saleSchema';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { DateFormatMDY, formatNumber } from 'utils';

const ViewSale: React.FC = () => {
  const { id }: any = useParams();
  const api = new SaleResource();
  const navigate = useNavigate();

  const dataQuery: any = useQuery<SaleSchema>(
    [`sale${id}`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.sales.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            Sale Details
          </Heading>
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() => navigate(routes.sales.edit.replace(':id', id))}>
            {'Edit Sale'}
          </Button>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Serial Number</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.id || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Ticket Number</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.ticket_number || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Invoice Number</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.invoice_number || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Invoice Date</FormLabel>
                <Box sx={infoStyles}>
                  {DateFormatMDY(dataQuery?.data?.invoice_date) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Pit</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.pit?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Product</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.product?.name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Customer</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.customer_name || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Trucking Company</FormLabel>
                <Box sx={infoStyles}>
                  {`${dataQuery?.data?.trucking_company}` || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Date</FormLabel>
                <Box sx={infoStyles}>
                  {DateFormatMDY(dataQuery?.data?.date) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>PO</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.po || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Truck Driver</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.truck_driver || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Vehicle Id</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.vehicle_id || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Job Name</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.job_name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Job Class</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.job_class || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Job Location</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.job_location || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Order Number</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.order_info || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Loader Scale Operator</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.loader_scale_operator || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Gross wt Tons</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.gross_wt_tons || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Tare wt Tons</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.tare_wt_tons, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Net wt Tons</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.net_wt_tons, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Sale Type</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.sale_type || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Price/Ton</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.price_per_ton, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Delivery Point</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.delivery_point || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Sales Tax Rate</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.sales_tax_rate, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Trucking Cost Ton</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.trucking_cost_ton, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Trucking Price Ton</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.trucking_price_ton, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Trucking Revenue Ton</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.trucking_revenue_ton, 2) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Trucking Margin Ton</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.trucking_margin_ton, 2) ||
                    '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Other Revenue</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.other_revenue, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Product Revenue</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.product_revenue, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Total Sales Tax</FormLabel>
                <Box sx={infoStyles}>
                  {formatNumber(+dataQuery?.data?.total_sales_tax, 2) || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Notes</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.notes || '-'}</Box>
              </FormControl>
            </GridItem>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewSale;
