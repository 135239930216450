import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import LaborReportResource from 'api/laborReport';
import ReportResource from 'api/report';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CreatedAtInput from 'components/common/CreatedAtInput';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import { formatNumber, TimeDifference } from 'utils';
import LaborDetailReport from './LaborDetailReport';
import { v4 as uuid } from "uuid";

interface Props {
  submitButtonLabel?: string;
  reportId: string;
}

const LaborReport: React.FC<Props> = (props) => {
  const { submitButtonLabel = 'Add Employee' } = props;
  const [openIndex, setOpenIndex] = useState(0);
  const containerRef = useRef<any>();
  const reportApi = new ReportResource();
  const api = new LaborReportResource();
  const methods = useForm<any>();
  const methodLabor = useForm<any>();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [errorAddMessage, setErrorAddMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [laborId, setLaborId] = useState<number>(0);

  const { laborList } = useSelector(
    (state: any) => ({
      laborList: state?.data?.report?.laborList,
    }),
    shallowEqual
  );
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [tagData, setTagData] = useState(0);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);

  const { reportId, previousReportId, setReportBody, updateQueue, reportBody } = useContext(OfflineContext);

  const offlineLabors = useSelector((state: any) => state.data.offline.labors);
  const offlineEmployeeList = useSelector((state: any) => state.data.offline.employees);
  const [offlineLaborsFilteredByPreviousReportId, setOfflineLaborsFilteredByPreviousReportId] = useState(offlineLabors.labors ? offlineLabors.labors?.filter(
    (labor: any) => labor.report_id === previousReportId
  ) : []);
  const isOnline = useConnectionStatus();

  const laborStatusQuery = useQuery(
    [`getLaborStatus`],
    async () => {
      if (isOnline) {
        const response = await api.getNoteTag(reportId, {
          limit: 5000,
        });
        return response?.data;
      }
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: laborListQuery } = useQuery(
    [`labor-list`],
    () =>
      isOnline &&
      api
        .listLaborReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          return res?.data?.results;
        }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: reportDateQuery } = useQuery(
    [`reportDate-list`],
    () =>
      isOnline &&
      reportApi.get(reportId).then((res) => {
        return res?.data;
      }),
    {
      // cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const labors = useMemo(() => isOnline
    ? laborListQuery?.length > 0
      ? laborListQuery
      : []
    : offlineLaborsFilteredByPreviousReportId, [offlineLaborsFilteredByPreviousReportId, laborListQuery])


  const handleToggle = (index: number, laborId: number) => {
    setOpenIndex(index);
    let display = document.querySelector<HTMLElement>(`#labor${index}`)!.style
      .display;
    if (display === '') display = 'none';
    document.querySelector<HTMLElement>(`#labor${index}`)!.style.display =
      display === 'none' ? 'table-row' : 'none';
  };

  useEffect(() => {
    if (isOnline) {
      if (laborListQuery?.length > 0) setLaborId(laborListQuery[0]?.id);
    }
  }, [laborListQuery]);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/labor')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev, labors: offlineLaborsFilteredByPreviousReportId?.map((lab: any) => {
        return {
          id: lab.id,
          labor: lab.labor,
          note: lab.note,
          clock_in: lab.clock_in,
          clock_out: lab.clock_out
        }
      })
    }))
    // update the queue
    updateQueue(reportBody.id, {
      ...reportBody, labors: offlineLaborsFilteredByPreviousReportId?.map((lab: any) => {
        return {
          id: lab.id,
          labor: lab.labor,
          note: lab.note,
          clock_in: lab.clock_in,
          clock_out: lab.clock_out
        }
      })
    })
  }, [offlineLaborsFilteredByPreviousReportId])

  const addData = useMutation(
    (data: any) => api.storeLaborReport(reportId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('labor-list');
        queryClient.invalidateQueries('labor-detail-list');
        queryClient.invalidateQueries('capital-equipment-list');
        setAddModalOpen(false);
        queryClient.invalidateQueries('loadout-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-detail-list');
      },
      onError: (error: any) => {
        setErrorAddMessage(error?.response?.data?.error || 'Creation Failed.');
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const clockIn = {
      date: moment(data?.clock_in).format('YYYY-MM-DD'),
      time: moment(data?.clock_in).format('HH:mm:ss'),
    };
    const clockOut = {
      date: moment(data?.clock_out).format('YYYY-MM-DD'),
      time: moment(data?.clock_out).format('HH:mm:ss'),
    };

    const timeD = TimeDifference(data?.clock_in, data?.clock_out);
    if (timeD < 0) {
      setErrorMessage('Please check clock in and out');
      return;
    } else {
      const formData = {
        labor: data?.labor?.value,
        clock_in: clockIn,
        clock_out: clockOut,
      };
      if (isOnline) {
        addData.mutate(formData);

        // refresh the page
        queryClient.invalidateQueries('labor-list');
        queryClient.invalidateQueries('labor-detail-list');
        queryClient.invalidateQueries('capital-equipment-list');
        queryClient.invalidateQueries('loadout-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-detail-list');
      } else {
        setOfflineLaborsFilteredByPreviousReportId((prev: any) => ([...prev,
        {
          ...formData,
          labor: {
            id: data?.labor?.value,
            name: data?.labor?.label,
            first_name: data?.labor?.first_name,
            last_name: data?.labor?.last_name
          },
          id: uuid()
        }]))
        setAddModalOpen(false);
      }
    }
  };

  const handleDetailDelete = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: string) => api.destroyLaborReport(reportId, id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('labor-list');
        queryClient.invalidateQueries('labor-detail-list');
        queryClient.invalidateQueries('capital-equipment-list');
        setDeleteModalOpen(false);
        queryClient.invalidateQueries('loadout-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-detail-list');
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: string) => {
    if (isOnline) {
      deleteData.mutate(id)
    } else {
      setOfflineLaborsFilteredByPreviousReportId((prev: any) => prev.filter((r: any) => r.id !== id))
    }
  };

  const updateData = useMutation(
    (data: any) => {
      return api.updateLaborReport(reportId, data?.id, data?.body);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('labor-list');
        queryClient.invalidateQueries('capital-equipment-list');
        queryClient.invalidateQueries('loadout-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-detail-list');
      },
      onError: (error: any) => {
        setErrorAddMessage(error?.response?.data?.error || 'Creation Failed.');
      },
    }
  );

  const handleDateChange = (date: any, fieldType: string, field: any) => {
    let data: any = { body: { labor: '', clock_in: '', clock_out: '' }, id: 0 };
    data.id = field.id;
    switch (fieldType) {
      case 'clock_in':
        data.body.labor = field?.labor?.id;
        data.body.clock_in = {
          date: moment(date).format('YYYY-MM-DD'),
          time: moment(date).format('HH:mm:ss'),
        };
        data.body.clock_out = field?.clock_out;
        break;
      case 'clock_out':
        data.body.labor = field?.labor?.id;
        data.body.clock_in = field?.clock_in;
        data.body.clock_out = {
          date: moment(date).format('YYYY-MM-DD'),
          time: moment(date).format('HH:mm:ss'),
        };
        break;
    }
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineLaborsFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          return {
            ...r,
            clock_in: data.body.clock_in || r.clock_in,
            clock_out: data.body.clock_out || r.clock_out
          }
        } else {
          return r;
        }
      }))
    }
  };

  const handleNoteModal = (data: any) => {
    setNoteDetailFieldData(data);
    setNoteModalOpen(true);
  };

  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        ...fieldData,
        labor: fieldData?.labor?.id,
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineLaborsFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          return {
            ...r,
            note: modalValue.note
          }
        } else {
          return r;
        }
      }))
    }
    setNoteModalOpen(false);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('labor-list');
    queryClient.invalidateQueries('labor-detail-list');
    queryClient.invalidateQueries('loadout-list');
    queryClient.invalidateQueries('getLoadoutStatus');
    queryClient.invalidateQueries('loadout-detail-list');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  const setClock = (clock: { date: string; time: string }) => {
    const { date, time } = clock;
    const dateTimeString = `${date} ${time}`;
    const dateTimeMoment = moment(dateTimeString).format('MM/DD/YYYY HH:mm:ss');
    return new Date(dateTimeMoment);
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        {/* <Flex justify="space-between" p={2}>
          // <Heading size="md">Labor</Heading>
          <Heading size="md">Total Cost $13233232</Heading>
          <Stack direction="row" spacing="6">
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(laborStatusQuery?.data, 'tagStatus')
                }
              />
            </Tooltip>
            {tagData === laborStatusQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={laborStatusQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
              />
            )}
          </Stack>
        </Flex> */}
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant="simple">
              <Tbody>
                <Tr bg={'gray.300'}>
                  <Td>Employee</Td>
                  <Td>Clock In</Td>
                  <Td>Clock Out</Td>
                  <Td>Today Hours</Td>
                  <Td>Today Cost</Td>
                  <Td>Weekly Hours</Td>
                  <Td>Weekly Cost</Td>
                  <Td></Td>
                </Tr>
                {labors &&
                  labors?.map((labor: any, indexNumber: number) => (
                    <React.Fragment key={indexNumber}>
                      <Tr>
                        <Td>
                          <Stack direction="row">
                            <Tooltip
                              hasArrow
                              label={openIndex === indexNumber ? 'Down' : 'Up'}>
                              <IconButton
                                icon={
                                  openIndex === indexNumber ? (
                                    <TriangleUpIcon />
                                  ) : (
                                    <TriangleDownIcon />
                                  )
                                }
                                variant="link"
                                aria-label={'Up'}
                                color="gray.300"
                                size="lg"
                                onClick={() => {
                                  setLaborId(laborId);
                                  handleToggle(indexNumber, labor?.id);
                                }}
                              />
                            </Tooltip>
                            <Text>
                              {labor?.labor.first_name}{' '}
                              {labor?.labor?.last_name}
                            </Text>
                          </Stack>
                        </Td>
                        <Td>
                          <Controller
                            name={'clock_in'}
                            control={methodLabor.control}
                            render={({ field }) => (
                              <ReactDatePicker
                                fixedHeight
                                dateFormat={'HH:mm:ss'}
                                customInput={<CreatedAtInput />}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  handleDateChange(
                                    selectedOption,
                                    'clock_in',
                                    labor
                                  );
                                }}
                                selected={setClock(labor?.clock_in)}
                                shouldCloseOnSelect={true}
                                timeInputLabel="Time:"
                                showTimeInput
                              // showTimeSelectOnly
                              />
                            )}
                          />
                        </Td>
                        <Td>
                          <Controller
                            name={'clock_out'}
                            control={methodLabor.control}
                            render={({ field }) => (
                              <ReactDatePicker
                                fixedHeight
                                dateFormat={'HH:mm:ss'}
                                customInput={<CreatedAtInput />}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  handleDateChange(
                                    selectedOption,
                                    'clock_out',
                                    labor
                                  );
                                }}
                                selected={
                                  setClock(labor?.clock_out) || field.value
                                }
                                shouldCloseOnSelect={true}
                                timeInputLabel="Time:"
                                showTimeInput
                              // showTimeSelectOnly
                              />
                            )}
                          />
                        </Td>
                        <Td>
                          <Text
                            color={
                              labor?.today_decimal_hours > labor?.activity_hours
                                ? 'tomato'
                                : ''
                            }>
                            {labor?.today_decimal_hours}
                          </Text>
                        </Td>
                        <Td>${formatNumber(+labor?.today_cost)}</Td>
                        <Td>{labor?.weekly_hours}</Td>
                        <Td>${formatNumber(+labor?.weekly_cost)}</Td>
                        <Td>
                          <Tooltip hasArrow label={'Tag'}>
                            <IconButton
                              icon={<BsTag />}
                              variant="link"
                              aria-label={'Tag'}
                              color={'gray.300'}
                              size="lg"
                              onClick={() => handleTagModal(labor, 'tagDetail')}
                            />
                          </Tooltip>
                          {tagData === labor?.tag && (
                            <TagModal
                              isModalOpen={tagDetailModalOpen}
                              setModalOpen={setTagDetailModalOpen}
                              fieldData={tagData}
                              onModalSubmit={() => onTagModalSubmit()}
                            />
                          )}
                          <Tooltip hasArrow label={labor?.note}>
                            <IconButton
                              icon={<MdOutlineModeComment />}
                              variant="link"
                              aria-label={labor?.note}
                              color={labor?.note ? 'primary.600' : 'gray.300'}
                              size="lg"
                              onClick={() => handleNoteModal(labor)}
                            />
                          </Tooltip>
                          <NoteModal
                            isModalOpen={noteModalOpen}
                            setModalOpen={setNoteModalOpen}
                            fieldData={noteDetailFieldData}
                            onModalSubmit={(notes: string) =>
                              onNoteModalSubmit(notes, noteDetailFieldData)
                            }
                            formMutation={updateData}
                            errorMessage={errorMessage}
                          />
                          <Tooltip hasArrow label={'Delete'}>
                            <IconButton
                              icon={<BsTrash />}
                              variant="link"
                              aria-label={'Delete'}
                              color="gray.300"
                              size="lg"
                              onClick={() => onDeleteConfirm(labor?.id)}
                            />
                          </Tooltip>
                        </Td>
                      </Tr>
                      <Tr
                        id={`labor${indexNumber}`}
                        style={{
                          display:
                            openIndex === indexNumber ? 'table-row' : 'none',
                        }}>
                        {
                          <Td colSpan={8}>
                            <LaborDetailReport
                              laborId={labor?.id}
                              reportId={reportId}
                            />
                          </Td>
                        }
                      </Tr>

                      <Modal
                        isOpen={isDeleteModalOpen}
                        isCentered
                        onClose={onDeleteModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>{'Delete Labor'}</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {'Are you sure you want to delete this ?'}
                            {deleteMsg && (
                              <Alert status="error" mb={2}>
                                <AlertIcon />
                                {deleteMsg}
                              </Alert>
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <ButtonGroup>
                              <Button
                                colorScheme="red"
                                onClick={() => onDeleteConfirm(labor?.id)}
                                isLoading={deleteData.isLoading}>
                                {'Delete'}
                              </Button>

                              <Button
                                variant="outline"
                                onClick={onDeleteModalClose}>
                                {'Cancel'}
                              </Button>
                            </ButtonGroup>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </React.Fragment>
                  ))}
              </Tbody>
            </Table>
            {isOnline && !laborListQuery && <CenterSpinner type="normal" />}
          </TableContainer>
        </Stack>

        {/* Add Modal */}
        <Modal
          isOpen={isAddModalOpen}
          isCentered
          onClose={onAddModalClose}
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Add Labor'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack direction="column" spacing="4">
                    {addData.isError && errorAddMessage && (
                      <Alert status="error">
                        <AlertIcon />
                        <Text>{errorAddMessage}</Text>
                      </Alert>
                    )}
                    <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods?.formState?.errors?.type}
                          isRequired>
                          <FormLabel>Employee</FormLabel>
                          <Controller
                            control={methods.control}
                            name="labor"
                            rules={{
                              required: 'Employee',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="labor"
                                placeholder={'Employee'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline ? laborList.isLaborLoading : offlineEmployeeList.isLoading}
                                options={isOnline ? laborList.laborOptions : offlineEmployeeList.employees}
                                styles={reactSelectStyles}
                                isSearchable={true}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods?.formState?.errors?.labor &&
                              !!methods?.formState?.errors?.labor?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.clock_in}
                          isRequired>
                          <FormLabel>{'Clock In'}</FormLabel>
                          <Controller
                            name={'clock_in'}
                            control={methods.control}
                            render={({ field }) => {
                              return (
                                <ReactDatePicker
                                  fixedHeight
                                  selected={field.value}
                                  onChange={field.onChange}
                                  shouldCloseOnSelect={true}
                                  timeInputLabel="Time:"
                                  showTimeInput
                                  dateFormat={'HH:mm:ss'}
                                  // showTimeSelectOnly
                                  customInput={<CreatedAtInput />}
                                />
                              );
                            }}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.clock_in &&
                              !!methods.formState.errors?.clock_in?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.clock_out}
                          isRequired>
                          <FormLabel>{'Clock Out'}</FormLabel>
                          <Controller
                            name={'clock_out'}
                            control={methods.control}
                            render={({ field }) => {
                              return (
                                <ReactDatePicker
                                  fixedHeight
                                  selected={field.value}
                                  onChange={field.onChange}
                                  shouldCloseOnSelect={true}
                                  timeInputLabel="Time:"
                                  showTimeInput
                                  // showTimeSelectOnly
                                  dateFormat={'HH:mm:ss'}
                                  customInput={<CreatedAtInput />}
                                />
                              );
                            }}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.clock_out &&
                              !!methods.formState.errors?.clock_out?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <ButtonGroup>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={addData.isLoading}>
                        Add
                      </Button>
                      <Button variant="outline" onClick={onAddModalClose}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Flex p={['3', '6']}>
          <Button
            variant={'outline'}
            onClick={() => {
              methods.reset();
              setErrorAddMessage('');
              setAddModalOpen(true);
              const initialDate = moment(reportDateQuery?.report_date).toDate();
              methods.setValue('clock_out', initialDate);
              methods.setValue('clock_in', initialDate);
            }}>
            {submitButtonLabel}
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default LaborReport;
