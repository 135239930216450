import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import PaymentListItem from 'components/user/PaymentListItem';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import { FilterParams } from 'interface/common/filterParam';
import { UserSchema } from 'interface/user/userListItem';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface Props {
  userId: string;
}
const PaymentList: React.FC<Props> = (props) => {
  const { userId } = props;
  const [isLoading, setIsLoading] = useState<string | null>();
  const api = new UserResource();
  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const queryClient = useQueryClient();
  const toast = useToast();
  const [errorMsg, setErrorMsg] = useState('');
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const methods = useForm<{
    hourly: string;
    overtime: string;
    salary: string;
    additional_pay: string;
  }>();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const { data: paymentList, isLoading: isListLoading } = useQuery(
    [
      'payment-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const response = await api.paymentList(userId, queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    }
  );

  const addData = useMutation((data: any) => api.storePayment(userId, data), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('payment-list');
      setAddModalOpen(false);
      toast({
        title: `Payment has been added`,
        status: 'success',
        isClosable: true,
      });
    },
  });

  const onAddPress = () => {
    setErrorMsg('');
    methods.reset();
    setAddModalOpen(true);
  };

  const onAddModalClose = () => {
    setErrorMsg('');
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    addData.mutate(data);
  };

  return (
    <>
      <Stack direction="column" spacing="4" mt={5}>
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Payment List</Heading>
          </Stack>

          <Stack direction="row">
            <Button size="sm" colorScheme="primary" onClick={onAddPress}>
              Add Payment
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Hourly</Th>
                  <Th>Overtime</Th>
                  <Th>Salary</Th>
                  <Th>Additional Pay</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  paymentList?.results?.map(
                    (listData: UserSchema, index: number) => (
                      <PaymentListItem listData={listData} key={listData.id} />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {paymentList?.results && (
          <Pagination
            dataList={paymentList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}

        <Modal isOpen={isAddModalOpen} isCentered onClose={onAddModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Add Payment'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Stack direction="column" spacing="4">
                      {addData.isError && (
                        <Alert status="error">
                          <AlertIcon />
                          {errorMsg}
                        </Alert>
                      )}

                      <Stack direction="row" align="start" spacing="4">
                        <Grid
                          gap="4"
                          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)']}
                          flex="1">
                          <GridItem>
                            <FormControl
                              isInvalid={!!methods.formState.errors?.hourly}
                              isRequired>
                              <FormLabel>Hourly</FormLabel>
                              <Input
                                {...methods.register('hourly', {
                                  required: 'Hourly',
                                })}
                                type="number"
                                step="0.01"
                                placeholder={'Hourly'}
                              />
                              <FormErrorMessage>
                                {methods.formState.errors?.hourly &&
                                  methods.formState.errors?.hourly?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl
                              isInvalid={!!methods.formState.errors?.overtime}
                              isRequired>
                              <FormLabel>Overtime</FormLabel>
                              <Input
                                {...methods.register('overtime', {
                                  required: 'Overtime',
                                })}
                                type="number"
                                step="0.01"
                                placeholder={'Overtime'}
                              />
                              <FormErrorMessage>
                                {methods.formState.errors?.hourly &&
                                  methods.formState.errors?.hourly?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl
                              isInvalid={!!methods.formState.errors?.salary}
                              isRequired>
                              <FormLabel>Salary</FormLabel>
                              <Input
                                {...methods.register('salary', {
                                  required: 'Salary',
                                })}
                                type="number"
                                step="0.01"
                                placeholder={'Salary'}
                              />
                              <FormErrorMessage>
                                {methods.formState.errors?.salary &&
                                  methods.formState.errors?.salary?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl
                              isInvalid={
                                !!methods.formState.errors?.additional_pay
                              }
                              isRequired>
                              <FormLabel>Additional Pay</FormLabel>
                              <Input
                                {...methods.register('additional_pay', {
                                  required: 'Additional Pay',
                                })}
                                type="number"
                                step="0.01"
                                placeholder={'Additional Pay'}
                              />
                              <FormErrorMessage>
                                {methods.formState.errors?.additional_pay &&
                                  methods.formState.errors?.additional_pay
                                    ?.message}
                              </FormErrorMessage>
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </Stack>

                      <ButtonGroup>
                        <Button
                          colorScheme="primary"
                          type="submit"
                          isLoading={addData.isLoading}>
                          Add
                        </Button>
                        <Button variant="outline" onClick={onAddModalClose}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </Stack>
                  </form>
                </FormProvider>
              </Box>
            </ModalBody>
            {/* <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onAddConfirm}
                  isLoading={addData.isLoading}>
                  {'Add'}
                </Button>

                <Button variant="outline" onClick={onAddModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
};

export default PaymentList;
