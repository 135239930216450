import routes from 'constants/routes';
import { Login } from 'pages/auth';
import ForgotPassword from 'pages/auth/ForgotPassword';
import ResetPassword from 'pages/auth/ResetPassword';
import SetPassword from 'pages/auth/SetPassword';
import AFEList from 'pages/dashboard/afe/AFEList';
import ViewAFE from 'pages/dashboard/afe/ViewAFE';
import CompanyList from 'pages/dashboard/companies/CompanyList';
import CreateCompany from 'pages/dashboard/companies/CreateCompany';
import ViewCompany from 'pages/dashboard/companies/ViewCompany';
import Dashboard from 'pages/dashboard/Dashboard';
import InventoryList from 'pages/dashboard/inventories/InventoryList';
import CreateEquipment from 'pages/dashboard/manage/equipments/CreateEquipment';
import EditEquipment from 'pages/dashboard/manage/equipments/EditEquipment';
import EquipmentList from 'pages/dashboard/manage/equipments/EquipmentList';
import ViewEquipment from 'pages/dashboard/manage/equipments/ViewEquipment';
import CreatePeople from 'pages/dashboard/manage/peoples/CreatePeople';
import EditPeople from 'pages/dashboard/manage/peoples/EditPeople';
import PeopleList from 'pages/dashboard/manage/peoples/PeopleList';
import ViewPeople from 'pages/dashboard/manage/peoples/ViewPeople';
import CreatePit from 'pages/dashboard/manage/pits/CreatePit';
import EditPit from 'pages/dashboard/manage/pits/EditPit';
import PitList from 'pages/dashboard/manage/pits/PitList';
import ViewPit from 'pages/dashboard/manage/pits/ViewPit';
import CreatePlant from 'pages/dashboard/manage/plants/CreatePlant';
import EditPlant from 'pages/dashboard/manage/plants/EditPlant';
import PlantList from 'pages/dashboard/manage/plants/PlantList';
import ViewPlant from 'pages/dashboard/manage/plants/ViewPlant';
import CreateProduct from 'pages/dashboard/manage/products/CreateProduct';
import EditProduct from 'pages/dashboard/manage/products/EditProduct';
import ProductList from 'pages/dashboard/manage/products/ProductList';
import ViewProduct from 'pages/dashboard/manage/products/ViewProduct';
import AdjustmentList from 'pages/dashboard/productions/AdjustmentList';
import ProductionList from 'pages/dashboard/productions/ProductionList';
import ViewProduction from 'pages/dashboard/productions/ViewProduction';
import ForemanReport from 'pages/dashboard/reports/ForemanReport';
import MechanicalReport from 'pages/dashboard/reports/MechanicalReport';
import ReportList from 'pages/dashboard/reports/ReportList';
import CreateSale from 'pages/dashboard/sales/CreateSale';
import EditSale from 'pages/dashboard/sales/EditSale';
import SaleList from 'pages/dashboard/sales/SaleList';
import ViewSale from 'pages/dashboard/sales/ViewSale';
import Setting from 'pages/dashboard/settings/Setting';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthenticateRoute from './AuthenticateRoute';
import UserRoleBasedRouting from './UserRoleBasedRouting';

// Top level application router.

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.auth.login} element={<Login />} />
        <Route path={routes.auth.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.auth.setPassword} element={<SetPassword />} />
        <Route path={routes.auth.resetPassword} element={<ResetPassword />} />

        <Route path={routes.dashboard} element={<AuthenticateRoute />}>
          <Route
            path={routes.dashboard}
            element={
              <UserRoleBasedRouting
                serviceName="read:dashboard"
                component={<Dashboard />}
              />
            }
          />
          {/* User Routing */}
          {/* <Route
            path={routes.manage.users.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:user"
                component={<UserList />}
              />
            }
          />
          <Route
            path={routes.manage.users.create}
            element={
              <UserRoleBasedRouting
                serviceName="manage:user"
                component={<CreateUser />}
              />
            }
          />
          <Route
            path={routes.manage.users.edit}
            element={
              <UserRoleBasedRouting
                serviceName="manage:user"
                component={<EditUser />}
              />
            }
          />
          <Route
            path={routes.manage.users.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:user"
                component={<ViewUser />}
              />
            }
          /> */}

          {/* Pit Routing */}
          <Route
            path={routes.manage.pits.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:pit"
                component={<PitList />}
              />
            }
          />
          <Route
            path={routes.manage.pits.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:pit"
                component={<ViewPit />}
              />
            }
          />
          <Route
            path={routes.manage.pits.create}
            element={
              <UserRoleBasedRouting
                serviceName="manage:pit"
                component={<CreatePit />}
              />
            }
          />
          <Route
            path={routes.manage.pits.edit}
            element={
              <UserRoleBasedRouting
                serviceName="manage:pit"
                component={<EditPit />}
              />
            }
          />

          {/* Plant Routing */}
          <Route
            path={routes.manage.plants.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:plant"
                component={<PlantList />}
              />
            }
          />
          <Route
            path={routes.manage.plants.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:plant"
                component={<ViewPlant />}
              />
            }
          />
          <Route
            path={routes.manage.plants.create}
            element={
              <UserRoleBasedRouting
                serviceName="manage:plant"
                component={<CreatePlant />}
              />
            }
          />
          <Route
            path={routes.manage.plants.edit}
            element={
              <UserRoleBasedRouting
                serviceName="manage:plant"
                component={<EditPlant />}
              />
            }
          />

          {/* People Routing */}
          <Route
            path={routes.manage.peoples.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:people"
                component={<PeopleList />}
              />
            }
          />
          <Route
            path={routes.manage.peoples.create}
            element={
              <UserRoleBasedRouting
                serviceName="manage:people"
                component={<CreatePeople />}
              />
            }
          />
          <Route
            path={routes.manage.peoples.edit}
            element={
              <UserRoleBasedRouting
                serviceName="manage:people"
                component={<EditPeople />}
              />
            }
          />
          <Route
            path={routes.manage.peoples.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:people"
                component={<ViewPeople />}
              />
            }
          />

          {/* Equipment Routing */}
          <Route
            path={routes.manage.equipments.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:equipment"
                component={<EquipmentList />}
              />
            }
          />
          <Route
            path={routes.manage.equipments.create}
            element={
              <UserRoleBasedRouting
                serviceName="manage:equipment"
                component={<CreateEquipment />}
              />
            }
          />
          <Route
            path={routes.manage.equipments.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:equipment"
                component={<ViewEquipment />}
              />
            }
          />
          <Route
            path={routes.manage.equipments.edit}
            element={
              <UserRoleBasedRouting
                serviceName="manage:equipment"
                component={<EditEquipment />}
              />
            }
          />

          {/* Product Routing */}
          <Route
            path={routes.manage.products.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:product"
                component={<ProductList />}
              />
            }
          />
          <Route
            path={routes.manage.products.edit}
            element={
              <UserRoleBasedRouting
                serviceName="manage:product"
                component={<EditProduct />}
              />
            }
          />
          <Route
            path={routes.manage.products.create}
            element={
              <UserRoleBasedRouting
                serviceName="manage:product"
                component={<CreateProduct />}
              />
            }
          />
          <Route
            path={routes.manage.products.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:product"
                component={<ViewProduct />}
              />
            }
          />

          <Route path={routes.settings.index} element={<Setting />} />

          {/* Shift Downtime Code Routing */}
          {/* <Route
            path={routes.manage.shiftDownTimeCode.list}
            element={<ShiftDownTimeCodeList />}
          />
          <Route
            path={routes.manage.shiftDownTimeCode.edit}
            element={<EditShiftDownTimeCode />}
          />
          <Route
            path={routes.manage.shiftDownTimeCode.create}
            element={<CreateShiftDownTimeCode />}
          /> */}

          {/* Operation Downtime Code Routing */}
          {/* <Route
            path={routes.manage.operationDownTimCode.list}
            element={<OperationDownTimeCodeList />}
          />
          <Route
            path={routes.manage.operationDownTimCode.edit}
            element={<EditOperationDownTimeCode />}
          />
          <Route
            path={routes.manage.operationDownTimCode.create}
            element={<CreateOperationDownTimeCode />}
          /> */}

          {/* Activity Code Routing */}
          {/* <Route
            path={routes.manage.activity.list}
            element={<ActivityList />}
          />
          <Route
            path={routes.manage.activity.edit}
            element={<EditActivity />}
          />
          <Route
            path={routes.manage.activity.create}
            element={<CreateActivity />}
          />

          <Route path={routes.settings.index} element={<Setting />} /> */}

          {/* Production Routing */}
          <Route
            path={routes.productions.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:inventory"
                component={<ProductionList />}
              />
            }
          />
          <Route
            path={routes.productions.view}
            element={
              <UserRoleBasedRouting
                serviceName="manage:inventory"
                component={<ViewProduction />}
              />
            }
          />

          {/* inventory Routing */}
          <Route
            path={routes.inventories.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:inventory"
                component={<InventoryList />}
              />
            }
          />

          {/* Adjusment Routing */}
          <Route
            path={routes.adjustments.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:adjustment"
                component={<AdjustmentList />}
              />
            }
          />

          <Route
            path={routes.afe.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:afe"
                component={<AFEList />}
              />
            }
          />
          <Route
            path={routes.afe.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:afe"
                component={<ViewAFE />}
              />
            }
          />
          <Route
            path={routes.sales.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:sale"
                component={<SaleList />}
              />
            }
          />
          <Route
            path={routes.sales.create}
            element={
              <UserRoleBasedRouting
                serviceName="manage:sale"
                component={<CreateSale />}
              />
            }
          />
          <Route
            path={routes.sales.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:sale"
                component={<ViewSale />}
              />
            }
          />
          <Route
            path={routes.sales.edit}
            element={
              <UserRoleBasedRouting
                serviceName="manage:sale"
                component={<EditSale />}
              />
            }
          />

          {/** Daily Report */}
          <Route
            path={routes.dailyreport.base}
            element={
              <UserRoleBasedRouting
                serviceName="manage:daily-report"
                component={<ReportList />}
              />
            }
          />

          {/* Mechanical Report Routing */}
          <Route
            path={routes.reports.mechanicalReport.list}
            element={
              <UserRoleBasedRouting
                serviceName="manage:mechanic"
                component={<MechanicalReport />}
              />
            }>
            <Route
              path={routes.reports.mechanicalReport.operationDetailReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:mechanic"
                  component={<MechanicalReport />}
                />
              }
            />
            <Route
              path={routes.reports.mechanicalReport.laborReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:mechanic"
                  component={<MechanicalReport />}
                />
              }
            />
            <Route
              path={routes.reports.mechanicalReport.partsReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:mechanic"
                  component={<MechanicalReport />}
                />
              }
            />
            <Route
              path={routes.reports.mechanicalReport.equipmentRepairReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:mechanic"
                  component={<MechanicalReport />}
                />
              }
            />
            <Route
              path={routes.reports.mechanicalReport.assignedEquipmentReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:mechanic"
                  component={<MechanicalReport />}
                />
              }
            />
            <Route
              path={routes.reports.mechanicalReport.rentalReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:mechanic"
                  component={<MechanicalReport />}
                />
              }
            />
            <Route
              path={routes.reports.mechanicalReport.equipmentDamageReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:mechanic"
                  component={<MechanicalReport />}
                />
              }
            />
            <Route
              path={routes.reports.mechanicalReport.noteReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:mechanic"
                  component={<MechanicalReport />}
                />
              }
            />
          </Route>

          {/* Foreman Report Routing */}
          <Route
            path={routes.reports.foremanReport.list}
            element={
              <UserRoleBasedRouting
                serviceName="manage:foreman"
                component={<ForemanReport />}
              />
            }>
            <Route
              path={routes.reports.foremanReport.operationDetailReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.laborReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.productionReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.capitalEquipmentReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.ShiftDownTimeReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.fuelUsageReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.loadoutReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.rentalReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.equipmentDamageReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
            <Route
              path={routes.reports.foremanReport.noteReport}
              element={
                <UserRoleBasedRouting
                  serviceName="manage:foreman"
                  component={<ForemanReport />}
                />
              }
            />
          </Route>

          {/* Company Routing */}
          <Route
            path={routes.companies.list}
            element={
              <UserRoleBasedRouting
                serviceName="read:company"
                component={<CompanyList />}
              />
            }
          />
          <Route
            path={routes.companies.create}
            element={
              <UserRoleBasedRouting
                serviceName="manage:company"
                component={<CreateCompany />}
              />
            }
          />
          <Route
            path={routes.companies.view}
            element={
              <UserRoleBasedRouting
                serviceName="read:company"
                component={<ViewCompany />}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
