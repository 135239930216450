import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import AssignedEquipmentReportResource from 'api/assignedEquipmentReport';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useDebounceFunc from 'hooks/useDebounceFunc';
import { ReportProps } from 'interface/report/reportSchema';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import { v4 as uuid } from "uuid";

const AssignedEquipmentReport: React.FC<ReportProps> = (props) => {
  const { setReportBody, updateQueue, reportBody } = useContext(OfflineContext);
  const offlineEquipmentList = useSelector((state: any) => state.data.offline.equipments)
  const [offlineAssignedEquipmentFilteredByPreviousReportId, setOfflineAssignedEquipmentFilteredByPreviousReportId] = useState<any>([]);
  const isOnline = useConnectionStatus();
  const { reportId } = props;
  const containerRef = useRef<any>();
  const api = new AssignedEquipmentReportResource();
  const methods = useForm<any>();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [totalCost, setTotalCost] = useState(0);
  const [tagData, setTagData] = useState(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);

  const { equipmentList } = useSelector(
    (state: any) => ({
      equipmentList: state?.data?.report?.equipmentList,
    }),
    shallowEqual
  );

  const assignedEquipmentQuery = useQuery(
    [`getAssignedEquipmentStatus`],
    async () => {
      const response = await api.mechanicListAssignedEquipmentReport(reportId, {
        limit: 5000,
      });
      return response?.data;
    }
  );

  const {
    data: assignedEquipmentListQuery,
    isLoading: isAssignedEquipmentListLoading,
  } = useQuery(
    [`assigned-equipment-list`],
    () =>
      api
        .mechanicListAssignedEquipmentDetailReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          if (res?.data?.results) getTotalCost(res?.data?.results);
          return res?.data.results;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const addData = useMutation(
    (data: any) =>
      api.mechanicStoreAssignedEquipmentDetailReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('assigned-equipment-list');
        setAddModalOpen(false);
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      equipment: data?.equipment?.value,
      op_hour: data?.op_hour,
    };
    if (isOnline) {
      addData.mutate(formData);
    } else {
      setOfflineAssignedEquipmentFilteredByPreviousReportId((prev: any) => ([...prev, {
        ...formData,
        equipment: { id: data?.equipment?.value, name: data?.equipment?.label },
        op_hour: data?.op_hour,
        id: uuid()
      }]))
    }
    onAddModalClose();
  };

  const updateData = useMutation(
    (data: any) => {
      return api.mechanicUpdateAssignedEquipmentDetailReport(
        reportId,
        data?.id,
        data?.body
      );
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('assigned-equipment-list');
      },
    }
  );

  const handleChangeData = (field: any, fieldValue: any, fieldType: string) => {
    let data: any = { body: { op_hour: '' }, id: field?.id };
    switch (fieldType) {
      case 'op_hour':
        data.body.op_hour = fieldValue;
        break;
    }
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineAssignedEquipmentFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          return {
            ...r,
            op_hour: data.body.op_hour || r.op_hour
          }
        } else {
          return r;
        }
      }))
    }
  };

  const debounce = useDebounceFunc(handleChangeData, 3000);

  const handleDetailDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: number) =>
      api.mechanicDestroyAssignedEquipmentDetailReport(reportId, id + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('assigned-equipment-list');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (isOnline) {
      if (id != 0) deleteData.mutate(id);
    } else {
      setOfflineAssignedEquipmentFilteredByPreviousReportId((prev: any) => prev.filter((r: any) => r.id !== id))
    }
    onDeleteModalClose();
  };

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        op_hour: fieldData?.op_hour,
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineAssignedEquipmentFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          return {
            ...r,
            note: modalValue.note
          }
        } else {
          return r;
        }
      }))
    }
    setNoteDetailModalOpen(false);
  };

  const handleNoteDetailModal = (equipmentDamage: any) => {
    setNoteDetailFieldData(equipmentDamage);
    setNoteDetailModalOpen(true);
  };

  const handleNoteModal = (equipmentDamage: any) => {
    setNoteModalOpen(true);
  };

  const updateAssignmentEquipmentData = useMutation(
    (data: any) => api.mechanicUpdateAssignedEquipmentReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getAssignedEquipmentStatus');
      },
    }
  );

  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      note: modalValue.note,
    };
    updateAssignmentEquipmentData.mutate(data);
    setNoteModalOpen(false);
  };

  const getTotalCost = (assignedEquipment: any) => {
    const total = assignedEquipment?.reduce(function (acc: number, obj: any) {
      return acc + +obj.idle_cost;
    }, 0);
    setTotalCost(total.toFixed(2));
  };

  const assignedEquipmentList = useMemo(() => isOnline
    ? assignedEquipmentListQuery?.length > 0
      ? assignedEquipmentListQuery
      : []
    : offlineAssignedEquipmentFilteredByPreviousReportId, [offlineAssignedEquipmentFilteredByPreviousReportId, assignedEquipmentListQuery])

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/assigned-equipment')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev, assigned_equipment: offlineAssignedEquipmentFilteredByPreviousReportId
    }))
    // update the queue
    updateQueue(reportBody.id, { ...reportBody, assigned_equipment: offlineAssignedEquipmentFilteredByPreviousReportId })
  }, [offlineAssignedEquipmentFilteredByPreviousReportId])

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('getAssignedEquipmentStatus');
    queryClient.invalidateQueries('assigned-equipment-list');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Assigned Equipment</Heading>
          <Heading size="md">Total Cost ${totalCost}</Heading>
          <Stack direction="row" spacing="6">
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(assignedEquipmentQuery?.data, 'tagStatus')
                }
              />
            </Tooltip>
            {tagData === assignedEquipmentQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={assignedEquipmentQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
              />
            )}
            <Tooltip hasArrow label={assignedEquipmentQuery?.data?.note}>
              <IconButton
                icon={<MdOutlineModeComment />}
                variant="link"
                aria-label={'Note'}
                color={
                  assignedEquipmentQuery?.data?.note
                    ? 'primary.600'
                    : 'gray.300'
                }
                size="lg"
                onClick={() => handleNoteModal(assignedEquipmentQuery?.data)}
              />
            </Tooltip>
            <NoteModal
              isModalOpen={noteModalOpen}
              setModalOpen={setNoteModalOpen}
              fieldData={assignedEquipmentQuery?.data}
              onModalSubmit={(notes: string) =>
                onNoteModalSubmit(notes, assignedEquipmentQuery?.data)
              }
              formMutation={addData}
              errorMessage={errorMessage}
            />
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant="simple">
              <Tbody>
                <Tr bg={'gray.300'}>
                  <Td>Equipment</Td>
                  <Td>Operating Hours</Td>
                  <Td>Idle Hours</Td>
                  <Td>Op Cost</Td>
                  <Td>Idle Cost</Td>
                  <Td></Td>
                </Tr>
                {assignedEquipmentList?.map(
                  (assignedEquipment: any, index: number) => (
                    <React.Fragment key={assignedEquipment?.id}>
                      <Tr>
                        <Td>{assignedEquipment?.equipment?.name}</Td>
                        <Td>
                          <Input
                            defaultValue={assignedEquipment?.op_hour}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              debounce(
                                assignedEquipment,
                                e.target.value,
                                'op_hour'
                              )
                            }
                            type="number"
                            step="0.01"
                            placeholder="Estimate Cost"
                          />
                        </Td>
                        <Td>{assignedEquipment?.idle_hour}</Td>
                        <Td>{assignedEquipment?.op_cost}</Td>
                        <Td>{assignedEquipment?.idle_cost}</Td>
                        <Td>
                          <Tooltip hasArrow label={'Tag'}>
                            <IconButton
                              icon={<BsTag />}
                              variant="link"
                              aria-label={'Tag'}
                              color={'gray.300'}
                              size="lg"
                              onClick={() =>
                                handleTagModal(assignedEquipment, 'tagDetail')
                              }
                            />
                          </Tooltip>
                          {tagData === assignedEquipment?.tag && (
                            <TagModal
                              isModalOpen={tagDetailModalOpen}
                              setModalOpen={setTagDetailModalOpen}
                              fieldData={tagData}
                              onModalSubmit={() => onTagModalSubmit()}
                              formMutation={addData}
                              errorMessage={errorMessage}
                            />
                          )}
                          <Tooltip hasArrow label={assignedEquipment?.note}>
                            <IconButton
                              icon={<MdOutlineModeComment />}
                              variant="link"
                              aria-label={'Note'}
                              color={
                                assignedEquipment?.note
                                  ? 'primary.600'
                                  : 'gray.300'
                              }
                              size="lg"
                              onClick={() =>
                                handleNoteDetailModal(assignedEquipment)
                              }
                            />
                          </Tooltip>
                          <NoteModal
                            isModalOpen={noteDetailModalOpen}
                            setModalOpen={setNoteDetailModalOpen}
                            fieldData={noteDetailFieldData}
                            onModalSubmit={(notes: string) =>
                              onNoteDetailModalSubmit(
                                notes,
                                noteDetailFieldData
                              )
                            }
                            formMutation={updateData}
                            errorMessage={errorMessage}
                          />
                          <Tooltip hasArrow label={'Delete'}>
                            <IconButton
                              icon={<BsTrash />}
                              variant="link"
                              aria-label={'Delete'}
                              color="gray.300"
                              size="lg"
                              onClick={() =>
                                handleDetailDelete(assignedEquipment?.id)
                              }
                            />
                          </Tooltip>
                        </Td>
                      </Tr>

                      <Modal
                        isOpen={isDeleteModalOpen}
                        isCentered
                        onClose={onDeleteModalClose}>
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>
                            {'Delete Assignment Equipment'}
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                            {'Are you sure you want to delete this ?'}
                            {deleteMsg && (
                              <Alert status="error" mb={2}>
                                <AlertIcon />
                                {deleteMsg}
                              </Alert>
                            )}
                          </ModalBody>
                          <ModalFooter>
                            <ButtonGroup>
                              <Button
                                colorScheme="red"
                                onClick={() => onDeleteConfirm(confirmDeleteId)}
                                isLoading={deleteData.isLoading}>
                                {'Delete'}
                              </Button>

                              <Button
                                variant="outline"
                                onClick={onDeleteModalClose}>
                                {'Cancel'}
                              </Button>
                            </ButtonGroup>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                    </React.Fragment>
                  )
                )}
              </Tbody>
            </Table>
            {isOnline && !assignedEquipmentListQuery && <CenterSpinner type="normal" />}
          </TableContainer>
        </Stack>

        {/* Add Modal */}
        <Modal
          isOpen={isAddModalOpen}
          isCentered
          onClose={onAddModalClose}
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Add Assignement Equipment'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack direction="column" spacing="4">
                    {addData.isError && (
                      <Alert status="error">
                        <AlertIcon />
                        {errorMessage}
                      </Alert>
                    )}
                    <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods?.formState?.errors?.equipment}
                          isRequired>
                          <FormLabel>Equipment</FormLabel>
                          <Controller
                            control={methods.control}
                            name="equipment"
                            rules={{
                              required: 'Equipment',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="equipment"
                                placeholder={'Equipment'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline ? equipmentList.isEquipmentLoading : offlineEquipmentList.isLoading}
                                options={isOnline ? equipmentList.equipmentOptions : offlineEquipmentList.equipments}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods?.formState?.errors?.equipment &&
                              !!methods?.formState?.errors?.equipment?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.op_hour}
                          isRequired>
                          <FormLabel>{'Op Hour'}</FormLabel>
                          <Input
                            type="number"
                            step="0.01"
                            {...methods.register('op_hour', {
                              required: 'Op Hour',
                            })}
                            placeholder="Op Hour"
                          />

                          <FormErrorMessage>
                            {!!methods.formState.errors?.op_hour &&
                              !!methods.formState.errors?.op_hour?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <ButtonGroup>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={addData.isLoading}>
                        Add
                      </Button>
                      <Button variant="outline" onClick={onAddModalClose}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Flex p={['3', '6']}>
          <Button
            variant={'outline'}
            onClick={() => {
              methods.reset();
              setAddModalOpen(true);
            }}>
            Add Equipment
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default AssignedEquipmentReport;
