import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import PitResource from 'api/pit';
import ProductResource from 'api/product';
import CustomerResource from 'api/user';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CreatedAtInput from 'components/common/CreatedAtInput';
import {
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
  SaleJobClassEnum,
  SaleJobClassList,
} from 'constants/common';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { DateFormatMDY, TimeFormat } from 'utils';

const SaleForm: React.FC<any> = (props) => {
  const { saleData } = props;
  const saleJobClassEnum: any = SaleJobClassEnum;
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<any>();
  const saleDate =
    saleData && saleData?.date ? new Date(saleData?.date) : new Date();
  const [createdAt, setCreatedAt] = useState<Date | null>(saleDate);
  const [invoiceDate, setInvoiceDate] = useState<Date | null>(saleDate);


  // Pit List
  const pitAPI = new PitResource();
  const { data: pitQuery, isLoading: isPitLoading } = useQuery(
    [`pit-list`],
    () => pitAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const pitList = pitQuery?.results;
  const pitOptions = pitList?.map((pit: any) => ({
    label: pit.name,
    value: pit.id,
  }));

  // Product List
  const productAPI = new ProductResource();
  const { data: productQuery, isLoading: isProductLoading } = useQuery(
    [`product-list`],
    () => productAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const productList = productQuery?.results;
  const productOptions = productList?.map((product: any) => ({
    label: product.name,
    value: product.id,
  }));

  // Customer List
  const userAPI = new CustomerResource();
  const { data: customerQuery, isLoading: isCustomerLoading } = useQuery(
    [`customer-list`],
    () => userAPI.customer().then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const customerList = customerQuery?.users;
  const customerOptions = customerList?.map((customer: any) => ({
    label: `${customer?.first_name} ${customer?.last_name}`,
    value: customer.id,
  }));
  
  // Product Revenue calculation
  const [netTons, setNetTons] = useState('');
  const [priceTons, setPriceTons] = useState('');
  const [productRevenue, setProductRevenue] = useState('');

  const handleChangeNetTons = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNetTons(event.target.value);
    setValue('net_wt_tons',event.target.value);
    if (typeof parseFloat(priceTons) === 'number'){
      const product_revenue = parseFloat(event.target.value) * parseFloat(priceTons);
      setProductRevenue(product_revenue.toString());
      setValue('product_revenue',product_revenue.toString());

      const trucking_revenue_ton = parseFloat(event.target.value) * parseFloat(truckingPriceTon);
      setTruckingRevenue(trucking_revenue_ton.toString());
      setValue('trucking_revenue_ton', trucking_revenue_ton.toString());
      
      const trucking_margin_ton = parseFloat(trucking_revenue_ton.toString()) - parseFloat(truckingCostTon);
      setTruckingMarginTon(trucking_margin_ton.toString());
      setValue('trucking_margin_ton', trucking_margin_ton.toString());
    }

    // if (typeof parseFloat(truckingPriceTon) === 'number'){
    //   const trucking_revenue_ton = parseFloat(truckingPriceTon) * parseFloat(event.target.value);
    //   setProductRevenue(trucking_revenue_ton.toString());
    //   setValue('trucking_price_ton', trucking_revenue_ton.toString());
    // }

    
  };

  const handleChangePriceTons = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriceTons(event.target.value);
    setValue('price_per_ton', event.target.value);
    const product_revenue = parseFloat(netTons) * parseFloat(event.target.value);
    setProductRevenue(product_revenue.toString());
    setValue('product_revenue', product_revenue.toString());
  };

  // Trucking Revenue calculation
  const [truckingPriceTon, setTruckingPriceTon] = useState<string>('');
  const [truckingRevenue, setTruckingRevenue] = useState<string>('');

  const handleChangeTruckingPriceTon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTruckingPriceTon(event.target.value);
    setValue('trucking_price_ton', event.target.value);
    const trucking_revenue_ton = parseFloat(event.target.value) * parseFloat(netTons);
    setTruckingRevenue(trucking_revenue_ton.toString());
    setValue('trucking_revenue_ton', trucking_revenue_ton.toString());
    // handleChangeTruckingRevenueTon(trucking_revenue_ton)

    // setTruckingRevenue(trucking_revenue_ton.toString());
    // setValue('trucking_revenue_ton', trucking_revenue_ton.toString());
    const trucking_margin_ton = parseFloat(trucking_revenue_ton.toString()) - parseFloat(truckingCostTon);
    setTruckingMarginTon(trucking_margin_ton.toString());
    setValue('trucking_margin_ton', trucking_margin_ton.toString());
  };

  // Trucking Margin Calculation
  const [truckingCostTon, setTruckingCostTon] = useState('');
  const [truckingMarginTon, setTruckingMarginTon] = useState('');

  const handleChangeTruckingRevenueTon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTruckingRevenue(event.target.value);
    setValue('trucking_revenue_ton', event.target.value);
    const trucking_margin_ton = parseFloat(event.target.value) - parseFloat(truckingCostTon);
    setTruckingMarginTon(trucking_margin_ton.toString());
    setValue('trucking_margin_ton', trucking_margin_ton.toString());
  };

  const handleChangeTruckingCostTon = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTruckingCostTon(event.target.value);
    setValue('trucking_cost_ton', event.target.value);
    const trucking_margin_ton = parseFloat(truckingRevenue) - parseFloat(event.target.value);
    setTruckingMarginTon(trucking_margin_ton.toString());
    setValue('trucking_margin_ton', trucking_margin_ton.toString());
  };


  const handleDateChange = (date: Date) => {
    setCreatedAt(date);
    setValue('time', TimeFormat(date));
    setValue('date', DateFormatMDY(date));
  };

  const handleInvoiceDateChange = (date: Date) => {
    setInvoiceDate(date);
    setValue('invoice_time', TimeFormat(date));
    setValue('invoice_date', DateFormatMDY(date));
  };

  useEffect(() => {
    if (!saleData) return;
    // setValue('customer_name', {
    //   label: `${saleData?.customer_name?.first_name} ${saleData?.customer_name?.last_name}`,
    //   value: saleData?.customer_name?.id,
    // });
    setValue('product', {
      label: `${saleData?.product?.name}`,
      value: saleData?.product?.id,
    });
    setValue('pit', {
      label: `${saleData?.pit?.name}`,
      value: saleData?.pit?.id,
    });
    setValue('job_class', {
      label: saleJobClassEnum[saleData?.job_class],
      value: saleData?.job_class,
    });
    setValue('date', DateFormatMDY(saleData?.date));
    setValue('time', saleData?.time);

    setValue('invoice_date', DateFormatMDY(saleData?.date));
    setValue('invoice_time', saleData?.time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleData, setValue]);

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.ticket_number} isRequired>
            <FormLabel>Ticket Number</FormLabel>
            <Input
              {...register('ticket_number', {
                required: 'Ticket Number',
                maxLength: {
                  value: 100,
                  message: 'Too many characters.',
                },
              })}
              type="text"
              defaultValue={saleData?.ticket_number}
              placeholder={'Ticket Number'}
            />
            <FormErrorMessage>
              {!!errors?.ticket_number && !!errors?.ticket_number?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.invoice_number} isRequired>
            <FormLabel>Invoice Number</FormLabel>
            <Input
              {...register('invoice_number', {
                required: 'Invoice Number',
                maxLength: {
                  value: 100,
                  message: 'Too many characters.',
                },
              })}
              type="text"
              defaultValue={saleData?.invoice_number}
              placeholder={'Invoice Number'}
            />
            <FormErrorMessage>
              {!!errors?.invoice_number && !!errors?.invoice_number?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.invoice_date} isRequired>
            <FormLabel>{'Invoice Date'}</FormLabel>
            <ReactDatePicker
              fixedHeight
              dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
              customInput={<CreatedAtInput />}
              onChange={handleInvoiceDateChange}
              selected={createdAt}
              value={DateFormatMDY(createdAt)}
              shouldCloseOnSelect={true}
              autoComplete="off"
            />
            <FormErrorMessage>
              {!!errors?.invoice_date && !!errors?.invoice_date?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.pit} isRequired>
            <FormLabel>{'Pit'}</FormLabel>
            <Controller
              control={control}
              name="pit"
              rules={{
                required: 'Pit',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="pit"
                  placeholder={'Pit'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isPitLoading}
                  options={pitOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                />
              )}
            />
            <FormErrorMessage>
              {!!errors?.pit && !!errors?.pit?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.product} isRequired>
            <FormLabel>{'Product'}</FormLabel>
            <Controller
              control={control}
              name="product"
              rules={{
                required: 'Product',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="product"
                  placeholder={'Product'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isProductLoading}
                  options={productOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                />
              )}
            />
            <FormErrorMessage>
              {!!errors?.product && !!errors?.product?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.customer_name} isRequired>
            <FormLabel>{'Customer'}</FormLabel>
            <Input
              {...register('customer_name', {
                required: 'Customer',
              })}
              type="text"
              defaultValue={saleData?.customer_name}
              placeholder={'Customer'}
            />
            <FormErrorMessage>
              {!!errors?.customer_name && !!errors?.customer_name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.trucking_company} isRequired>
            <FormLabel>{'Trucking Company'}</FormLabel>
            <Input
              {...register('trucking_company', {
                required: 'Trucking Company',
              })}
              type="text"
              defaultValue={saleData?.trucking_company}
              placeholder={'Trucking Company'}
            />
            {/* <Controller
              control={control}
              name="trucking_company"
              rules={{
                required: 'Trucking Company',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="trucking_company"
                  placeholder={'Trucking Company'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isUserLoading}
                  options={userOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                />
              )}
            /> */}
            <FormErrorMessage>
              {!!errors?.trucking_company &&
                !!errors?.trucking_company?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.date} isRequired>
            <FormLabel>{'Date'}</FormLabel>
            <ReactDatePicker
              fixedHeight
              dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
              customInput={<CreatedAtInput />}
              onChange={handleDateChange}
              selected={createdAt}
              value={DateFormatMDY(createdAt)}
              shouldCloseOnSelect={true}
              autoComplete="off"
            />
            <FormErrorMessage>
              {!!errors?.date && !!errors?.date?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.po} isRequired>
            <FormLabel>{'PO'}</FormLabel>
            <Input
              {...register('po', {
                required: 'PO',
              })}
              type="string"
              defaultValue={saleData?.po.toString()}
              placeholder={'PO'}
            />
            <FormErrorMessage>
              {!!errors?.po && !!errors?.po?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.truck_driver} isRequired>
            <FormLabel>{'Truck Driver'}</FormLabel>
            <Input
              {...register('truck_driver', {
                required: 'Truck Driver',
              })}
              type="text"
              defaultValue={saleData?.truck_driver}
              placeholder={'Truck Driver'}
            />
            <FormErrorMessage>
              {!!errors?.truck_driver && !!errors?.truck_driver?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.vehicle_id} isRequired>
            <FormLabel>{'Vehicle Id'}</FormLabel>
            <Input
              {...register('vehicle_id', {
                required: 'Vehicle Id',
              })}
              type="text"
              defaultValue={saleData?.vehicle_id}
              placeholder={'Vehicle Id'}
            />
            <FormErrorMessage>
              {!!errors?.vehicle_id && !!errors?.vehicle_id?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.job_name} isRequired>
            <FormLabel>{'Job Name'}</FormLabel>
            <Input
              {...register('job_name', {
                required: 'Job Name',
              })}
              type="text"
              defaultValue={saleData?.job_name}
              placeholder={'Job Name'}
            />
            <FormErrorMessage>
              {!!errors?.job_name && !!errors?.job_name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.job_class} isRequired>
            <FormLabel>{'Job Class'}</FormLabel>
            <Controller
              control={control}
              name="job_class"
              rules={{
                required: 'Job Class',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="job_class"
                  placeholder={'Job Class'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  options={SaleJobClassList}
                  styles={reactSelectStyles}
                  isSearchable={true}
                />
              )}
            />
            <FormErrorMessage>
              {!!errors?.job_class && !!errors?.job_class?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.job_location} isRequired>
            <FormLabel>{'Job Location'}</FormLabel>
            <Input
              {...register('job_location', {
                required: 'Job Location',
              })}
              type="text"
              defaultValue={saleData?.job_location}
              placeholder={'Job Location'}
            />
            <FormErrorMessage>
              {!!errors?.job_location && !!errors?.job_location?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.order_info} isRequired>
            <FormLabel>{'Order Number'}</FormLabel>
            <Input
              {...register('order_info', {
                required: 'Order Number',
              })}
              type="text"
              defaultValue={saleData?.order_info}
              placeholder={'Order Number'}
            />
            <FormErrorMessage>
              {!!errors?.order_info && !!errors?.order_info?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.loader_scale_operator} isRequired>
            <FormLabel>{'Loader Scale Operator'}</FormLabel>
            <Input
              {...register('loader_scale_operator', {
                required: 'Loader Scale Operator',
              })}
              type="text"
              defaultValue={saleData?.loader_scale_operator}
              placeholder={'Loader Scale Operator'}
            />
            <FormErrorMessage>
              {!!errors?.loader_scale_operator &&
                !!errors?.loader_scale_operator?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.gross_wt_tons} isRequired>
            <FormLabel>{'Gross wt Tons'}</FormLabel>
            <Input
              {...register('gross_wt_tons', {
                required: 'Gross wt Tons',
              })}
              type="number"
              step="0.01"
              defaultValue={saleData?.gross_wt_tons}
              placeholder={'Gross wt Tons'}
            />
            <FormErrorMessage>
              {!!errors?.gross_wt_tons && !!errors?.gross_wt_tons?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.tare_wt_tons} isRequired>
            <FormLabel>{'Tare wt Tons'}</FormLabel>
            <Input
              {...register('tare_wt_tons', {
                required: 'Tare wt Tons',
              })}
              type="number"
              step="0.01"
              defaultValue={saleData?.tare_wt_tons}
              placeholder={'Tare wt Tons'}
            />
            <FormErrorMessage>
              {!!errors?.tare_wt_tons && !!errors?.tare_wt_tons?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.net_wt_tons} isRequired>
            <FormLabel>{'Net wt Tons'}</FormLabel>
            <Input
              {...register('net_wt_tons', {
                required: 'Net wt Tons',
              })}
              type="number"
              step="0.01"
              // defaultValue={saleData?.net_wt_tons}
              onChange={handleChangeNetTons}
              value={netTons}
              placeholder={'Net wt Tons'}
            />
            <FormErrorMessage>
              {!!errors?.net_wt_tons && !!errors?.net_wt_tons?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.sale_type} isRequired>
            <FormLabel>{'Sale Type'}</FormLabel>
            <Input
              {...register('sale_type', {
                required: 'Sale Type',
              })}
              type="text"
              defaultValue={saleData?.sale_type}
              placeholder={'Sale Type'}
            />
            <FormErrorMessage>
              {!!errors?.sale_type && !!errors?.sale_type?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.price_per_ton} isRequired>
            <FormLabel>{'Price/Ton'}</FormLabel>
            <Input
              {...register('price_per_ton', {
                required: 'Price/Ton',
              })}
              type="number"
              step="0.01"
              // defaultValue={saleData?.price_per_ton}
              placeholder={'Minimum Royalty'}
              value={priceTons}
              onChange={handleChangePriceTons}
            />
            <FormErrorMessage>
              {!!errors?.price_per_ton && !!errors?.price_per_ton?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.delivery_point} isRequired>
            <FormLabel>{'Delivery Point'}</FormLabel>
            <Input
              {...register('delivery_point', {
                required: 'Delivery Point',
              })}
              type="number"
              step="0.01"
              defaultValue={saleData?.delivery_point}
              placeholder={'Delivery Point'}
            />
            <FormErrorMessage>
              {!!errors?.delivery_point && !!errors?.delivery_point?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.sales_tax_rate} isRequired>
            <FormLabel>{'Sales Tax Rate'}</FormLabel>
            <Input
              {...register('sales_tax_rate', {
                required: 'Sales Tax Rate',
              })}
              type="number"
              step="0.01"
              defaultValue={saleData?.sales_tax_rate}
              placeholder={'Sales Tax Rate'}
            />
            <FormErrorMessage>
              {!!errors?.sales_tax_rate && !!errors?.sales_tax_rate?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        
        <GridItem>
          <FormControl isInvalid={!!errors?.trucking_price_ton} isRequired>
            <FormLabel>{'Trucking Price Ton'}</FormLabel>
            <Input
              {...register('trucking_price_ton', {
                required: 'Trucking Price Ton',
              })}
              type="number"
              step="0.01"
              // defaultValue={saleData?.trucking_price_ton}
              value={truckingPriceTon}
              onChange={handleChangeTruckingPriceTon}
              placeholder={'Trucking Price Ton'}
            />
            <FormErrorMessage>
              {!!errors?.trucking_price_ton &&
                !!errors?.trucking_price_ton?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.trucking_revenue_ton} isRequired>
            <FormLabel>{'Trucking Revenue Ton (Trucking Price Ton * Net wt Tons)'}</FormLabel>
            <Input
              {...register('trucking_revenue_ton', {
                required: 'Trucking Revenue Ton',
              })}
              type="number"
              step="0.01"
              // defaultValue={saleData?.trucking_revenue_ton}
              placeholder={'Trucking Revenue Ton'}
              value={truckingRevenue}
              onChange={handleChangeTruckingRevenueTon}
              disabled
            />
            <FormErrorMessage>
              {!!errors?.trucking_revenue_ton &&
                !!errors?.trucking_revenue_ton?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.trucking_cost_ton} isRequired>
            <FormLabel>{'Trucking Cost Ton'}</FormLabel>
            <Input
              {...register('trucking_cost_ton', {
                required: 'Trucking Cost Ton',
              })}
              type="number"
              step="0.01"
              // defaultValue={saleData?.trucking_cost_ton}
              value={truckingCostTon}
              onChange={handleChangeTruckingCostTon}
              placeholder={'Trucking Cost Ton'}
            />
            <FormErrorMessage>
              {!!errors?.trucking_cost_ton &&
                !!errors?.trucking_cost_ton?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.trucking_margin_ton} isRequired>
            <FormLabel>{'Trucking Margin Ton (Trucking Revenue Ton - Trucking Cost Ton)'}</FormLabel>
            <Input
              {...register('trucking_margin_ton', {
                required: 'Trucking Margin Ton',
              })}
              type="number"
              step="0.01"
              // defaultValue={saleData?.trucking_margin_ton}
              value={truckingMarginTon}
              placeholder={'Trucking Margin Ton'}
              disabled
            />
            <FormErrorMessage>
              {!!errors?.trucking_margin_ton &&
                !!errors?.trucking_margin_ton?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.other_revenue} isRequired>
            <FormLabel>{'Other Revenue'}</FormLabel>
            <Input
              {...register('other_revenue', {
                required: 'Other Revenue',
              })}
              type="number"
              step="0.01"
              defaultValue={saleData?.other_revenue}
              placeholder={'Other Revenue'}
            />
            <FormErrorMessage>
              {!!errors?.other_revenue && !!errors?.other_revenue?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.product_revenue} isRequired>
            <FormLabel>{'Product Revenue (Net wt Tons * Price/Ton)'}</FormLabel>
            <Input
              {...register('product_revenue', {
                required: 'Product Revenue',
              })}
              type="number"
              step="0.01"
              // defaultValue={saleData?.product_revenue}
              // defaultValue = {saleData?.net_wt_tons * saleData?.price_per_ton}
              placeholder={'Product Revenue'}
              value={productRevenue}
              disabled
            />
            <FormErrorMessage>
              {!!errors?.product_revenue && !!errors?.product_revenue?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.total_sales_tax} isRequired>
            <FormLabel>{'Total Sales Tax'}</FormLabel>
            <Input
              {...register('total_sales_tax', {
                required: 'Total Sales Tax',
              })}
              type="number"
              step="0.01"
              defaultValue={saleData?.total_sales_tax}
              placeholder={'Total Sales Tax'}
            />
            <FormErrorMessage>
              {!!errors?.total_sales_tax && !!errors?.total_sales_tax?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.notes} isRequired>
            <FormLabel>{'Notes'}</FormLabel>
            <Textarea
              {...register('notes', {
                required: 'Notes',
              })}
              defaultValue={saleData?.notes}
              placeholder={'Notes'}
            />
            <FormErrorMessage>
              {!!errors?.notes && !!errors?.notes?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default SaleForm;
