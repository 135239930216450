import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

interface Props {
  type?: string;
}
export const CenterSpinner: React.FC<Props> = (props) => {
  const { type } = props;

  const getSpinner = () => {
    if (type === 'normal') {
      return (
        <Center h="100px" color="primary.500">
          <Spinner />
        </Center>
      );
    }
    return (
      <Center h="calc(100vh - 128px)" color="primary.500">
        <Spinner />
      </Center>
    );
  };

  return getSpinner();
};
