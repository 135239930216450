import Resource from 'api/resource';
import http from 'utils/http';

class ProductResource extends Resource {
  constructor() {
    super('products');
  }

  bulkDelete(resource: any) {
    return http({
      url: '/bulk-destroy/products',
      method: 'post',
      data: resource,
    });
  }

  productGroups(query?: any) {
    return http({
      url: '/product-groups',
      method: 'get',
      params: query,
    });
  }

  productNameList(query?: any) {
    return http({
      url: '/product-names',
      method: 'get',
      params: query,
    });
  }

}

export { ProductResource as default };
