import Resource from 'api/resource';
import http from 'utils/http';

class Tagesource extends Resource {
  constructor() {
    super('tags');
  }

  listTagText(query?: any) {
    return http({
      url: '/tag-texts/',
      method: 'get',
      params: query,
    });
  }

  getTagText(id: any) {
    return http({
      url: '/tag-texts/' + id,
      method: 'get',
    });
  }
  storeTagText(resource: any, headers?: any) {
    return http({
      url: '/tag-texts/',
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateTagText(id: any, resource: any) {
    return http({
      url: '/tag-texts/' + id,
      method: 'put',
      data: resource,
    });
  }
  destroyTagText(id: any) {
    return http({
      url: '/tag-texts/' + id,
      method: 'delete',
    });
  }
}

export { Tagesource as default };
