import { Box, Divider, Heading, Stack } from '@chakra-ui/react';
import routes from 'constants/routes';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  reportId?: number;
}
const ForemanReportSidebar: React.FC<Props> = (props) => {
  const { title, reportId = 0 } = props;
  return (
    <>
      <Stack direction={'column'} border={'1px solid #C5C6C9'} bg={'#fff'}>
        <Box p={4}>
          <Heading size="md">{title}</Heading>
          <Heading size="md">Daily Report</Heading>
        </Box>
        <Divider />
        <Box p={4}>
          <Stack
            direction={'column'}
            spacing={'24px'}
            fontWeight={500}
            color={'#737373'}>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.operationDetailReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.operationDetailReport
              }>
              Operational Details
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.laborReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.laborReport
              }>
              Labor
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.productionReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.productionReport
              }>
              Production
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.capitalEquipmentReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.capitalEquipmentReport
              }>
              Capital Equipment
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.ShiftDownTimeReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.ShiftDownTimeReport
              }>
              Shift Downtime
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.fuelUsageReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.fuelUsageReport
              }>
              Fuel Usage
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.loadoutReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.loadoutReport
              }>
              Loadout
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.rentalReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.rentalReport
              }>
              Purchases/Short Term Rental
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.equipmentDamageReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.equipmentDamageReport
              }>
              Equipment Damage/MOB/Service Callout
            </Link>
            <Link
              to={
                reportId > 0
                  ? routes.reports.foremanReport.noteReport +
                    `?report_id=${reportId}`
                  : routes.reports.foremanReport.noteReport
              }>
              Notes
            </Link>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default ForemanReportSidebar;
