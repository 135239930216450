import { Td, Tr } from '@chakra-ui/react';
import React from 'react';
import { DateFormatMDY, formatNumber } from 'utils';

const InventoryListItem: React.FC<any> = (props) => {
  const { listData } = props;

  return (
    <Tr key={listData.id}>
      <Td>{DateFormatMDY(listData?.date)}</Td>
      <Td>{listData?.product_name}</Td>
      <Td>{listData?.product_type}</Td>
      <Td>{listData?.pit}</Td>
      {/* <Td>{formatNumber(listData?.total_adjusted_production)}</Td> */}
      <Td>{formatNumber(listData?.net_tons)}</Td>
      {/* <Td>{formatNumber(listData?.total_sales)}</Td> */}
    </Tr>
  );
};

export default InventoryListItem;
