import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import PitResource from 'api/pit';
import ProductResource from 'api/product';
import InventoryResource from 'api/production';
import CreatedAtInput from 'components/common/CreatedAtInput';
import { MulitSelectAll } from 'components/common/MultiSelectAll';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ProductionListItem from 'components/production/ProductionListItem';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  INVENTORY_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import { InventorySchema } from 'interface/inventory/inventorySchema';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { BiImport, BiPlus, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  DateFormat,
  DateFormatYMD,
  formatNumber,
  getStartingSerialNumber,
} from 'utils';
import CsvUpload from 'utils/CsvUpload';
import { csvExport } from 'utils/fileExport';
import {
  InventoryChartItem,
  InventoryChartRowProps,
} from './ProductionChartRow';

const ProductionList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const [inventoryIds, setInventoryIds] = useState<any>([]);
  const api = new InventoryResource();
  const searchRef = useRef<any>('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<any>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
    inventory_date: '',
    pits: [],
    products: [],
    start_date: '',
    end_date: '',
  });

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'inventoryList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: filterParams.keyword,
        start_date: filterParams.start_date,
        end_date: filterParams.end_date,
        inventory_date: filterParams.inventory_date,
        pits: filterParams.pits,
        products: filterParams.products,
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
        date_after: filterParams.start_date,
        date_before: filterParams.end_date,
        pits: filterParams.pits,
        products: filterParams.products,
      };
      const response = await api.list(queryParams);
      setTotalData(response?.data?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    }
  );

  const handleExport = async () => {
    let dataArray = [
      {
        ['Serial Number']: '',
        ['Product']: '',
        ['Date']: '',
        ['Product Type']: '',
        ['Pit']: '',
        ['Plant']: '',
        ['Shift']: '',
        ['Pay']: '',
        ['Reported Production']: '',
        ['Adjusted Pproduction']: '',
        ['Adjusted Value']: '',
        ['Adjusted Type']: '',
      },
    ];

    const response = await api.list({ paginated: false });

    if (response.data.length > 0) {
      if (inventoryIds.length > 0) {
        dataArray = await response?.data
          ?.filter((res: any, index: number) => inventoryIds.includes(res.id))
          .map((data: any) => {
            return {
              ['Serial Number']: data?.id,
              ['Product']: data?.product_name ?? '',
              ['Date']: data?.date ?? '',
              ['Product Type']: data?.product_type ?? '',
              ['Pit']: data?.pit ?? '',
              ['Plant']: data?.plant ?? '',
              ['Shift']: data?.shift ?? '',
              ['Pay']: data?.pay ?? '',
              ['Reported Production']:
                formatNumber(+data?.reported_production, 2) || 0,
              ['Adjusted Pproduction']:
                formatNumber(+data?.adjusted_production, 2) || 0,
              ['Adjusted Value']: formatNumber(+data?.adjusted_value, 2) || 0,
              ['Adjusted Type']: data?.adjusted_type ?? '',
            };
          });
      } else {
        dataArray = await response?.data?.map((res: any, index: number) => {
          return {
            ['Serial Number']: res?.id,
            ['Product']: res?.product_name ?? '',
            ['Date']: res?.date ?? '',
            ['Product Type']: res?.product_type ?? '',
            ['Pit']: res?.pit ?? '',
            ['Plant']: res?.plant ?? '',
            ['Shift']: res?.shift ?? '',
            ['Pay']: res?.pay ?? '',
            ['Reported Production']:
              formatNumber(+res?.reported_production, 2) || 0,
            ['Adjusted Pproduction']:
              formatNumber(+res?.adjusted_production, 2) || 0,
            ['Adjusted Value']: formatNumber(+res?.adjusted_value, 2) || 0,
            ['Adjusted Type']: res?.adjusted_type ?? '',
          };
        });
      }
    }

    csvExport(dataArray, `Production_${DateFormat(new Date())}` + ' .xlsx');
  };

  const methods = useForm<any>();

  const filterList = async (data: any) => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };

  const handleFilter = () => {
    setFilterParams((prevState: any) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      products: selectedProducts
        .map((selectedProduct: any) => selectedProduct.id)
        .join(','),
      pits: selectedPits.map((selectedPit: any) => selectedPit.id).join(','),
    }));
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  /**
   * Handle filter params submit
   */
  const onSubmit = (data: any) => {
    filterList(data);
  };

  // Product List
  const productAPI = new ProductResource();
  const { data: productQuery, isLoading: isProductLoading } = useQuery(
    [`product-list`],
    () => productAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const productList = productQuery?.results;
  const productOptions = productList?.map((product: any) => ({
    name: `${product.class_code} - ${product.name}`,
    id: product.id,
  }));

  // Pit List
  const pitAPI = new PitResource();
  const { data: pitQuery, isLoading: isPitLoading } = useQuery(
    [`pit-list`],
    () => pitAPI.list({ limit: 5000 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const pitList = pitQuery?.results;
  const pitOptions = pitList?.map((pit: any) => ({
    name: pit.name,
    id: pit.id,
  }));

  const [isFilteredDown, setIsFilteredDown] = useState(true);
  const filterDownButtonHandler = () => setIsFilteredDown(false);
  const filterUpButtonHandler = () => setIsFilteredDown(true);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedPits, setSelectedPits] = useState([]);
  useEffect(() => {
    handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts, selectedPits]);

  let navigate = useNavigate();

  const handleSearch = () => {
    filterList({ keyword: searchRef.current.value });
  };

  const mapSummaryToChart = (summaries: any) => {
    if (!summaries) return [];

    const inventoryChartRows: InventoryChartRowProps[] = [];
    for (let i = 0; i < summaries.length; i++) {
      const summary = summaries[i];

      const row: InventoryChartRowProps = {
        header: {
          title: summary.product_groups?.name,
          column1: '-',
          column2: '-',
        },
        children: [],
      };

      let rowTotalTons = 0.0;
      for (let j = 0; j < summary?.products.length; j++) {
        const product = summary?.products[j];
        const chartItem: InventoryChartItem = {
          title: product?.name,
          column1: product?.tons,
          column2: product?.pits?.join(','),
        };

        row.children.push(chartItem);
        rowTotalTons += Number.parseFloat(product?.tons);
      }

      row.header.column1 = rowTotalTons.toString();
      inventoryChartRows.push(row);
    }

    return inventoryChartRows;

    // const rows: InventoryChartRowProps[] = summaries.map((result: any) => ({
    //   header: {
    //     title: result?.product_groups?.name,
    //     column1: '-',
    //     column2: '-',
    //   },
    //   children: result?.products?.map((product: any) => ({
    //     title: product?.name,
    //     column1: product?.tons,
    //     column2: product?.pits?.join(','),
    //   })),
    // }));

    // return rows;
  };

  const computeSummaryGrandTotalTons = (summaries: any) => {
    let grandTotalTons = 0.0;
    summaries.map((summary: any) =>
      summary?.products.map((product: any) => {
        grandTotalTons += Number.parseFloat(product?.tons);
      })
    );
    return formatNumber(grandTotalTons);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Production</Heading>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <InputGroup>
                <InputLeftElement
                  children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
                />
                <Input
                  w={[96, 60, 60, 96]}
                  type="text"
                  placeholder="Search"
                  ref={searchRef}
                  onKeyUp={handleSearch}
                />
              </InputGroup>
            </form>
          </Stack>

          <Stack direction="row">
            <Button
              size="sm"
              colorScheme="primary"
              leftIcon={<Icon as={BiPlus} w="6" h="6" />}
              onClick={() => navigate(routes.adjustments.list)}>
              Adjustment
            </Button>
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiImport} w="6" h="6" />}
              onClick={handleExport}
              size="sm">
              Download
            </Button>
            <CsvUpload
              url="upload-inventory-csv"
              modal="sale"
              setRefetch={setRefetch}
              headerSchema={INVENTORY_HEADER}
              label="Import"
            />
          </Stack>
        </Flex>

        {/* <Stack direction="column">
          <Flex justify="space-between">
            <Stack direction="row">
              <Table>
                <Thead>
                  <Tr>
                    <Td>Row Labels</Td>
                    <Td>
                      {isFilteredDown && (
                        <IconButton
                          aria-label="Order"
                          icon={<TriangleDownIcon />}
                          style={{ marginRight: '5px' }}
                          onClick={filterDownButtonHandler}
                        />
                      )}
                      {!isFilteredDown && (
                        <IconButton
                          aria-label="Order"
                          icon={<TriangleUpIcon />}
                          style={{ marginRight: '5px' }}
                          onClick={filterUpButtonHandler}
                        />
                      )}
                      Total Production
                    </Td>
                    <Td>Pit</Td>
                  </Tr>
                </Thead>

                <Tbody>
                  {!isListLoading &&
                    dataList?.summaries &&
                    mapSummaryToChart(dataList?.summaries).map((row, index) => (
                      <InventoryChartRow key={index} {...row} />
                    ))}
                  {isListLoading && <TableSkeletonLoader rows={3} cols={3} />}
                </Tbody>

                <Tfoot>
                  <Tr>
                    <Td>Grand Total</Td>

                    <Td textAlign="right">
                      {!isListLoading &&
                        dataList?.summaries &&
                        computeSummaryGrandTotalTons(dataList?.summaries)}
                    </Td>
                    <Td textAlign="right">-</Td>
                  </Tr>
                </Tfoot>
              </Table>
            </Stack>
          </Flex>
        </Stack> */}

        <Stack direction="column">
          <Flex justify="space-between">
            <Stack direction="row" spacing="4">
              <Grid
                gap="6"
                templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
                flex="1">
                <GridItem>
                  <FormControl>
                    <Controller
                      control={methods.control}
                      name="inventory_date"
                      rules={{
                        required: 'Inventory Date',
                      }}
                      render={({ field }) => (
                        // <ReactDatePicker
                        //   fixedHeight
                        //   id="inventory_date"
                        //   customInput={<CreatedAtInput />}
                        //   onChange={(selected: any) => {
                        //     field.onChange(selected);
                        //     handleFilter();
                        //   }}
                        //   selected={field.value}
                        //   shouldCloseOnSelect={true}
                        //   autoComplete="off"
                        //   placeholderText="Select Date"
                        // />
                        <ReactDatePicker
                          fixedHeight
                          id="inventory_date"
                          customInput={<CreatedAtInput />}
                          onChange={(selected: any) => {
                            setDateRange(selected);
                            setFilterParams((prevState: any) => ({
                              ...prevState,
                              currentPage: INITIAL_CURRENT_PAGE,
                              start_date: DateFormatYMD(selected[0]),
                              end_date: DateFormatYMD(selected[1]),
                            }));
                          }}
                          isClearable={true}
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          selected={field.value}
                          shouldCloseOnSelect={true}
                          autoComplete="off"
                        />
                      )}
                    />
                  </FormControl>
                </GridItem>
                <GridItem w={'300px'}>
                  <FormControl>
                    <MulitSelectAll
                      options={productOptions}
                      placeholder={'Select Product'}
                      arrayValue={selectedProducts}
                      setArrayValue={setSelectedProducts}
                      disabled={isProductLoading}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <MulitSelectAll
                      options={pitOptions}
                      placeholder={'Select Pit'}
                      arrayValue={selectedPits}
                      setArrayValue={setSelectedPits}
                      disabled={isPitLoading}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </Stack>
          </Flex>
        </Stack>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Checkbox
                      isChecked={
                        inventoryIds.length ===
                        dataList?.results?.map(
                          (inventory: InventorySchema) => inventory.id
                        ).length
                      }
                      onChange={() => {
                        const inventorys = dataList?.results?.map(
                          (inventory: InventorySchema) => inventory.id
                        );
                        if (inventoryIds.length === inventorys.length) {
                          setInventoryIds([]);
                        } else {
                          setInventoryIds(inventorys);
                        }
                      }}></Checkbox>
                  </Th>
                  <Th>Product</Th>
                  <Th>Date</Th>
                  <Th>Product Type</Th>
                  <Th>Pit</Th>
                  <Th>Plant</Th>
                  <Th>Shift</Th>
                  <Th>Pay</Th>
                  <Th>Reported Production</Th>
                  <Th>Adjusted Production</Th>
                  <Th>Net Production</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map(
                    (listData: InventorySchema, index: number) => (
                      <ProductionListItem
                        listData={listData}
                        key={listData.id}
                        index={startingSN + index}
                        setInventoryIds={setInventoryIds}
                        inventoryIds={inventoryIds}
                      />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={11} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default ProductionList;
