import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  chartList: any;
  labels: any;
}

const CumulativeChart: React.FC<Props> = (props) => {
  const { chartList, labels } = props;
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        align: 'end',
      },
      title: {
        padding: '0',
        display: true,
        text: 'Cumulative Tons',
        align: 'start',
      },
    },
  };

  let data = {
    labels,
    datasets: [
      {
        label: 'Net Tons',
        data: chartList?.cumulative_net_tons.map(
          (res: { tons: string }) => res?.tons
        ),
        borderColor: '#7FC875',
        backgroundColor: '#7FC875',
      },
      // {
      //   label: 'Adj tons',
      //   data: chartList?.cumulative_adjusted_tons.map(
      //     (res: { tons: string }) => res?.tons
      //   ),
      //   borderColor: '#406F96',
      //   backgroundColor: '#406F96',
      // },
      // {
      //   label: 'Reported tons',
      //   data: chartList?.cumulative_reported_tons.map(
      //     (res: { tons: string }) => res?.tons
      //   ),
      //   borderColor: '#e35f3b',
      //   backgroundColor: '#e35f3b',
      // },
    ],
  };

  return <Line options={options} data={data} />;
};

export default CumulativeChart;
