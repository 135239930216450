import Resource from 'api/resource';
import http from 'utils/http';

class ProductionReportResource extends Resource {
  constructor() {
    super('reports');
  }

  // Note Tag
  getNoteTag(id: string, query?: any) {
    return http({
      url: `/reports/${id}/production-tag/`,
      method: 'get',
      params: query,
    });
  }

  listProductionReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/products`,
      method: 'get',
      params: query,
    });
  }
  getProductionReport(id: string, productionId: string) {
    return http({
      url: `/reports/${id}/products/${productionId}`,
      method: 'get',
    });
  }
  storeProductionReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/products`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateProductionReport(id: string, productionId: string, resource: any) {
    return http({
      url: `/reports/${id}/products/${productionId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyProductionReport(id: string, productionId: string) {
    return http({
      url: `/reports/${id}/products/${productionId}`,
      method: 'delete',
    });
  }

  listProductionDetailReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/production-details`,
      method: 'get',
      params: query,
    });
  }
  getProductionDetailReport(id: string, productionDetailId: string) {
    return http({
      url: `/reports/${id}/production-details/${productionDetailId}`,
      method: 'get',
    });
  }
  storeProductionDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/production-details`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateProductionDetailReport(id: string, resource: any) {
    return http({
      url: `/reports/${id}/production-details`,
      method: 'put',
      data: resource,
    });
  }
  destroyProductionDetailReport(id: string, productionDetailId: string) {
    return http({
      url: `/reports/${id}/production-details/${productionDetailId}`,
      method: 'delete',
    });
  }
}

export { ProductionReportResource as default };
