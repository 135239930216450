import { Td, Tr } from '@chakra-ui/react';
import React from 'react';

interface Props {
  listData: any;
}
const LogListItem: React.FC<Props> = (props) => {
  const { listData } = props;

  return (
    <Tr key={listData?.id}>
      <Td>{listData?.equipment?.name}</Td>
      <Td>{listData?.plant?.name}</Td>
      <Td>{listData?.pit?.name}</Td>
      <Td>{listData?.beginning_date}</Td>
      <Td>{listData?.end_date || '-'}</Td>
    </Tr>
  );
};

export default LogListItem;
