import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import PlantResource from 'api/plant';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import PlantListItem from 'components/plant/PlantListItem';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  PLANT_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import useDebounce from 'hooks/useDebounce';
import { FilterParams } from 'interface/common/filterParam';
import { PlantListSchema, PlantSchema } from 'interface/plant/plantSchema';
import React, { useRef, useState } from 'react';
import { BiImport, BiSearch } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DateFormat } from 'utils';
import CsvUpload from 'utils/CsvUpload';
import { csvExport } from 'utils/fileExport';

const PlantList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const [plantIds, setPlantIds] = useState<any>([]);
  const api = new PlantResource();
  const navigate = useNavigate();
  const searchRef = useRef<any>('');
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'plantList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: useDebounce(filterParams.keyword, 500),
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
      };
      const response = await api.list(queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };

  const handleSearch = () => {
    filterList({ keyword: searchRef.current.value });
  };

  const handleExport = async () => {
    let dataArray = [
      {
        ['Serial Number']: '',
        ['Plant']: '',
        ['MSHA Identification']: '',
        ['Pit']: '',
      },
    ];

    const response = await api.list({ paginated: false });

    if (response.data.length > 0) {
      if (plantIds.length > 0) {
        dataArray = await response?.data
          ?.filter((res: any, index: number) => plantIds.includes(res.id))
          .map((data: any) => {
            return {
              ['Serial Number']: data?.id,
              ['Plant']: data?.name,
              ['MSHA Identification']: data?.msha_identification || '',
              ['Pit']: data?.pit?.name || '-',
            };
          });
      } else {
        dataArray = await response?.data?.map((res: any, index: number) => {
          return {
            ['Serial Number']: res?.id,
            ['Plant']: res?.name,
            ['MSHA Identification']: res?.msha_identification || '',
            ['Pit']: res?.pit?.name || '-',
          };
        });
      }
    }

    csvExport(dataArray, `Plant_${DateFormat(new Date())}` + '.xlsx');
  };

  const deleteData = useMutation(
    () => api.bulkDelete({ plant_ids: plantIds }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('pitList');
        setDeleteModalOpen(false);
        toast({
          title: `${plantIds.length} Plant has been deleted.`,
          status: 'success',
          isClosable: true,
        });
        setPlantIds([]);
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">Plant management</Heading>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                ref={searchRef}
                onKeyUp={handleSearch}
              />
            </InputGroup>
          </Stack>

          <Stack direction="row">
            {plantIds.length && (
              <Button color="red.300" onClick={onDeletePress} size="sm">
                Delete Plant ({plantIds.length})
              </Button>
            )}
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiImport} w="6" h="6" />}
              onClick={handleExport}
              size="sm">
              Download
            </Button>
            <CsvUpload
              url="upload-plant-csv"
              setRefetch={setRefetch}
              headerSchema={PLANT_HEADER}
              label="Import"
              modal="plant"
            />
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => navigate(routes.manage.plants.create)}>
              Add Plant
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    {' '}
                    <Checkbox
                      isChecked={
                        plantIds.length ===
                        dataList?.results?.map((plant: PlantSchema) => plant.id)
                          .length
                      }
                      onChange={() => {
                        const plants = dataList?.results?.map(
                          (plant: PlantSchema) => plant.id
                        );
                        if (plantIds.length === plants.length) {
                          setPlantIds([]);
                        } else {
                          setPlantIds(plants);
                        }
                      }}></Checkbox>
                  </Th>
                  <Th>Plant</Th>
                  <Th>Pit</Th>
                  <Th>MSHA Identification</Th>
                  {/* <Th>Business Unit</Th>
                  <Th>Operation</Th>
                  <Th>Shift</Th> */}
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map(
                    (listData: PlantListSchema, index: number) => (
                      <PlantListItem
                        plantList={listData}
                        key={listData.id}
                        setPlantIds={setPlantIds}
                        plantIds={plantIds}
                      />
                    )
                  )}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={5} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Plant'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete all ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </>
  );
};

export default PlantList;
