import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import PlantResource from 'api/plant';
import RegionResource from 'api/region';
import RoleResource from 'api/role';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CreatedAtInput from 'components/common/CreatedAtInput';
import UserRightsManager from 'components/user/UserRightsManager';
import { PeopleTypeEnum, PeopleTypeList } from 'constants/common';
import { PeopleFormSchema } from 'interface/people/peopleListType';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import {
  capitalizeEachWord,
  capitalizeFirstLetter,
  DateFormatMDY,
} from 'utils';

const PeopleForm: React.FC<any> = (props) => {
  const { peopleData } = props;
  const peopleTypeEnum: any = PeopleTypeEnum;
  const {
    register,
    control,
    setError,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<PeopleFormSchema>();
  const userTypeRef = useRef<any>();
  userTypeRef.current = watch('user_type');

  const roleRef = useRef<any>();
  roleRef.current = watch('role');

  const [startDateCreatedAt, setStartDateCreatedAt] = useState<Date | null>();

  const [plantPermission, setPlantPermission] = useState(
    peopleData?.plants ? peopleData?.plants : []
  );

  const [permissions, setPermissions] = useState(
    peopleData?.permissions ? peopleData?.permissions : []
  );

  // Region List
  const regionAPI = new RegionResource();
  const { data: regionQuery, isLoading: isRegionLoading } = useQuery(
    [`region-list`],
    () => regionAPI.list({ limit: 50 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const regionList = regionQuery?.results;
  const regionOptions = regionList?.map((region: any) => ({
    label: region.name,
    value: region.id,
  }));

  // Role List
  const roleAPI = new RoleResource();
  const { data: roleQuery, isLoading: isRoleLoading } = useQuery(
    [`role-list`],
    () => roleAPI.list({ limit: 50 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const roleList = roleQuery?.results;
  const roleOptions = roleList?.map((role: any) => {
    return {
      label: capitalizeEachWord(role.name),
      value: role.id,
    };
  });

  // Plant List
  const plantAPI = new PlantResource();
  const { data: plantQuery, isLoading: isPlantLoading } = useQuery<any>(
    [`plant-list`],
    () => {
      return plantAPI
        .list({
          limit: 50,
        })
        .then((res) => res?.data);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const plantList = plantQuery?.results;
  const plantOptions = plantList?.map((plant: any) => ({
    label: plant.name,
    value: plant.id,
  }));

  const handlePlantChange = (option: any) => {
    setPlantPermission(option);
  };

  useEffect(() => {
    const startDate =
      peopleData && peopleData?.start_date
        ? new Date(peopleData?.start_date)
        : new Date();
    setStartDateCreatedAt(startDate);
    setValue('start_date', DateFormatMDY(startDate));
    if (!peopleData) return;

    setValue('user_type', {
      label: peopleTypeEnum[peopleData?.user_type],
      value: peopleData?.user_type,
    });

    if (peopleData?.user_type === 'employee') {
      setValue('is_active_employee', {
        label: peopleData?.is_active_employee ? 'Active' : 'Inactive',
        value: peopleData?.is_active_employee,
      });
    }

    setValue('region', {
      label: peopleData?.region?.name,
      value: peopleData?.region?.id,
    });

    const plants = peopleData?.plants.map((res: any) => {
      return {
        label: res?.name,
        value: res?.id,
      };
    });
    setValue('plants', plants);
    setPlantPermission(plants);

    peopleData?.region &&
      setValue('region', {
        label:
          peopleData?.region?.name &&
          capitalizeFirstLetter(peopleData?.region?.name),
        value: peopleData?.region?.id,
      });

    peopleData?.role &&
      setValue('role', {
        label:
          peopleData?.role?.name &&
          peopleData?.role?.name
            .toLowerCase()
            .split('_')
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
        value: peopleData?.role?.id,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleData, setValue]);

  const handleStartDateChange = (date: Date) => {
    setStartDateCreatedAt(date);
    setValue('start_date', DateFormatMDY(date));
  };

  const getEmailField = () => {
    if (peopleData?.email)
      return (
        <>
          <Input
            type="hidden"
            value={peopleData?.email}
            {...register('email')}
          />
          <Input type="text" defaultValue={peopleData?.email} disabled />
        </>
      );
    else
      return (
        <Input
          {...register('email', {
            required: 'Email',
          })}
          type="email"
          defaultValue={peopleData?.email}
          placeholder={'Email'}
        />
      );
  };

  return (
    <>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.first_name} isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('first_name', {
                  required: 'First Name',
                  maxLength: {
                    value: 100,
                    message: 'Too many characters.',
                  },
                })}
                type="text"
                defaultValue={peopleData?.first_name}
                placeholder={'First Name'}
              />
              <FormErrorMessage>
                {errors?.first_name && errors?.first_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.last_name} isRequired>
              <FormLabel>{'Last Name'}</FormLabel>
              <Input
                {...register('last_name', {
                  required: 'Last Name',
                })}
                type="text"
                defaultValue={peopleData?.last_name}
                placeholder={'Last Name'}
              />
              <FormErrorMessage>
                {errors?.last_name && errors?.last_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.email} isRequired>
              <FormLabel>{'Email'}</FormLabel>
              {getEmailField()}
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.company_name}>
              <FormLabel>{'Company Name'}</FormLabel>
              <Input
                {...register('company_name', {
                  required: false,
                })}
                type="text"
                defaultValue={peopleData?.company_name}
                placeholder={'Company Name'}
              />
              <FormErrorMessage>
                {errors?.company_name && errors?.company_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.user_type} isRequired>
              <FormLabel>{'Type'}</FormLabel>
              <Controller
                control={control}
                name="user_type"
                rules={{
                  required: 'Type',
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="user_type"
                    ref={userTypeRef}
                    placeholder={'Type'}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                    }}
                    options={PeopleTypeList}
                    styles={reactSelectStyles}
                    isSearchable={true}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.user_type && errors?.user_type?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.ein_ssn}>
              <FormLabel>{'EIN/SSN'}</FormLabel>
              <Input
                {...register('ein_ssn', {
                  required: false,
                })}
                type="text"
                defaultValue={peopleData?.ein_ssn}
                placeholder={'EIN/SSN'}
              />
              <FormErrorMessage>
                {errors?.ein_ssn && errors?.ein_ssn?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.address} isRequired>
              <FormLabel>{'Address'}</FormLabel>
              <Input
                {...register('address', {
                  required: 'Address',
                })}
                type="text"
                defaultValue={peopleData?.address}
                placeholder={'Address'}
              />
              <FormErrorMessage>
                {errors?.address && errors?.address?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.phone} isRequired>
              <FormLabel>{'Phone'}</FormLabel>
              <Input
                {...register('phone', {
                  required: 'Phone',
                })}
                type="text"
                defaultValue={peopleData?.phone}
                placeholder={'Phone'}
              />
              <FormErrorMessage>
                {errors?.phone && errors?.phone?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.region} isRequired>
              <FormLabel>{'Region'}</FormLabel>
              <Controller
                control={control}
                name="region"
                rules={{
                  required: 'Region',
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="region"
                    placeholder={'Region'}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                    }}
                    isLoading={isRegionLoading}
                    options={regionOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                    menuPortalTarget={document.body}
                  />
                )}
              />
              <FormErrorMessage>
                {errors?.region && errors?.region?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.note}>
              <FormLabel>{'Note'}</FormLabel>
              <Textarea
                {...register('note', {
                  required: false,
                })}
                defaultValue={peopleData?.note}
                placeholder={'Note...'}
              />
              <FormErrorMessage>
                {errors?.note && errors?.note?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          {userTypeRef?.current?.value === 'employee' && (
            <>
              <GridItem>
                <FormControl
                  isInvalid={!!errors?.role}
                  isRequired={
                    userTypeRef?.current?.value === 'employee' ? true : false
                  }>
                  <FormLabel>{'Role'}</FormLabel>
                  <Controller
                    control={control}
                    name="role"
                    rules={{
                      required:
                        userTypeRef?.current?.value === 'employee'
                          ? true
                          : false,
                    }}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        id="role"
                        ref={roleRef}
                        placeholder={'Role'}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        isLoading={isRoleLoading}
                        options={roleOptions}
                        styles={reactSelectStyles}
                        isSearchable={true}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors?.role && errors?.role?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isInvalid={!!errors?.start_date}
                  isRequired={
                    userTypeRef?.current?.value === 'employee' ? true : false
                  }>
                  <FormLabel>Start Date</FormLabel>
                  <Controller
                    control={control}
                    name="start_date"
                    rules={{
                      required:
                        userTypeRef?.current?.value === 'employee'
                          ? true
                          : false,
                      // validate: (startDate) => {if(endDateCreatedAt) return startDate < endDateCreatedAt}
                    }}
                    render={({ field }) => (
                      <ReactDatePicker
                        id="start_date"
                        fixedHeight
                        customInput={<CreatedAtInput />}
                        selected={startDateCreatedAt}
                        onChange={handleStartDateChange}
                        value={DateFormatMDY(startDateCreatedAt)}
                        shouldCloseOnSelect={true}
                        autoComplete="off"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors?.start_date && errors?.start_date?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isInvalid={!!errors?.is_active_employee}>
                  <FormLabel>{'Status'}</FormLabel>
                  <Controller
                    control={control}
                    name="is_active_employee"
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        id="is_active_employee"
                        placeholder={'Status'}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                        }}
                        isLoading={isPlantLoading}
                        options={[
                          {
                            value: 'true',
                            label: 'Active',
                          },
                          {
                            value: 'false',
                            label: 'Inactive',
                          },
                        ]}
                        styles={reactSelectStyles}
                        menuPortalTarget={document.body}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors?.is_active_employee &&
                      errors?.is_active_employee?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
              {userTypeRef?.current?.value === 'employee' &&
                roleRef?.current?.value !== 1 &&
                roleRef?.current?.value !== undefined && (
                  <>
                    <GridItem>
                      <FormControl
                        isInvalid={!!errors?.plants}
                        isRequired={
                          roleRef?.current?.value === 1 ? false : true
                        }>
                        <FormLabel>{'Plants'}</FormLabel>
                        <Controller
                          control={control}
                          name="plants"
                          rules={{
                            required:
                              roleRef?.current?.value === 1 ? false : true,
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="plants"
                              placeholder={'Plants'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                handlePlantChange(selectedOption);
                              }}
                              isLoading={isPlantLoading}
                              options={plantOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              isMulti
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {errors?.plants && errors?.plants?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </>
                )}
            </>
          )}
        </Grid>
      </Stack>
      {userTypeRef?.current?.value === 'employee' &&
        roleRef?.current?.value !== 1 &&
        roleRef?.current?.value !== 1 && (
          <UserRightsManager
            plantPermission={plantPermission}
            permissions={permissions}
          />
        )}
    </>
  );
};

export default PeopleForm;
