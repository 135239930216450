import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from './auth';
import offline from './offline';
import report from './report';

/**
 * Persist Auth Reducer.
 */
const authPersistConfig = {
  key: 'auth',
  storage: storage,
};

/**
 * Persist Report Reducer.
 */
const reportPersistConfig = {
  key: 'report',
  storage: storage,
};

const offlinePersistConfig = {
  key: 'offline',
  storage,
};
export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  report: persistReducer(reportPersistConfig, report),
  offline: persistReducer(offlinePersistConfig, offline),
});
