const routes = {
  dashboard: '/',
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password*',
    setPassword: '/set-password',
    verifyEmail: '/verify-email',
    register: '/register',
  },
  settings: {
    base: '/settings/*',
    index: '/settings/index',
  },
  inventories: {
    base: '/inventories/*',
    list: '/inventories/list',
    view: '/inventories/view/:id',
  },
  productions: {
    base: '/productions/*',
    list: '/productions/list',
    view: '/productions/view/:id',
  },
  adjustments: {
    base: '/adjustments/*',
    list: '/adjustments/list',
  },
  manage: {
    base: '/manage/*',
    pits: {
      list: '/manage/pits/list',
      create: '/manage/pits/create',
      edit: '/manage/pits/edit/:id',
      view: '/manage/pits/view/:id',
    },
    plants: {
      list: '/manage/plants/list',
      create: '/manage/plants/create',
      edit: '/manage/plants/edit/:id',
      view: '/manage/plants/view/:id',
    },
    users: {
      list: '/manage/users/list',
      create: '/manage/users/create',
      edit: '/manage/users/edit/:id',
      view: '/manage/users/view/:id',
    },
    peoples: {
      list: '/manage/peoples/list',
      create: '/manage/peoples/create',
      edit: '/manage/peoples/edit/:id',
      view: '/manage/peoples/view/:id',
    },
    equipments: {
      list: '/manage/equipments/list',
      create: '/manage/equipments/create',
      edit: '/manage/equipments/edit/:id',
      view: '/manage/equipments/view/:id',
    },
    products: {
      list: '/manage/products/list',
      create: '/manage/products/create',
      edit: '/manage/products/edit/:id',
      view: '/manage/products/view/:id',
    },
    shiftDownTimeCode: {
      list: '/manage/shift-downtime-code/list',
      create: '/manage/shift-downtime-code/create',
      edit: '/manage/shift-downtime-code/edit/:id',
      view: '/manage/shift-downtime-code/view/:id',
    },
    operationDownTimCode: {
      list: '/manage/operation-downtime-code/list',
      create: '/manage/operation-downtime-code/create',
      edit: '/manage/operation-downtime-code/edit/:id',
      view: '/manage/operation-downtime-code/view/:id',
    },
    activity: {
      list: '/manage/activities/list',
      create: '/manage/activities/create',
      edit: '/manage/activities/edit/:id',
      view: '/manage/activities/view/:id',
    },
  },
  afe: {
    base: '/afe/*',
    list: '/afe/list',
    view: '/afe/view/:id',
  },
  sales: {
    base: '/sales/*',
    list: '/sales/list',
    view: '/sales/view/:id',
    edit: '/sales/edit/:id',
    create: '/sales/create',
  },
  companies: {
    base: '/companies/*',
    list: '/companies/list',
    create: '/companies/create',
    edit: '/companies/edit/:id',
    view: '/companies/view/:id',
  },
  dailyreport: {
    base: '/daily-report',
  },
  reports: {
    base: '/reports/*',
    mechanicalReport: {
      list: '/reports/mechanic',
      operationDetailReport: '/reports/mechanic/operation-detail',
      laborReport: '/reports/mechanic/labor',
      partsReport: '/reports/mechanic/parts',
      equipmentRepairReport: '/reports/mechanic/equipment-repair',
      assignedEquipmentReport: '/reports/mechanic/assigned-equipment',
      rentalReport: '/reports/mechanic/rental',
      equipmentDamageReport: '/reports/mechanic/equipment-damage',
      noteReport: '/reports/mechanic/notes',
    },
    foremanReport: {
      list: '/reports/foreman',
      operationDetailReport: '/reports/foreman/operation-detail',
      laborReport: '/reports/foreman/labor',
      productionReport: '/reports/foreman/production',
      capitalEquipmentReport: '/reports/foreman/capital-equipment',
      ShiftDownTimeReport: '/reports/foreman/shift-downtime',
      fuelUsageReport: '/reports/foreman/fuel-usage',
      loadoutReport: '/reports/foreman/loadout',
      rentalReport: '/reports/foreman/rental',
      equipmentDamageReport: '/reports/foreman/equipment-damage',
      noteReport: '/reports/foreman/notes',
    },
  },
};

export default routes;
