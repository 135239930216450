import Resource from 'api/resource';
import http from 'utils/http';

class SaleResource extends Resource {
  constructor() {
    super('sales');
  }

  bulkDelete(resource: any) {
    return http({
      url: '/bulk-destroy/sales',
      method: 'post',
      data: resource,
    });
  }

  customer(query?: any) {
    return http({
      url: '/list-sales-customers',
      method: 'get',
      params: query,
    });
  }
}

export { SaleResource as default };
