import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import AFEResource from 'api/afe';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AFEProductPriceForm from './AFEProductPriceForm';

const AFEProductPriceListItem: React.FC<any> = (props) => {
  const { listData, afeId } = props;
  const navigate = useNavigate();
  const api = new AFEResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [errMsgEdit, setErrMsgEdit] = useState<any>();

  const methods = useForm<any>();

  const deleteData = useMutation(
    () => api.destroyProductPrice(afeId, listData.id),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('afeProductPriceList');

        if (res.data[0].success) {
          setDeleteModalOpen(false);
          toast({
            title: 'AFE Product Price has been deleted.',
            status: 'success',
            isClosable: true,
          });
        }
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  const onEditPress = () => {
    setErrMsgEdit('');
    setEditModalOpen(true);
  };

  const onEditModalClose = () => {
    setErrMsgEdit('');
    setEditModalOpen(false);
  };

  const updateProductPrice = useMutation(
    (data: any) => api.updateProductPrice(afeId, listData.id, data),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Product Price has been updated.',
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('afeProductPriceList');
        setErrMsgEdit('');
        setEditModalOpen(false);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrMsgEdit(error?.response?.data[objKey[0]][0]);
        else setErrMsgEdit('Update Failed.');
      },
    }
  );

  const onSubmit = (data: any) => {
    const formData = {
      product: data?.product?.value,
      price: data?.price || 0,
    };
    updateProductPrice.mutate(formData);
  };

  return (
    <Tr key={listData.id}>
      <Td>
        {`${listData?.product?.class_code} - ${listData?.product?.name}` || '-'}
      </Td>
      <Td>{listData?.price}</Td>
      <Td>
        <Tooltip hasArrow label={'Edit'}>
          <IconButton
            icon={<BiEdit />}
            variant="link"
            aria-label={'Edit'}
            color="blue.300"
            size={'lg'}
            onClick={onEditPress}
          />
        </Tooltip>

        <Tooltip hasArrow label={'Delete'}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={'Delete'}
            color="red.300"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete AFE Product Price'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete this ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isEditModalOpen} isCentered onClose={onEditModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Edit AFE Product Price'}</ModalHeader>
            <ModalCloseButton />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack direction="column" spacing="4">
                  {errMsgEdit && (
                    <Alert status="error">
                      <AlertIcon />
                      {errMsgEdit}
                    </Alert>
                  )}
                  <ModalBody>
                    <AFEProductPriceForm listData={listData} />
                  </ModalBody>
                  <ModalFooter>
                    <ButtonGroup>
                      <Button
                        colorScheme="blue"
                        type="submit"
                        isLoading={updateProductPrice.isLoading}>
                        {'Update'}
                      </Button>

                      <Button variant="outline" onClick={onEditModalClose}>
                        {'Cancel'}
                      </Button>
                    </ButtonGroup>
                  </ModalFooter>
                </Stack>
              </form>
            </FormProvider>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default AFEProductPriceListItem;
