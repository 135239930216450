import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PeopleResource from 'api/user';
import PeopleForm from 'components/people/PeopleForm';
import routes from 'constants/routes';
import { PeopleSchema } from 'interface/people/peopleListType';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const CreatePeople: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const navigate = useNavigate();
  const methods = useForm<PeopleSchema>();
  const api = new PeopleResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addPeople = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'People has been created.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('peopleList');
      navigate(routes.manage.peoples.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('People Creation Failed.');
    },
  });

  const onSubmit = (data: any) => {
    let formData: any = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      user_type: data?.user_type?.value,
      ein_ssn: data?.ein_ssn,
      address: data?.address,
      phone: data?.phone,
      region: data?.region?.value,
      note: data?.note || '',
      company_name: data?.company_name,
      is_active_employee: data?.is_active_employee?.value,
    };

    if (data?.user_type?.value === 'employee') {
      formData['role'] = data?.role?.value;
      formData['start_date'] = data?.start_date;
      formData['end_date'] = data?.end_date;
    }

    if (data?.user_type?.value === 'employee' && data?.role?.value !== 1) {
      formData['plants'] = data?.plants?.map((res: any) => +res?.value);
      formData['permissions'] = data?.permissions;
    }
    addPeople.mutate(formData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">Add People</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addPeople.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <PeopleForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addPeople.isLoading}>
                  Add People
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreatePeople;
