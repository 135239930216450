import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';
import ProductResource from 'api/product';
import { reactSelectStyles } from 'assets/css/commonStyles';
import useDebounceFunc from 'hooks/useDebounceFunc';
import {
  ProductFormSchema,
  ProductSchemaType,
} from 'interface/product/productSchema';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const ProductForm: React.FC<ProductSchemaType> = (props) => {
  const { productData } = props;
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ProductFormSchema>();
  // const [classCode, setClassCode] = useState<number | undefined>();
  // const [productGroup, setProductGroup] = useState<string | undefined>();

  // Product Group List
  const productGroupAPI = new ProductResource();
  const { data: productGroupQuery, isLoading: isProductGroupLoading } =
    useQuery(
      [`productGroup-list`],
      () =>
        productGroupAPI.productGroups({ limit: 5000 }).then((res) => res?.data),
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );
  const productGroupList = productGroupQuery?.results;
  const productGroupOptions = productGroupList?.map((productGroup: any) => ({
    label: productGroup.name,
    value: productGroup.id,
  }));

  // Product Name List
  // const productGroupAPI = new ProductResource();
  // const { data: productNameListQuery, isLoading: isProductNameListLoading } =
  //   useQuery(
  //     [`product-name-list`],
  //     () =>
  //       productGroupAPI
  //         .productNameList({ limit: 10000 })
  //         .then((res) => res?.data),
  //     {
  //       cacheTime: 0,
  //       refetchOnWindowFocus: false,
  //     }
  //   );
  // const productNameList = productNameListQuery?.results;
  // const productNameOptions = productNameList?.map((productName: any) => ({
  //   label: productName.name,
  //   value: productName.id,
  // }));

  useEffect(() => {
    if (!productData) return;
    setValue('product_group', {
      label: productData?.product_group?.name,
      value: productData?.product_group?.id,
    });
    setValue('class_code', productData?.class_code);
  }, [productData, setValue]);

  const handleProductCode = (code: number) => {
    const arr = [...`${code + ''}`].map((n, index) => (index === 0 ? n : '0'));
    setValue('class_code', arr.join(''));
  };

  const debounce = useDebounceFunc(handleProductCode, 2000);

  return (
    <Stack direction="row" align="start" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        flex="1">
        <GridItem>
          <FormControl isInvalid={!!errors?.name} isRequired>
            <FormLabel>Product</FormLabel>
            <Input
              {...register('name', {
                required: 'Product Name',
              })}
              type="text"
              defaultValue={productData?.name}
              placeholder={'Product Name'}
            />
            {/* <Controller
              control={control}
              name="name"
              rules={{
                required: 'Product Name',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="name"
                  placeholder={'Product Name'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                    handleProductName(selectedOption);
                  }}
                  isLoading={isProductNameListLoading}
                  options={productNameOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  menuPortalTarget={document.body}
                />
              )}
            /> */}
            <FormErrorMessage>
              {errors?.name && errors?.name?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.product_code} isRequired>
            <FormLabel>Product Code</FormLabel>
            <Input
              {...register('product_code', {
                required: 'Product Code',
              })}
              type="number"
              defaultValue={productData?.product_code || 0}
              placeholder={'Product Code'}
              onKeyUp={(data: any) => debounce(data?.target.value || 0)}
            />
          </FormControl>
          <FormErrorMessage>
            {errors?.product_code && errors?.product_code?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.class_code} isRequired>
            <FormLabel>Class Code</FormLabel>
            <Input
              type="number"
              // disabled
              {...register('class_code', {
                required: 'Class Code',
              })}
            />
          </FormControl>
          <FormErrorMessage>
            {errors?.class_code && errors?.class_code?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.product_group} isRequired>
            <FormLabel>Product Group</FormLabel>
            <Controller
              control={control}
              name="product_group"
              rules={{
                required: 'Product Group',
              }}
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="product_group"
                  placeholder={'Product Group'}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption);
                  }}
                  isLoading={isProductGroupLoading}
                  options={productGroupOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  menuPortalTarget={document.body}
                />
              )}
            />
          </FormControl>
          <FormErrorMessage>
            {errors?.product_group && errors?.product_group?.message}
          </FormErrorMessage>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.price}>
            <FormLabel>Rack Price</FormLabel>
            <Input
              {...register('price', {
                required: false,
              })}
              type="number"
              step="0.01"
              defaultValue={productData?.price || 0}
              placeholder={'Rack Price'}
            />
            <FormErrorMessage>
              {errors?.price && errors?.price?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default ProductForm;
