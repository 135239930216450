import Resource from 'api/resource';
import http from 'utils/http';

class EquipmentDamageReportResource extends Resource {
  constructor() {
    super('equipment-status');
  }

  listEquipmentReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/equipment-status`,
      method: 'get',
      params: query,
    });
  }
  getEquipmentReport(id: string, equipmentStatusId: string) {
    return http({
      url: `/reports/${id}/equipment-status/${equipmentStatusId}`,
      method: 'get',
    });
  }
  storeEquipmentReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/equipment-status`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateEquipmentReport(id: string, resource: any) {
    return http({
      url: `/reports/${id}/equipment-status`,
      method: 'put',
      data: resource,
    });
  }
  destroyEquipmentReport(id: string, equipmentStatusId: string) {
    return http({
      url: `/reports/${id}/equipment-status/${equipmentStatusId}`,
      method: 'delete',
    });
  }

  listEquipmentDetailReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/equipment-status-detail`,
      method: 'get',
      params: query,
    });
  }
  getEquipmentDetailReport(id: string, equipmentStatusDetailId: string) {
    return http({
      url: `/reports/${id}/equipment-status-detail/${equipmentStatusDetailId}`,
      method: 'get',
    });
  }
  storeEquipmentDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/equipment-status-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateEquipmentDetailReport(
    id: string,
    equipmentStatusDetailId: string,
    resource: any
  ) {
    return http({
      url: `/reports/${id}/equipment-status-detail/${equipmentStatusDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyEquipmentDetailReport(id: string, equipmentStatusDetailId: string) {
    return http({
      url: `/reports/${id}/equipment-status-detail/${equipmentStatusDetailId}`,
      method: 'delete',
    });
  }

  // Mechanical Equipment Damage Report
  mechanicalListEquipmentReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetEquipmentReport(id: string, equipmentStatusId: string) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status/${equipmentStatusId}`,
      method: 'get',
    });
  }
  mechanicalStoreEquipmentReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdateEquipmentReport(id: string, resource: any) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyEquipmentReport(id: string, equipmentStatusId: string) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status/${equipmentStatusId}`,
      method: 'delete',
    });
  }

  mechanicalListEquipmentDetailReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status-detail`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetEquipmentDetailReport(
    id: string,
    equipmentStatusDetailId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status-detail/${equipmentStatusDetailId}`,
      method: 'get',
    });
  }
  mechanicalStoreEquipmentDetailReport(
    id: string,
    resource: any,
    headers?: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdateEquipmentDetailReport(
    id: string,
    equipmentStatusDetailId: string,
    resource: any
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status-detail/${equipmentStatusDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyEquipmentDetailReport(
    id: string,
    equipmentStatusDetailId: string
  ) {
    return http({
      url: `/mechanic-reports/${id}/equipment-status-detail/${equipmentStatusDetailId}`,
      method: 'delete',
    });
  }
}

export { EquipmentDamageReportResource as default };
