import Resource from 'api/resource';
import http from 'utils/http';

class PartsReportResource extends Resource {
  constructor() {
    super('reports');
  }

  listPartReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/parts`,
      method: 'get',
      params: query,
    });
  }
  getPartReport(id: string, partId: string) {
    return http({
      url: `/mechanic-reports/${id}/parts/${partId}`,
      method: 'get',
    });
  }
  storePartReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/mechanic-reports/${id}/parts`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updatePartReport(id: string, partId: string, resource: any) {
    return http({
      url: `/mechanic-reports/${id}/parts/${partId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyPartReport(id: string, partId: string) {
    return http({
      url: `/mechanic-reports/${id}/parts/${partId}`,
      method: 'delete',
    });
  }

  listPartDetailReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/part-report-details`,
      method: 'get',
      params: query,
    });
  }
  getPartDetailReport(id: string, partDetailId: string) {
    return http({
      url: `/mechanic-reports/${id}/part-report-details/${partDetailId}`,
      method: 'get',
    });
  }
  storePartDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/mechanic-reports/${id}/part-report-details`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updatePartDetailReport(id: string, resource: any) {
    return http({
      url: `/mechanic-reports/${id}/part-report-details`,
      method: 'put',
      data: resource,
    });
  }
  destroyPartDetailReport(id: string, partDetailId: string) {
    return http({
      url: `/mechanic-reports/${id}/part-report-details/${partDetailId}`,
      method: 'delete',
    });
  }
}

export { PartsReportResource as default };
