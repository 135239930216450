import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import ReportResource from 'api/report';
import routes from 'constants/routes';
import { ReportListInterface } from 'interface/report/reportSchema';
import React, { useState } from 'react';
import { BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

const ReportListItem: React.FC<ReportListInterface> = (props) => {
  const { listData } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const api = new ReportResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteData = useMutation(
    () =>
      api.delete(
        listData.id,
        listData.report === 'mechanic' ? 'mechanic-reports' : 'reports'
      ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('total-report-list');

        if (res.data[0].success) {
          setDeleteModalOpen(false);
          toast({
            title: 'Report has been deleted.',
            status: 'success',
            isClosable: true,
          });
        }
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  return (
    <Tr key={listData.id}>
      <Td>{listData?.plant?.name}</Td>
      <Td>{listData?.pit?.name}</Td>
      <Td>{listData?.shift?.name}</Td>
      <Td>{listData?.shift_hours}</Td>
      <Td>{listData.is_submitted ? 'Complete' : 'In Progress'}</Td>
      <Td>{listData?.report?.toUpperCase()}</Td>
      <Td>
        <Link
          to={
            listData?.report === 'mechanic'
              ? routes.reports.mechanicalReport.list +
                '/?report_id=' +
                listData?.id
              : routes.reports.foremanReport.list +
                '/?report_id=' +
                listData?.id
          }>
          <Tooltip hasArrow label={'View'}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={'View'}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </Link>
        <Tooltip hasArrow label={'Delete'}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={'Delete'}
            color="red.300"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete User'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete this ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default ReportListItem;
