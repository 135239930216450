import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Logo } from 'assets/images';
import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import React, { useState } from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import { BiLogOut } from 'react-icons/bi';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HideControl from 'services/HideControl';

interface Props {
  logout: () => void;
}
interface Props {
  logout: () => void;
}

const Nav: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setModalOpen] = useState(false);
  const [report, setReport] = useState('');

  let navigate = useNavigate();
  const { logout } = props;

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const onLogoutClick = async () => {
    try {
      logout();
    } catch (err) {}
  };

  const handleReport = () => {
    switch (loggedInUser?.role) {
      case 'company_admin':
        setModalOpen(true);
        break;
      case 'mechanic':
        navigate(routes.reports.mechanicalReport.list);
        break;
      case 'foreman':
        navigate(routes.reports.foremanReport.list);
        break;
      default:
        setModalOpen(true);
    }
  };

  const onModalSubmit = () => {
    switch (report) {
      case 'mechanical':
        setReport('');
        setModalOpen(false);
        navigate(routes.reports.mechanicalReport.list);
        break;
      case 'foreman':
        setReport('');
        setModalOpen(false);
        navigate(routes.reports.foremanReport.list);
        break;
      default:
        setModalOpen(true);
    }
  };

  return (
    <>
      <Box bg={'#393A3B'} px={4}>
        <Flex
          h={20}
          alignItems={'center'}
          justifyContent={'space-between'}
          color={'#fff'}>
          <IconButton
            bg={'#393A3B'}
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />

          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <Image src={Logo} w={[56, 60, 60, 72]} />
            </Box>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
              <HideControl
                hideFor="dashboard-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.dashboard)}>
                  Dashboard
                </Link>
              </HideControl>
              <HideControl
                hideFor="manage-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.manage.peoples.list)}>
                  Manage
                </Link>
              </HideControl>
              <HideControl
                hideFor="production-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.productions.list)}>
                  Production
                </Link>
              </HideControl>
              <HideControl
                hideFor="inventory-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.inventories.list)}>
                  Inventory
                </Link>
              </HideControl>
              <HideControl
                hideFor="sale-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.sales.list)}>
                  Sales
                </Link>
              </HideControl>
              <HideControl
                hideFor="afe-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.afe.list)}>
                  AFE
                </Link>
              </HideControl>
              <HideControl
                hideFor="report-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={handleReport}>
                  Reports
                </Link>
              </HideControl>
              <HideControl
                hideFor="company-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.companies.list)}>
                  Companies
                </Link>
              </HideControl>
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  name={`${loggedInUser?.first_name} ${loggedInUser?.last_name}`}
                />
              </MenuButton>
              <MenuList color={'#000'}>
                <MenuItem onClick={() => navigate(routes.settings.index)}>
                  <IconButton
                    icon={<AiOutlineSetting />}
                    variant="link"
                    aria-label={'Settings'}
                    color="blue.300"
                    size="lg"
                  />
                  Settings
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={onLogoutClick}>
                  <IconButton
                    icon={<BiLogOut />}
                    variant="link"
                    aria-label={'Settings'}
                    color="blue.300"
                    size="lg"
                  />
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              <HideControl
                hideFor="dashboard-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.dashboard)}>
                  Dashboard
                </Link>
              </HideControl>
              <HideControl
                hideFor="manage-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.manage.peoples.list)}>
                  Manage
                </Link>
              </HideControl>
              <HideControl
                hideFor="production-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.productions.list)}>
                  Production
                </Link>
              </HideControl>
              <HideControl
                hideFor="inventory-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.inventories.list)}>
                  Inventory
                </Link>
              </HideControl>
              <HideControl
                hideFor="sale-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.sales.list)}>
                  Sales
                </Link>
              </HideControl>
              <HideControl
                hideFor="afe-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.afe.list)}>
                  AFE
                </Link>
              </HideControl>
              <HideControl
                hideFor="report-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={handleReport}>
                  Reports
                </Link>
              </HideControl>
              <HideControl
                hideFor="company-list"
                renderNoAccess={(data: any) => {
                  return data ? data : '';
                }}>
                <Link
                  px={2}
                  py={1}
                  color={'white'}
                  rounded={'md'}
                  lineHeight={'16px'}
                  fontWeight={'500'}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(routes.companies.list)}>
                  Companies
                </Link>
              </HideControl>
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Modal
        isOpen={isModalOpen}
        isCentered
        onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Report'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{'Which report do you want to check ?'}</Text>
            <RadioGroup onChange={setReport}>
              <Stack direction="row">
                <Radio value="mechanical">Mechanic</Radio>
                <Radio value="foreman">Foreman</Radio>
              </Stack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="red" onClick={onModalSubmit}>
                {'Ok'}
              </Button>

              <Button variant="outline" onClick={() => setModalOpen(false)}>
                {'Cancel'}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default withAuthState(Nav);
