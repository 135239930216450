import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import PartsReportResource from 'api/partsReport';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { PMTTypeEnum, PMTTypeList } from 'constants/common';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useDebounceFunc from 'hooks/useDebounceFunc';
import { ReportProps } from 'interface/report/reportSchema';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import { v4 as uuid } from "uuid";

const PartsReport: React.FC<ReportProps> = (props) => {
  const { setReportBody, updateQueue, reportBody } = useContext(OfflineContext);
  const offlineVendorList = useSelector((state: any) => state.data.offline.vendors)
  const offlineEquipmentList = useSelector((state: any) => state.data.offline.equipments)
  const [offlinePartsFilteredByPreviousReportId, setOfflinePartsFilteredByPreviousReportId] = useState<any>([]);
  const isOnline = useConnectionStatus();
  const { reportId } = props;
  const containerRef = useRef<any>();
  const api = new PartsReportResource();
  const methods = useForm<any>();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const pmtTypeEnum: any = PMTTypeEnum;
  const [tagData, setTagData] = useState(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);

  const { vendorList, equipmentList } = useSelector(
    (state: any) => ({
      vendorList: state?.data?.report?.vendorList,
      equipmentList: state?.data?.report?.equipmentList,
    }),
    shallowEqual
  );

  const partsQuery = useQuery([`getPartsStatus`], async () => {
    const response = await api.listPartDetailReport(reportId, {
      limit: 5000,
    });
    return response?.data;
  });

  const { data: partsListQuery } = useQuery(
    [`parts-list`],
    () =>
      api
        .listPartReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          return res?.data?.results;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const partsList = useMemo(() => isOnline
    ? partsListQuery?.length > 0
      ? partsListQuery
      : []
    : offlinePartsFilteredByPreviousReportId, [offlinePartsFilteredByPreviousReportId, partsListQuery])

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/parts')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev, parts: offlinePartsFilteredByPreviousReportId
    }))
    // update the queue
    updateQueue(reportBody.id, { ...reportBody, parts: offlinePartsFilteredByPreviousReportId })
  }, [offlinePartsFilteredByPreviousReportId])

  const addData = useMutation(
    (data: any) => api.storePartReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('parts-list');
        setAddModalOpen(false);
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      vendor: data?.vendor?.value,
      equipment: data?.equipment?.value,
      cost: data?.cost,
      pmt_type: data?.pmt_type?.value,
      description: data?.description,
    };
    if (isOnline) {
      addData.mutate(formData);
    } else {
      setOfflinePartsFilteredByPreviousReportId((prev: any) => ([...prev, {
        ...formData,
        vendor: {
          id: data?.vendor?.value,
          name: data?.vendor?.label,
          first_name: data?.vendor?.first_name,
          last_name: data?.vendor?.last_name
        },
        equipment: { id: data?.equipment?.value, name: data?.equipment?.label },
        pmt_type: { id: data?.pmt_type?.value, name: data?.pmt_type?.label },
        cost: data?.cost,
        description: data?.description,
        id: uuid()
      }]))
    }
    onAddModalClose();
  };

  const updateData = useMutation(
    (data: any) => api.updatePartReport(reportId, data?.id, data?.body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('parts-list');
      },
    }
  );

  const handleChange = (
    fieldData: any,
    fieldValue: string,
    fieldType: string
  ) => {
    let data: any = {
      body: {
        fuel: '',
        hours: '',
        cost: 0,
        equipment: 0,
        pmt_type: 0,
        description: '',
        vendor: 0,
      },
      id: 0,
    };
    data.id = fieldData.id;
    data.body.note = fieldData?.note || '';
    switch (fieldType) {
      case 'equipment':
        data.body.vendor = fieldData?.vendor?.value;
        data.body.equipment = +fieldValue;
        data.body.cost = fieldData?.cost;
        data.body.pmt_type = fieldData?.equipment?.value;
        data.body.description = fieldData?.description;
        break;
      case 'vendor':
        data.body.vendor = +fieldValue;
        data.body.equipment = fieldData?.equipment?.value;
        data.body.cost = fieldData?.cost;
        data.body.pmt_type = fieldData?.equipment?.value;
        data.body.description = fieldData?.description;
        break;
      case 'description':
        data.body.vendor = fieldData?.vendor?.value;
        data.body.equipment = fieldData?.equipment?.value;
        data.body.cost = fieldData?.cost;
        data.body.pmt_type = fieldData?.equipment?.value;
        data.body.description = fieldValue;
        break;
      case 'cost':
        data.body.vendor = fieldData?.vendor?.value;
        data.body.equipment = fieldData?.equipment?.value;
        data.body.cost = fieldValue;
        data.body.pmt_type = fieldData?.equipment?.value;
        data.body.description = fieldData?.description;
        break;
      case 'pmt_type':
        data.body.vendor = fieldData?.vendor?.value;
        data.body.equipment = fieldData?.equipment?.value;
        data.body.cost = fieldData?.cost;
        data.body.pmt_type = +fieldValue;
        data.body.description = fieldData?.description;
        break;
    }
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflinePartsFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          switch (fieldType) {
            case 'description':
              return {
                ...r,
                description: fieldValue,
              }
            case 'cost':
              return {
                ...r,
                cost: fieldValue
              }
            case 'pmt_type':
              const _pmt_type = PMTTypeList.find((elem) => elem.value === fieldValue);
              return {
                ...r,
                pmt_type: _pmt_type ? { id: _pmt_type.value, name: _pmt_type.label } : r.pmt_type
              }
            case 'equipment':
              const _equipment = offlineEquipmentList.equipments.find((elem: any) => elem.value === fieldValue);
              return {
                ...r,
                equipment: _equipment ? { id: _equipment.value, name: _equipment.label } : r.equipment
              }
            case 'vendor':
              const _vendor = offlineVendorList.vendors.find((elem: any) => elem.value === fieldValue);
              return {
                ...r,
                vendor: _vendor ? { id: _vendor.value, name: _vendor.label } : r.vendor
              }
            default:
              return r;
          }
        } else {
          return r;
        }
      }))
    }
  };

  const debounce = useDebounceFunc(handleChange, 3000);

  const handleDetailDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: number) => api.destroyPartReport(reportId, id + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('parts-list');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (isOnline) {
      if (id != 0) deleteData.mutate(id);
    } else {
      setOfflinePartsFilteredByPreviousReportId((prev: any) => prev.filter((r: any) => r.id !== id))
    }
    onDeleteModalClose();
  };

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        vendor: fieldData?.vendor?.id,
        equipment: fieldData?.equipment?.id,
        cost: fieldData?.cost,
        pmt_type: fieldData?.pmt_type,
        description: fieldData?.description,
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflinePartsFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          return {
            ...r,
            note: modalValue.note
          }
        } else {
          return r;
        }
      }))
    }
    setNoteDetailModalOpen(false);
  };

  const handleNoteDetailModal = (parts: any) => {
    setNoteDetailFieldData(parts);
    setNoteDetailModalOpen(true);
  };

  const handleNoteModal = (parts: any) => {
    setNoteModalOpen(true);
  };

  const updatePartsData = useMutation(
    (data: any) => api.updatePartDetailReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getPartsStatus');
      },
    }
  );

  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      note: modalValue.note,
    };
    updatePartsData.mutate(data);
    setNoteModalOpen(false);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('getPartsStatus');
    queryClient.invalidateQueries('parts-list');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Parts</Heading>
          <Stack direction="row" spacing="6">
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() => handleTagModal(partsQuery?.data, 'tagStatus')}
              />
            </Tooltip>
            {tagData === partsQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={partsQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
              />
            )}
            <Tooltip hasArrow label={partsQuery?.data?.note}>
              <IconButton
                icon={<MdOutlineModeComment />}
                variant="link"
                aria-label={'Note'}
                color={partsQuery?.data?.note ? 'primary.600' : 'gray.300'}
                size="lg"
                onClick={() => handleNoteModal(partsQuery?.data)}
              />
            </Tooltip>
            <NoteModal
              isModalOpen={noteModalOpen}
              setModalOpen={setNoteModalOpen}
              fieldData={partsQuery?.data}
              onModalSubmit={(notes: string) =>
                onNoteModalSubmit(notes, partsQuery?.data)
              }
              formMutation={addData}
              errorMessage={errorMessage}
            />
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant={'simple'}>
              <Tbody>
                <Tr bg={'gray.300'}>
                  <Td>Equipment</Td>
                  <Td>Vendor</Td>
                  <Td>Part Description</Td>
                  <Td>Cost</Td>
                  <Td>PMT Type</Td>
                  <Td></Td>
                </Tr>
                {partsList?.map((parts: any) => (
                  <React.Fragment key={parts?.id}>
                    <Tr>
                      <Td>
                        <ReactSelect
                          defaultValue={{
                            value: parts?.equipment?.id,
                            label: parts?.equipment?.name,
                          }}
                          placeholder={'Select Equipment'}
                          onChange={(e: any) => {
                            handleChange(parts, e.value, 'equipment');
                          }}
                          isLoading={isOnline ? equipmentList?.isLoading : offlineEquipmentList.isLoading}
                          options={isOnline ? equipmentList.equipmentOptions : offlineEquipmentList.equipments}
                          styles={reactSelectStyles}
                          isSearchable={true}
                          menuPortalTarget={document.body}
                        />
                      </Td>
                      <Td>
                        <ReactSelect
                          defaultValue={{
                            value: parts?.vendor?.id,
                            label: `${parts?.vendor?.first_name} ${parts?.vendor?.last_name}`,
                          }}
                          placeholder={'Select Vendor'}
                          onChange={(e: any) => {
                            handleChange(parts, e.value, 'vendor');
                          }}
                          isLoading={isOnline ? vendorList.isVendorLoading : offlineVendorList.isLoading}
                          options={isOnline ? vendorList.vendorOptions : offlineVendorList.vendors}
                          styles={reactSelectStyles}
                          isSearchable={true}
                          menuPortalTarget={document.body}
                        />
                      </Td>
                      <Td>
                        <Input
                          defaultValue={parts?.description}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChange(parts, e.target.value, 'description');
                          }}
                          type="text"
                          placeholder="Part Description"
                        />
                      </Td>
                      <Td>
                        <Input
                          defaultValue={parts?.cost}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            debounce(parts, e.target.value, 'cost')
                          }
                          type="number"
                          step="0.01"
                          placeholder="Cost"
                        />
                      </Td>
                      <Td>
                        <ReactSelect
                          defaultValue={{
                            value: isOnline ? parts?.pmt_type : parts?.pmt_type?.id,
                            label: isOnline ?
                              pmtTypeEnum[
                                parts?.pmt_type
                              ]?.toString() : parts?.pmt_type?.name,
                          }}
                          placeholder={'Select PMT Type'}
                          onChange={(e: any) => {
                            handleChange(parts, e.value, 'pmt_type');
                          }}
                          options={PMTTypeList}
                          styles={reactSelectStyles}
                          isSearchable={true}
                          menuPortalTarget={document.body}
                        />
                      </Td>
                      <Td>
                        <Tooltip hasArrow label={'Tag'}>
                          <IconButton
                            icon={<BsTag />}
                            variant="link"
                            aria-label={'Tag'}
                            color={'gray.300'}
                            size="lg"
                            onClick={() => handleTagModal(parts, 'tagDetail')}
                          />
                        </Tooltip>
                        {tagData === parts?.tag && (
                          <TagModal
                            isModalOpen={tagDetailModalOpen}
                            setModalOpen={setTagDetailModalOpen}
                            fieldData={tagData}
                            onModalSubmit={() => onTagModalSubmit()}
                          />
                        )}
                        <Tooltip hasArrow label={parts?.note}>
                          <IconButton
                            icon={<MdOutlineModeComment />}
                            variant="link"
                            aria-label={'Note'}
                            color={parts?.note ? 'primary.600' : 'gray.300'}
                            size="lg"
                            onClick={() => handleNoteDetailModal(parts)}
                          />
                        </Tooltip>
                        <NoteModal
                          isModalOpen={noteDetailModalOpen}
                          setModalOpen={setNoteDetailModalOpen}
                          fieldData={noteDetailFieldData}
                          onModalSubmit={(notes: string) =>
                            onNoteDetailModalSubmit(notes, noteDetailFieldData)
                          }
                          formMutation={updateData}
                          errorMessage={errorMessage}
                        />
                        <Tooltip hasArrow label={'Delete'}>
                          <IconButton
                            icon={<BsTrash />}
                            variant="link"
                            aria-label={'Delete'}
                            color="gray.300"
                            size="lg"
                            onClick={() => onDeleteConfirm(parts?.id)}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>

                    <Modal
                      isOpen={isDeleteModalOpen}
                      isCentered
                      onClose={onDeleteModalClose}>
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>{'Delete Parts'}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                          {'Are you sure you want to delete this ?'}
                          {deleteMsg && (
                            <Alert status="error" mb={2}>
                              <AlertIcon />
                              {deleteMsg}
                            </Alert>
                          )}
                        </ModalBody>
                        <ModalFooter>
                          <ButtonGroup>
                            <Button
                              colorScheme="red"
                              onClick={() => onDeleteConfirm(confirmDeleteId)}
                              isLoading={deleteData.isLoading}>
                              {'Delete'}
                            </Button>

                            <Button
                              variant="outline"
                              onClick={onDeleteModalClose}>
                              {'Cancel'}
                            </Button>
                          </ButtonGroup>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
            {isOnline && !partsListQuery && <CenterSpinner type="normal" />}
          </TableContainer>
        </Stack>

        {/* Add Modal */}
        <Modal
          isOpen={isAddModalOpen}
          isCentered
          onClose={onAddModalClose}
          closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Add Parts'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Stack direction="column" spacing="4">
                    {addData.isError && (
                      <Alert status="error">
                        <AlertIcon />
                        {errorMessage}
                      </Alert>
                    )}
                    <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods?.formState?.errors?.type}
                          isRequired>
                          <FormLabel>Equipment</FormLabel>
                          <Controller
                            control={methods.control}
                            name="equipment"
                            rules={{
                              required: 'Equipment',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="equipment"
                                placeholder={'Equipment'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline ? equipmentList?.isLoading : offlineEquipmentList.isLoading}
                                options={isOnline ? equipmentList.equipmentOptions : offlineEquipmentList.equipments}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods?.formState?.errors?.equipment &&
                              !!methods?.formState?.errors?.equipment?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.vendor}
                          isRequired>
                          <FormLabel>{'Vendor'}</FormLabel>
                          <Controller
                            control={methods.control}
                            name="vendor"
                            rules={{
                              required: 'Vendor',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="vendor"
                                placeholder={'Vendor'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                isLoading={isOnline ? vendorList.isVendorLoading : offlineVendorList.isLoading}
                                options={isOnline ? vendorList.vendorOptions : offlineVendorList.vendors}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.vendor &&
                              !!methods.formState.errors?.vendor?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.description}>
                          <FormLabel>{'Description'}</FormLabel>
                          <Input
                            type="text"
                            {...methods.register('description', {
                              required: 'Description',
                            })}
                            placeholder="Description"
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.description &&
                              !!methods.formState.errors?.description?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.cost}>
                          <FormLabel>{'Cost'}</FormLabel>
                          <Input
                            type="number"
                            step="0.01"
                            {...methods.register('cost', {
                              required: 'Cost',
                            })}
                            placeholder="Cost"
                          />
                        </FormControl>
                        <FormErrorMessage>
                          {!!methods.formState.errors?.cost &&
                            !!methods.formState.errors?.cost?.message}
                        </FormErrorMessage>
                      </GridItem>
                      <GridItem>
                        <FormControl
                          isInvalid={!!methods.formState.errors?.equipment}>
                          <FormLabel>{'PMT Type'}</FormLabel>
                          <Controller
                            control={methods.control}
                            name="pmt_type"
                            rules={{
                              required: 'PMT Type',
                            }}
                            render={({ field }) => (
                              <ReactSelect
                                {...field}
                                id="pmt_type"
                                placeholder={'PMT Type'}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                }}
                                options={PMTTypeList}
                                styles={reactSelectStyles}
                                isSearchable={true}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            {!!methods.formState.errors?.pmt_type &&
                              !!methods.formState.errors?.pmt_type?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <ButtonGroup>
                      <Button
                        colorScheme="primary"
                        type="submit"
                        isLoading={addData.isLoading}>
                        Add
                      </Button>
                      <Button variant="outline" onClick={onAddModalClose}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </form>
              </FormProvider>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Flex p={['3', '6']}>
          <Button
            variant={'outline'}
            onClick={() => {
              methods.reset();
              setErrorMessage('');
              setAddModalOpen(true);
            }}>
            Add Another
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default PartsReport;
