import React, { createContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ReportResource from 'api/report';
import useConnectionStatus from 'hooks/useConnectionStatus';
import {
  setForemanReport,
  setLabors,
  setMechanicalReport,
  setBusinessUnitList,
  setOperationsList,
  setLabourActivitiesList,
  setEquipmentList,
  setProductList,
  setVendorList,
  setEmployeeList,
  setShiftDowntimeCodeList,
  setOperationDowntimeCodeList,
  setEquipmentTypeList,
  setFuelUsage,
  setCapitalEquipment
} from 'actions/data/offline';
import {
  default as LaborResource,
  default as VendorResource,
  default as EmployeeResource,
} from 'api/user';
import OperationDownTimeCodeResource from 'api/operationDownTime';
import ShiftDownTimeCodeResource from 'api/shiftDownTime';
import BusinessUnitResource from 'api/businessUnit';
import OperationResource from 'api/operation';
import EquipmentResource from 'api/equipment';
import ActivityResource from 'api/activity';
import ProductResource from 'api/product';
import FuelUsageReportResource from 'api/fuelUsageReport';
import CapitalEquipmentReportResource from 'api/capitalEquipmentReport';

type OfflineProviderProps = {
  children: React.ReactNode;
};

interface OfflineContextInterface {
  queue: any[];
  setQueue: (queueItem: {
    data: any;
    functionName: string;
    fn: (data: any) => Promise<any> | string;
  }) => void;
  updateQueue: (id: string, updatedItem: any) => void;
  reportId: any;
  reportBody: any;
  previousReportId: any;
  setReportId: (id: any) => void;
  setPreviousReportId: (id: any) => void;
  setReportBody: (body: any) => void;
}

export const OfflineContext = createContext<OfflineContextInterface>(
  {} as OfflineContextInterface
);

const OfflineProvider = ({ children }: OfflineProviderProps) => {
  const [state, setState] = useState<Array<any>>([]);
  const [responseData, setResponseData] = useState<any>();
  const [reportId, setReportId] = useState(0);
  const [reportBody, setReportBody] = useState<any>({});
  console.log('[offline]::[report body]', reportBody);
  // console.log('[offline]::[stringified report body]', JSON.stringify(reportBody));
  const [previousReportId, setPreviousReportId] = useState();

  const isOnline = useConnectionStatus();

  const dispatch = useDispatch();

  // Initialize API resource
  const stateRef = useRef(state);
  const queue = stateRef.current;
  // console.log('[offline]::[queue]', queue);

  const api = new ReportResource();
  const laborAPI = new LaborResource();
  const businessUnitAPI = new BusinessUnitResource();
  const operationAPI = new OperationResource();
  const equipmentAPI = new EquipmentResource();
  const activityAPI = new ActivityResource();
  const productAPI = new ProductResource();
  const vendorAPI = new VendorResource();
  const employeeAPI = new EmployeeResource();
  const operationDownTimeCodeAPI = new OperationDownTimeCodeResource();
  const shiftDownTimeCodeAPI = new ShiftDownTimeCodeResource();
  const fuelUsageAPI = new FuelUsageReportResource();
  const capitalEquipmentAPI = new CapitalEquipmentReportResource();

  const end_date = moment();

  const setQueue = (queueItem: any) => {
    setState((prevState: any) => [...prevState, queueItem]);
  };

  const updateQueue = (id: string, updatedItem: any) => {
    setState((prevState: any) => [...prevState.map((elem: any) => {
      if (elem.data.id === id) {
        return { ...elem, data: updatedItem };
      } else {
        return elem;
      }
    })]);
  }

  const { data: laborQuery, isLoading: isLaborLoading } = useQuery(
    [`labor-dropdown-list`],
    () => laborAPI.labor().then((res) => res?.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: reportsList, isLoading: isReportsLoading } = useQuery(
    [
      'offlineReportList',
      {
        end_date: end_date.format('MM/DD/YYYY'),
        start_date: end_date.subtract(7, 'days').format('MM/DD/YYYY'),
      },
    ],
    async () => {
      try {
        const { data } = await api.allDateReport({
          end_date: end_date.format('MM/DD/YYYY'),
          start_date: end_date.subtract(100, 'days').format('MM/DD/YYYY'),
        });
        return data;
      } catch (err) {
        console.error(err);
      }
    }
  );

  const { data: laborReportList, isLoading: isLaborReportLoading } = useQuery(
    ['offlineLaborReportList'],
    async () => {
      try {
        const today = moment();

        const queryParams: any = {
          end_date: today.format('MM/DD/YYYY'),
          start_date: today.subtract(100, 'days').format('MM/DD/YYYY'),
        };

        const { data } = await api.getLaborReport(queryParams);
        return data?.data;
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: businessUnitList, isLoading: isBusinessUnitLoading } = useQuery(
    ['offlineBusinessUnitList'],
    async () => {
      try {
        const res = await businessUnitAPI.list({
          limit: 10000,
        });
        const options = res?.data?.results?.map((businessUnit: any) => ({
          label: businessUnit.name,
          value: businessUnit.id,
        }))
        return options;
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: operationsList, isLoading: areOperationsLoading } = useQuery(
    ['offlineOperationsList'],
    async () => {
      try {
        const res = await operationAPI.list({
          limit: 10000,
        });
        return res?.data?.results?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: equipmentList, isLoading: isEquipmentLoading } = useQuery(
    ['offlineEquipmentList'],
    async () => {
      try {
        const res = await equipmentAPI.list({
          limit: 10000,
        });
        return res?.data?.results?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: activityList, isLoading: isActivityLoading } = useQuery(
    ['offlineActivityList'],
    async () => {
      try {
        const res = await activityAPI.list({
          limit: 10000,
        });
        return res?.data?.results?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: productList, isLoading: isProductLoading } = useQuery(
    ['offlineProductList'],
    async () => {
      try {
        const res = await productAPI.list({
          limit: 10000,
        });
        return res?.data?.results?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: vendorList, isLoading: isVendorLoading } = useQuery(
    ['offlineVendorList'],
    async () => {
      try {
        const res = await vendorAPI.vendor();
        return res?.data?.users?.map((vendor: any) => ({
          label: `${vendor.first_name} ${vendor.last_name}`,
          first_name: vendor.first_name,
          last_name: vendor.last_name,
          value: vendor.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: employeeList, isLoading: isEmployeeLoading } = useQuery(
    ['offlineEmployeeList'],
    async () => {
      try {
        const res = await employeeAPI.list({
          limit: 10000,
        });
        return res?.data?.results?.map((item: any) => ({
          label: `${item.first_name} ${item.last_name}`,
          first_name: item.first_name,
          last_name: item.last_name,
          value: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: shiftDownTimeCodeList, isLoading: isShiftDownTimeCodeLoading } = useQuery(
    ['offlineShiftDownTimeCodeList'],
    async () => {
      try {
        const res = await shiftDownTimeCodeAPI.list({
          limit: 10000,
        });
        return res?.data?.results?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: operationDownTimeCodeList, isLoading: isOperationDownTimeCodeLoading } = useQuery(
    ['offlineOperationTimeCodeList'],
    async () => {
      try {
        const res = await operationDownTimeCodeAPI.list({
          limit: 10000,
        });
        return res?.data?.results?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  // Equipment Type
  const { data: equipmentTypeList, isLoading: isEquipmentTypeLoading } =
    useQuery(
      ['offlineEquipmentTypeList'],
      () =>
        equipmentAPI
          .type({
            limit: 5000,
          })
          .then((res) => {
            return res?.data?.results?.map((item: any) => ({
              label: item.name,
              value: item.id,
            }));
          }),
      {
        refetchOnWindowFocus: false,
      }
    );
  // Fuel Usage
  const { data: fuelUsageList, isLoading: isFuelUsageLoading } =
    useQuery([
      'offlineFuelUsageList',
      {
        end_date: end_date.format('MM/DD/YYYY'),
        start_date: end_date.subtract(7, 'days').format('MM/DD/YYYY'),
      },
    ],
      async () => {
        try {
          const { data } = await fuelUsageAPI.allDate({
            end_date: end_date.format('MM/DD/YYYY'),
            start_date: end_date.subtract(100, 'days').format('MM/DD/YYYY'),
          });
          return data.data;
        } catch (err) {
          console.error(err);
        }
      }
    );
  // Capital Equipment
  // Other Equipment
  const { data: otherCapitalEquipmentList, isLoading: isOtherCapitalEquipmentLoading } =
    useQuery([
      'offlineOtherCapitalEquipmentList',
      {
        end_date: end_date.format('MM/DD/YYYY'),
        start_date: end_date.subtract(7, 'days').format('MM/DD/YYYY'),
      },
    ],
      async () => {
        try {
          const { data } = await capitalEquipmentAPI.allDateOther({
            end_date: end_date.format('MM/DD/YYYY'),
            start_date: end_date.subtract(14, 'days').format('MM/DD/YYYY'),
          });
          return data.data;
        } catch (err) {
          console.error(err);
        }
      }
    );
  // Operated Equipment
  const { data: capitalEquipmentList } =
    useQuery([
      'offlineCapitalEquipmentList',
      {
        end_date: end_date.format('MM/DD/YYYY'),
        start_date: end_date.subtract(7, 'days').format('MM/DD/YYYY'),
      },
    ],
      async () => {
        try {
          const { data } = await capitalEquipmentAPI.allDate({
            end_date: end_date.format('MM/DD/YYYY'),
            start_date: end_date.subtract(50, 'days').format('MM/DD/YYYY'),
          });
          console.log('offline prev capital equipment units: ', data);
          return data.data;
        } catch (err) {
          console.error(err);
        }
      }
    );


  useEffect(() => {
    dispatch(
      setMechanicalReport({
        reports: reportsList?.mechanic_reports,
        isLoading: isReportsLoading,
      })
    );
    dispatch(
      setForemanReport({
        reports: reportsList?.foreman_reports,
        isLoading: isReportsLoading,
      })
    );
    dispatch(
      setLabors({
        labors: laborReportList,
        isLoading: isLaborReportLoading
      })
    );
    dispatch(
      setBusinessUnitList({
        business_units: businessUnitList,
        isLoading: isBusinessUnitLoading
      })
    );
    dispatch(
      setOperationsList({
        operations: operationsList,
        isLoading: areOperationsLoading
      })
    );
    dispatch(
      setLabourActivitiesList({
        activities: activityList,
        isLoading: isActivityLoading
      })
    );
    dispatch(
      setEquipmentList({
        equipments: equipmentList,
        isLoading: isEquipmentLoading
      })
    );
    dispatch(
      setProductList({
        products: productList,
        isLoading: isProductLoading
      })
    );
    dispatch(
      setVendorList({
        vendors: vendorList,
        isLoading: isVendorLoading
      })
    );
    dispatch(
      setEmployeeList({
        employees: employeeList,
        isLoading: isEmployeeLoading
      })
    );
    dispatch(
      setShiftDowntimeCodeList({
        shift_downtime_codes: shiftDownTimeCodeList,
        isLoading: isShiftDownTimeCodeLoading
      })
    );
    dispatch(
      setOperationDowntimeCodeList({
        operation_downtime_codes: operationDownTimeCodeList,
        isLoading: isOperationDownTimeCodeLoading
      })
    );
    dispatch(
      setEquipmentTypeList({
        t_equipment: equipmentTypeList,
        isLoading: isEquipmentTypeLoading
      })
    );
    dispatch(
      setFuelUsage({
        fuel_usage: fuelUsageList,
        isLoading: isFuelUsageLoading
      })
    );
    dispatch(
      setCapitalEquipment({
        capital_equipment: {
          operated_equipment: capitalEquipmentList,
          nonoperated_equipment: [],
          other_equipment: otherCapitalEquipmentList || []
        },
        isLoading: isOtherCapitalEquipmentLoading
      })
    );
  }, [
    dispatch,
    isReportsLoading,
    reportsList,
    laborReportList,
    isLaborReportLoading,
    isBusinessUnitLoading,
    businessUnitList,
    operationsList,
    areOperationsLoading,
    equipmentList,
    isEquipmentLoading,
    activityList,
    isActivityLoading,
    productList,
    isProductLoading,
    vendorList,
    isVendorLoading,
    shiftDownTimeCodeList,
    isShiftDownTimeCodeLoading,
    operationDownTimeCodeList,
    isOperationDownTimeCodeLoading,
    equipmentTypeList,
    isEquipmentTypeLoading,
    employeeList,
    isEmployeeLoading,
    fuelUsageList,
    isFuelUsageLoading,
    capitalEquipmentList,
    otherCapitalEquipmentList,
    isOtherCapitalEquipmentLoading
  ]);


  useEffect(() => {
    // Initialize queue with items in the localStorage if found
    const items = localStorage.getItem('offlineReportsQueue');
    if (items) setState(JSON.parse(items));
  }, []);

  useEffect(() => {
    stateRef.current = state;
    localStorage.setItem('offlineReportsQueue', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    if (isOnline && queue.length > 0) {
      (async () => {
        for (const item of queue) {
          const { data } = item;

          try {
            const responseData = await item.fn(data);
            setResponseData({ [item.functionName]: responseData });
          } catch (error) {
            console.error(error);
          }

          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        setState([]);
      })();
    }
  }, [isOnline]);

  return (
    <OfflineContext.Provider
      value={{
        queue: state,
        setQueue,
        reportId,
        setReportId,
        previousReportId,
        setPreviousReportId,
        reportBody,
        setReportBody,
        updateQueue
      }}>
      {children}
    </OfflineContext.Provider>
  );
};

export default OfflineProvider;
