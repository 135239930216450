import { Td, Tr } from '@chakra-ui/react';
import routes from 'constants/routes';
import { CompanyListItemType } from 'interface/company/companySchema';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyListItem: React.FC<CompanyListItemType> = (props) => {
  const { listData } = props;
  const navigate = useNavigate();

  return (
    <Tr>
      <Td
        _hover={{ cursor: 'pointer' }}
        key={listData.id}
        onClick={() =>
          navigate(routes.companies.view.replace(':id', listData.id.toString()))
        }>
        {listData.name}
      </Td>
      <Td>{listData.address}</Td>
      <Td>{listData.company_tenant}</Td>
      <Td>{listData.license_key}</Td>
      <Td>{listData.contact}</Td>
    </Tr>
  );
};

export default CompanyListItem;
