import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import LoadoutDetailResource from 'api/loadoutDetail';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import useDebounceFunc from 'hooks/useDebounceFunc';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import ReactSelect from 'react-select';

interface Props {
  loadoutId: string;
  reportId: string;
}
const LoadoutDetailReport: React.FC<Props> = (props) => {
  const { loadoutId, reportId } = props;
  const methods = useForm<any>();
  const loadoutDetailAPI = new LoadoutDetailResource();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [tagData, setTagData] = useState(0);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);

  const { afeList, customerList, productList } = useSelector(
    (state: any) => ({
      afeList: state?.data?.report?.afeList,
      customerList: state?.data?.report?.customerList,
      productList: state?.data?.report?.productList,
    }),
    shallowEqual
  );

  const { data: loadoutListQuery, isLoading: isLoadoutListLoading } = useQuery(
    [`loadout-detail-list`, { loadoutId }],
    () =>
      loadoutDetailAPI
        .listLoadoutReport(loadoutId, {
          limit: 5000,
        })
        .then((res) => {
          return res?.data;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const addData = useMutation(
    (data: any) => loadoutDetailAPI.storeLoadoutReport(loadoutId, data),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('loadout-detail-list');
        queryClient.invalidateQueries('getLoadoutStatus');
        queryClient.invalidateQueries('loadout-list');
        setAddModalOpen(false);
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const formData = {
      loads: data?.loads + '',
      tons: data?.tons + '',
      afe: data?.afe?.value,
      customer: data?.customer?.value,
      product: data?.product?.value,
    };
    addData.mutate(formData);
  };

  const updateData = useMutation(
    (data: any) =>
      loadoutDetailAPI.updateLoadoutReport(reportId, data?.id, data?.body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loadout-detail-list');
      },
    }
  );
  const handleChange = (
    fieldData: any,
    fieldValue: string,
    fieldType: string
  ) => {
    let data: any = {
      body: { loads: '', afe: 0, customer: 0, product: 0 },
      id: 0,
    };
    data.id = fieldData.id;
    switch (fieldType) {
      case 'afe':
        data.body.loads = fieldData?.loads;
        data.body.tons = fieldData?.tons;
        data.body.afe = +fieldValue;
        data.body.customer = fieldData?.customer?.id;
        data.body.product = fieldData?.product?.id;
        break;
      case 'customer':
        data.body.loads = fieldData?.loads;
        data.body.tons = fieldData?.tons;
        data.body.afe = fieldData?.afe?.id;
        data.body.customer = +fieldValue;
        data.body.product = fieldData?.produc?.id;
        break;
      case 'product':
        data.body.loads = fieldData?.loads;
        data.body.tons = fieldData?.tons;
        data.body.afe = fieldData?.afe?.id;
        data.body.customer = fieldData?.customer?.id;
        data.body.product = +fieldValue;
        break;
      case 'loads':
        data.body.loads = fieldValue;
        data.body.tons = fieldData?.tons;
        data.body.afe = fieldData?.afe?.id;
        data.body.customer = fieldData?.customer?.id;
        data.body.product = fieldData?.product?.id;
        break;
      case 'tons':
        data.body.loads = fieldData?.loads;
        data.body.tons = fieldValue;
        data.body.afe = fieldData?.afe?.id;
        data.body.customer = fieldData?.customer?.id;
        data.body.product = fieldData?.product?.id;
        break;
    }
    updateData.mutate(data);
  };
  const debounce = useDebounceFunc(handleChange, 3000);

  const handleDetailDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
  };

  const deleteData = useMutation(
    (id: number) => loadoutDetailAPI.destroyLoadoutReport(loadoutId, id + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('loadout-detail-list');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (id != 0) deleteData.mutate(id);
  };

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        ...fieldData,
        afe: fieldData?.afe?.id,
        customer: fieldData?.customer?.id,
        product: fieldData?.product?.id,
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    updateData.mutate(data);
    setNoteDetailModalOpen(false);
  };

  const handleNoteDetailModal = (data: any) => {
    setNoteDetailFieldData(data);
    setNoteDetailModalOpen(true);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    queryClient.invalidateQueries('loadout-detail-list');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(true);
      }
    }
  };

  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            <Tr bg={'gray.300'}>
              <Td>AFE</Td>
              <Td>Customer</Td>
              <Td>Product</Td>
              <Td>Loads</Td>
              <Td>Tons</Td>
              <Td>Change Details</Td>
              <Td></Td>
            </Tr>
            {loadoutListQuery?.results?.map(
              (loadout: any, indexNumber: number) => (
                <React.Fragment key={loadout?.id}>
                  <Tr bg={'white'}>
                    <Td>
                      <ReactSelect
                        defaultValue={{
                          label: loadout?.afe?.name,
                          value: loadout?.afe?.id,
                        }}
                        placeholder={'Select Afe'}
                        isLoading={afeList.isAfeLoading}
                        options={afeList.afeOptions}
                        styles={reactSelectStyles}
                        isSearchable={true}
                        onChange={(e: any) =>
                          handleChange(loadout, e.target.value, 'afe')
                        }
                        menuPortalTarget={document.body}
                      />
                    </Td>
                    <Td>
                      <ReactSelect
                        defaultValue={{
                          label: `${loadout?.customer?.first_name} ${loadout?.customer?.last_name}`,
                          value: loadout?.customer?.id,
                        }}
                        placeholder={'Select Customer'}
                        isLoading={customerList.isCustomerLoading}
                        options={customerList.customerOptions}
                        styles={reactSelectStyles}
                        isSearchable={true}
                        onChange={(e: any) =>
                          handleChange(loadout, e.target.value, 'customer')
                        }
                        menuPortalTarget={document.body}
                      />
                    </Td>
                    <Td>
                      <ReactSelect
                        defaultValue={{
                          label: loadout?.product?.name,
                          value: loadout?.product?.id,
                        }}
                        placeholder={'Select Product'}
                        isLoading={productList.isProductLoading}
                        options={productList.productOptions}
                        styles={reactSelectStyles}
                        isSearchable={true}
                        onChange={(e: any) =>
                          handleChange(loadout, e.target.value, 'product')
                        }
                        menuPortalTarget={document.body}
                      />
                    </Td>
                    <Td>
                      <Input
                        type="number"
                        step="0.01"
                        defaultValue={loadout?.loads}
                        onChange={(e: any) =>
                          debounce(loadout, e.target.value, 'loads')
                        }
                      />
                    </Td>
                    <Td>
                      <Input
                        type="number"
                        step="0.01"
                        defaultValue={loadout?.tons}
                        onChange={(e: any) =>
                          debounce(loadout, e.target.value, 'tons')
                        }
                      />
                    </Td>
                    <Td>+</Td>
                    <Td>
                      <Tooltip hasArrow label={'Tag'}>
                        <IconButton
                          icon={<BsTag />}
                          variant="link"
                          aria-label={'Tag'}
                          color={'gray.300'}
                          size="lg"
                          onClick={() => handleTagModal(loadout, 'tagDetail')}
                        />
                      </Tooltip>
                      {tagData === loadout?.tag && (
                        <TagModal
                          isModalOpen={tagModalOpen}
                          setModalOpen={setTagModalOpen}
                          fieldData={tagData}
                          onModalSubmit={() => onTagModalSubmit()}
                        />
                      )}
                      <Tooltip hasArrow label={'Comment'}>
                        <IconButton
                          icon={<MdOutlineModeComment />}
                          variant="link"
                          aria-label={'Comment'}
                          color={loadout?.note ? 'primary.600' : 'gray.300'}
                          size="lg"
                          onClick={() => handleNoteDetailModal(loadout)}
                        />
                      </Tooltip>
                      <NoteModal
                        isModalOpen={noteDetailModalOpen}
                        setModalOpen={setNoteDetailModalOpen}
                        fieldData={noteDetailFieldData}
                        onModalSubmit={(notes: string) =>
                          onNoteDetailModalSubmit(notes, noteDetailFieldData)
                        }
                        formMutation={updateData}
                        errorMessage={errorMessage}
                      />
                      <Tooltip hasArrow label={'Delete'}>
                        <IconButton
                          icon={<BsTrash />}
                          variant="link"
                          aria-label={'Delete'}
                          color="gray.300"
                          size="lg"
                          onClick={() => handleDetailDelete(loadout?.id)}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>

                  {/* Delete Modal */}
                  <Modal
                    isOpen={isDeleteModalOpen}
                    isCentered
                    onClose={onDeleteModalClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>{'Delete Loadout Detail'}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        {'Are you sure you want to delete this ?'}
                        {deleteMsg && (
                          <Alert status="error" mb={2}>
                            <AlertIcon />
                            {deleteMsg}
                          </Alert>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <ButtonGroup>
                          <Button
                            colorScheme="red"
                            onClick={() => onDeleteConfirm(confirmDeleteId)}
                            isLoading={deleteData.isLoading}>
                            {'Delete'}
                          </Button>

                          <Button
                            variant="outline"
                            onClick={onDeleteModalClose}>
                            {'Cancel'}
                          </Button>
                        </ButtonGroup>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </React.Fragment>
              )
            )}
          </Tbody>
          <Tfoot>
            <Flex p={['3', '6']}>
              <Button
                variant={'outline'}
                onClick={() => {
                  methods.reset();
                  setAddModalOpen(true);
                }}>
                Add Another
              </Button>
            </Flex>
          </Tfoot>
        </Table>
        {!loadoutListQuery && <CenterSpinner type="normal" />}
      </TableContainer>

      {/* Add Modal */}
      <Modal isOpen={isAddModalOpen} isCentered onClose={onAddModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Add Loadout Detail'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack direction="column" spacing="4">
                  {addData.isError && (
                    <Alert status="error">
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                  <Grid gap="4" templateColumns={['repeat(1, 1fr)']} flex="1">
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods?.formState?.errors?.type}
                        isRequired>
                        <FormLabel>AFE</FormLabel>
                        <Controller
                          control={methods.control}
                          name="afe"
                          rules={{
                            required: 'AFE',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="afe"
                              placeholder={'Select AFE'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={afeList.isAfeLoading}
                              options={afeList.afeOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods?.formState?.errors?.afe &&
                            !!methods?.formState?.errors?.afe?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.customer}
                        isRequired>
                        <FormLabel>{'Customer'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="customer"
                          rules={{
                            required: 'Customer',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="customer"
                              placeholder={'Select Customer'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={customerList.isCustomerLoading}
                              options={customerList.customerOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.customer &&
                            !!methods.formState.errors?.customer?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.product}
                        isRequired>
                        <FormLabel>{'Product'}</FormLabel>
                        <Controller
                          control={methods.control}
                          name="product"
                          rules={{
                            required: 'Product',
                          }}
                          render={({ field }) => (
                            <ReactSelect
                              {...field}
                              id="product"
                              placeholder={'Select Product'}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                              }}
                              isLoading={productList.isProductLoading}
                              options={productList.productOptions}
                              styles={reactSelectStyles}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        <FormErrorMessage>
                          {!!methods.formState.errors?.product &&
                            !!methods.formState.errors?.product?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.loads}
                        isRequired>
                        <FormLabel>{'Loads'}</FormLabel>
                        <Input
                          {...methods.register('loads', {
                            required: 'Loads',
                          })}
                          placeholder="Loads"
                        />
                      </FormControl>
                      <FormErrorMessage>
                        {!!methods.formState.errors?.loads &&
                          !!methods.formState.errors?.loads?.message}
                      </FormErrorMessage>
                    </GridItem>
                    <GridItem>
                      <FormControl
                        isInvalid={!!methods.formState.errors?.tons}
                        isRequired>
                        <FormLabel>{'Tons'}</FormLabel>
                        <Input
                          {...methods.register('tons', {
                            required: 'Tons',
                          })}
                          placeholder="Tons"
                        />
                      </FormControl>
                      <FormErrorMessage>
                        {!!methods.formState.errors?.tons &&
                          !!methods.formState.errors?.tons?.message}
                      </FormErrorMessage>
                    </GridItem>
                  </Grid>
                  <ButtonGroup>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={addData.isLoading}>
                      Add
                    </Button>
                    <Button variant="outline" onClick={onAddModalClose}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Stack>
              </form>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoadoutDetailReport;
