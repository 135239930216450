export enum ReportActionTypes {
  SET_VENDORS = 'SET_VENDORS',
  SET_EQUIPMENT = 'SET_EQUIPMENT',
  SET_EQUIPMENT_TYPE = 'SET_EQUIPMENT_TYPE',
  SET_PLANT = 'SET_PLANT',
  SET_PIT = 'SET_PIT',
  SET_AFE = 'SET_AFE',
  SET_BUSINESS_UNIT = 'SET_BUSINESS_UNIT',
  SET_OPERATION = 'SET_OPERATION',
  SET_SHIFT = 'SET_SHIFT',
  SET_FOREMAN = 'SET_FOREMAN',
  SET_MANAGER = 'SET_MANAGER',
  SET_CUSTOMER = 'SET_CUSTOMER',
  SET_PRODUCT = 'SET_PRODUCT',
  SET_OPERATION_DOWNTIME_CODE = 'SET_OPERATION_DOWNTIME_CODE',
  SET_SHIFT_DOWNTIME_CODE = 'SET_SHIFT_DOWNTIME_CODE',
  SET_EMPLOYEE = 'SET_EMPLOYEE',
  SET_ACTIVITY = 'SET_ACTIVITY',
  SET_LABOR = 'SET_LABOR',
  SET_MECHANIC_REPORT = 'SET_MECHANIC_REPORT',
  SET_FOREMAN_REPORT = 'SET_FOREMAN_REPORT'
}

export interface ReportAction {
  data: any;
  type: ReportActionTypes;
}

/**
 * Action creator for vendor list.
 *
 * @param {array} vendors
 * @returns {array}
 */
export function setVendorList(vendors: any): ReportAction {
  return {
    data: vendors,
    type: ReportActionTypes.SET_VENDORS,
  };
}

/**
 * Action creator for equipment list.
 *
 * @param {array} equipments
 * @returns {array}
 */
export function setEquipmentList(equipments: any): ReportAction {
  return {
    data: equipments,
    type: ReportActionTypes.SET_EQUIPMENT,
  };
}

/**
 * Action creator for equipment type list.
 *
 * @param {array} equipmentType
 * @returns {array}
 */
export function setEquipmentTypeList(equipmentType: any): ReportAction {
  return {
    data: equipmentType,
    type: ReportActionTypes.SET_EQUIPMENT_TYPE,
  };
}

/**
 * Action creator for plant list.
 *
 * @param {array} plant
 * @returns {array}
 */
export function setPlantList(plant: any): ReportAction {
  return {
    data: plant,
    type: ReportActionTypes.SET_PLANT,
  };
}

/**
 * Action creator for pit list.
 *
 * @param {array} pit
 * @returns {array}
 */
export function setPitList(pit: any): ReportAction {
  return {
    data: pit,
    type: ReportActionTypes.SET_PIT,
  };
}

/**
 * Action creator for afe list.
 *
 * @param {array} afe
 * @returns {array}
 */
export function setAfeList(afe: any): ReportAction {
  return {
    data: afe,
    type: ReportActionTypes.SET_AFE,
  };
}

/**
 * Action creator for operation list.
 *
 * @param {array} operation
 * @returns {array}
 */
export function setOperationList(operation: any): ReportAction {
  return {
    data: operation,
    type: ReportActionTypes.SET_OPERATION,
  };
}

/**
 * Action creator for business unit list.
 *
 * @param {array} business_unit
 * @returns {array}
 */
export function setBusinessUnitList(business_unit: any): ReportAction {
  return {
    data: business_unit,
    type: ReportActionTypes.SET_BUSINESS_UNIT,
  };
}

/**
 * Action creator for shift list.
 *
 * @param {array} shift
 * @returns {array}
 */
export function setShiftList(shift: any): ReportAction {
  return {
    data: shift,
    type: ReportActionTypes.SET_SHIFT,
  };
}

/**
 * Action creator for foreman list.
 *
 * @param {array} foreman
 * @returns {array}
 */
export function setForemanList(foreman: any): ReportAction {
  return {
    data: foreman,
    type: ReportActionTypes.SET_FOREMAN,
  };
}

/**
 * Action creator for manager list.
 *
 * @param {array} manager
 * @returns {array}
 */
export function setManagerList(manager: any): ReportAction {
  return {
    data: manager,
    type: ReportActionTypes.SET_MANAGER,
  };
}

/**
 * Action creator for customer list.
 *
 * @param {array} customer
 * @returns {array}
 */
export function setCustomerList(customer: any): ReportAction {
  return {
    data: customer,
    type: ReportActionTypes.SET_CUSTOMER,
  };
}

/**
 * Action creator for product list.
 *
 * @param {array} product
 * @returns {array}
 */
export function setProductList(product: any): ReportAction {
  return {
    data: product,
    type: ReportActionTypes.SET_PRODUCT,
  };
}

/**
 * Action creator for operation downtime code list.
 *
 * @param {array} operationDowntimeCode
 * @returns {array}
 */
export function setOperationDowntimeCodeList(
  operationDowntimeCode: any
): ReportAction {
  return {
    data: operationDowntimeCode,
    type: ReportActionTypes.SET_OPERATION_DOWNTIME_CODE,
  };
}

/**
 * Action creator for shift downtime code list.
 *
 * @param {array} shiftDowntimeCode
 * @returns {array}
 */
export function setShiftDowntimeCodeList(shiftDowntimeCode: any): ReportAction {
  return {
    data: shiftDowntimeCode,
    type: ReportActionTypes.SET_SHIFT_DOWNTIME_CODE,
  };
}

/**
 * Action creator for employee list.
 *
 * @param {array} employee
 * @returns {array}
 */
export function setEmployeeList(employee: any): ReportAction {
  return {
    data: employee,
    type: ReportActionTypes.SET_EMPLOYEE,
  };
}

/**
 * Action creator for activity list.
 *
 * @param {array} activity
 * @returns {array}
 */
export function setActivityList(activity: any): ReportAction {
  return {
    data: activity,
    type: ReportActionTypes.SET_ACTIVITY,
  };
}

/**
 * Action creator for labor list.
 *
 * @param {array} labor
 * @returns {array}
 */
export function setLaborList(labor: any): ReportAction {
  return {
    data: labor,
    type: ReportActionTypes.SET_LABOR,
  };
}

export function setMechanicReport(reports: any): ReportAction {
  return {
    data: reports,
    type: ReportActionTypes.SET_MECHANIC_REPORT
  }
}

export function setForemanReport(reports: any): ReportAction {
  return {
    data: reports,
    type: ReportActionTypes.SET_FOREMAN_REPORT
  }
}