import Resource from 'api/resource';
import http from 'utils/http';

class CapitalEquipmentReportResource extends Resource {
  constructor() {
    super('reports');
  }

  allDate(query?: any) {
    return http({
      url: 'capital-equipments-details',
      method: 'get',
      params: query,
    });
  }

  allDateOther(query?: any) {
    return http({
      url: 'other-equipments',
      method: 'get',
      params: query,
    });
  }

  listCapitalEquipmentReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/capital-equipment`,
      method: 'get',
      params: query,
    });
  }
  getCapitalEquipmentReport(id: string, capitalEquipmentId: string) {
    return http({
      url: `/reports/${id}/capital-equipment/${capitalEquipmentId}`,
      method: 'get',
    });
  }
  storeCapitalEquipmentReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/capital-equipment`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateCapitalEquipmentReport(id: string, resource: any) {
    return http({
      url: `/reports/${id}/capital-equipment`,
      method: 'put',
      data: resource,
    });
  }
  destroyCapitalEquipmentReport(id: string, capitalEquipmentId: string) {
    return http({
      url: `/reports/${id}/capital-equipment/${capitalEquipmentId}`,
      method: 'delete',
    });
  }

  listCapitalEquipmentDetailReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/capital-equipment-detail`,
      method: 'get',
      params: query,
    });
  }
  getCapitalEquipmentDetailReport(
    id: string,
    CapitalEquipmentDetailId: string
  ) {
    return http({
      url: `/reports/${id}/capital-equipment-detail/${CapitalEquipmentDetailId}`,
      method: 'get',
    });
  }
  storeCapitalEquipmentDetailReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/capital-equipment-detail`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateCapitalEquipmentDetailReport(
    id: string,
    CapitalEquipmentDetailId: string,
    resource: any
  ) {
    return http({
      url: `/reports/${id}/capital-equipment-detail/${CapitalEquipmentDetailId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyCapitalEquipmentDetailReport(
    id: string,
    CapitalEquipmentDetailId: string
  ) {
    return http({
      url: `/reports/${id}/capital-equipment-detail/${CapitalEquipmentDetailId}`,
      method: 'delete',
    });
  }
}

export { CapitalEquipmentReportResource as default };
