import axios from 'axios';

/**
 * Http Utility.
 */
export const baseURL =
  process.env.REACT_APP_BASE_URL ||
  window.location.protocol + '//' + window.location.hostname + '/api/v1';
const http = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default http;
