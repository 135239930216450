import {
  Box,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import FuelUsageReportResource from 'api/fuelUsageReport';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { FuelTypeEnum, FuelTypeList } from 'constants/common';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useDebounceFunc from 'hooks/useDebounceFunc';
import { ReportProps } from 'interface/report/reportSchema';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BsTag } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';

const FuelUsageReport: React.FC<ReportProps> = (props) => {
  const { setReportBody, updateQueue, reportBody } = useContext(OfflineContext);
  const offlineFuelUsageList = useSelector((state: any) => state.data.offline.fuel_usage)
  const [offlineFuelUsageFilteredByPreviousReportId, setOfflineFuelUsageFilteredByPreviousReportId] = useState<any>(offlineFuelUsageList.fuel_usage
    // we need to use the report id, just we do not have much info there :(
    //   ? offlineFuelUsageList.fuel_usage?.filter(
    //   (fuel: any) => fuel.report === +previousReportId
    // )
    ? offlineFuelUsageList.fuel_usage
    : []);
  const isOnline = useConnectionStatus();

  const { reportId } = props;
  const containerRef = useRef<any>();
  const api = new FuelUsageReportResource();
  const fuelTypeEnum: any = FuelTypeEnum;
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState<any>();
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [tagData, setTagData] = useState(0);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);

  const fuelUsageStatusQuery = useQuery(
    [`getFuelUsageStatus`],
    async () => {
      const response = await api.listFuelUsageReport(reportId, {
        limit: 5000,
      });
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: fuelUsageListQuery, isLoading: isFuelUsageListLoading } =
    useQuery(
      [`fuel-usage-list`],
      () =>
        api
          .listFuelUsageDetailReport(reportId, {
            limit: 5000,
          })
          .then((res) => {
            return res?.data?.results;
          }),
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  const fuelUsageList = useMemo(() => isOnline
    ? fuelUsageListQuery?.length > 0
      ? fuelUsageListQuery
      : []
    : offlineFuelUsageFilteredByPreviousReportId, [offlineFuelUsageFilteredByPreviousReportId, fuelUsageListQuery])

  const updateData = useMutation(
    (data: any) => {
      return api.updateFuelUsageDetailReport(reportId, data?.id, data?.body);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('fuel-usage-list');
      },
    }
  );

  const handleChange = (
    fieldData: any,
    fieldValue: string,
    fieldType: string
  ) => {
    let data: any = { body: { fuel: '', hours: '', gallons: 0 }, id: 0 };
    data.id = fieldData.id;
    switch (fieldType) {
      case 'hours':
        data.body.fuel = fieldData?.fuel;
        data.body.hours = +fieldValue;
        break;
      case 'gallons':
        data.body.fuel = fieldData?.fuel;
        data.body.gallons = +fieldValue;
        break;
      case 'fuelType':
        data.body.fuel = fieldValue;
        data.body.hours = fieldData?.hours;
        data.body.gallons = fieldData?.gallons;
        break;
    }
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineFuelUsageFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          switch (fieldType) {
            case 'gallons':
              return {
                ...r,
                gallons: +fieldValue,
              }
            case 'fuelType':
              const t_fuel = FuelTypeList.find((elem: any) => elem.value === fieldValue);
              return {
                ...r,
                fuel: t_fuel ? { id: t_fuel.value, name: t_fuel.label } : r.fuel
              }
            default:
              return r;
          }
        } else {
          return r;
        }
      }))
    }
  };
  const debounce = useDebounceFunc(handleChange, 3000);

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/fuel-usage')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev, fuel_usage: offlineFuelUsageFilteredByPreviousReportId.filter((fuel: any) => +fuel.gallons > 0)
    }))
    // update the queue
    updateQueue(reportBody.id, { ...reportBody, fuel_usage: offlineFuelUsageFilteredByPreviousReportId })
  }, [offlineFuelUsageFilteredByPreviousReportId])

  // const getTotaCost = () => {
  //   const total = fuelUsageListQuery?.results?.reduce(function (
  //     acc: number,
  //     obj: any
  //   ) {
  //     return acc + +obj.cost;
  //   },
  //   0);
  //   return total || 0;
  // };

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        ...fieldData,
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineFuelUsageFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === data.id) {
          return {
            ...r,
            note: modalValue.note
          }
        } else {
          return r;
        }
      }))
    }
    setNoteDetailModalOpen(false);
  };

  const handleNoteDetailModal = (data: any) => {
    setNoteDetailFieldData(data);
    setNoteDetailModalOpen(true);
  };

  const handleNoteModal = (data: any) => {
    setNoteModalOpen(true);
  };

  const updateFuelUsageData = useMutation(
    (data: any) => api.updateFuelUsageReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getFuelUsageStatus');
      },
    }
  );
  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      note: modalValue.note,
    };
    updateFuelUsageData.mutate(data);
    setNoteModalOpen(false);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('fuel-usage-list');
    queryClient.invalidateQueries('getFuelUsageStatus');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Fuel Usage</Heading>
          <Stack direction="row" spacing="6">
            {/* <Stack direction={'column'}>
              <Text>Total Cost</Text>
              <Text>${getTotaCost()}</Text>
            </Stack> */}
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(fuelUsageStatusQuery?.data, 'tagStatus')
                }
              />
            </Tooltip>
            {tagData === fuelUsageStatusQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={fuelUsageStatusQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
              />
            )}
            <Tooltip hasArrow label={fuelUsageStatusQuery?.data?.note}>
              <IconButton
                icon={<MdOutlineModeComment />}
                variant="link"
                aria-label={fuelUsageStatusQuery?.data?.note}
                color={
                  fuelUsageStatusQuery?.data?.note ? 'primary.600' : 'gray.300'
                }
                size="lg"
                onClick={() => handleNoteModal(fuelUsageStatusQuery?.data)}
              />
            </Tooltip>
            <NoteModal
              isModalOpen={noteModalOpen}
              setModalOpen={setNoteModalOpen}
              fieldData={fuelUsageStatusQuery?.data}
              onModalSubmit={(notes: string) =>
                onNoteModalSubmit(notes, fuelUsageStatusQuery?.data)
              }
              formMutation={updateFuelUsageData}
              errorMessage={errorMessage}
            />
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant={'simple'}>
              <Tbody>
                <Tr bg={'gray.300'}>
                  <Td>Equipment</Td>
                  <Td>Gallons</Td>
                  <Td>Fuel Type</Td>
                  {/* <Td>Cost</Td> */}
                  <Td></Td>
                </Tr>
                {fuelUsageList?.map((fuelUsage: any) => (
                  <React.Fragment key={fuelUsage.id}>
                    <Tr>
                      <Td>{fuelUsage?.equipment?.name}</Td>
                      <Td>
                        <Input
                          defaultValue={fuelUsage?.gallons}
                          type="number"
                          step="0.01"
                          placeholder={'gallons'}
                          onChange={(e: any) =>
                            handleChange(fuelUsage, e.target.value, 'gallons')
                          }
                        />
                      </Td>
                      <Td>
                        <ReactSelect
                          defaultValue={{
                            label: fuelTypeEnum[fuelUsage?.fuel],
                            value: fuelUsage?.fuel,
                          }}
                          onChange={(e: any) =>
                            handleChange(fuelUsage, e.value, 'fuelType')
                          }
                          placeholder={'Fuel Type'}
                          options={FuelTypeList}
                          styles={reactSelectStyles}
                          isSearchable={true}
                          menuPortalTarget={document.body}
                        />
                      </Td>
                      {/* <Td>${fuelUsage.cost}</Td> */}
                      <Td>
                        <Tooltip hasArrow label={'Tag'}>
                          <IconButton
                            icon={<BsTag />}
                            variant="link"
                            aria-label={'Tag'}
                            color={'gray.300'}
                            size="lg"
                            onClick={() =>
                              handleTagModal(fuelUsage, 'tagDetail')
                            }
                          />
                        </Tooltip>
                        {tagData === fuelUsage?.tag && (
                          <TagModal
                            isModalOpen={tagDetailModalOpen}
                            setModalOpen={setTagDetailModalOpen}
                            fieldData={tagData}
                            onModalSubmit={() => onTagModalSubmit()}
                          />
                        )}
                        <Tooltip hasArrow label={fuelUsage?.note}>
                          <IconButton
                            icon={<MdOutlineModeComment />}
                            variant="link"
                            aria-label={fuelUsage?.note}
                            color={fuelUsage?.note ? 'primary.600' : 'gray.300'}
                            size="lg"
                            onClick={() => handleNoteDetailModal(fuelUsage)}
                          />
                        </Tooltip>
                        <NoteModal
                          isModalOpen={noteDetailModalOpen}
                          setModalOpen={setNoteDetailModalOpen}
                          fieldData={noteDetailFieldData}
                          onModalSubmit={(notes: string) =>
                            onNoteDetailModalSubmit(notes, noteDetailFieldData)
                          }
                          formMutation={updateData}
                          errorMessage={errorMessage}
                        />
                      </Td>
                    </Tr>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
            {isOnline && !fuelUsageListQuery && <CenterSpinner type="normal" />}
          </TableContainer>
        </Stack>
      </Box>
    </>
  );
};

export default FuelUsageReport;
