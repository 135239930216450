import {
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import PeopleResource from 'api/user';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import PeopleListItem from 'components/people/PeopleListItem';
import {
  DEFAULT_PAGE_SIZE,
  INITIAL_CURRENT_PAGE,
  PEOPLE_HEADER,
} from 'constants/common';
import routes from 'constants/routes';
import useDebounce from 'hooks/useDebounce';
import { FilterParams } from 'interface/common/filterParam';
import { PeopleSchema } from 'interface/people/peopleListType';
import React, { useRef, useState } from 'react';
import { BiImport, BiSearch } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter, DateFormat } from 'utils';
import CsvUpload from 'utils/CsvUpload';
import { csvExport } from 'utils/fileExport';

const PeopleList: React.FC = () => {
  const [isLoading, setIsLoading] = useState<string | null>();
  const [refetch, setRefetch] = useState<number>(1);
  const [peopleIds, setPeopleIds] = useState<any>([]);
  const api = new PeopleResource();
  const navigate = useNavigate();
  const searchRef = useRef<any>('');
  const queryClient = useQueryClient();
  const toast = useToast();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [totalData, setTotalData] = useState<number | undefined>(undefined);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: INITIAL_CURRENT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    keyword: '',
  });

  const { loggedInUser } = useSelector(
    (state: any) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  const { data: dataList, isLoading: isListLoading } = useQuery(
    [
      'peopleList',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: useDebounce(filterParams.keyword, 500),
      },
    ],
    async () => {
      const queryParams: any = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        search: filterParams.keyword,
      };
      const response = await api.list(queryParams);
      setTotalData(response?.data?.meta?.total);
      response && setIsLoading(null);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const filterList = async (data: any) => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: data.keyword,
    }));
  };

  const handleSearch = () => {
    filterList({ keyword: searchRef.current.value });
  };

  const handleExport = async () => {
    let dataArray = [
      {
        ['Serial Number']: '',
        ['First Name']: '',
        ['Last Name']: '',
        ['Company Name']: '',
        ['Email']: '',
        ['Type']: '',
        ['EIN/SSN']: '',
        ['Address']: '',
        ['Phone']: '',
        ['Region']: '',
        ['Notes']: '',
      },
    ];

    const response = await api.list({ paginated: false });
    if (response?.data?.results.length > 0) {
      if (peopleIds.length > 0) {
        dataArray = await response?.data?.results
          ?.filter((res: any, index: number) => peopleIds.includes(res.id))
          .map((data: any) => {
            return {
              ['Serial Number']: data?.id,
              ['First Name']: `${data?.first_name}`,
              ['Last Name']: `${data?.last_name}`,
              ['Company Name']: `${data?.company_name}`,
              ['Email']: data?.email,
              ['Type']: capitalizeFirstLetter(data?.user_type),
              ['EIN/SSN']: data?.ein_ssn,
              ['Address']: data?.address,
              ['Phone']: data?.phone,
              ['Region']: data?.region,
              ['Notes']: data?.notes || '',
            };
          });
      } else {
        dataArray = await response?.data?.results?.map(
          (res: any, index: number) => {
            return {
              ['Serial Number']: res?.id,
              ['First Name']: `${res?.first_name}`,
              ['Last Name']: `${res?.last_name}`,
              ['Company Name']: `${res?.company_name}`,
              ['Email']: res?.email,
              ['Type']: capitalizeFirstLetter(res?.user_type),
              ['EIN/SSN']: res?.ein_ssn,
              ['Address']: res?.address,
              ['Phone']: res?.phone,
              ['Region']: res?.region,
              ['Notes']: res?.notes || '',
            };
          }
        );
      }
    }

    csvExport(dataArray, `People_${DateFormat(new Date())}` + '.xlsx');
  };

  const deleteData = useMutation(
    () => api.bulkDelete({ people_ids: peopleIds }),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('peopleList');
        setDeleteModalOpen(false);
        toast({
          title: `${peopleIds.length} People has been deleted.`,
          status: 'success',
          isClosable: true,
        });
        setPeopleIds([]);
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Stack direction="row">
            <Heading size="md">People management</Heading>
            <InputGroup>
              <InputLeftElement
                children={<Icon color="#737373" w="4" h="4" as={BiSearch} />}
              />
              <Input
                w={[96, 60, 60, 96]}
                type="text"
                placeholder="Search"
                ref={searchRef}
                onKeyUp={handleSearch}
              />
            </InputGroup>
          </Stack>

          <Stack direction="row">
            {/* {peopleIds.length > 0 && (
              <Button color="red.300" onClick={onDeletePress} size="sm">
                Delete People ({peopleIds.length})
              </Button>
            )} */}
            <Button
              colorScheme="primary"
              leftIcon={<Icon as={BiImport} w="6" h="6" />}
              onClick={handleExport}
              size="sm">
              Download
            </Button>
            <CsvUpload
              url="upload-people-csv"
              modal="people"
              setRefetch={setRefetch}
              headerSchema={PEOPLE_HEADER}
              label="Import"
            />
            <Button
              size="sm"
              colorScheme="primary"
              onClick={() => navigate(routes.manage.peoples.create)}>
              Add People
            </Button>
          </Stack>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>
                    <Checkbox
                      isChecked={
                        peopleIds.length ===
                        dataList?.results?.map(
                          (people: PeopleSchema) => people.id
                        ).length
                      }
                      onChange={() => {
                        const peoples = dataList?.results?.map(
                          (people: PeopleSchema) => people.id
                        );
                        if (peopleIds.length === peoples.length) {
                          setPeopleIds([]);
                        } else {
                          setPeopleIds(peoples);
                        }
                      }}></Checkbox>
                  </Th> */}
                  <Th>Name</Th>
                  <Th>Type</Th>
                  <Th>Company Name</Th>
                  <Th>EIN/SSN</Th>
                  <Th>Address</Th>
                  <Th>Phone</Th>
                  <Th>Email</Th>
                  <Th>Status</Th>
                  <Th>Role Type</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isListLoading &&
                  dataList?.results?.map((listData: PeopleSchema) => {
                    if (listData.id !== loggedInUser.id)
                      return (
                        <PeopleListItem
                          listData={listData}
                          key={listData.id}
                          setPeopleIds={setPeopleIds}
                          peopleIds={peopleIds}
                        />
                      );
                  })}
                {isListLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={10} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        {dataList?.results && (
          <Pagination
            dataList={dataList}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
        )}
      </Stack>
    </>
  );
};

export default PeopleList;
