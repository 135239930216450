import {
  Box,
  Button,
  ButtonGroup,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import {
  setActivityList,
  setCustomerList,
  setEmployeeList,
  setEquipmentList,
  setEquipmentTypeList,
  setLaborList,
  setOperationDowntimeCodeList,
  setProductList,
  setShiftDowntimeCodeList,
  setVendorList,
} from 'actions/data/report';

import ReportResource from 'api/report';
import CapitalEquipmentReport from 'components/report/CapitalEquipmentReport';
import EquipmentReport from 'components/report/EquipmentReport';
import ForemanReportSidebar from 'components/report/ForemanReportSidebar';
import FuelUsageReport from 'components/report/FuelUsageReport';
import LaborReport from 'components/report/LaborReport';
import LoadoutReport from 'components/report/LoadoutReport';
import NotesReport from 'components/report/NotesReport';
import OperationalDetailReport from 'components/report/OperationalDetailReport';
import OperationsDownTimeReport from 'components/report/OperationsDownTimeReport';
import ProductionReport from 'components/report/ProductionReport';
import RentalReport from 'components/report/RentalReport';
import ShiftDownTimeReport from 'components/report/ShiftDownTimeReport';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useReportDropDown from 'hooks/useReportDropDown';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

const ForemanReport: React.FC = () => {
  const [reportName, setReportName] = useState<string>('');
  const params = new URLSearchParams(location.search);
  const reportIdParams = params.get('report_id') || '';
  const api = new ReportResource();
  const isOnline = useConnectionStatus();
  const { queue, reportId, setReportId, reportBody } = useContext(OfflineContext);

  const navigate = useNavigate();

  // useQuery(
  //   [`isExistForemanId${reportIdParams}`, reportIdParams],
  //   () =>
  //     api
  //       .getForeman(reportIdParams)
  //       .then((res) => {
  //         return res?.data;
  //       })
  //       .catch((err) => {
  //         alert('error');
  //         navigate(-1);
  //       }),
  //   {
  //     cacheTime: 0,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  React.useEffect(() => {
    setReportId(reportIdParams);
  }, [reportIdParams]);

  const dispatch = useDispatch();
  const {
    isVendorLoading,
    vendorOptions,
    isEquipmentLoading,
    equipmentOptions,
    isEquipmentTypeLoading,
    equipmentTypeOptions,
    isCustomerLoading,
    customerOptions,
    isProductLoading,
    productOptions,
    isOperationDownTimeCodeLoading,
    operationDownTimeCodeOptions,
    isShiftDownTimeCodeLoading,
    shiftDownTimeCodeOptions,
    isEmployeeLoading,
    employeeOptions,
    isActivityLoading,
    activityOptions,
    isLaborLoading,
    laborOptions,
  } = useReportDropDown();
  useEffect(() => {
    dispatch(setVendorList({ isVendorLoading, vendorOptions }));
    dispatch(setEquipmentList({ isEquipmentLoading, equipmentOptions }));
    dispatch(
      setEquipmentTypeList({ isEquipmentTypeLoading, equipmentTypeOptions })
    );

    dispatch(setCustomerList({ isCustomerLoading, customerOptions }));
    dispatch(setProductList({ isProductLoading, productOptions }));
    dispatch(
      setOperationDowntimeCodeList({
        isOperationDownTimeCodeLoading,
        operationDownTimeCodeOptions,
      })
    );
    dispatch(
      setShiftDowntimeCodeList({
        isShiftDownTimeCodeLoading,
        shiftDownTimeCodeOptions,
      })
    );
    dispatch(setEmployeeList({ isEmployeeLoading, employeeOptions }));
    dispatch(setActivityList({ isActivityLoading, activityOptions }));
    dispatch(setLaborList({ isLaborLoading, laborOptions }));
  });
  const toast = useToast();

  const reportSave: any = useMutation(
    () => api.storeReportSave(reportId, { is_saved: true }),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Report has been saved.',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => { },
    }
  );

  const handleSave = () => reportSave.mutate();

  const reportComplete = useMutation(
    () => api.storeReportComplete(reportId, { is_completed: true }),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Report has been completed.',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => {
        toast({
          title: error?.response?.data?.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const handleComplete = () => {
    if (isOnline) {
      reportComplete.mutate()
    } else {
      toast({
        title: 'To complete the report, please go online',
        status: 'info',
        isClosable: true,
      });
    }
  };

  // if (isExistForemanId.isError) {
  //   navigate(-1);
  // }

  // if (isExistForemanId.isLoading) {
  //   return <CenterSpinner />;
  // }

  return (
    <>
      <SimpleGrid
        columns={[1, null, 2]}
        spacing="15px"
        gridTemplateColumns={{ base: 'repeat(1,1fr)', lg: '20% 80%' }}>
        <Box>
          <ForemanReportSidebar title={reportName} reportId={+reportId} />
        </Box>
        <Box mr={'20px'}>
          <OperationalDetailReport
            setReportId={setReportId}
            reportId={reportId}
            setReportName={setReportName}
          />
          {reportId && <LaborReport reportId={reportId} />}
          {reportId && <ProductionReport reportId={reportId} />}
          {reportId && <CapitalEquipmentReport reportId={reportId} />}
          {reportId && <ShiftDownTimeReport reportId={reportId} />}
          {reportId && <OperationsDownTimeReport reportId={reportId} />}
          {reportId && <FuelUsageReport reportId={reportId} />}
          {reportId && isOnline && <LoadoutReport reportId={reportId} />}
          {reportId && <RentalReport reportId={reportId} />}
          {reportId && <EquipmentReport reportId={reportId} />}
          {reportId && <NotesReport reportId={reportId} />}
          {reportId && (
            <Box mt={3}>
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  onClick={handleComplete}
                  isLoading={reportComplete.isLoading}>
                  Complete
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={handleSave}
                  isLoading={reportSave.isLoading}>
                  Save
                </Button>
              </ButtonGroup>
            </Box>
          )}
        </Box>
      </SimpleGrid>
    </>
  );
};

export default ForemanReport;
