import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import PlantResource from 'api/plant';
import PlantForm from 'components/plant/PlantForm';
import routes from 'constants/routes';
import { PlantSchema } from 'interface/plant/plantSchema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

const CreatePlant: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const navigate = useNavigate();
  const methods = useForm<PlantSchema>();
  const api = new PlantResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addPlant = useMutation((data: any) => api.store(data), {
    onSuccess: (res: any) => {
      toast({
        title: 'Plant has been created.',
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('plantList');
      navigate(routes.manage.plants.list);
    },
    onError: (error: any) => {
      const objKey: any = Object.keys(error?.response?.data);
      if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
      else setErrorMessage('Plant Creation Failed.');
    },
  });

  const onSubmit = async (data: any) => {
    const plant = {
      name: data?.name,
      msha_identification: data?.msha_identification,
      pit: data?.pit?.value,
      // business_unit: await data?.business_unit?.map((res: any) => res?.id),
      // operation: await data?.operation?.map((res: any) => res?.id),
      // shift: await data?.shift?.map((res: any) => res?.id),
    };
    addPlant.mutate(plant);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">Add Plant</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addPlant.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <PlantForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addPlant.isLoading}>
                  Add Plant
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreatePlant;
