import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import PlantResource from 'api/plant';
import { infoStyles, labelStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { ShiftDays } from 'constants/common';
import routes from 'constants/routes';
import { PlantListSchema } from 'interface/plant/plantSchema';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const ViewPlant: React.FC = () => {
  const { id }: any = useParams();
  const api = new PlantResource();
  const navigate = useNavigate();
  const shiftDaysEnum = ShiftDays;

  const dataQuery = useQuery<PlantListSchema>(
    [`plant${id}`, id],
    () => api.get(id).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (dataQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (dataQuery.isError) {
    navigate(routes.manage.plants.list);
  }

  return (
    <>
      <Stack direction="column" spacing="4">
        <Flex justify="space-between">
          <Heading size="md" textTransform="capitalize">
            Plant Details
          </Heading>
          <Button
            size="sm"
            colorScheme="primary"
            onClick={() =>
              navigate(routes.manage.plants.edit.replace(':id', id))
            }>
            {'Edit Plant'}
          </Button>
        </Flex>

        <Stack bg="white" p={['3', '6']} shadow="box" rounded="sm">
          <Grid
            gap="4"
            templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
            w="100%">
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Plant</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>MSHA Identification</FormLabel>
                <Box sx={infoStyles}>
                  {dataQuery?.data?.msha_identification || '-'}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Pit</FormLabel>
                <Box sx={infoStyles}>{dataQuery?.data?.pit?.name || '-'}</Box>
              </FormControl>
            </GridItem>
            {/* <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Business Unit</FormLabel>
                <Box sx={infoStyles}>
                  {groupData(dataQuery?.data?.business_unit)}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Operation</FormLabel>
                <Box sx={infoStyles}>
                  {groupData(dataQuery?.data?.operation)}
                </Box>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel sx={labelStyles}>Shift</FormLabel>
                <Box sx={infoStyles}>{groupData(dataQuery?.data?.shift)}</Box>
              </FormControl>
            </GridItem> */}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default ViewPlant;
