import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import AFEResource from 'api/afe';
import routes from 'constants/routes';
import { AFESchemaType } from 'interface/afe/afeSchema';
import React, { useState } from 'react';
import { BiListMinus, BiListPlus, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { DateFormatMDY, formatNumber } from 'utils';

const AFEListItem: React.FC<AFESchemaType> = (props) => {
  const { listData, setAFEIds, afeIds } = props;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const api = new AFEResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isStatusModalOpen, setStatusModalOpen] = useState(false);

  const handleCheckBox = () => {
    const index = afeIds.indexOf(listData.id);
    if (index > -1) {
      setAFEIds([...afeIds.slice(0, index), ...afeIds.slice(index + 1)]);
    } else {
      setAFEIds([...afeIds, listData.id]);
    }
  };

  const deleteData = useMutation(() => api.destroy(listData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('afeList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: 'Afe has been deleted.',
          status: 'success',
          isClosable: true,
        });
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteData.mutate();
  };

  const changeStatus = useMutation(() => api.toggleStatus(listData.id), {
    onSuccess: (res) => {
      const statusText = listData?.completed ? 'active' : 'inactive';
      queryClient.invalidateQueries('afeList');

      setStatusModalOpen(false);
      toast({
        title: `AFE has been ${statusText}.`,
        status: 'success',
        isClosable: true,
      });
    },
  });

  const onStatusPress = () => {
    setStatusModalOpen(true);
  };

  const onStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const onStatusConfirm = () => {
    changeStatus.mutate();
  };

  return (
    <Tr key={listData.id}>
      <Td>
        <Checkbox
          isChecked={afeIds.includes(listData.id)}
          onChange={handleCheckBox}></Checkbox>
      </Td>
      <Td
        _hover={{ cursor: 'pointer' }}
        key={listData.id}
        onClick={() =>
          navigate(routes.afe.view.replace(':id', listData.id.toString()))
        }>
        {listData?.number}
      </Td>
      <Td>{listData?.name}</Td>
      <Td>{listData?.plant?.name || '-'}</Td>
      <Td>{DateFormatMDY(listData?.start_date)}</Td>
      <Td>{DateFormatMDY(listData?.end_date)}</Td>
      <Td>{formatNumber(+listData?.shift_hr, 2) || 0}</Td>
      <Td>{formatNumber(+listData?.shift_per_week, 2) || 0}</Td>
      <Td>{formatNumber(+listData?.op_efficiency, 2) || 0}</Td>
      <Td>{formatNumber(+listData?.op_hour, 2) || 0}</Td>
      <Td>{formatNumber(+listData?.op_hour, 2) || 0}</Td>
      <Td>{formatNumber(+listData?.op_hr_per_shift, 2) || 0}</Td>
      <Td>{formatNumber(+listData?.total_shifts, 2) || 0}</Td>
      <Td>
        <Link to={routes.afe.view.replace(':id', listData?.id.toString())}>
          <Tooltip hasArrow label={'View'}>
            <IconButton
              icon={<BiShow />}
              variant="link"
              aria-label={'View'}
              color="green.300"
              size="lg"
            />
          </Tooltip>
        </Link>

        <Tooltip hasArrow label={'Delete'}>
          <IconButton
            icon={<BiTrash />}
            variant="link"
            aria-label={'Delete'}
            color="red.300"
            size={'lg'}
            onClick={onDeletePress}
          />
        </Tooltip>

        <Tooltip hasArrow label={listData.completed ? 'Active' : 'Inactive'}>
          <IconButton
            icon={listData.completed ? <BiListMinus /> : <BiListPlus />}
            variant="link"
            aria-label={listData.completed ? 'Active' : 'Inactive'}
            color="red.300"
            size={'lg'}
            onClick={onStatusPress}
          />
        </Tooltip>

        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Delete Afe'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {'Are you sure you want to delete this ?'}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={deleteData.isLoading}>
                  {'Delete'}
                </Button>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {'Cancel'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isStatusModalOpen}
          isCentered
          onClose={onStatusModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {listData?.completed ? 'Active' : 'Inactive'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            Are you sure you want to change it to{' '}
              {listData?.completed ? 'active' : 'inactive'}?
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onStatusConfirm}
                  isLoading={changeStatus.isLoading}>
                  Submit
                </Button>

                <Button variant="outline" onClick={onStatusModalClose}>
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default AFEListItem;
