import Resource from 'api/resource';
import http from 'utils/http';

class PitResource extends Resource {
  constructor() {
    super('pits');
  }

  bulkDelete(resource: any) {
    return http({
      url: '/bulk-destroy/pits',
      method: 'post',
      data: resource,
    });
  }

  relatedPlant(id: string, query?: any) {
    return http({
      url: `/pits/${id}/plants`,
      method: 'get',
      params: query,
    });
  }
}

export { PitResource as default };
