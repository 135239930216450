import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import EquipmentClassResource from 'api/equipmentClass';
import PlantResource from 'api/plant';
import OwnerResource from 'api/user';
import { reactSelectStyles } from 'assets/css/commonStyles';
import CreatedAtInput from 'components/common/CreatedAtInput';
import {
  OperatedStatusEnum,
  OperatedStatusList,
  OwnerOrRentList,
  ownerOrRentMapping,
  REACT_DATE_PICKER_FORMAT_YYYY_MM_DD,
} from 'constants/common';
import { EquipmentSchema } from 'interface/equipment/equipmentSchema';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';
import { DateFormatMDY } from 'utils';

interface EquipmentProps {
  equipmentData?: any;
}

const EquipmentForm: React.FC<EquipmentProps> = (props) => {
  const { equipmentData } = props;
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext<EquipmentSchema>();
  const operatedStatusEnum: any = OperatedStatusEnum;

  const beginningDate =
    equipmentData && equipmentData?.beginning_date
      ? new Date(equipmentData?.beginning_date)
      : new Date();
  const [beginningDateCreatedAt, setBeginningDateCreatedAt] =
    useState<Date | null>(beginningDate);

  const endDate =
    equipmentData && equipmentData?.end_date
      ? new Date(equipmentData?.end_date)
      : null;
  const [endDateCreatedAt, setEndDateCreatedAt] = useState<Date | null>(
    endDate
  );

  const handleBeginningDateChange = (date: Date) => {
    setBeginningDateCreatedAt(date);
    setValue('beginning_date', DateFormatMDY(date));
  };

  const handleEndDateChange = (date: Date) => {
    setEndDateCreatedAt(date);
    setValue('end_date', DateFormatMDY(date));
  };

  // Owner List
  const ownerAPI = new OwnerResource();
  const { data: ownerQuery, isLoading: isOwnerLoading } = useQuery(
    [`owner-list`],
    () => ownerAPI.list({ limit: 50 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const ownerList = ownerQuery?.results;
  const ownerOptions = ownerList?.map((owner: any) => ({
    label: `${owner?.first_name} ${owner?.last_name}`,
    value: owner.id.replaceAll('-', ''),
  }));

  // Plant List
  const plantAPI = new PlantResource();
  const { data: plantQuery, isLoading: isPlantLoading } = useQuery(
    [`plant-list`],
    () => plantAPI.list({ limit: 50 }).then((res) => res?.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const plantList = plantQuery?.results;
  const plantOptions = plantList?.map((plant: any) => ({
    label: plant.name,
    value: plant.id,
  }));

  // Equipment Class List
  const equipmentClassAPI = new EquipmentClassResource();
  const { data: equipmentClassQuery, isLoading: isEquipmentClassLoading } =
    useQuery(
      [`equipment-class-list`],
      () => equipmentClassAPI.list({ limit: 50 }).then((res) => res?.data),
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );
  const equipmentClassList = equipmentClassQuery?.results;
  const equipmentClassOptions = equipmentClassList?.map(
    (equipmentClass: any) => ({
      label: equipmentClass.class_code,
      value: equipmentClass.id,
    })
  );

  useEffect(() => {
    if (!equipmentData) return;
    setValue('beginning_date', equipmentData?.beginning_date);
    setValue('end_date', equipmentData?.end_date);
    if (equipmentData?.operated_status)
      setValue('operated_status', {
        label: operatedStatusEnum[equipmentData?.operated_status].toString(),
        value: equipmentData?.operated_status,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentData, setValue]);

  useEffect(() => {
    if (!equipmentData) return;
    setValue('owner_or_rent', {
      label: ownerOrRentMapping[equipmentData?.owner_or_rent],
      value: equipmentData?.owner_or_rent,
    });

    setValue('plant', {
      label: equipmentData?.plant?.name,
      value: equipmentData?.plant?.id,
    });

    setValue('equipment_class', {
      label: equipmentData?.equipment_class?.class_code,
      value: equipmentData?.equipment_class?.id,
    });
    setValue('code', equipmentData?.code);
  }, [equipmentData, setValue]);

  const handleEquipmentClass = (option: { label: string; value: number }) => {
    const equipmentClass = equipmentClassList.filter(
      (res: any) => res.id === option.value
    );
    if (equipmentClass.length > 0) setValue('code', equipmentClass[0].code);
  };

  return (
    <>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <GridItem>
            <FormControl isInvalid={!!errors?.name} isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', {
                  required: 'Name',
                  minLength: {
                    value: 1,
                    message: 'Too few characters.',
                  },
                  maxLength: {
                    value: 64,
                    message: 'Too many characters.',
                  },
                })}
                type="text"
                defaultValue={equipmentData?.name}
                placeholder={'Name'}
              />
              <FormErrorMessage>
                {errors?.name && errors?.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.description}>
              <FormLabel>Description</FormLabel>
              <Textarea
                {...register('description', {
                  required: 'Description',
                  maxLength: {
                    value: 100,
                    message: 'Too many characters.',
                  },
                })}
                placeholder="Enter description here"
                defaultValue={equipmentData?.description}></Textarea>
              <FormErrorMessage>
                {errors?.description && errors?.description?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.equipment_class} isRequired>
              <FormLabel>Equipment class</FormLabel>
              <Controller
                control={control}
                name="equipment_class"
                rules={{
                  required: 'Equipment class',
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="equipment_class"
                    placeholder={'Equipment class'}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                      // handleEquipmentClass(selectedOption);
                    }}
                    options={equipmentClassOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                  />
                )}
              />
              <FormErrorMessage>
                {!!errors?.equipment_class &&
                  !!errors?.equipment_class?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>Code</FormLabel>
              <Input
                {...register('code', {})}
                type="text"
                defaultValue={equipmentData?.code}
                placeholder={'Code'}
              />
            </FormControl>
          </GridItem>
          {/* <GridItem>
            <FormControl isInvalid={!!errors?.optional}>
              <FormLabel>{'Optional'}</FormLabel>
              <Controller
                control={control}
                name="optional"
                rules={{}}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="optional"
                    placeholder={'Optional'}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                    }}
                    options={
                      [
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ] as any
                    }
                    styles={reactSelectStyles}
                    isSearchable={true}
                  />
                )}
              />
              <FormErrorMessage>
                {!!errors?.optional && !!errors?.optional?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem> */}
          <GridItem>
            <FormControl isInvalid={!!errors?.owner_or_rent} isRequired>
              <FormLabel>{'Own, Rent, or RPO'}</FormLabel>
              <Controller
                control={control}
                name="owner_or_rent"
                rules={{
                  required: 'Owner, Rent, or RPO',
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="owner_or_rent"
                    placeholder={'Own, Rent, or RPO'}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                    }}
                    options={OwnerOrRentList as any}
                    styles={reactSelectStyles}
                    isSearchable={true}
                  />
                )}
              />
              <FormErrorMessage>
                {!!errors?.owner_or_rent && !!errors?.owner_or_rent?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.owner} isRequired>
              <FormLabel>{'Owner'}</FormLabel>
              <Input
                {...register('owner', {
                  required: 'Owner',
                })}
                type="text"
                defaultValue={equipmentData?.owner}
                placeholder={'Owner'}
              />
              <FormErrorMessage>
                {!!errors?.owner && !!errors?.owner?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.location} isRequired>
              <FormLabel>Physical Location</FormLabel>
              <Input
                {...register('location', {
                  required: 'Physical Location',
                  minLength: {
                    value: 1,
                    message: 'Too few characters.',
                  },
                  maxLength: {
                    value: 64,
                    message: 'Too many characters.',
                  },
                })}
                type="text"
                defaultValue={equipmentData?.location}
                placeholder={'Physical Location'}
              />
              <FormErrorMessage>
                {errors?.location && errors?.location?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.party} isRequired>
              <FormLabel>Party</FormLabel>
              <Input
                {...register('party', {
                  required: 'Party',
                  minLength: {
                    value: 1,
                    message: 'Too few characters.',
                  },
                  maxLength: {
                    value: 32,
                    message: 'Too many characters.',
                  },
                })}
                type="text"
                defaultValue={equipmentData?.party}
                placeholder={'Party'}
              />
              <FormErrorMessage>
                {errors?.party && errors?.party?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.wet_rate} isRequired>
              <FormLabel>Wet rate</FormLabel>
              <Input
                {...register('wet_rate', {
                  required: 'Wet rate',
                })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.wet_rate}
                placeholder={'Wet rate'}
              />
              <FormErrorMessage>
                {errors?.wet_rate && errors?.wet_rate?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.dry_rate} isRequired>
              <FormLabel>Dry rate</FormLabel>
              <Input
                {...register('dry_rate', {
                  required: 'Dry rate',
                })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.dry_rate}
                placeholder={'Dry rate'}
              />
              <FormErrorMessage>
                {errors?.dry_rate && errors?.dry_rate?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.beginning_date} isRequired>
              <FormLabel>{'Beginning date'}</FormLabel>
              <ReactDatePicker
                fixedHeight
                dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                customInput={<CreatedAtInput />}
                onChange={handleBeginningDateChange}
                selected={beginningDateCreatedAt}
                value={DateFormatMDY(beginningDateCreatedAt)}
                shouldCloseOnSelect={false}
                autoComplete="off"
              />
              <FormErrorMessage>
                {!!errors?.beginning_date && !!errors?.beginning_date?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.end_date}>
              <FormLabel>{'End date'}</FormLabel>
              <ReactDatePicker
                fixedHeight
                dateFormat={REACT_DATE_PICKER_FORMAT_YYYY_MM_DD}
                customInput={<CreatedAtInput />}
                onChange={handleEndDateChange}
                selected={endDateCreatedAt}
                value={DateFormatMDY(endDateCreatedAt)}
                shouldCloseOnSelect={false}
                isClearable
                autoComplete="off"
              />
              <FormErrorMessage>
                {!!errors?.end_date && !!errors?.end_date?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.serial_number}>
              <FormLabel>Serial number</FormLabel>
              <Input
                {...register('serial_number', {
                  minLength: {
                    value: 1,
                    message: 'Too few characters.',
                  },
                  maxLength: {
                    value: 32,
                    message: 'Too many characters.',
                  },
                })}
                type="text"
                defaultValue={equipmentData?.serial_number}
                placeholder={'Serial number'}
              />
              <FormErrorMessage>
                {errors?.serial_number && errors?.serial_number?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.fuel_per_hour}>
              <FormLabel>Fuel/hour</FormLabel>
              <Input
                {...register('fuel_per_hour', { required: false })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.fuel_per_hour || 0}
                placeholder={'Fuel per hour'}
              />
              <FormErrorMessage>
                {errors?.fuel_per_hour && errors?.fuel_per_hour?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.monthly_rental}>
              <FormLabel>Monthly rental</FormLabel>
              <Input
                {...register('monthly_rental', { required: false })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.monthly_rental || 0}
                placeholder={'Monthly rental'}
              />
              <FormErrorMessage>
                {errors?.monthly_rental && errors?.monthly_rental?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.cost}>
              <FormLabel>Equipment Cost</FormLabel>
              <Input
                {...register('cost', { required: false })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.cost || 0}
                placeholder={'Equipment Cost'}
              />
              <FormErrorMessage>
                {errors?.cost && errors?.cost?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.wear_per_hour_insurance}>
              <FormLabel>Wear/hr. Insurance</FormLabel>
              <Input
                {...register('wear_per_hour_insurance', { required: false })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.wear_per_hour_insurance || 0}
                placeholder={'Wear/hr. Insurance'}
              />
              <FormErrorMessage>
                {errors?.wear_per_hour_insurance &&
                  errors?.wear_per_hour_insurance?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.yard_accural}>
              <FormLabel>Yard Accrual</FormLabel>
              <Input
                {...register('yard_accural', { required: false })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.yard_accural || 0}
                placeholder={'Yard Accrual'}
              />
              <FormErrorMessage>
                {errors?.yard_accural && errors?.yard_accural?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.r_m_per_hour}>
              <FormLabel>R+M/hr</FormLabel>
              <Input
                {...register('r_m_per_hour', { required: false })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.r_m_per_hour || 0}
                placeholder={'R+M/hr'}
              />
              <FormErrorMessage>
                {errors?.r_m_per_hour && errors?.r_m_per_hour?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.budgeted_operating_hours}>
              <FormLabel>Budgeted Operating Hours</FormLabel>
              <Input
                {...register('budgeted_operating_hours', { required: false })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.budgeted_operating_hours || 0}
                placeholder={'Budgeted Operating Hours'}
              />
              <FormErrorMessage>
                {errors?.budgeted_operating_hours &&
                  errors?.budgeted_operating_hours?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.budgeted_idle_hours_month}>
              <FormLabel>Budgeted Idle Hours Month</FormLabel>
              <Input
                {...register('budgeted_idle_hours_month', { required: false })}
                type="number"
                step="0.01"
                defaultValue={equipmentData?.budgeted_idle_hours_month || 0}
                placeholder={'Budgeted Idle Hours Month'}
              />
              <FormErrorMessage>
                {errors?.budgeted_idle_hours_month &&
                  errors?.budgeted_idle_hours_month?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.note}>
              <FormLabel>Note</FormLabel>
              <Textarea
                {...register('note', {
                  required: false,
                })}
                placeholder="Enter note here"
                defaultValue={equipmentData?.note}></Textarea>
              <FormErrorMessage>
                {errors?.note && errors?.note?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.plant}>
              <FormLabel>{'Plant'}</FormLabel>
              <Controller
                control={control}
                name="plant"
                rules={{}}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="plant"
                    placeholder={'Plant'}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                    }}
                    options={plantOptions}
                    styles={reactSelectStyles}
                    isSearchable={true}
                  />
                )}
              />
              <FormErrorMessage>
                {!!errors?.plant && !!errors?.plant?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.operated_status}>
              <FormLabel>{'Operated/Non Operated'}</FormLabel>
              <Controller
                control={control}
                name="operated_status"
                rules={{}}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    id="operated_status"
                    placeholder={'Operated/Non Operated'}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption);
                    }}
                    options={OperatedStatusList as any}
                    styles={reactSelectStyles}
                    isSearchable={true}
                  />
                )}
              />
              <FormErrorMessage>
                {!!errors?.operated_status &&
                  !!errors?.operated_status?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};

export default EquipmentForm;
