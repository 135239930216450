import Resource from 'api/resource';
import http from 'utils/http';

class LaborDetailResource extends Resource {
  constructor() {
    super('labors');
  }

  listLaborDetailReport(id: number, query?: any) {
    return http({
      url: `/labors/${id}/activities`,
      method: 'get',
      params: query,
    });
  }
  getLaborDetailReport(id: number, laborId: string) {
    return http({
      url: `/labors/${id}/activities/${laborId}`,
      method: 'get',
    });
  }
  storeLaborDetailReport(id: number, resource: any, headers?: any) {
    return http({
      url: `/labors/${id}/activities`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateLaborDetailReport(id: number, laborId: string, resource: any) {
    return http({
      url: `/labors/${id}/activities/${laborId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyLaborDetailReport(id: number, laborId: string) {
    return http({
      url: `/labors/${id}/activities/${laborId}`,
      method: 'delete',
    });
  }

  // Mechanic Report
  mechanicalListLaborDetailReport(id: number, query?: any) {
    return http({
      url: `/labors/${id}/mechanic-activities`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetLaborDetailReport(id: number, laborId: string) {
    return http({
      url: `/labors/${id}/mechanic-activities/${laborId}`,
      method: 'get',
    });
  }
  mechanicalStoreLaborDetailReport(id: number, resource: any, headers?: any) {
    return http({
      url: `/labors/${id}/mechanic-activities`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdateLaborDetailReport(
    id: number,
    laborId: string,
    resource: any
  ) {
    return http({
      url: `/labors/${id}/mechanic-activities/${laborId}`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyLaborDetailReport(id: number, laborId: string) {
    return http({
      url: `/labors/${id}/mechanic-activities/${laborId}`,
      method: 'delete',
    });
  }
}

export { LaborDetailResource as default };
