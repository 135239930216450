import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import BusinessUnitResource from 'api/businessUnit';
import OperationResource from 'api/operation';
import ProductionReportResource from 'api/productionReport';
import ReportResource from 'api/report';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import TagModal from 'components/common/TagModal';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useDebounceFunc from 'hooks/useDebounceFunc';
import {
  ReportProps,
  ReportSearchInterface,
} from 'interface/report/reportSchema';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { BsTag, BsTrash } from 'react-icons/bs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactSelect from 'react-select';
import { formatNumber } from 'utils';
import { v4 as uuid } from "uuid";

const ProductionReport: React.FC<ReportProps> = (props) => {
  const { previousReportId, setReportBody, updateQueue, reportBody } = useContext(OfflineContext);
  const offlineBusinessUnitList = useSelector((state: any) => state.data.offline.business_units)
  const offlineOperationList = useSelector((state: any) => state.data.offline.operations)
  const offlineProductList = useSelector((state: any) => state.data.offline.products)
  const [offlineProductFilteredByPreviousReportId, setOfflineProductFilteredByPreviousReportId] = useState(offlineProductList.products
    ? [...offlineProductList.products?.filter(
      (item: any) => item.report_id === previousReportId
    )]
    : []);
  const isOnline = useConnectionStatus();
  const { reportId } = props;
  const [tonState, setTonState] = useState(0);
  const [yardState, setYardState] = useState(0);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('');
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const containerRef = useRef<any>();
  const [totalSplit, setTotalSplit] = useState(0);
  const [totalReveneue, setTotalReveneue] = useState(0);
  const [tagData, setTagData] = useState(0);
  const [confirmDeleteId, setConfirmDeleteId] = useState(0);
  const reportAPI = new ReportResource();

  const [tagHeaderModalOpen, setTagHeaderModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);
  const { productList } = useSelector(
    (state: any) => ({
      productList: state?.data?.report?.productList,
    }),
    shallowEqual
  );

  const methods = useForm<any>();
  const queryClient = useQueryClient();

  const [filterParams, setFilterParams] = useState<ReportSearchInterface>({
    searchProduct: '',
  });

  const { data: reportDetails } = useQuery(
    [`reportDetails`, { reportId }],
    async () => {
      const response = await reportAPI.get(reportId);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const noteQuery = useQuery(
    [`getProductionHeaderStatus`],
    async () => {
      const response = await api.getNoteTag(reportId);
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const api = new ProductionReportResource();
  const { data: productListQuery, isLoading: isProductListLoading } = useQuery(
    [`production-list`],
    () =>
      api
        .listProductionReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          getTotalReveneue(res?.data?.results);
          getSplitPercentage(res?.data?.results);
          return res?.data?.results;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const products = useMemo(() => isOnline
    ? productListQuery?.length > 0
      ? productListQuery
      : []
    : offlineProductFilteredByPreviousReportId, [offlineProductFilteredByPreviousReportId, productListQuery])

  const businessUnitAPI = new BusinessUnitResource();
  const { data: businessUnitQuery, isLoading: isBusinessUnitLoading } =
    useQuery<any>(
      [
        `businessunit-list`,
        {
          search: filterParams.searchBusinessUnit,
        },
      ],
      () => {
        return businessUnitAPI
          .list({
            search: filterParams.searchBusinessUnit,
            limit: 10000,
          })
          .then((res) => res?.data);
      },
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );
  const businessUnitList = businessUnitQuery?.results;
  const businessUnitOptions = businessUnitList?.map((businessUnit: any) => ({
    label: businessUnit.name,
    value: businessUnit.id,
  }));

  // Operation List
  const operationAPI = new OperationResource();
  const { data: operationQuery, isLoading: isOperationLoading } = useQuery<any>(
    [
      `operation-list`,
      {
        search: filterParams.searchOperation,
      },
    ],
    () => {
      return operationAPI
        .list({
          search: filterParams.searchOperation,
          limit: 10000,
        })
        .then((res) => res?.data);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const operationList = operationQuery?.results;
  const operationOptions = operationList?.map((operation: any) => ({
    label: operation.name,
    value: operation.id,
  }));

  const getTons = useQuery(
    [`getTons`],
    () =>
      api
        .listProductionDetailReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          setTonState(res?.data?.tons);
          return res?.data;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setYardState(+(+tonState / 1.4).toFixed(2));
  }, [tonState]);

  const updateProductDetailData = useMutation(
    (data: any) => api.updateProductionDetailReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('production-list');
      },
    }
  );

  const handleTonToYard = (ton: string) => {
    setTonState(+ton);
    if (ton) updateProductDetailData.mutate({ tons: +ton });
    setYardState(+(+ton / 1.4).toFixed(2));
  };
  const debounceTonToYard = useDebounceFunc(handleTonToYard, 3000);

  const handleAdjTons = (ton: string) => {
    if (ton) updateProductDetailData.mutate({ adj_tons: +ton });
  };
  const debounceAdjTons = useDebounceFunc(handleAdjTons, 3000);

  const updateData = useMutation(
    (data: any) =>
      api.updateProductionReport(reportId, data?.productionId, data?.body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('production-list');
      },
    }
  );

  const handleSplit = (product: any, split: string) => {
    if (!product.id) return;
    const data = {
      body: {
        split: split,
        pay: product?.pay,
        tons: product?.tons,
        revenue: product?.revenue,
        product: product?.product?.id,
      },
      productionId: product?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineProductFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === product?.id) {
          return {
            ...r,
            split
          }
        } else {
          return r;
        }
      }))
    }
  };

  const debounceSplit = useDebounceFunc(handleSplit, 3000);

  const handleOperationBusiness = (
    product: any,
    type: string,
    value: number
  ) => {
    if (!product.id) return;
    const data = {
      body: {
        split: product?.split,
        pay: product?.pay,
        tons: product?.tons,
        revenue: product?.revenue,
        product: product?.product?.id,
        business_unit:
          type === 'business_unit' ? value : product?.business_unit?.id,
        operation: type === 'operation' ? value : product?.business_unit?.id,
      },
      productionId: product?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineProductFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === product?.id) {
          switch (type) {
            case 'business_unit':
              const b_unit = offlineBusinessUnitList.business_units.find((elem: any) => elem.value === value);
              return {
                ...r,
                business_unit: b_unit ? { id: b_unit.value, name: b_unit.label } : r.business_unit
              }
            case 'operation':
              const _operation = offlineOperationList.operations.find((elem: any) => elem.value === value);
              return {
                ...r,
                operation: _operation ? { id: _operation.value, name: _operation.label } : r.operation
              }
            default:
              return r;
          }
        } else {
          return r;
        }
      }))
    }
  };

  const debounceOperationBusinessUpdate = useDebounceFunc(
    handleOperationBusiness,
    3000
  );

  const handlePay = (product: any, pay: boolean) => {
    if (!product.id) return;
    const data = {
      body: {
        split: product?.split,
        pay: pay,
        tons: product?.tons,
        revenue: product?.revenue,
        product: product?.product?.id,
      },
      productionId: product?.id,
    };
    if (isOnline) {
      updateData.mutate(data);
    } else {
      setOfflineProductFilteredByPreviousReportId((prev: any) => prev.map((r: any) => {
        if (r.id === product?.id) {
          return {
            ...r,
            pay
          }
        } else {
          return r;
        }
      }))
    }
  };

  const handleProductDelete = (id: number) => {
    setDeleteMsg('');
    setConfirmDeleteId(id);
    setDeleteModalOpen(true);
    queryClient.invalidateQueries('production-list');
  };

  const deleteData = useMutation(
    (productId: number) =>
      api.destroyProductionReport(reportId, productId + ''),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('production-list');
        setDeleteModalOpen(false);
      },
    }
  );

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setConfirmDeleteId(0);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = (id: number) => {
    if (isOnline) {
      if (id != 0) deleteData.mutate(id);
    } else {
      setOfflineProductFilteredByPreviousReportId((prev: any) => prev.filter((r: any) => r.id !== id))
      setDeleteModalOpen(false);
    }
  };

  const getTotalReveneue = (productData: any) => {
    const totalRevenue = productData.reduce(function (acc: number, obj: any) {
      return acc + +obj.revenue;
    }, 0);
    setTotalReveneue(totalRevenue);
  };

  const getSplitPercentage = (productData: any) => {
    const totalSplit = productData.reduce(function (acc: number, obj: any) {
      return acc + +obj.split;
    }, 0);
    setTotalSplit(totalSplit);
  };

  const addData = useMutation(
    (data: any) => {
      return api.storeProductionReport(reportId, data);
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('production-list');
        setAddModalOpen(false);
      },
    }
  );

  const onAddModalClose = () => {
    setAddModalOpen(false);
  };

  const onSubmit = (data: any) => {
    const product = {
      split: data?.split,
      pay: true,
      product: data?.product?.value,
      business_unit: data?.business_unit?.value,
      operation: data?.operation?.value,
    };
    if (isOnline) {
      addData.mutate(product);

      // refresh the page
      queryClient.invalidateQueries('labor-list');
      queryClient.invalidateQueries('labor-detail-list');
      queryClient.invalidateQueries('capital-equipment-list');
      queryClient.invalidateQueries('loadout-list');
      queryClient.invalidateQueries('getLoadoutStatus');
      queryClient.invalidateQueries('loadout-detail-list');
    } else {
      setOfflineProductFilteredByPreviousReportId((prev: any) => ([...prev, {
        ...product,
        product: { id: data?.product?.value, name: data?.product?.label },
        operation: { id: data?.operation?.value, name: data?.operation?.label },
        business_unit: { id: data?.business_unit?.value, name: data?.business_unit?.label },
        id: uuid()
      }]))
      setAddModalOpen(false);
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/production')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    setReportBody((prev: any) => ({
      ...prev, products: offlineProductFilteredByPreviousReportId
    }))
    // update the queue
    updateQueue(reportBody.id, { ...reportBody, products: offlineProductFilteredByPreviousReportId })
  }, [offlineProductFilteredByPreviousReportId])

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    setTagHeaderModalOpen(false);
    queryClient.invalidateQueries('production-list');
    queryClient.invalidateQueries('getTons');
    queryClient.invalidateQueries('getProductionHeaderStatus');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      setTagHeaderModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
      setTagHeaderModalOpen(false);
      return;
    } else if (type === 'tagDetail') {
      setTagModalOpen(false);
      setTagDetailModalOpen(true);
      setTagHeaderModalOpen(false);
      return;
    } else if (type === 'tagHeaderStatus') {
      setTagHeaderModalOpen(true);
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Production</Heading>
          <Stack direction="row" spacing="6">
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(noteQuery?.data, 'tagHeaderStatus')
                }
              />
            </Tooltip>
            {tagData === noteQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagHeaderModalOpen}
                setModalOpen={setTagHeaderModalOpen}
                fieldData={noteQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
              />
            )}
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant={'simple'}>
              <Tbody>
                <Tr bg={'gray.300'}>
                  <Td>Tons</Td>
                  <Td>Adj Tons</Td>
                  <Td>Yards</Td>
                  <Td></Td>
                </Tr>
                <Tr>
                  <Td>
                    <Input
                      type="number"
                      step="0.01"
                      placeholder={'Tons'}
                      defaultValue={getTons?.data?.tons}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        debounceTonToYard(e?.target?.value)
                      }
                    />
                  </Td>
                  <Td>
                    <Input
                      type="number"
                      step="0.01"
                      placeholder={'Adj Tons'}
                      defaultValue={getTons?.data?.adj_tons}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        debounceAdjTons(e.target.value)
                      }
                    />
                  </Td>
                  <Td>{yardState}</Td>
                  <Td>
                    <Tooltip hasArrow label={'Tag'}>
                      <IconButton
                        icon={<BsTag />}
                        variant="link"
                        aria-label={'Tag'}
                        color={'gray.300'}
                        size="lg"
                        onClick={() =>
                          handleTagModal(getTons?.data, 'tagStatus')
                        }
                      />
                    </Tooltip>
                    {tagData === getTons?.data?.tag && (
                      <TagModal
                        isModalOpen={tagModalOpen}
                        setModalOpen={setTagModalOpen}
                        fieldData={getTons?.data?.tag}
                        onModalSubmit={() => onTagModalSubmit()}
                      />
                    )}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>

        <TableContainer>
          <Table>
            <Tbody>
              <Tr bg={'gray.300'}>
                <Td>Product</Td>
                <Td>Split {totalSplit}%</Td>
                <Td>Business Unit</Td>
                <Td>Operation</Td>
                <Td>Pay/Reject</Td>
                <Td isNumeric>Tons</Td>
                <Td isNumeric>Total Revenue ${totalReveneue}</Td>
                <Td>
                  <Tooltip hasArrow label={'Tag'}>
                    <IconButton
                      icon={<BsTag />}
                      variant="link"
                      aria-label={'TAg'}
                      color="gray.300"
                      size="lg"
                    // onClick={()}
                    />
                  </Tooltip>
                </Td>
              </Tr>

              {products?.map((product: any, index: number) => (
                <React.Fragment key={product?.id}>
                  <Tr>
                    <Td>
                      <Text>{product?.product?.name}</Text>
                    </Td>
                    <Td>
                      <Input
                        type="number"
                        step="0.01"
                        placeholder={'Split'}
                        defaultValue={product?.split}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          debounceSplit(product, e.target.value)
                        }
                      />
                    </Td>
                    <Td>
                      <ReactSelect
                        defaultValue={{
                          label: product?.business_unit?.name,
                          value: product?.business_unit?.id,
                        }}
                        placeholder={'Select Business Unit'}
                        isLoading={isOnline ? isBusinessUnitLoading : offlineBusinessUnitList.isLoading}
                        options={isOnline ? businessUnitOptions : offlineBusinessUnitList.business_units}
                        styles={reactSelectStyles}
                        isSearchable={true}
                        onChange={(e: any) =>
                          debounceOperationBusinessUpdate(
                            product,
                            'business_unit',
                            e.value
                          )
                        }
                        menuPortalTarget={document.body}
                      />
                    </Td>
                    <Td>
                      <ReactSelect
                        defaultValue={{
                          label: product?.operation?.name,
                          value: product?.operation?.id,
                        }}
                        placeholder={'Select Operation'}
                        isLoading={isOnline ? isOperationLoading : offlineOperationList.isLoading}
                        options={isOnline ? operationOptions : offlineOperationList.operations}
                        styles={reactSelectStyles}
                        isSearchable={true}
                        onChange={(e: any) =>
                          debounceOperationBusinessUpdate(
                            product,
                            'operation',
                            e.value
                          )
                        }
                        menuPortalTarget={document.body}
                      />
                    </Td>
                    <Td>
                      <Switch
                        id="pay-reject"
                        defaultChecked={product?.pay}
                        colorScheme="teal"
                        size="lg"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePay(product, e.target.checked)
                        }
                      />
                    </Td>
                    <Td isNumeric>{product?.tons}</Td>
                    <Td isNumeric>${formatNumber(product?.revenue)}</Td>
                    <Td>
                      <Tooltip hasArrow label={'Tag'}>
                        <IconButton
                          icon={<BsTag />}
                          variant="link"
                          aria-label={'Tag'}
                          color={'gray.300'}
                          size="lg"
                          onClick={() => handleTagModal(product, 'tagDetail')}
                        />
                      </Tooltip>
                      {tagData === product?.tag && (
                        <TagModal
                          isModalOpen={tagDetailModalOpen}
                          setModalOpen={setTagDetailModalOpen}
                          fieldData={product?.tag}
                          onModalSubmit={() => onTagModalSubmit()}
                        />
                      )}
                      <Tooltip hasArrow label={'Delete'}>
                        <IconButton
                          icon={<BsTrash />}
                          variant="link"
                          aria-label={'Delete'}
                          color="gray.300"
                          size="lg"
                          onClick={() => handleProductDelete(product?.id)}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>

                  <Modal
                    isOpen={isDeleteModalOpen}
                    isCentered
                    onClose={onDeleteModalClose}>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>{'Delete Product'}</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        {'Are you sure you want to delete this ?'}
                        {deleteMsg && (
                          <Alert status="error" mb={2}>
                            <AlertIcon />
                            {deleteMsg}
                          </Alert>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <ButtonGroup>
                          <Button
                            colorScheme="red"
                            onClick={() => onDeleteConfirm(confirmDeleteId)}
                            isLoading={deleteData.isLoading}>
                            {'Delete'}
                          </Button>

                          <Button
                            variant="outline"
                            onClick={onDeleteModalClose}>
                            {'Cancel'}
                          </Button>
                        </ButtonGroup>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </React.Fragment>
              ))}

              {/* Add Product Modal */}
              <Modal
                isOpen={isAddModalOpen}
                isCentered
                onClose={onAddModalClose}
                closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{'Add Production'}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormProvider {...methods}>
                      <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Stack direction="column" spacing="4">
                          {addData.isError && (
                            <Alert status="error">
                              <AlertIcon />
                              {errorMessage}
                            </Alert>
                          )}
                          <Grid
                            gap="4"
                            templateColumns={['repeat(1, 1fr)']}
                            flex="1">
                            <GridItem>
                              <FormControl
                                isInvalid={
                                  !!methods?.formState?.errors?.product
                                }
                                isRequired>
                                <FormLabel>Product</FormLabel>
                                <Controller
                                  control={methods.control}
                                  name="product"
                                  rules={{
                                    required: 'Product',
                                  }}
                                  render={({ field }) => (
                                    <ReactSelect
                                      {...field}
                                      id="product"
                                      placeholder={'Product'}
                                      onChange={(selectedOption) => {
                                        field.onChange(selectedOption);
                                      }}
                                      isLoading={isOnline ? productList.isProductLoading : offlineProductList.isLoading}
                                      options={isOnline ? productList.productOptions : offlineProductList.products}
                                      styles={reactSelectStyles}
                                      isSearchable={true}
                                      menuPortalTarget={document.body}
                                    />
                                  )}
                                />
                                <FormErrorMessage>
                                  {!!methods?.formState?.errors?.product &&
                                    !!methods?.formState?.errors?.product
                                      ?.message}
                                </FormErrorMessage>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl
                                isInvalid={!!methods.formState.errors?.split}
                                isRequired>
                                <FormLabel>{'Split'}</FormLabel>
                                <Input
                                  type="number"
                                  step="0.01"
                                  placeholder={'Split'}
                                  {...methods.register('split', {
                                    required: 'Split ',
                                  })}
                                />
                                <FormErrorMessage>
                                  {!!methods.formState.errors?.split &&
                                    !!methods.formState.errors?.split?.message}
                                </FormErrorMessage>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl
                                isInvalid={
                                  !!methods.formState.errors?.business_unit
                                }
                                isRequired>
                                <FormLabel>{'Business Unit'}</FormLabel>
                                <Controller
                                  control={methods.control}
                                  name="business_unit"
                                  rules={{
                                    required: 'Business Unit',
                                  }}
                                  defaultValue={
                                    {
                                      label: reportDetails?.business_unit?.name,
                                      value: reportDetails?.business_unit?.id,
                                    } || 'Select'
                                  }
                                  render={({ field }) => (
                                    <ReactSelect
                                      {...field}
                                      id="business_unit"
                                      placeholder={'Select Business Unit'}
                                      onChange={(selectedOption) => {
                                        field.onChange(selectedOption);
                                      }}
                                      isLoading={isOnline ? isBusinessUnitLoading : offlineBusinessUnitList.isLoading}
                                      options={isOnline ? businessUnitOptions : offlineBusinessUnitList.business_units}
                                      styles={reactSelectStyles}
                                      isSearchable={true}
                                      menuPortalTarget={document.body}
                                    />
                                  )}
                                />
                                <FormErrorMessage>
                                  {!!methods.formState.errors?.business_unit &&
                                    !!methods.formState.errors?.business_unit
                                      ?.message}
                                </FormErrorMessage>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl
                                isInvalid={
                                  !!methods.formState.errors?.operation
                                }
                                isRequired>
                                <FormLabel>{'Operation'}</FormLabel>
                                <Controller
                                  control={methods.control}
                                  name="operation"
                                  rules={{
                                    required: 'Operation',
                                  }}
                                  defaultValue={
                                    {
                                      label: reportDetails?.operation?.name,
                                      value: reportDetails?.operation?.id,
                                    } || 'Select'
                                  }
                                  render={({ field }) => (
                                    <ReactSelect
                                      {...field}
                                      id="operation"
                                      placeholder={'Select Operation'}
                                      onChange={(selectedOption) => {
                                        field.onChange(selectedOption);
                                      }}
                                      isLoading={isOnline ? isOperationLoading : offlineOperationList.isLoading}
                                      options={isOnline ? operationOptions : offlineOperationList.operations}
                                      styles={reactSelectStyles}
                                      isSearchable={true}
                                      menuPortalTarget={document.body}
                                    />
                                  )}
                                />
                                <FormErrorMessage>
                                  {!!methods.formState.errors?.operation &&
                                    !!methods.formState.errors?.operation
                                      ?.message}
                                </FormErrorMessage>
                              </FormControl>
                            </GridItem>
                          </Grid>
                          <ButtonGroup>
                            <Button
                              colorScheme="primary"
                              type="submit"
                              isLoading={addData.isLoading}>
                              Add
                            </Button>
                            <Button variant="outline" onClick={onAddModalClose}>
                              Cancel
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      </form>
                    </FormProvider>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Tbody>
          </Table>
          {!productListQuery && <CenterSpinner type="normal" />}
        </TableContainer>

        <Flex p={['3', '6']}>
          <Button
            variant={'outline'}
            onClick={() => {
              methods.reset();
              setAddModalOpen(true);
            }}>
            Add Product
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default ProductionReport;
