import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  ScaleFade,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import {
  getAccountProfile,
  updateProfileEmail,
  updateProfileName,
  updateProfilePassword,
} from 'api/profile';
import {
  ChangePasswordInput,
  EmailInformationForm,
  ProfileInformationForm,
} from 'interface/user/userInformation';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiHide, BiShow } from 'react-icons/bi';
import { validatePassword, validEmail } from 'utils/validate';

const Setting: React.FC = () => {
  const [isChangePasswordShow, setIsChangePasswordShow] =
    useState<boolean>(false);
  const [viewOldPassword, setViewOldPassword] = useState<boolean>(false);
  const [viewNewPassword, setViewNewPassword] = useState<boolean>(false);
  const [viewConfirmPassword, setViewConfirmPassword] =
    useState<boolean>(false);
  const [showChangeName, setShowChangeName] = useState<boolean>(false);
  const [showChangeEmail, setShowChangeEmail] = useState<boolean>(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
    getValues,
    reset,
  } = useForm<ChangePasswordInput>();
  const emailMethods = useForm<EmailInformationForm>();
  const nameMethods = useForm<ProfileInformationForm>();

  interface NameDetails {
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
  }

  const [nameDetails, setNameDetails] = useState<NameDetails>({
    fullName: '...',
    firstName: '...',
    lastName: '...',
    email: '...',
  });
  const [isLoadingNameDetails, setIsLoadingNameDetails] =
    useState<boolean>(false);

  const [showGeneralSuccess, setShowGeneralSuccess] = useState<boolean>(false);
  const [generalSuccessMessage, setGeneralSuccessMessage] =
    useState<string>('');
  const [showGeneralError, setShowGeneralError] = useState<boolean>(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState<string>('');

  const [showProfileSuccess, setShowProfileSuccess] = useState<boolean>(false);
  const [profileSuccessMessage, setProfileSuccessMessage] =
    useState<string>('');
  const [showProfileError, setShowProfileError] = useState<boolean>(false);
  const [profileErrorMessage, setProfileErrorMessage] = useState<string>('');

  const [isNameSubmitting, setIsNameSubmitting] = useState<boolean>(false);
  const [isEmailSubmitting, setIsEmailSubmitting] = useState<boolean>(false);
  const [isPasswordSubmitting, setIsPasswordSubmitting] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoadingNameDetails(true);

    getAccountProfile()
      .then((response) => {
        const userProfile = response.data.user_profile;

        setNameDetails({
          fullName: userProfile.first_name + ' ' + userProfile.last_name,
          firstName: userProfile.first_name,
          lastName: userProfile.last_name,
          email: userProfile.email,
        });

        nameMethods.setValue(
          'fullName',
          userProfile.first_name + ' ' + userProfile.last_name
        );
        nameMethods.setValue('firstName', userProfile.first_name);
        nameMethods.setValue('lastName', userProfile.last_name);

        emailMethods.setValue('newEmail', userProfile.email);
      })
      .catch((_) => _)
      .finally(() => setIsLoadingNameDetails(false));
  }, [nameMethods, emailMethods]);

  const openChangePassword = () => {
    reset();
    setIsChangePasswordShow(true);

    setShowProfileSuccess(false);
    setProfileSuccessMessage('');
    setShowProfileError(false);
    setProfileErrorMessage('');
  };

  const openChangeName = () => {
    setShowChangeName(!showChangeName);

    setShowGeneralSuccess(false);
    setGeneralSuccessMessage('');
    setShowGeneralError(false);
    setGeneralErrorMessage('');
  };

  const cancelChangeName = () => {
    nameMethods.reset();
    nameMethods.setValue('fullName', nameDetails.fullName);
    nameMethods.setValue('firstName', nameDetails.firstName);
    nameMethods.setValue('lastName', nameDetails.lastName);
    setShowChangeName(false);
  };

  const openChangeEmail = () => {
    setShowChangeEmail(!showChangeEmail);

    setShowGeneralSuccess(false);
    setGeneralSuccessMessage('');
    setShowGeneralError(false);
    setGeneralErrorMessage('');
  };

  const cancelChangeEmail = () => {
    emailMethods.reset();
    emailMethods.setValue('newEmail', nameDetails.email);
    setShowChangeEmail(false);
  };

  const cancelChangeLogin = () => {
    nameMethods.reset();
    nameMethods.setValue('fullName', nameDetails.fullName);
    nameMethods.setValue('firstName', nameDetails.firstName);
    nameMethods.setValue('lastName', nameDetails.lastName);
    emailMethods.setValue('newEmail', nameDetails.email);
    setIsChangePasswordShow(false);
  };

  const onSubmitLogin = async (data: ChangePasswordInput) => {
    setIsPasswordSubmitting(true);

    updateProfilePassword({
      old_password: data.oldPassword,
      new_password: data.newPassword,
      confirm_password: data.confirmPassword,
    })
      .then((_) => {
        setShowProfileSuccess(true);
        setProfileSuccessMessage('Succesfully updated password.');
      })
      .catch((_) => {
        setShowProfileError(true);
        setProfileErrorMessage('Error while updating password.');
      })
      .finally(() => {
        reset();
        setIsPasswordSubmitting(false);
        setIsChangePasswordShow(false);
      });
  };

  const onSubmitName = async (data: ProfileInformationForm) => {
    setIsNameSubmitting(true);

    updateProfileName({ first_name: data.firstName, last_name: data.lastName })
      .then((_) => {
        setNameDetails((oldNameDetails) => {
          return {
            ...oldNameDetails,
            fullName: data.firstName + ' ' + data.lastName,
            firstName: data.firstName,
            lastName: data.lastName,
          };
        });

        nameMethods.setValue('fullName', data.fullName);
        nameMethods.setValue('firstName', data.firstName);
        nameMethods.setValue('lastName', data.lastName);

        setShowGeneralSuccess(true);
        setGeneralSuccessMessage('Succesfully updated name.');
      })
      .catch((_) => {
        setShowGeneralError(true);
        setGeneralErrorMessage('Error while updating name.');
      })
      .finally(() => {
        setIsNameSubmitting(false);
        setShowChangeName(false);
      });
  };

  const onSubmitEmail = async (data: EmailInformationForm) => {
    setIsEmailSubmitting(true);

    updateProfileEmail({ email: data.newEmail })
      .then((_) => {
        setNameDetails((oldNameDetails) => {
          return {
            ...oldNameDetails,
            email: data.newEmail,
          };
        });
        emailMethods.setValue('newEmail', data.newEmail);

        setShowGeneralSuccess(true);
        setGeneralSuccessMessage('Succesfully updated email.');
      })
      .catch((_) => {
        setShowGeneralError(true);
        setGeneralErrorMessage('Error while updating email.');
      })
      .finally(() => {
        setIsEmailSubmitting(false);
        setShowChangeEmail(false);
      });
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">Settings</Heading>
      </Flex>
      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <Heading size="md" ml={6}>
          General Profile Information
        </Heading>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '20%', padding: '1.5rem' }}>Name</div>
          {!showChangeName && (
            <div style={{ width: '80%', display: 'flex' }}>
              <div style={{ width: '77%', padding: '1.5rem' }}>
                {isLoadingNameDetails && <Spinner />}
                {!isLoadingNameDetails && <p>{nameDetails.fullName}</p>}
              </div>
              <Button
                onClick={() => openChangeName()}
                isLoading={isLoadingNameDetails}>
                Edit
              </Button>
            </div>
          )}
          {showChangeName && (
            <div style={{ width: '60%' }}>
              <form onSubmit={nameMethods.handleSubmit(onSubmitName)}>
                <div>
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!nameMethods.formState.errors.firstName}>
                    <FormLabel mt={'1rem'}>First Name</FormLabel>
                    <InputGroup display={'block'}>
                      <div
                        style={{
                          display: 'flex',
                          position: 'relative',
                          width: '100%',
                        }}>
                        <Input
                          width={'100%'}
                          placeholder="First Name"
                          type={'text'}
                          {...nameMethods.register('firstName', {
                            required: 'First Name',
                          })}
                        />
                      </div>
                      <FormErrorMessage>
                        {nameMethods.formState.errors.firstName &&
                          nameMethods.formState.errors.firstName?.message}
                      </FormErrorMessage>
                    </InputGroup>
                  </FormControl>
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!nameMethods.formState.errors.lastName}>
                    <FormLabel mt={'1rem'}>Last Name</FormLabel>
                    <InputGroup display={'block'}>
                      <div
                        style={{
                          display: 'flex',
                          position: 'relative',
                          width: '100%',
                        }}>
                        <Input
                          width={'100%'}
                          placeholder="Last Name"
                          type={'text'}
                          {...nameMethods.register('lastName', {
                            required: 'Last Name',
                          })}
                        />
                      </div>
                      <FormErrorMessage>
                        {nameMethods.formState.errors.lastName &&
                          nameMethods.formState.errors.lastName?.message}
                      </FormErrorMessage>
                    </InputGroup>
                  </FormControl>
                </div>
                <Button
                  type="submit"
                  mt={'1rem'}
                  isLoading={
                    nameMethods.formState.isSubmitting || isNameSubmitting
                  }>
                  Save
                </Button>
                <Button
                  mt={'1rem'}
                  ml={'1rem'}
                  onClick={() => {
                    cancelChangeName();
                  }}
                  disabled={isNameSubmitting}>
                  Cancel
                </Button>
              </form>
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '20%', padding: '1.5rem' }}>Email</div>
          {!showChangeEmail && (
            <div style={{ width: '80%', display: 'flex' }}>
              <div style={{ width: '77%', padding: '1.5rem' }}>
                {isLoadingNameDetails && <Spinner />}
                {!isLoadingNameDetails && <p>{nameDetails.email}</p>}
              </div>
              <Button
                onClick={() => openChangeEmail()}
                isLoading={isLoadingNameDetails}>
                Edit
              </Button>
            </div>
          )}
          {showChangeEmail && (
            <div style={{ width: '60%' }}>
              <form onSubmit={emailMethods.handleSubmit(onSubmitEmail)}>
                <div>
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!emailMethods.formState.errors.newEmail}>
                    <FormLabel mt={'1rem'}>Email</FormLabel>
                    <InputGroup display={'block'}>
                      <div
                        style={{
                          display: 'flex',
                          position: 'relative',
                          width: '100%',
                        }}>
                        <Input
                          width={'100%'}
                          placeholder="Email"
                          type={'text'}
                          {...emailMethods.register('newEmail', {
                            required: 'Email',
                            validate: (value) =>
                              validEmail(value) || 'Please provide email.',
                          })}
                        />
                      </div>
                      <FormErrorMessage>
                        {emailMethods.formState.errors.newEmail &&
                          emailMethods.formState.errors.newEmail?.message}
                      </FormErrorMessage>
                    </InputGroup>
                  </FormControl>
                </div>
                <Button
                  type="submit"
                  mt={'1rem'}
                  isLoading={
                    emailMethods.formState.isSubmitting || isEmailSubmitting
                  }>
                  Save
                </Button>
                <Button
                  mt={'1rem'}
                  ml={'1rem'}
                  onClick={() => {
                    cancelChangeEmail();
                  }}
                  disabled={isEmailSubmitting}>
                  Cancel
                </Button>
              </form>
            </div>
          )}
        </div>
        {showGeneralSuccess && (
          <ScaleFade in={showGeneralSuccess}>
            <Alert status="success">
              <AlertIcon />
              {generalSuccessMessage}
            </Alert>
          </ScaleFade>
        )}
        {showGeneralError && (
          <ScaleFade in={showGeneralError}>
            <Alert status="error">
              <AlertIcon />
              {generalErrorMessage}
            </Alert>
          </ScaleFade>
        )}
      </Box>
      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <Heading size="md" ml={6}>
          Login
        </Heading>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '20%', padding: '1.5rem' }}>Change Password</div>
          {!isChangePasswordShow && (
            <div style={{ width: '80%', display: 'flex' }}>
              <div style={{ width: '77%', padding: '1rem' }}>
                Use a strong password that you&#8217;re not using elsewhere
              </div>
              <Button onClick={() => openChangePassword()}>Edit</Button>
            </div>
          )}
          {isChangePasswordShow && (
            <div style={{ width: '60%' }}>
              <form onSubmit={handleSubmit(onSubmitLogin)}>
                <div>
                  <div>
                    Use 8 or more characters with a mix of letters, numbers and
                    characters
                  </div>
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!errors.oldPassword}>
                    <FormLabel mt={'1rem'}>Old Password</FormLabel>
                    <InputGroup display={'block'}>
                      <div
                        style={{
                          display: 'flex',
                          position: 'relative',
                          width: '100%',
                        }}>
                        <Input
                          width={'100%'}
                          placeholder="Old Password"
                          type={viewOldPassword ? 'text' : 'password'}
                          {...register('oldPassword', {
                            required: 'Old Password',
                          })}
                        />
                        <InputRightElement>
                          <IconButton
                            size="sm"
                            icon={viewOldPassword ? <BiHide /> : <BiShow />}
                            title={
                              viewOldPassword
                                ? 'Hide Password'
                                : 'Show Password'
                            }
                            onClick={() => {
                              setViewOldPassword(!viewOldPassword);
                            }}
                            aria-label={viewOldPassword ? 'Hide' : 'Show'}
                          />
                        </InputRightElement>
                      </div>
                      <FormErrorMessage>
                        {errors.oldPassword && errors.oldPassword?.message}
                      </FormErrorMessage>
                    </InputGroup>
                  </FormControl>
                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!errors.newPassword}>
                    <FormLabel mt={'1rem'}>Password</FormLabel>
                    <InputGroup display={'block'}>
                      <div
                        style={{
                          display: 'flex',
                          position: 'relative',
                          width: '100%',
                        }}>
                        <Input
                          width={'100%'}
                          placeholder="New Password"
                          type={viewNewPassword ? 'text' : 'password'}
                          {...register('newPassword', {
                            required: 'New Password',
                            validate: (value) =>
                              validatePassword(value) ||
                              'Please provide valid password.',
                          })}
                        />
                        <InputRightElement>
                          <IconButton
                            size="sm"
                            icon={viewNewPassword ? <BiHide /> : <BiShow />}
                            title={
                              viewNewPassword
                                ? 'Hide Password'
                                : 'Show Password'
                            }
                            onClick={() => {
                              setViewNewPassword(!viewNewPassword);
                            }}
                            aria-label={viewNewPassword ? 'Hide' : 'Show'}
                          />
                        </InputRightElement>
                      </div>
                      <FormErrorMessage>
                        {errors.newPassword && errors.newPassword.message}
                      </FormErrorMessage>
                    </InputGroup>
                  </FormControl>

                  <FormControl
                    colorScheme="primary"
                    isInvalid={!!errors.confirmPassword}>
                    <FormLabel mt={'1rem'}>Confirm Password</FormLabel>
                    <InputGroup display={'block'}>
                      <div
                        style={{
                          display: 'flex',
                          position: 'relative',
                          width: '100%',
                        }}>
                        <Input
                          width={'100%'}
                          placeholder="Confirm Password"
                          type={viewConfirmPassword ? 'text' : 'password'}
                          {...register('confirmPassword', {
                            required: 'Confirm Password',
                            validate: (value) =>
                              value === getValues('newPassword') ||
                              'The passwords do not match.',
                          })}
                        />
                        <InputRightElement>
                          <IconButton
                            size="sm"
                            icon={viewConfirmPassword ? <BiHide /> : <BiShow />}
                            title={
                              viewConfirmPassword
                                ? 'Hide Password'
                                : 'Show Password'
                            }
                            onClick={() => {
                              setViewConfirmPassword(!viewConfirmPassword);
                            }}
                            aria-label={viewConfirmPassword ? 'Hide' : 'Show'}
                          />
                        </InputRightElement>
                      </div>
                      <FormErrorMessage>
                        {errors.confirmPassword &&
                          errors.confirmPassword.message}
                      </FormErrorMessage>
                    </InputGroup>
                  </FormControl>
                </div>
                <Button
                  type="submit"
                  mt={'1rem'}
                  isLoading={formState.isSubmitting || isPasswordSubmitting}>
                  Save
                </Button>
                <Button
                  mt={'1rem'}
                  ml={'1rem'}
                  onClick={() => {
                    cancelChangeLogin();
                  }}
                  disabled={isPasswordSubmitting}>
                  Cancel
                </Button>
              </form>
            </div>
          )}
        </div>
        {showProfileSuccess && (
          <ScaleFade in={showProfileSuccess}>
            <Alert status="success">
              <AlertIcon />
              {profileSuccessMessage}
            </Alert>
          </ScaleFade>
        )}
        {showProfileError && (
          <ScaleFade in={showProfileError}>
            <Alert status="error">
              <AlertIcon />
              {profileErrorMessage}
            </Alert>
          </ScaleFade>
        )}
      </Box>
      {/* <HideControl
        hideFor="user-information"
        renderNoAccess={(data: any) => {
          return data ? data : '';
        }}>
        <UserPermission />
      </HideControl> */}
    </Stack>
  );
};

export default Setting;
