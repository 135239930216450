import {
  Box,
  Button,
  ButtonGroup,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react';
import {
  setActivityList,
  setCustomerList,
  setEmployeeList,
  setEquipmentList,
  setEquipmentTypeList,
  setLaborList,
  setOperationDowntimeCodeList,
  setProductList,
  setShiftDowntimeCodeList,
  setVendorList,
} from 'actions/data/report';
import ReportResource from 'api/report';
import AssignedEquipmentReport from 'components/report/AssignedEquipmentReport';
import EquipmentRepair from 'components/report/EquipmentRepairReport';
import MechanicalEquipmentReport from 'components/report/MechanicalEquipmentReport';
import MechanicalLaborReport from 'components/report/MechanicalLaborReport';
import MechanicalNotesReport from 'components/report/MechanicalNotesReport';
import MechanicalOperationalDetailReport from 'components/report/MechanicalOperationalDetailReport';
import MechanicalRentalReport from 'components/report/MechanicalRentalReport';
import MechanicalReportSidebar from 'components/report/MechanicalReportSidebar';
import PartsReport from 'components/report/PartsReport';
import { OfflineContext } from 'context/OfflineContext';
import useConnectionStatus from 'hooks/useConnectionStatus';
import useReportDropDown from 'hooks/useReportDropDown';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

const MechanicalReport: React.FC = () => {
  const [reportId, setReportId] = useState<string>('');
  const [reportName, setReportName] = useState<string>('');
  const params = new URLSearchParams(location.search);
  const reportIdParams = params.get('report_id') || '';
  const api = new ReportResource();
  const { queue } = useContext(OfflineContext);
  const isOnline = useConnectionStatus();

  const navigate = useNavigate();

  // useQuery(
  //   [`isExistMechanicId${reportIdParams}`, reportIdParams],
  //   () =>
  //     api
  //       .getMechanic(reportIdParams)
  //       .then((res) => {
  //         return res?.data;
  //       })
  //       .catch((err) => {
  //         alert('error');
  //         navigate(-1);
  //       }),
  //   {
  //     cacheTime: 0,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    setReportId(reportIdParams);
  }, [reportIdParams]);

  const dispatch = useDispatch();
  const {
    isVendorLoading,
    vendorOptions,
    isEquipmentLoading,
    equipmentOptions,
    isEquipmentTypeLoading,
    equipmentTypeOptions,
    isCustomerLoading,
    customerOptions,
    isProductLoading,
    productOptions,
    isOperationDownTimeCodeLoading,
    operationDownTimeCodeOptions,
    isShiftDownTimeCodeLoading,
    shiftDownTimeCodeOptions,
    isEmployeeLoading,
    employeeOptions,
    isActivityLoading,
    activityOptions,
    isLaborLoading,
    laborOptions,
  } = useReportDropDown();
  useEffect(() => {
    dispatch(setVendorList({ isVendorLoading, vendorOptions }));
    dispatch(setEquipmentList({ isEquipmentLoading, equipmentOptions }));
    dispatch(
      setEquipmentTypeList({ isEquipmentTypeLoading, equipmentTypeOptions })
    );

    dispatch(setCustomerList({ isCustomerLoading, customerOptions }));
    dispatch(setProductList({ isProductLoading, productOptions }));
    dispatch(
      setOperationDowntimeCodeList({
        isOperationDownTimeCodeLoading,
        operationDownTimeCodeOptions,
      })
    );
    dispatch(
      setShiftDowntimeCodeList({
        isShiftDownTimeCodeLoading,
        shiftDownTimeCodeOptions,
      })
    );
    dispatch(setEmployeeList({ isEmployeeLoading, employeeOptions }));
    dispatch(setActivityList({ isActivityLoading, activityOptions }));
    dispatch(setLaborList({ isLaborLoading, laborOptions }));
  });
  const toast = useToast();

  const reportSave: any = useMutation(
    () => api.mechanicalStoreReportSave(reportId, { is_saved: true }),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Report has been saved.',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => { },
    }
  );

  const handleSave = () => reportSave.mutate();

  const reportComplete = useMutation(
    () => api.mechanicalStoreReportComplete(reportId, { is_completed: true }),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Report has been completed.',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (error: any) => {
        toast({
          title: error?.response?.data?.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const handleComplete = () => {
    if (isOnline) {
      reportComplete.mutate()
    } else {
      toast({
        title: 'To complete the report, please go online',
        status: 'info',
        isClosable: true,
      });
    }
  };

  // if (isExistMechanicId.isError) {
  //   navigate(-1);
  // }

  // if (isExistMechanicId.isLoading) {
  //   return <CenterSpinner />;
  // }z

  return (
    <>
      <SimpleGrid
        columns={[1, null, 2]}
        spacing="15px"
        gridTemplateColumns={{ base: 'repeat(1,1fr)', lg: '20% 80%' }}>
        <Box>
          <MechanicalReportSidebar title={reportName} reportId={+reportId} />
        </Box>
        <Box>
          <MechanicalOperationalDetailReport
            setReportId={setReportId}
            setReportName={setReportName}
          />
          {reportId && (
            <MechanicalLaborReport
              submitButtonLabel="Add Mechanic"
              reportId={reportId}
            />
          )}
          {reportId && <PartsReport reportId={reportId} />}
          {reportId && <EquipmentRepair reportId={reportId} />}
          {reportId && <AssignedEquipmentReport reportId={reportId} />}
          {reportId && <MechanicalRentalReport reportId={reportId} />}
          {reportId && <MechanicalEquipmentReport reportId={reportId} />}
          {reportId && (
            <MechanicalNotesReport
              title="Mechanics Journal"
              reportId={reportId}
            />
          )}
          {reportId && (
            <Box mt={3}>
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  onClick={handleComplete}
                  isLoading={reportComplete.isLoading}>
                  Complete
                </Button>
                <Button
                  colorScheme="primary"
                  onClick={handleSave}
                  isLoading={reportSave.isLoading}>
                  Save
                </Button>
              </ButtonGroup>
            </Box>
          )}
        </Box>
      </SimpleGrid>
    </>
  );
};

export default MechanicalReport;
