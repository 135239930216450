import Resource from 'api/resource';
import http from 'utils/http';

class LaborReportResource extends Resource {
  constructor() {
    super('reports');
  }

  getLaborActivityEquipment(reportId: any, query?: any) {
    return http({
      url: `/reports/${reportId}/labor-activity-equipments/`,
      method: 'get',
      params: query,
    });
  }

  // Note Tag
  getNoteTag(id: string, query?: any) {
    return http({
      url: `/reports/${id}/labor-tag/`,
      method: 'get',
      params: query,
    });
  }

  // Mechanical Note Tag
  getMechanicalNoteTag(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/labor-tag/`,
      method: 'get',
      params: query,
    });
  }

  listLaborReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/labors`,
      method: 'get',
      params: query,
    });
  }
  getLaborReport(id: string, laborId: string) {
    return http({
      url: `/reports/${id}/labors/${laborId}`,
      method: 'get',
    });
  }
  storeLaborReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/labors`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateLaborReport(id: string, laborId: string, resource: any) {
    return http({
      url: `/reports/${id}/labors/${laborId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyLaborReport(id: string, laborId: string) {
    return http({
      url: `/reports/${id}/labors/${laborId}`,
      method: 'delete',
    });
  }

  // Mechanical Report
  mechanicalListLaborReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/labors`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetLaborReport(id: string, laborId: string) {
    return http({
      url: `/mechanic-reports/${id}/labors/${laborId}`,
      method: 'get',
    });
  }
  mechanicalStoreLaborReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/mechanic-reports/${id}/labors`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdateLaborReport(id: string, laborId: string, resource: any) {
    return http({
      url: `/mechanic-reports/${id}/labors/${laborId}`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyLaborReport(id: string, laborId: string) {
    return http({
      url: `/mechanic-reports/${id}/labors/${laborId}`,
      method: 'delete',
    });
  }
}

export { LaborReportResource as default };
