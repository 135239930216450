import Resource from 'api/resource';
import http from 'utils/http';

class NoteReportResource extends Resource {
  constructor() {
    super('reports');
  }

  // Note Tag
  getNoteTag(id: string, query?: any) {
    return http({
      url: `/reports/${id}/note-tag/`,
      method: 'get',
      params: query,
    });
  }
  //Mechanical Note Tag
  getMechanicalNoteTag(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/note-tag/`,
      method: 'get',
      params: query,
    });
  }

  // Note Report
  listNoteReport(id: string, query?: any) {
    return http({
      url: `/reports/${id}/notes`,
      method: 'get',
      params: query,
    });
  }
  getNoteReport(id: string, noteId: string) {
    return http({
      url: `/reports/${id}/notes/${noteId}`,
      method: 'get',
    });
  }
  storeNoteReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/reports/${id}/notes`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateNoteReport(id: string, noteId: string, resource: any) {
    return http({
      url: `/reports/${id}/notes/${noteId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyNoteReport(id: string, noteId: string) {
    return http({
      url: `/reports/${id}/notes/${noteId}`,
      method: 'delete',
    });
  }

  // Mechanical Note Report
  mechanicalListNoteReport(id: string, query?: any) {
    return http({
      url: `/mechanic-reports/${id}/notes`,
      method: 'get',
      params: query,
    });
  }
  mechanicalGetNoteReport(id: string, noteId: string) {
    return http({
      url: `/mechanic-reports/${id}/notes/${noteId}`,
      method: 'get',
    });
  }
  mechanicalStoreNoteReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/mechanic-reports/${id}/notes`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  mechanicalUpdateNoteReport(id: string, noteId: string, resource: any) {
    return http({
      url: `/mechanic-reports/${id}/notes/${noteId}`,
      method: 'put',
      data: resource,
    });
  }
  mechanicalDestroyNoteReport(id: string, noteId: string) {
    return http({
      url: `/mechanic-reports/${id}/notes/${noteId}`,
      method: 'delete',
    });
  }
}

export { NoteReportResource as default };
