import { OfflineAction, OfflineActionTypes } from 'actions/data/offline';

const INITIAL_STATE = {
  mechanicalReport: [],
  foremanReport: [],
  labors: {
    laborsList: [],
    isLaborLoading: false,
  },
  capital_equipment: {
    operated_equipment: [],
    nonoperated_equipment: [],
    other_equipment: []
  },
  business_units: [],
  equipments: [],
  t_equipment: [],
  operations: [],
  activities: [],
  products: [],
  vendors: [],
  shift_downtime_codes: [],
  operation_downtime_codes: [],
  employees: [],
  fuel_usage: [],
};

export default function (state = INITIAL_STATE, action: OfflineAction) {
  switch (action.type) {
    case OfflineActionTypes.SET_FOREMAN_REPORT:
      return {
        ...state,
        foremanReport: action.data,
      };
    case OfflineActionTypes.SET_MECHANICAL_REPORT:
      return {
        ...state,
        mechanicalReport: action.data,
      };
    case OfflineActionTypes.SET_LABORS:
      return {
        ...state,
        labors: action.data,
      };
    case OfflineActionTypes.SET_BUSINESS_UNITS:
      return {
        ...state,
        business_units: action.data,
      };
    case OfflineActionTypes.SET_EQUIPMENTS:
      return {
        ...state,
        equipments: action.data,
      };
    case OfflineActionTypes.SET_EQUIPMENT_TYPES:
      return {
        ...state,
        t_equipment: action.data,
      };
    case OfflineActionTypes.SET_LABOUR_ACTIVITIES:
      return {
        ...state,
        activities: action.data,
      };
    case OfflineActionTypes.SET_OPERATIONS:
      return {
        ...state,
        operations: action.data,
      };
    case OfflineActionTypes.SET_EMPLOYEES:
      return {
        ...state,
        employees: action.data,
      };
    case OfflineActionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.data,
      };
    case OfflineActionTypes.SET_VENDOR:
      return {
        ...state,
        vendors: action.data,
      };
    case OfflineActionTypes.SET_OPERATION_DOWNTIME_CODES:
      return {
        ...state,
        operation_downtime_codes: action.data,
      };
    case OfflineActionTypes.SET_SHIFT_DOWNTIME_CODES:
      return {
        ...state,
        shift_downtime_codes: action.data,
      };
    case OfflineActionTypes.SET_FUEL_USAGE:
      return {
        ...state,
        fuel_usage: action.data,
      };
    case OfflineActionTypes.SET_CAPITAL_EQUIPMENT:
      return {
        ...state,
        capital_equipment: action.data,
      };
    default:
      return state;
  }
}
