import Resource from 'api/resource';
import http from 'utils/http';

class LoadoutDetailResource extends Resource {
  constructor() {
    super('loadout-report');
  }

  listLoadoutReport(id: string, query?: any) {
    return http({
      url: `/loadout-report/${id}/details`,
      method: 'get',
      params: query,
    });
  }
  getLoadoutReport(id: string, laborId: string) {
    return http({
      url: `/loadout-report/${id}/details/${laborId}`,
      method: 'get',
    });
  }
  storeLoadoutReport(id: string, resource: any, headers?: any) {
    return http({
      url: `/loadout-report/${id}/details`,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  updateLoadoutReport(id: string, laborId: string, resource: any) {
    return http({
      url: `/loadout-report/${id}/details/${laborId}`,
      method: 'put',
      data: resource,
    });
  }
  destroyLoadoutReport(id: string, laborId: string) {
    return http({
      url: `/loadout-report/${id}/details/${laborId}`,
      method: 'delete',
    });
  }
}

export { LoadoutDetailResource as default };
