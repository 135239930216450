import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CompanyResource from 'api/company';
import CompanyForm from 'components/company/CompanyForm';
import routes from 'constants/routes';
import { CompanySchema } from 'interface/company/companySchema';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { buildFormData } from 'utils';

const CreateCompany: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const navigate = useNavigate();
  const methods = useForm<CompanySchema>();
  const api = new CompanyResource();

  const [errorMessage, setErrorMessage] = useState<any>();

  const addCompany = useMutation(
    (data: any) =>
      api.store(data, {
        'content-type': 'multipart/form-data',
      }),
    {
      onSuccess: (res: any) => {
        toast({
          title: 'Company has been created.',
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('companyList');
        navigate(routes.companies.list);
      },
      onError: (error: any) => {
        const objKey: any = Object.keys(error?.response?.data);
        if (objKey) setErrorMessage(error?.response?.data[objKey[0]][0]);
        else setErrorMessage('Company Creation Failed.');
      },
    }
  );

  const onSubmit = (data: any) => {
    const company = buildFormData(data);
    addCompany.mutate(company);
  };

  return (
    <Stack direction="column" spacing="4">
      <Flex justify="space-between">
        <Heading size="md">Add Company</Heading>
      </Flex>

      <Box bg="white" p={['3', '6']} shadow="box" rounded="sm">
        <FormProvider {...methods}>
          <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addCompany.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {errorMessage}
                </Alert>
              )}
              <CompanyForm />
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  type="submit"
                  isLoading={addCompany.isLoading}>
                  Add Company
                </Button>
                <Button variant="outline" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Stack>
  );
};

export default CreateCompany;
