import { Switch, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';
import EquipmentRepairResource from 'api/equipmentRepair';
import { reactSelectStyles } from 'assets/css/commonStyles';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactSelect from 'react-select';

interface Props {
  equipmentRepair: any;
  reportId: string;
}
const EquipmentRepairDetailReport: React.FC<Props> = (props) => {
  const { equipmentRepair, reportId } = props;
  const equipmentRepairDetailAPI = new EquipmentRepairResource();
  const queryClient = useQueryClient();

  const { data: repairReasonListQuery, isLoading: isrRepairReasonListLoading } =
    useQuery(
      [`repair-reason-list`],
      () =>
        equipmentRepairDetailAPI
          .getRepairReasons({
            limit: 5000,
          })
          .then((res) => {
            return res?.data;
          }),
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );
  const repairReasonList = repairReasonListQuery?.results;
  const repairReasonOptions = repairReasonList?.map((repairReason: any) => ({
    label: repairReason.name,
    value: repairReason.id,
  }));

  const updateData = useMutation(
    (data: any) =>
      equipmentRepairDetailAPI.mechanicalUpdateEquipmentRepairDetailReport(
        reportId,
        data?.id,
        data?.body
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('equipment-repair-list');
      },
    }
  );

  const handleChange = (
    fieldData: any,
    fieldValue: string,
    fieldType: string
  ) => {
    let data: any = {
      body: {
        parts_cost: 0,
        labor_cost: 0,
        repair_reasons: 0,
        operator_error: false,
        note: '',
      },
      id: 0,
    };
    data.id = fieldData.id;
    switch (fieldType) {
      case 'repair-reason':
        data.body.parts_cost = fieldData.parts_cost;
        data.body.labor_cost = fieldData.labor_cost;
        data.body.repair_reasons = +fieldValue;
        data.body.operator_error = fieldData.operator_error;
        data.body.note = fieldData.note;
        break;
      case 'operator-error':
        data.body.parts_cost = fieldData.parts_cost;
        data.body.labor_cost = fieldData.labor_cost;
        data.body.repair_reasons = fieldData.repair_reasons?.id;
        data.body.operator_error = fieldValue;
        data.body.note = fieldData.note;
        break;
    }
    updateData.mutate(data);
  };

  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            <Tr bg={'gray.300'}>
              <Td>Repair reason</Td>
              <Td>Operator Error?</Td>
              <Td></Td>
            </Tr>

            <Tr bg={'white'}>
              <Td>
                <ReactSelect
                  defaultValue={{
                    label: equipmentRepair?.repair_reasons?.name,
                    value: equipmentRepair?.repair_reasons?.id,
                  }}
                  placeholder={'Select'}
                  isLoading={isrRepairReasonListLoading}
                  options={repairReasonOptions}
                  styles={reactSelectStyles}
                  isSearchable={true}
                  onChange={(e: any) =>
                    handleChange(equipmentRepair, e.value, 'repair-reason')
                  }
                  menuPortalTarget={document.body}
                />
              </Td>
              <Td>
                <Switch
                  id="operator-error"
                  defaultChecked={equipmentRepair?.operator_error}
                  colorScheme="teal"
                  size="lg"
                  onChange={(e: any) =>
                    handleChange(
                      equipmentRepair,
                      e.target.checked,
                      'operator-error'
                    )
                  }
                />
              </Td>

              <Td>
                {/* <Tooltip hasArrow label={'Tag'}>
                  <IconButton
                    icon={<BsTag />}
                    variant="link"
                    aria-label={'TAg'}
                    color="gray.300"
                    size="lg"
                    // onClick={()}
                  />
                </Tooltip> */}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EquipmentRepairDetailReport;
