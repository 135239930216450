import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  Heading,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import LoadoutReportResource from 'api/loadoutReport';
import { CenterSpinner } from 'components/common/CenterSpinner';
import NoteModal from 'components/common/NoteModal';
import TagModal from 'components/common/TagModal';
import { ReportProps } from 'interface/report/reportSchema';
import React, { useEffect, useRef, useState } from 'react';
import { BsTag } from 'react-icons/bs';
import { MdOutlineModeComment } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import LoadoutDetailReport from './LoadoutDetailReport';

const LoadoutReport: React.FC<ReportProps> = (props) => {
  const { reportId } = props;
  const [openIndex, setOpenIndex] = useState(0);
  const containerRef = useRef<any>();
  const api = new LoadoutReportResource();
  const [errorMessage, setErrorMessage] = useState<any>();
  const queryClient = useQueryClient();
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [noteDetailModalOpen, setNoteDetailModalOpen] = useState(false);
  const [noteDetailFieldData, setNoteDetailFieldData] = useState();
  const [tagData, setTagData] = useState(0);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagDetailModalOpen, setTagDetailModalOpen] = useState(false);

  const loadoutStatusQuery = useQuery(
    [`getLoadoutStatus`],
    async () => {
      const response = await api.listLoadoutReport(reportId, {
        limit: 5000,
      });
      return response?.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: loadoutListQuery, isLoading: isLoadoutListLoading } = useQuery(
    [`loadout-list`],
    () =>
      api
        .listLoadoutDetailReport(reportId, {
          limit: 5000,
        })
        .then((res) => {
          return res?.data;
        }),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const handleToggle = (index: number) => {
    setOpenIndex(index);
    let display = document.querySelector<HTMLElement>(`#loadout${index}`)!.style
      .display;
    if (display === '') display = 'none';
    document.querySelector<HTMLElement>(`#loadout${index}`)!.style.display =
      display === 'none' ? 'table-row' : 'none';
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/loadout')) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.pathname]);

  const updateData = useMutation(
    (data: any) => api.updateLoadoutReport(reportId, data?.body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loadout-list');
      },
    }
  );

  const updatLoadoutDetailData = useMutation(
    (data: any) =>
      api.updateLoadoutDetailReport(reportId, data?.id, data?.body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loadout-list');
      },
    }
  );

  const onNoteDetailModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      body: {
        note: modalValue.note,
      },
      id: fieldData?.id,
    };
    updatLoadoutDetailData.mutate(data);
    setNoteDetailModalOpen(false);
  };

  const handleNoteDetailModal = (loadout: any) => {
    setNoteDetailFieldData(loadout);
    setNoteDetailModalOpen(true);
  };

  const handleNoteModal = (data: any) => {
    setNoteModalOpen(true);
  };

  const updateLoadoutData = useMutation(
    (data: any) => api.updateLoadoutReport(reportId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getLoadoutStatus');
      },
    }
  );
  const onNoteModalSubmit = (modalValue: any, fieldData: any) => {
    const data = {
      note: modalValue.note,
    };
    updateLoadoutData.mutate(data);
    setNoteModalOpen(false);
  };

  const onTagModalSubmit = () => {
    setTagModalOpen(false);
    setTagDetailModalOpen(false);
    queryClient.invalidateQueries('equipment-damage-list');
    queryClient.invalidateQueries('loadout-list');
  };

  const handleTagModal = (tagData: any, type: string) => {
    if (tagData?.tag < 0) {
      setTagModalOpen(false);
      setTagDetailModalOpen(false);
      return;
    }
    setTagData(tagData?.tag);
    if (type === 'tagStatus') {
      setTagModalOpen(true);
      setTagDetailModalOpen(false);
    } else {
      if (type === 'tagDetail') {
        setTagModalOpen(false);
        setTagDetailModalOpen(true);
      }
    }
  };

  return (
    <>
      <Box
        border={'1px solid #C5C6C9'}
        rounded="sm"
        mt={5}
        bg={'white'}
        ref={containerRef}>
        <Flex justify="space-between" p={2}>
          <Heading size="md">Loadout</Heading>
          <Stack direction="row" spacing="6">
            <Tooltip hasArrow label={'Tag'}>
              <IconButton
                icon={<BsTag />}
                variant="link"
                aria-label={'Tag'}
                color={'gray.300'}
                size="lg"
                onClick={() =>
                  handleTagModal(loadoutStatusQuery?.data, 'tagStatus')
                }
              />
            </Tooltip>
            {tagData === loadoutStatusQuery?.data?.tag && (
              <TagModal
                isModalOpen={tagModalOpen}
                setModalOpen={setTagModalOpen}
                fieldData={loadoutStatusQuery?.data?.tag}
                onModalSubmit={() => onTagModalSubmit()}
              />
            )}
            <Tooltip hasArrow label={loadoutStatusQuery?.data?.note}>
              <IconButton
                icon={<MdOutlineModeComment />}
                variant="link"
                aria-label={loadoutStatusQuery?.data?.note}
                color={
                  loadoutStatusQuery?.data?.note ? 'primary.600' : 'gray.300'
                }
                size="lg"
                onClick={() => handleNoteModal(loadoutStatusQuery?.data)}
              />
            </Tooltip>
            <NoteModal
              isModalOpen={noteModalOpen}
              setModalOpen={setNoteModalOpen}
              fieldData={loadoutStatusQuery?.data}
              onModalSubmit={(notes: string) =>
                onNoteModalSubmit(notes, loadoutStatusQuery?.data)
              }
              formMutation={updateLoadoutData}
              errorMessage={errorMessage}
            />
          </Stack>
        </Flex>
        <Divider />
        <Stack direction="column" spacing="4" border={'1px solid #C5C6C9'}>
          <TableContainer>
            <Table variant="simple">
              <Tbody>
                <Tr bg={'gray.300'}>
                  <Td>Employee</Td>
                  <Td>Equipment</Td>
                  <Td></Td>
                </Tr>
                {loadoutListQuery?.results?.map(
                  (loadout: any, indexNumber: number) => (
                    <React.Fragment key={loadout?.id}>
                      <Tr>
                        <Td>
                          <Stack direction="row">
                            <Tooltip
                              hasArrow
                              label={openIndex === indexNumber ? 'Down' : 'Up'}>
                              <IconButton
                                icon={
                                  openIndex === indexNumber ? (
                                    <TriangleUpIcon />
                                  ) : (
                                    <TriangleDownIcon />
                                  )
                                }
                                variant="link"
                                aria-label={'Up'}
                                color="gray.300"
                                size="lg"
                                onClick={() => handleToggle(indexNumber)}
                              />
                            </Tooltip>
                            <Text>
                              {loadout?.labor?.first_name}{' '}
                              {loadout?.labor?.last_name}
                            </Text>
                          </Stack>
                        </Td>
                        <Td>{loadout?.equipment?.name}</Td>
                        <Td>
                          <Tooltip hasArrow label={'Tag'}>
                            <IconButton
                              icon={<BsTag />}
                              variant="link"
                              aria-label={'Tag'}
                              color={'gray.300'}
                              size="lg"
                              onClick={() =>
                                handleTagModal(loadout, 'tagDetail')
                              }
                            />
                          </Tooltip>
                          {tagData === loadout?.tag && (
                            <TagModal
                              isModalOpen={tagDetailModalOpen}
                              setModalOpen={setTagDetailModalOpen}
                              fieldData={tagData}
                              onModalSubmit={() => onTagModalSubmit()}
                            />
                          )}
                          <Tooltip hasArrow label={loadout?.note}>
                            <IconButton
                              icon={<MdOutlineModeComment />}
                              variant="link"
                              aria-label={'Note'}
                              color={loadout?.note ? 'primary.600' : 'gray.300'}
                              size="lg"
                              onClick={() => handleNoteDetailModal(loadout)}
                            />
                          </Tooltip>
                          <NoteModal
                            isModalOpen={noteDetailModalOpen}
                            setModalOpen={setNoteDetailModalOpen}
                            fieldData={noteDetailFieldData}
                            onModalSubmit={(notes: string) =>
                              onNoteDetailModalSubmit(
                                notes,
                                noteDetailFieldData
                              )
                            }
                            formMutation={updateData}
                            errorMessage={errorMessage}
                          />
                        </Td>
                      </Tr>
                      <Tr
                        id={`loadout${indexNumber}`}
                        style={{
                          display:
                            openIndex === indexNumber ? 'table-row' : 'none',
                        }}>
                        <Td colSpan={3}>
                          <LoadoutDetailReport
                            loadoutId={loadout?.id}
                            reportId={reportId}
                          />
                        </Td>
                      </Tr>
                    </React.Fragment>
                  )
                )}
              </Tbody>
            </Table>
            {!loadoutListQuery && <CenterSpinner type="normal" />}
          </TableContainer>
        </Stack>
      </Box>
    </>
  );
};

export default LoadoutReport;
